import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import ModalApplyJob from "../ModalApplyJob";
import { FaArrowRight, FaLocationDot, FaRegBookmark, FaUser } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { CgCalendarDates } from "react-icons/cg";
import { useGetJobsDetailsQuery } from "../../app/feature/API/Jobs";
import { useTranslation } from "react-i18next";

const RightSideContent = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const { name } = useParams();
  const { data } = useGetJobsDetailsQuery(name);
  const [slug, setSlug] = useState("");
  const { t , i18n} = useTranslation();

  return (
    <React.Fragment>
      <div className="side-bar ms-lg-4">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17 serv-color">{t('jobOverview')}</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <div className="icon-job-overview">
                    <FaUser className="text-white" />
                  </div>
                  <div className={i18n.language==='en'?"ms-3":"me-3"}>
                    <h6 className="fs-14 mb-0 serv-color">{t('jobTitle')}</h6>
                    <p className="text-muted mb-0">{data?.job?.title}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <div className="icon-job-overview">
                    <FaLocationDot className="text-white" />
                  </div>
                  <div className={i18n.language==='en'?"ms-3":"me-3"}>
                    <h6 className="fs-14 mb-0 serv-color">{t('location')}</h6>
                    <p className="text-muted mb-0">{data?.job?.job_location}</p>
                  </div>
                </div>
              </li>
              <li>
  <div className="d-flex mt-4">
    <div className="icon-job-overview">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 384 512"
        className="text-white"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z" />
      </svg>
    </div>
    <div className={i18n.language==='en'?"ms-3":"me-3"}>
      <h6 className="fs-14 mb-0 serv-color">{i18n.language==='en'?'Industry':'صناعة'}</h6>
      <p className="text-muted mb-0">{data?.job?.jcategory}</p>
    </div>
  </div>
</li>
<li>
  <div className="d-flex mt-4">
    <div className="icon-job-overview">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 640 512"
        className="text-white"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"></path>
      </svg>
    </div>
    <div className={i18n.language==='en'?"ms-3":"me-3"}>
      <h6 className="fs-14 mb-0 serv-color">{i18n.language==='en'?'Qualification':'مؤهل'}</h6>
      <p className="text-muted mb-0">{data?.job?.qualification_name}</p>
    </div>
  </div>
</li>

              <li>
                <div className="d-flex mt-4">
                  <div className="icon-job-overview">
                    <FaHistory className="text-white" />
                  </div>
                  <div className={i18n.language==='en'?"ms-3":"me-3"}>
                    <h6 className="fs-14 mb-0 serv-color">{t('jobPosted')}</h6>
                    <p className="text-muted mb-0">{data?.job?.created_at}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <div className="icon-job-overview">
                    <CgCalendarDates className="text-white" />
                  </div>
                  <div className={i18n.language==='en'?"ms-3":"me-3"}>
                    <h6 className="fs-14 mb-0 serv-color">{t('closingDate')}</h6>
                    <p className="text-muted mb-0">{data?.job?.deadline}</p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-3">
              <Link
                onClick={() => { onOpenModal(); setSlug(data?.job.slug) }}
                className="btn-apply w-100 mt-2"
              >
                {t('applyNow')} <FaArrowRight />
              </Link>
              {/* <a className="bookmark-btn w-100 mt-2" href="/jobdetails">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 384 512"
                  className="mr-5"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 48C0 21.5 21.5 0 48 0l0 48V441.4l130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4V48H48V0H336c26.5 0 48 21.5 48 48V488c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488V48z"></path>
                </svg>
                Add Bookmark
              </a> */}
            </div>
          </CardBody>
        </Card>
        {/* <div className="mt-4">
          <h6 className="fs-16 mb-3 serv-color">{t('jobLocation')}</h6>
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11025897.871543838!2d24.087537140245593!3d26.820553937623954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458478d0e4db229%3A0x1466c34b3b1bdf21!2sEgypt!5e0!3m2!1sen!2sus!4v1620023120074!5m2!1sen!2sus"
            style={{ width: "100%", height: "250px" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div> */}
        <ModalApplyJob onCloseModal={onCloseModal} slug={slug} open={open} />
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
