import TransactionalNotifications from "./Forms/TransactionalNotifications";
import SystemNotifications from "./Forms/SystemNotifications";

const Notifications = () => {

  return (
    <div className="page-content content">
      <div className="container">
        <div className="row">
          <TransactionalNotifications/>
          <SystemNotifications/>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
