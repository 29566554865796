import { useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useFetchCountriesQuery, useFetchIndustriesQuery } from "../app/feature/API/Helpers";
import { useLazyGetServicesSmartQuery } from "../app/feature/API/Services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {  setServicesData } from "../app/feature/SaveJobData";
import { useGetHomeSectionsQuery } from "../app/feature/API/CMS";

const GetStarted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const { data: CtaData } = useGetHomeSectionsQuery('home_page');

  const getStartedSection = CtaData?.find(cta => cta.slug === 'get_started');
  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  // const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleActivityChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const [triggerSearch, { data: ServicesData ,isSuccess:ServicesDataSuccess , isLoading}] = useLazyGetServicesSmartQuery(); 

  const handleSearch = () => {
    if (!selectedOption && !selectedIndustry && !selectedCountry) {
      const message = i18n.language==="en"?"من فضلك،ادخل البيانات بالكامل.":"Please enter all the data"
      toast.warning(message);
      return; 
    }

    triggerSearch({
      user_type: selectedOption || '',
      industry_id: selectedIndustry || '',
      country_code: selectedCountry || '',
      
    });
    localStorage.setItem('selectedActivityRecommended', selectedOption)
    localStorage.setItem('selectedIndustryRecommended', selectedIndustry)
    localStorage.setItem('selectedCountryRecommended', selectedCountry)
  };

  useEffect(() => {
    if (ServicesDataSuccess) {
      if (ServicesData?.length > 0) {
        toast.success(t("Results found for the search!"));
        dispatch(setServicesData(ServicesData));
        localStorage.setItem("services", JSON.stringify(ServicesData))
        navigate(`/smart-service-request`);
      } else {
        toast.info(t('No results found for this value!'));
      }
    }
  }, [dispatch, navigate, ServicesDataSuccess, ServicesData?.length, ServicesData]);
  const getStartedData = t("GetStartedSection", { returnObjects: true });
  const { data: AllCountries } = useFetchCountriesQuery();
  const { data: AllIndustries } = useFetchIndustriesQuery();
  if(getStartedSection?.show_sec !==1 ){
    return null;
  }
  return (
    <section
      className={`${
        location.pathname === "/exportservices"
          ? "mt-30 mb-100"
          : "content-block-wrapper section-padding bg-white fix"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="block-contents">
              <div className="section-title">
              <h2>{getStartedSection?.title}</h2>
                <p className="text-getstarted">
                  {getStartedSection?.dec}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs-change">
          <span className="tab-getStarted active-tab-get-started"></span>
          <span
            className={`tab-getStarted2 ${
              i18n.language === "ar" ? "ml-60" : ""
            }`}
          ></span>
        </div>
        <div className="content-tabs-getStarted">
          <div className="row">
            {!selectedOption && (
              <>
                <div className="col-lg-6 col-md-6 col-12">
                  <div
                    className="single-pricing-plan bg-gray flex-center-center"
                    onClick={() => handleSelectOption("exporter")}
                  >
                    <div className="package-head">
                      <div className="icon">
                        {/* <svg
                          className="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Outline"
                          x="0px"
                          y="0px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                          xmlSpace="preserve"
                          fill="#632166"
                          width={150}
                        >
                          <g>
                            <path d="M24.75,20.31714v-5h-3v-5H8.25v2.53998h1.5v-1.03998h1.5v1.75h1.5v-1.75h1.5v3.5h-4.5v-0.96002h-1.5v0.96002h-3v5h-2v4.5h2   v2h5.5v-2h5.03998v-1.5H9.25v2h-2.5v-2h-2v-1.5h20.5v1.5h-2v2h-2.5v-2h-3.46002v1.5H19.25v2h5.5v-2h2v-4.5H24.75z M15.75,11.81714   h1.5v1.75h1.5v-1.75h1.5v3.5h-4.5V11.81714z M11.25,20.31714h-4.5v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z M17.25,20.31714h-4.5   v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z M23.25,20.31714h-4.5v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z" />
                            <polygon
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#ff5e14"
                              points="23.25,7.42035 23.25,10.06714 24.75,10.06714 24.75,7.42035 25.60254,7.95337 26.39746,6.68091 24,5.18286    21.60254,6.68091 22.39746,7.95337  "
                            />
                            <polygon
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#ff5e14"
                              points="14.25,5.42035 14.25,8.06714 15.75,8.06714 15.75,5.42035 16.60254,5.95337 17.39746,4.68091 15,3.18286    12.60254,4.68091 13.39746,5.95337  "
                            />
                            <polygon
                              fill="#ff5e14"
                              points="5.25,7.42035 5.25,10.06714 6.75,10.06714 6.75,7.42035 7.60254,7.95337 8.39746,6.68091 6,5.18286    3.60254,6.68091 4.39746,7.95337  "
                            />
                          </g>
                        </svg> */}
                        <img src={getStartedSection?.image} alt="" style={{height:"180px",width:'180px',objectFit:'fill'}}/>
                      </div>
                    </div>
                    <p className="mt-10 txt-getStarted text-center ">
                    {getStartedSection?.btn_text}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div
                    className="single-pricing-plan bg-gray flex-center-center"
                    onClick={() => handleSelectOption("importer")}
                  >
                    <div className="package-head">
                      <div className="icon">
                        {/* <svg
                          className="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Outline"
                          x="0px"
                          y="0px"
                          viewBox="0 0 30 30"
                          enableBackground="new 0 0 30 30"
                          xmlSpace="preserve"
                          fill="#632166"
                          width={150}
                        >
                          <g xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.125,16.30499v-5.69l-9.75-5.41998l-9.75,5.41998v14.19h19.5v-4h-1.5v2.5h-1.5v-0.97003h-1.5v0.97003h-1.5v-3h-1.5v-3   h-4.5v3h-1.5v3h-1.5v-4.96002h-1.5v4.96002h-1.5v-11.81l8.25-4.58002l8.25,4.58002v4.81H23.125z M15.625,23.30499h-1.5v-1.5h1.5   V23.30499z M12.625,18.80499h1.5v1.5h-1.5V18.80499z M11.125,21.80499h1.5v1.5h-1.5V21.80499z" />
                            <polygon points="6.625,16.84503 8.125,16.84503 8.125,14.80499 20.125,14.80499 20.125,13.30499 6.625,13.30499  " />
                            <rect
                              x="10.375"
                              y="10.30499"
                              width="6"
                              height="1.5"
                            />
                            <polygon
                              fill="#ff5e14"
                              points="19.9986,17.80499 20.86279,16.46027 19.60107,15.64972 17.7334,18.55499 19.60107,21.46027 20.86279,20.64972    19.9986,19.30499 26.375,19.30499 26.375,17.80499  "
                            />
                          </g>
                        </svg> */}
                        <img src={getStartedSection?.image_2} alt="" style={{height:"180px",width:'180px',objectFit:'fill'}}/>
                      </div>
                    </div>
                    <p className="mt-10 txt-getStarted text-center">
                    {getStartedSection?.btn2_text}
                    </p>
                  </div>
                </div>
              </>
            )}
            {selectedOption === "exporter" && (
              <div className="col-12">
                <div className="single-pricing-plan bg-gray flex-center-center">
                  <div className="package-head">
                    <div className="icon">
                      {/* <svg
                        className="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        id="Outline"
                        x="0px"
                        y="0px"
                        viewBox="0 0 30 30"
                        enableBackground="new 0 0 30 30"
                        xmlSpace="preserve"
                        fill="#632166"
                        width={150}
                      >
                        <g>
                          <path d="M24.75,20.31714v-5h-3v-5H8.25v2.53998h1.5v-1.03998h1.5v1.75h1.5v-1.75h1.5v3.5h-4.5v-0.96002h-1.5v0.96002h-3v5h-2v4.5h2   v2h5.5v-2h5.03998v-1.5H9.25v2h-2.5v-2h-2v-1.5h20.5v1.5h-2v2h-2.5v-2h-3.46002v1.5H19.25v2h5.5v-2h2v-4.5H24.75z M15.75,11.81714   h1.5v1.75h1.5v-1.75h1.5v3.5h-4.5V11.81714z M11.25,20.31714h-4.5v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z M17.25,20.31714h-4.5   v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z M23.25,20.31714h-4.5v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z" />
                          <polygon
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#ff5e14"
                            points="23.25,7.42035 23.25,10.06714 24.75,10.06714 24.75,7.42035 25.60254,7.95337 26.39746,6.68091 24,5.18286    21.60254,6.68091 22.39746,7.95337  "
                          />
                          <polygon
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#ff5e14"
                            points="14.25,5.42035 14.25,8.06714 15.75,8.06714 15.75,5.42035 16.60254,5.95337 17.39746,4.68091 15,3.18286    12.60254,4.68091 13.39746,5.95337  "
                          />
                          <polygon
                            fill="#ff5e14"
                            points="5.25,7.42035 5.25,10.06714 6.75,10.06714 6.75,7.42035 7.60254,7.95337 8.39746,6.68091 6,5.18286    3.60254,6.68091 4.39746,7.95337  "
                          />
                        </g>
                      </svg> */}
                        <img src={getStartedSection?.image} alt="" style={{height:"180px",width:'180px',objectFit:'fill'}}/>
                    </div>
                  </div>
                  <p className="mt-10 txt-getStarted text-center">
                  {/* {getStartedData[0].nameBox1} */}
                  {getStartedSection?.btn_text}
                  </p>
                </div>
              </div>
            )}
            {selectedOption === "importer" && (
              <div className="col-12">
                <div className="single-pricing-plan bg-gray flex-center-center">
                  <div className="package-head">
                    <div className="icon">
                      {/* <svg
                        className="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        id="Outline"
                        x="0px"
                        y="0px"
                        viewBox="0 0 30 30"
                        enableBackground="new 0 0 30 30"
                        xmlSpace="preserve"
                        fill="#632166"
                        width={150}
                      >
                        <g xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.125,16.30499v-5.69l-9.75-5.41998l-9.75,5.41998v14.19h19.5v-4h-1.5v2.5h-1.5v-0.97003h-1.5v0.97003h-1.5v-3h-1.5v-3   h-4.5v3h-1.5v3h-1.5v-4.96002h-1.5v4.96002h-1.5v-11.81l8.25-4.58002l8.25,4.58002v4.81H23.125z M15.625,23.30499h-1.5v-1.5h1.5   V23.30499z M12.625,18.80499h1.5v1.5h-1.5V18.80499z M11.125,21.80499h1.5v1.5h-1.5V21.80499z" />
                          <polygon points="6.625,16.84503 8.125,16.84503 8.125,14.80499 20.125,14.80499 20.125,13.30499 6.625,13.30499  " />
                          <rect
                            x="10.375"
                            y="10.30499"
                            width="6"
                            height="1.5"
                          />
                          <polygon
                            fill="#ff5e14"
                            points="19.9986,17.80499 20.86279,16.46027 19.60107,15.64972 17.7334,18.55499 19.60107,21.46027 20.86279,20.64972    19.9986,19.30499 26.375,19.30499 26.375,17.80499  "
                          />
                        </g>
                      </svg> */}
                      <img src={getStartedSection?.image_2} alt="" style={{height:"180px",width:'180px',objectFit:'fill'}}/>
                    </div>
                  </div>
                  <p className="mt-10 txt-getStarted text-center">
                  {/* {getStartedData[0].nameBox2} */}
                  {getStartedSection?.btn2_text}
                  </p>
                  {/* <FormGroup check>
                    <Label check>
                      <Input type="checkbox" /> Option A
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" /> Option B
                    </Label>
                  </FormGroup> */}
                </div>
              </div>
            )}
          </div>
          {selectedOption && (
            <form action="">
      <div className="pt-40 flex-select-getStarted">
        {/* <FormGroup className="mb-3 flex-1 wrap-select-get-start">
          <Label for={`I want to`} className="form-label label-getStarted">
            {i18n.language === 'en' ? 'I want to' : 'اريد'}
          </Label>
          <Input
            type="select"
            name="select an Activity"
            id="I want to"
            required
            className="select-getStarted"
            value={selectedOption}
            onChange={handleActivityChange}
          >
            <option value="" disabled>
              {i18n.language === 'en' ? 'select an Activity' : 'حدد نشاطا'}
            </option>
            <option value="importer">
              {i18n.language === 'en'
                ? 'Import Products From Egypt'
                : 'استيراد المنتجات من مصر'}
            </option>
            <option value="exporter">
              {i18n.language === 'en'
                ? 'Export Products From Egypt'
                : 'تصدير المنتجات الي مصر'}
            </option>
          </Input>
        </FormGroup> */}

        <FormGroup className="mb-3 flex-1 wrap-select-get-start">
          <Label
            for="Choose Your Industry"
            className="form-label label-getStarted"
          >
            {i18n.language === 'en' ? 'Choose Your Industry' : 'اختر صناعتك'}
          </Label>
          <Input
            type="select"
            name="Choose Your Industry"
            id="Choose Your Industry"
            required
            className="select-getStarted"
            value={selectedIndustry}
            onChange={handleIndustryChange}
          >
            <option value="" disabled>
              {i18n.language === 'en' ? 'Choose Your Industry' : 'اختر صناعتك'}
            </option>
            <option value="0">
              {i18n.language === 'en' ? 'All' : 'الكل'}
            </option>
            {AllIndustries &&
              AllIndustries.map((industry) => (
                <option key={industry.id} value={industry.id}>
                  {industry.name}
                </option>
              ))}
          </Input>
        </FormGroup>

        <FormGroup className="mb-3 flex-1 wrap-select-get-start">
          <Label
            for="Choose Your Country"
            className="form-label label-getStarted"
          >
            {i18n.language === 'en' ? 'Choose Your Country' : 'اختر دولتك'}
          </Label>
          <Input
            type="select"
            name="Choose Your Country"
            id="Choose Your Country"
            required
            className="select-getStarted"
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            <option value="" disabled>
              {i18n.language === 'en' ? 'Choose Your Country' : 'اختر دولتك'}
            </option>
            <option value="0">
              {i18n.language === 'en' ? 'All' : 'الكل'}
            </option>
            {AllCountries &&
              AllCountries.map((country) => (
                <option key={country.id} value={country.code}>
                  {country.name}
                </option>
              ))}
          </Input>
        </FormGroup>
      </div>
      <div className="text-align-end mt-20">
        <span style={{cursor:'pointer'}} className="btn-advance" onClick={handleSearch}>
          {isLoading?t('loading'):t('btnNameSectionGetStarted')}
        </span>
      </div>
    </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
