import { useTranslation } from "react-i18next";
import PageBanner from "../Shared/PageBanner";
import bannerBg from "../assets/img/Banner/Top-Image7.jpg"
import About from "../components/About/About";
import Capabilities from "../components/Services/main/Capabilities";
import WhatWeDo from '../components/Services/main/WhatWeDo'
import { useGetBannersQuery } from "../app/feature/API/CMS";
const AboutUs = () => {
  const { t } = useTranslation();
const {data} = useGetBannersQuery('aboutus')

  return (
    <div>
      <PageBanner
        currentPage={data?.title}
        title={data?.title}
        bannerBg={data?.image}
      />
      <About/>
      <Capabilities/>
      <WhatWeDo/>
    </div>
  );
};

export default AboutUs;
