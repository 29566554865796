import React from "react";
import PageBanner from "../Shared/PageBanner";
import bannerBg from "../assets/img/Banner/Top-Image6.jpg";
import Recommended from "../components/ResultsPage/Recommended";
import SelectLevel from "../components/ResultsPage/SelectLevel";
import ReportsRecommended from "../components/ResultsPage/ReportsRecommended";
import { useTranslation } from "react-i18next";

const ResultPage = () => {
  const {i18n} = useTranslation()
  return (
    <>
      <PageBanner
        currentPage={i18n.language==='en'?"Smart Service Request":"طلب الخدمة الذكية"}
        title={i18n.language==='en'?"Smart Service Request":"طلب الخدمة الذكية"}
        bannerBg={bannerBg}
      />
      <Recommended/>
      <SelectLevel/>
      <ReportsRecommended/>
    </>
  );
};

export default React.memo(ResultPage);
