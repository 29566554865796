import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../../../app/feature/API/CMS";

const ExporterServices = () => {

  const {t,i18n} = useTranslation();
  const { data: aboutData, isLoading } = useGetHomeSectionsQuery('whatwedo');
  const aboutSection = aboutData?.find(cta => cta.slug === 's1_whatwedo');
  if (isLoading) {
    return <p>{t('loading')}</p>; 
  }
  if(aboutSection.show_sec !==1 ){
    return null;
  }
  return (
    <section className="section-padding bg-gray">
      <div className="container">
        <div className="row mb-80"  data-aos="fade-up">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title pb-20">
                <h5>{aboutSection?.title}</h5>
                <span style={{fontSize:i18n.language==='ar'&&"40px"}}>{aboutSection?.sub}</span>
                <h2>{aboutSection?.title}</h2>
              </div>
              <p className="desc-exporter max-desc-sec" dangerouslySetInnerHTML={{__html:aboutSection?.dec}}>
              </p>
            </div>
          </div>
        </div>
        <div className="row center-sm-what-we-do" data-aos="fade-up">
          {aboutSection?.list?.map((list, index) =>(
            <div className="col-lg-3 col-md-6" key={index}>
                <div className="service-four__single">
                  <i className={list?.icon} />
                  <h3>
                    <a>
                      {list?.title} <br />
                    </a>
                  </h3>
                  <p className="desc-app" style={{fontSize:i18n.language==='ar'&&"30px"}}>{list?.desc}</p>
                </div>
              </div>
          ))}
            </div>
      </div>
    </section>
  );
};

export default ExporterServices;
















{/* <div className="row mt-50 center-sm-what-we-do" data-aos="fade-up">
  <div className="col-lg-3 col-md-6">
    <div className="service-four__single">
      <i className="fal fa-atom-alt" />
      <h3>
        <a>
          {t('advancedBusinessSolutions')} <br />
        </a>
      </h3>
      <p className="desc-app">{t('businessSolutionsDesc')}</p>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="service-four__single">
      <i className="fal fa-bullseye-arrow" />
      <h3>
        <a>
          {t('achieveTargets')} <br />
          {t('withGoals')}
        </a>
      </h3>
      <p className="desc-app">{t('achieveTargetsDesc')}</p>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="service-four__single">
      <i className="fal fa-recycle" />
      <h3>
        <a>
          {t('increaseOnlineWorkflow')} <br />
        </a>
      </h3>
      <p className="desc-app">{t('increaseOnlineWorkflowDesc')}</p>
    </div>
  </div>
  <div className="col-lg-3 col-md-6">
    <div className="service-four__single">
      <i className="fal fa-anchor" />
      <h3>
        <a>
          {t('higherAnnualizedGrowth')} <br />
        </a>
      </h3>
      <p className="desc-app">{t('higherAnnualizedGrowthDesc')}</p>
    </div>
  </div>
</div> */}
