import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import i18next from "i18next";
import { base_url } from "../../../Base/base_Url";
import { toast } from "react-toastify";
import CookieService from "../../../Services/CookiesServices";

const initialState = {
  loading: false,
  data: null,
  error: false,
};

export const userLogin = createAsyncThunk("login/userLogin", async (user, thunkAPI) => {
  try {
    const { data } = await axios.post(`${base_url}/login`, user, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = false;

        const { token, token_type } = action.payload;
        if (token) {
          localStorage.setItem("token_Front_Mied", action.payload.data.token);
          localStorage.setItem("token_type", token_type);
          const date = new Date();
          const EXPIRE_AT_HOURS = 24;
          date.setTime(date.getTime() + EXPIRE_AT_HOURS * 60 * 60 * 1000); // 24 hours
          const options = { path: "/", expires: date };
          CookieService.set("jwt", token, options);

          const successMessage = i18next.t('login.success');
          // toast.success(successMessage);
        } else {
          const errorMessage = i18next.t('login.noData');
          // toast.error(errorMessage);
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        const error = action.payload || {};

        const warningMessage = error.message || i18next.t('login.credentialsError');
        toast.warning(warningMessage);
      });
  },
});

export const selectLogin = (state) => state.login;
export default loginSlice.reducer;
    