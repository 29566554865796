import React from "react";
import logo from "../assets/img/logo1.jpg";
import logo2 from "../assets/img/logo (3).jpg";
import { useTranslation } from "react-i18next";
import { useFetchExtraSettingQuery } from "../app/feature/API/Helpers";

const Preloader = () => {
  const {t} = useTranslation()
  const { data } = useFetchExtraSettingQuery();

  return (
    <div id="preloader" className="preloader">
      <div className="animation-preloader">
        <div className="beat">
          {/* <img src={logo} alt="" loading="lazy" /> */}
        </div>
        <div className="preloader-img-logo">
          <img src={data?.extra?.map((e)=>e.preloader)} alt="" />
        </div>
        <p className="loading-text">
        <span className="text-center hover-color">{t('Loading')}</span>
          <span className="dots">...</span>
        </p>
      </div>
      <div className="loader">
        <div className="row">
          <div className="col-3 loader-section section-left">
            <div className="bg"></div>
          </div>
          <div className="col-3 loader-section section-left">
            <div className="bg"></div>
          </div>
          <div className="col-3 loader-section section-right">
            <div className="bg"></div>
          </div>
          <div className="col-3 loader-section section-right">
            <div className="bg"></div>
          </div>
        </div>
      </div>
      {/* <div className="txt-loading">
               <span data-text-preloader="M" className="letters-loading">
                   M
               </span>
               <span data-text-preloader="I" className="letters-loading">
                   I
               </span>
               <span data-text-preloader="E" className="letters-loading">
                  E
               </span>
               <span data-text-preloader="D" className="letters-loading">
                   D
               </span>
           </div> */}
    </div>
  );
};

export default Preloader;
