import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import Sidebar from "../../components/News/Sidebar";
import { useTranslation } from "react-i18next";
import { useGetBlogsQuery } from "../../app/feature/API/Blogs";
import ReactPaginate from "react-paginate";
import { GrNext, GrPrevious } from "react-icons/gr";

const NewsWrapper = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { t, i18n } = useTranslation();
  const { data: blogsData, error, isLoading } = useGetBlogsQuery({ title: '', page: currentPage });

  const getFirstFTagContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const firstFTag = doc.querySelector('p');
    return firstFTag ? firstFTag.innerHTML : '';
  };

  const blogs = blogsData?.data || [];
  const pageCount = blogsData?.meta?.last_page;

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1); 
  };

  if (isLoading) {
    return         <div className="spinner-overlay">
    <div className="spinner"></div>
  </div>
  }

  if (error) {
    return <div className="error">An error occurred while fetching the news. Please try again later.</div>;
  }

  if (blogs.length === 0) {
    return <div className="no-data">{t('No_Data')}</div>;
  }

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="EZ9DrY43wtw"
        onClose={() => setOpen(false)}
      />
      <section className="blog-wrapper news-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="blog-posts">
                {blogs.map((data, idx) => (
                  <div key={idx} className="single-blog-post">
                    <div
                      className="post-featured-thumb bg-cover"
                      style={{ backgroundImage: `url(${data?.main_image})` }}
                    ></div>
                    <div className="post-content">
                      <div className="post-cat">
                        <Link to="/news" className="border-raduis">
                          {data?.bcategory}
                        </Link>
                      </div>
                      <h2>
                        <Link to={`/newsDetails/${data?.slug}`} className="hover-news-paper">
                          {data?.title}
                        </Link>
                      </h2>
                      <div className="post-meta">
                        <span>
                          <i className="fal fa-calendar-alt"></i>
                          {new Date(data?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                        </span>
                      </div>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: getFirstFTagContent(data?.content || '') }} />
                      </p>
                      <div className="d-flex justify-content-between align-items-center mt-30">
                        <div className="author-info"></div>
                        <div className="post-link">
                          <Link to={`/newsDetails/${data?.slug}`} className="hover-news-paper">
                            {i18n.language === "en" ? (
                              <>
                                <i className="fal fa-arrow-right"></i> Read More
                              </>
                            ) : (
                              <>
                                <i className="fal fa-arrow-right"></i> {" "} قراءه المزيد
                              </>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {pageCount > 1 && blogs.length > 0 && (
                <div className="page-nav-wrap mt-60 text-center">
                  <div className="row" style={{ marginTop: '40px' }}>
                    <div className="col-12">
                      <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousLabel={<GrPrevious />}
                        nextLabel={<GrNext />}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Sidebar />
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsWrapper;
