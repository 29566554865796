import React, { useEffect, useState } from "react";
import ConfirmEmailForm from "./ConfirmEmailForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ConfirmEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");

    if (token) {
      navigate("/");
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [navigate]);

  if (loading) {
    return <div>{t("Loading...")}</div>; 
  }

  return (
    <div className="auth right-panel-active" id="container">
      <ConfirmEmailForm />
      <div className="overlay-container">
        <div className="overlay-auth">
          <div className="overlay-panel overlay-left">
            <h1>{t("Welcome Back!")}</h1>
            <p>{t("You can confirm your email here")}</p>
          </div>
          <div className="overlay-panel overlay-right">
            <h1>{t("Hello, Friend!")}</h1>
            <p>{t("Enter your personal details and start your journey with us")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
