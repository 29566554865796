import { configureStore } from "@reduxjs/toolkit";
import { tabSlice } from "./feature/TabSlice";
import jobsReducer from './feature/SaveJobData';
import loginSlice from './feature/API/Login';
import { UserApi } from "./feature/API/User";
import { HelpersApi } from "./feature/API/Helpers";
import { ProfileApi } from "./feature/API/Profile";
import { SliderApi } from "./feature/API/Sliders";
import { FaqsApi } from "./feature/API/Faqs";
import { ServicesApi } from "./feature/API/Services";
import { LogoutApi } from "./feature/API/Logout";
import { FeedbackApi } from "./feature/API/Feedbacks";
import { JobsApi } from "./feature/API/Jobs";
import { PortfolioApi } from "./feature/API/Portfolio";
import { TicketsApi } from "./feature/API/Tickets";
import { ArticleApi } from "./feature/API/Articles";
import { BlogsApi } from './feature/API/Blogs';
import { ReportsApi } from "./feature/API/Reports";
import { CMSApi } from "./feature/API/CMS";
const store = configureStore({
  reducer: {
    tabs: tabSlice.reducer,
    jobs: jobsReducer,
    login:loginSlice,
    [UserApi.reducerPath]: UserApi.reducer,
    [HelpersApi.reducerPath]: HelpersApi.reducer,
    [ProfileApi.reducerPath]: ProfileApi.reducer,
    [SliderApi.reducerPath]: SliderApi.reducer,
    [FaqsApi.reducerPath]: FaqsApi.reducer,
    [ServicesApi.reducerPath]: ServicesApi.reducer,
    [LogoutApi.reducerPath]: LogoutApi.reducer,
    [FeedbackApi.reducerPath]: FeedbackApi.reducer,
    [JobsApi.reducerPath]: JobsApi.reducer,
    [PortfolioApi.reducerPath]: PortfolioApi.reducer,
    [TicketsApi.reducerPath]: TicketsApi.reducer,
    [ArticleApi.reducerPath]: ArticleApi.reducer,
    [BlogsApi.reducerPath]: BlogsApi.reducer,
    [ReportsApi.reducerPath]: ReportsApi.reducer,
    [CMSApi.reducerPath]: CMSApi.reducer,
  },
  middleware: (gDM) =>
    gDM().concat(
        UserApi.middleware,
        HelpersApi.middleware,  
        ProfileApi.middleware,  
        SliderApi.middleware,  
        FaqsApi.middleware,  
        ServicesApi.middleware,  
        LogoutApi.middleware,  
        FeedbackApi.middleware,  
        JobsApi.middleware,  
        PortfolioApi.middleware,  
        TicketsApi.middleware,  
        ArticleApi.middleware,  
        BlogsApi.middleware,  
        ReportsApi.middleware,  
        CMSApi.middleware,
    ),
});

export default store;
