import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const TicketsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
  }),
  reducerPath: "TicketsApi",
  tagTypes: ["tickets"],
  endpoints: (build) => ({
    getTickets: build.query({
      query: ({ status, sort }) => {
        const params = new URLSearchParams();
        if (status) {
          params.append('status', status);
        }
        if (sort) {
          params.append('sort', sort);
        }
        return `/tickets?${params.toString()}`;
      },
      transformResponse: (response) => response.data,
      providesTags: ["tickets"],
    }),
    
    getTicketsDetails: build.query({
        query: (ticket_number) => `/tickets/${ticket_number}`,
        transformResponse: (response) => response.data,
        providesTags: ["tickets"],
      }),
    saveTickets: build.mutation({
      query: (ticketsData) => ({
        url: `/tickets`,
        method: "POST",
        body: ticketsData,
      }),
      invalidatesTags: ["tickets"],
    }),
    
    saveTicketsReply: build.mutation({
      query: ({reply,ticket_number}) => ({
        url: `/tickets/reply/${ticket_number}`,
        method: "POST",
        body: {reply},
      }),
      invalidatesTags: ["tickets"],
    }),
  }),
});

export const {
  useSaveTicketsMutation,
  useGetTicketsQuery,
  useGetTicketsDetailsQuery,
  useSaveTicketsReplyMutation,
} = TicketsApi;
