import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPortfolioDetailsQuery } from "../app/feature/API/Portfolio";
import { useFetchExtraSettingQuery, useSaveNewsletterMutation } from "../app/feature/API/Helpers";
import { toast } from "react-toastify";

const SidebarPortfolio = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const { slug } = useParams();
  const { data: blogsData, error, isLoading } = useGetPortfolioDetailsQuery(slug);
  const { data } = useFetchExtraSettingQuery();
  const [email, setEmail] = useState("");
  const [saveNewsletter , {isLoading:isLoadingNewSletter}] = useSaveNewsletterMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveNewsletter({ email }).unwrap();
      toast.success(i18n.language === "en" ? "Subscribed successfully!" : "تم الاشتراك بنجاح!");
      setEmail("");
    } catch (error) {
      toast.error(i18n.language === "en" ? "Subscription failed!" : "فشل الاشتراك!");
    }
  };
  if(isLoading){
    return <p>{t('loading')}</p>
  }
  return (
    <div className="col-12 col-lg-4">
      <div className="main-sidebar">
        <div className="single-sidebar-widget">
          <div>
            <h3>{blogsData?.title}</h3>
          </div>
          <div className="popular-posts">
              <div className="single-post-item" key={blogsData?.id}>
                <div className="post-content">
                {blogsData?.client_name && (
                <p className="fs-5 mb-2">
                    {i18n.language === 'en' ? 'Client Name: ' : 'اسم العميل: '}
                    {blogsData.client_name}
                </p>
                )}
                {blogsData?.service && (
                <p className="fs-5 mb-2">
                    {i18n.language === 'en' ? 'Service: ' : 'خدمة: '}
                    {blogsData.service}
                </p>
                )}
                {blogsData?.startDate && (
                <p className="fs-5 mb-2">
                    {i18n.language === 'en' ? 'Start Date: ' : 'تاريخ البدايه: '}
                    {blogsData.startDate}
                </p>
                )}
                {blogsData?.endDate && (
                <p className="fs-5 mb-2">
                    {i18n.language === 'en' ? 'End Date: ' : 'تاريخ النهايه: '}
                    {blogsData.endDate}
                </p>
                )}
                {blogsData?.status && (
                <p className="fs-5 mb-2">
                    {i18n.language === 'en' ? 'Status: ' : 'حالة: '}
                    {blogsData.status}
                </p>
                )}
                </div>
              </div>
          </div>
        </div>
        <div className="single-sidebar-widget">
          <div>
            <h3> {i18n.language === "en" ? 'SUBSCRIBE' : 'اشتراك'}</h3>
            <p> {i18n.language === "en" ? 'SUBSCRIBE FOR NEWSLETTER' : 'اشترك في النشرة الإخبارية'}</p>
          </div>
          <div className="search_widget mt-3">
            <form onSubmit={handleSubmit}>
            <input
            style={{paddingRight:i18n.language==='en'?"0":"80px"}}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={`${i18n.language === "en" ? "Enter email address" : "أدخل عنوان البريد الالكتروني"}`}
               required
            />
              <button type="submit">
                {isLoadingNewSletter ? (
                  i18n.language === "en" ? "JOIN..." : "جارٍ التحميل..."
                  ) : (
                  i18n.language === "en" ? "JOIN" : "ادخل"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarPortfolio;
