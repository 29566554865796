import React, { useEffect, useRef, useState } from "react";
import { BsPostcard } from "react-icons/bs";
import { LuTable } from "react-icons/lu";
import "react-datepicker/dist/react-datepicker.css";
import TableReports from "./ReportsDashboard/TableReports";
import { BiCategoryAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useGetMyReportsQuery } from "../../app/feature/API/Profile";
import logo from "../../assets/img/logo.jpg";
import { useGetCategoriesReportsQuery } from "../../app/feature/API/Reports";
import jsPDF from "jspdf";
import ModalReviewReport from "../../components/ModalReviewReport";
const MyReports = () => {
  const [open2, setOpen2] = useState(false);
  const [reportId, setReportId] = useState("");
  const onCloseModal2 = () => setOpen2(false);
  const dropDownRef = useRef(null);
  const [dropDown, setDropDown] = useState(false);
  const [dropDownChoose, setDropDownChoose] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const { t ,i18n} = useTranslation();
  const [isNearRightEdge, setIsNearRightEdge] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // حالة للبحث
  const [selectedCategory, setSelectedCategory] = useState(""); // حالة للفئة المختارة

  const handleActiveTab = (number) => {
    setActiveTab(number);
  };

  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  const handleShowAllReports = () => {
    setSearchQuery("");
    setSelectedCategory("");
    setDropDownChoose("");
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropDownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText || "";
  };

  const handleViewReport = (report) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFillColor(240, 240, 240);
    doc.rect(0, 0, pageWidth, 297, "F");

    doc.setFontSize(22);
    doc.setTextColor(40, 40, 40);
    doc.setFont("helvetica", "bold");
    doc.text("Report Details", pageWidth / 2, 30, null, null, "center");

    doc.addImage(report.feature_image, "JPEG", 15, 40, 60, 60);

    doc.setDrawColor(0, 51, 102); 
    doc.setLineWidth(0.5);
    doc.roundedRect(80, 40, pageWidth - 95, 60, 3, 3, 'S'); 

    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.setFont("helvetica", "normal");

    doc.setTextColor(0, 51, 102); 
    doc.text("ID:", 85, 50);
    doc.text("Title:", 85, 60);
    doc.text("Category:", 85, 70);
    doc.text("Price:", 85, 80);
    doc.text("Updated:", 85, 90);

    doc.setTextColor(40, 40, 40);
    doc.text(`#${report.id}`, 115, 50);
    doc.text(report.title, 115, 60);
    doc.text(report.category, 115, 70);
    doc.text(`$${report.current_price}`, 115, 80);
    doc.text(
      new Date(report.updated_at).toLocaleDateString('en-EG', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }),
      115, 90
    );

    doc.setDrawColor(200, 200, 200); 
    doc.line(15, 110, pageWidth - 15, 110);

    doc.setFontSize(16);
    doc.setTextColor(0, 51, 102);
    doc.text("Summary:", 15, 120);
    
    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.setFont("helvetica", "normal");
    doc.text(report.summary, 15, 130, { maxWidth: pageWidth - 30, align: "justify" });

    doc.line(15, 150, pageWidth - 15, 150);

    doc.setFontSize(16);
    doc.setTextColor(0, 51, 102);
    doc.text("Description:", 15, 160);

    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.text(stripHtmlTags(report.description), 15, 170, { maxWidth: pageWidth - 30, align: "justify" });

    doc.output("dataurlnewwindow");
  };
  

  const { data: MyReportsData, isLoading } = useGetMyReportsQuery();
  const { data: ReportsDataCta } = useGetCategoriesReportsQuery();
  useEffect(() => {
    const handleMouseEnter = (e) => {
      const gridContainer = document.querySelector(".boxesss-section");
      const gridItems = document.querySelectorAll(".box-report");
      if (gridItems.length === 0) return;

      const gridItemWidth = gridItems[0]?.getBoundingClientRect().width;
      const gridContainerWidth = gridContainer?.offsetWidth;
      const itemsInOneRow = Math.floor(gridContainerWidth / gridItemWidth);

      let order = Array.from(gridContainer.children).indexOf(e.target) + 1;
      setIsNearRightEdge(order % itemsInOneRow === 0);
    };

    const gridItems = document.querySelectorAll(".box-report");
    gridItems.forEach((div) => {
      div.addEventListener("mouseenter", handleMouseEnter);
    });

    return () => {
      gridItems.forEach((div) => {
        div.removeEventListener("mouseenter", handleMouseEnter);
      });
    };
  }, [MyReportsData]);

  const filteredReports = MyReportsData?.filter((report) => {
    const matchesSearch = report?.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory ? report?.category === selectedCategory : true;
    return matchesSearch && matchesCategory;
  });

  if (isLoading) {
    return (
      <>
      {t("loading")} 
      <i className="fa fa-spinner fa-spin" /> 
      </>
    );
  }

  return (
    <>
      <div className="dashboard-header">
        <div className="main-title">
          <h3>{t('my_reports.title')}</h3>
        </div>
      </div>
      {activeTab === 1 && (
        <>
          <div className="table-filter">
            <ul className="filter-item">
              <li>
                <p>{t('my_reports.filter')}</p>
              </li>
              <li>
                <div className="drop-down-con" ref={dropDownRef}>
                  <div className="drop-down" onClick={handleDropDown}>
                    <BiCategoryAlt />
                    <p>{dropDownChoose ? dropDownChoose : t('my_reports.category')}</p>
                    <button className="search-btn">
                      <i className="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div className={`drop ${dropDown ? "" : "d-none"}`}>
                    <p onClick={handleShowAllReports}>
                      {t('all')}
                    </p>
                    {ReportsDataCta?.map((reportCta, idx) => (
                      <p
                        key={idx}
                        onClick={() => {
                          setDropDownChoose(reportCta?.name);
                          setSelectedCategory(reportCta?.name);
                        }}
                      >
                        {reportCta?.name}
                      </p>
                    ))}
                  </div>
                </div>
              </li>
            </ul>
            <div id="tablefilter" className="filter-reports">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={t('my_reports.search')}
                    aria-controls="DataTables_Table_0"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </label>
              </div>
              <div className="display-table-card">
                <p
                  onClick={() => handleActiveTab(1)}
                  className={`${activeTab === 1 ? "active" : ""}`}
                >
                  <BsPostcard />
                </p>
                <p
                  onClick={() => handleActiveTab(2)}
                  className={`${activeTab === 2 ? "active" : ""}`}
                >
                  <LuTable />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="boxesss-section"  style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
              {filteredReports?.length === 0 ? (
                <p>{t('No_Data')}</p> 
              ) : (
                filteredReports?.map((report) => (
            <div className="box-report" key={report?.slug}>
              <div className="bg-white">
                <div className="top-box-report">
                  <div className="header-box-report">
                    <div className="flex-center">
                      <div className="logo">
                        <img src={logo} alt="" loading="lazy" />
                      </div>
                      <div className="user-information w-70">
                      {report.review !== undefined && report.total_reviews !== undefined && (
                        <ul>
                          <li>
                            <i className="fas fa-star"></i>{" "}
                            {Number(report.review) !== 0 ? report.review : ""}
                            {Number(report.total_reviews) !== 0 ? ` (${report.total_reviews})` : ""}
                          </li>
                        </ul>
                      )}
                      </div>
                    </div>
                    <div className="text-desc">
                      {i18n.language==='en'?report?.title:report?.title_ar}
                    </div>
                  </div>
                  <div className="body-box-report">
                    <img src={report?.feature_image} alt="" />
                  </div>
                </div>
                <div className="bottom-box-report">
                {i18n.language==='en'?(
                  <p>
                    {report?.description
                      .replace(/<[^>]+>/g, '') 
                      .trim() 
                      .split('.')
                      .filter(Boolean)[0]
                      .trim()}
                  </p>
                ):(
                  <p>
                  {report?.description_ar
                    .replace(/<[^>]+>/g, '') 
                    .trim() 
                    .split('.')
                    .filter(Boolean)[0]
                    .trim()}
                </p>
                )}
                  {/* <p>{report?.summary || <p dangerouslySetInnerHTML={{__html:i18n.language==='en'?report?.description:report?.description_ar}}></p>}</p> */}
                </div>
              </div>
              <div
                className={`${
                  isNearRightEdge ? "right-screen-hover" : `${i18n.language === 'en' ? "hover-box-report":"hover-box-report hover-box-report-ar"}`
                }`} 
              >
                <p className="main-title">
                {i18n.language==='en'?report?.title:report?.title_ar}
                </p>
                <div className="subtitle-hover">
                  <span className="best-hover-box">{report?.category}</span>
                  <p className="updated-hover-box">
                    Updated <span className="date-hover-box">{new Date(report?.updated_at).toLocaleDateString(i18n.language==='en'?'en-US':'ar-US', { year: 'numeric', month: 'long' })}</span>
                  </p>
                </div>
                <div className="time-zone-hover">
                  <p> {report?.page || report.pages || 28} Pages </p>
                </div>
                <div className="desc-hover">
                  {i18n.language==='en'?(
                    <p>
                      {report?.description
                        .replace(/<[^>]+>/g, '') 
                        .trim() 
                        .split('.')
                        .filter(Boolean)[0]
                        .trim()}
                    </p>
                  ):(
                    <p>
                    {report?.description_ar
                      .replace(/<[^>]+>/g, '') 
                      .trim() 
                      .split('.')
                      .filter(Boolean)[0]
                      .trim()}
                  </p>
                  )}
                </div>
                <ul className={`${i18n.language==='en'?"ul-hover-box":"ul-hover-box-ar"}`}>
                  {i18n.language==='en'?(
                    report?.description
                    .replace(/<[^>]+>/g, '')
                    .trim() 
                    .split('.')
                    .filter(Boolean)
                    .slice(1) 
                    .map((item, index) => (
                      <li key={index}><i className="fas fa-check"></i>{item.trim()}</li>
                    ))
                  ):(
                    report?.description_ar
                    .replace(/<[^>]+>/g, '')
                    .trim() 
                    .split('.')
                    .filter(Boolean)
                    .slice(1) 
                    .map((item, index) => (
                      <li key={index}><i className="fas fa-check"></i>{item.trim()}</li>
                    ))
                  )}
                </ul>
                {/* <div className="desc-hover">
                  <p dangerouslySetInnerHTML={{ __html: report?.description }}></p>
                </div> */}
                {/* <ul class="ul-hover-box"><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li></ul> */}
                <div className="btns-hover">
                      {/* <h5>${report?.current_price}</h5> */}
                      <button onClick={()=>{setOpen2(true);setReportId(report?.id)}}>{t("Add your review")}</button>
                    </div>
              </div>
            </div>
                ))
              )}
            </div>
          </div>
          <ModalReviewReport open={open2} onClose={onCloseModal2} reportId={reportId} />
        </>
      )}
      {activeTab === 2 && (
        <>
          <div className="table-filter">
            <ul className="filter-item">
              <li>
                <p>{t('my_reports.filter')}</p>
              </li>
              <li>
                <div className="drop-down-con" ref={dropDownRef}>
                  <div className="drop-down" onClick={handleDropDown}>
                    <BiCategoryAlt />
                    <p>{dropDownChoose ? dropDownChoose : t('my_reports.category')}</p>
                    <button className="search-btn">
                      <i className="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div className={`drop ${dropDown ? "" : "d-none"}`}>
                  <p onClick={handleShowAllReports}>
                    {t('all')}
                  </p>
                    {ReportsDataCta?.map((reportCta, idx) => (
                      <p
                        key={idx}
                        onClick={() => {
                          setDropDownChoose(reportCta?.name);
                          setSelectedCategory(reportCta?.name);
                        }}
                      >
                        {reportCta?.name}
                      </p>
                    ))}
                  </div>
                </div>
              </li>
            </ul>
            <div id="tablefilter" className="filter-reports">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={t('my_reports.search')}
                    aria-controls="DataTables_Table_0"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </label>
              </div>
              <div className="display-table-card">
                <p
                  onClick={() => handleActiveTab(1)}
                  className={`${activeTab === 1 ? "active" : ""}`}
                >
                  <BsPostcard />
                </p>
                <p
                  onClick={() => handleActiveTab(2)}
                  className={`${activeTab === 2 ? "active" : ""}`}
                >
                  <LuTable />
                </p>
              </div>
            </div>
          </div>
          <TableReports MyReportsData={filteredReports} />
        </>
      )}
    </>
  );
};

export default MyReports;
