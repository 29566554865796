import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ImageDisplay = ({ image }) => {
  const [imageURL, setImageURL] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (image instanceof File) {
      const fileURL = URL.createObjectURL(image);
      setImageURL(fileURL);

      return () => URL.revokeObjectURL(fileURL);
    }
  }, [image]);

  return (
    <div className={`profile-img ${i18n.language === 'ar' ? "ml-30" : ""}`}>
      {image instanceof File ? (
        <img src={imageURL} alt="Uploaded" />
      ) : (
        <img src={image} alt="Uploaded" />
      )}
    </div>
  );
};

export default ImageDisplay;
