import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetTicketsQuery } from "../../app/feature/API/Tickets";

const TicketSupport = () => {
  const { t } = useTranslation();
  const { data: ticketsStatus } = useGetTicketsQuery('');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTickets = ticketsStatus?.filter(ticket =>
    ticket.subject.toLowerCase().includes(searchTerm.toLowerCase()) || 
    ticket.ticket_number.includes(searchTerm)
  );

  return (
    <>
      <div className="dashboard-header">
        <div className="main-title">
          <h3>{t('ticket_support.title')}</h3>
          <div id="tableinfo"></div>
        </div>
      </div>
      <div className="text-ticket-support">
        <h1 className="title-ticket">{t('ticket_support.how_can_we_help')}</h1>
        <div style={{position:'relative',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <div className="form-search-ticket-support">
            <div className="search-support">
              <i className="icon fas fa-search"></i>
              <input
                type="text"
                placeholder={t('ticket_support.enter_search_term')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} 
              />
          </div>
          </div>
          {searchTerm&&(
            <div className="ticket-results-search">
              {filteredTickets?.length ? (
                <ul>
                  {filteredTickets.map((ticket) => (
                    <Link  key={ticket.id} to={`/user-dashboard/ticket-details/${ticket.ticket_number}`} style={{borderBottom:'1px solid #eee',paddingBottom:"5px",display:"flex",alignItems:"center"}}>
                      <p style={{marginRight:'5px'}}>#{ticket.ticket_number} - </p>
                      <p style={{marginRight:'5px'}}>{ticket.subject.slice(0,50)}</p>
                      <p style={{marginRight:'5px'}}> - {ticket.status}</p>
                    </Link>
                  ))}
                </ul>
              ) : (
                <p className="text-center">{t('No_Data')}</p>
              )}
            </div>
          )}
        </div>
        <div className="btn-ticket-support">
          <Link
            to="/user-dashboard/new-ticket"
            className="theme-btn me-sm-4 wow fadeInLeft btn-explore border-raduis height"
            data-wow-duration="1.2s"
            data-wow-delay=".8s"
          >
            {t('ticket_support.new_ticket')}
          </Link>
          <Link
            to="/user-dashboard/ticket-status"
            className="theme-btn me-sm-4 wow fadeInLeft btn-explore border-raduis height"
            data-wow-duration="1.2s"
            data-wow-delay=".8s"
          >
            {t('ticket_support.check_ticket_status')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TicketSupport;
