import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForgetPasswordMutation } from "../../../app/feature/API/User";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha"; // Import the ReCAPTCHA component

export default function ForgetPasswordForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: '' });
  const [recaptchaToken, setRecaptchaToken] = useState(null); // State for reCAPTCHA token
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();
  const navigate = useNavigate();

  const validate = () => {
    let isValid = true;
    const errors = { email: '' };

    if (!email) {
      errors.email = t('login.emailRequired');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = t('login.emailInvalid');
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    if (validate() && recaptchaToken) { 
      // Check if reCAPTCHA is verified
      try {
        const response = await forgetPassword( email ).unwrap(); 
        toast.success(response.message || "Form submitted successfully");
        localStorage.setItem('emailForgetPassword', email);
        navigate('/reset-password');
        setEmail('');
        setRecaptchaToken(null); // Reset token after successful submission
      } catch (err) {
        toast.error("هناك مشكله في البيانات المرسله");
      }
    } else {
      toast.error(t('captchaRequired')); 
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token); // Set the reCAPTCHA token when verified
  };

  return (
    <div className="form-container sign-in-container">
      <form onSubmit={handleForgetPassword}>
        <h1>{t('login.forget')}</h1>
        <div className="flex-required-input">
          <input
            type="email"
            placeholder={t('login.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="astric">*</span>
        </div>
        <div style={{ width: '100%', textAlign: 'start' }}>
          {errors.email && (
            <span style={{ color: 'red', fontSize: '14px', display: 'block' }}>
              {errors.email}
            </span>
          )}
        </div>
        <div className="mb-2">
        <ReCAPTCHA
          sitekey="6LcYG20qAAAAABJgHbWG60SBUQ7EUw3YX0f_Pt6S" // Replace with your reCAPTCHA site key
          onChange={handleRecaptchaChange} // Handle token change
        />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? t('login.loading') : t('login.resetPassword')}
        </button>
      </form>
    </div>
  );
}
