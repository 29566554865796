import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MainProfile from './tabs/MainProfile';
import Security from './tabs/Security';
// import Preferences from './tabs/Preferences';
import Plan_Billing from './tabs/Plan_Billing';
import Notifications from './tabs/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTabSlice } from '../../../app/feature/TabSlice';
import { useGetActivitiesQuery } from '../../../app/feature/API/Profile';

const Profile = () => {
  const { t, i18n } = useTranslation();
  const activeTabSlice = useSelector((state) => state.tabs.activeTabSlice);
  const dispatch = useDispatch();

  const handleActiveTabSlice = (number) => {
    dispatch(setActiveTabSlice(number));
  };
  const { data: activities } = useGetActivitiesQuery();

  return (
    <div className="page-content content mt-100 mb-50">
      <div className="container">
        <div className="page-back-btn">
          <Link to="/user-dashboard">
            <i className="fas fa-arrow-left me-2"></i>{t('Back to Dashboard')}
          </Link>
        </div>
        <div className="settings-page-lists">
          <ul className="settings-head">
            <li onClick={() => handleActiveTabSlice(1)}>
              <a className={`${activeTabSlice === 1 ? 'active' : ''}`}>
                {t('Profile')}
              </a>
            </li>
            <li onClick={() => handleActiveTabSlice(2)}>
              <a className={`${activeTabSlice === 2 ? 'active' : ''}`}>
                {t('Security')}
              </a>
            </li>
            {/* <li onClick={() => handleActiveTabSlice(3)}>
              <a className={`${activeTabSlice === 3 ? 'active' : ''}`}>
                {t('Preferences')}
              </a>
            </li> */}
            <li onClick={() => handleActiveTabSlice(4)}>
              <a className={`${activeTabSlice === 4 ? 'active' : ''}`}>
                {t('Plan & Billing')}
              </a>
            </li>
            <li onClick={() => handleActiveTabSlice(5)}>
              <a className={`${i18n.language==='ar'?"mr-25":""} ${activeTabSlice === 5 ? 'active' : ''}`}>
                {t('Notifications')}
              </a>
            </li>
          </ul>
        </div>
        {activeTabSlice === 1 && <MainProfile />}
        {activeTabSlice === 2 && <Security />}
        {activeTabSlice === 4 && <Plan_Billing />}
        {activeTabSlice === 5 && <Notifications />}
        {activeTabSlice === 3 && (
          <div className="col-lg-6">
          <div className="settings-card">
            <div className="settings-card-head">
              <h4>{t('Account Activity')}</h4>
            </div>
            <div className="settings-card-body" style={{maxHeight:'600px',overflow:'auto'}}>
              <span className="changed-info">
              {activities && activities.length > 0 && (
                <>
                  {activities.map((activity, index) => (
                    <div key={index}>
                      {activity.action}{" "}
                      {new Date(activity.updated_at).toLocaleString(
                        `${i18n.language === 'en' ? "en" : "ar"}-EG`,
                        { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
                      )}
                    </div>
                  ))}
                </>
              )}
              </span>
            </div>
          </div>
        </div>

        )}
      </div>
    </div>
  );
};

export default Profile;
