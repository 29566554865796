// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logoImg from "../../assets/img/services/logofooter.jpg";
import { Link } from "react-router-dom";
import { useFetchExtraSettingQuery, useFetchGeneralSettingQuery, useSaveNewsletterMutation } from "../../app/feature/API/Helpers";
import { useState } from "react";
import { toast } from "react-toastify";

const FooterFour = () => {
  const [t,i18n] = useTranslation();
  const [email, setEmail] = useState("");
  const [saveNewsletter , {isLoading:isLoadingNewSletter}] = useSaveNewsletterMutation();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const { data } = useFetchExtraSettingQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveNewsletter({ email }).unwrap();
      toast.success(i18n.language === "en" ? "Subscribed successfully!" : "تم الاشتراك بنجاح!");
      setEmail("");
    } catch (error) {
      toast.error(i18n.language === "en" ? "Subscription failed!" : "فشل الاشتراك!");
    }
  };

  return (
    <>
      <footer className="footer-4 footer-wrap">
        <div className="footer-widgets-wrapper text-white col-sm-text-center">
          <div className="container">
            <div className="row" data-aos="fade-in">
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="footer-site-info me-lg-5 mt-40">
                  <div className="footer-site-info me-lg-5 mt-40">
                    <div className="wid-title col-sm-flex-center-center">
                      <a href="/">
                      {GeneralSetting?.map((footer, index)=>(
                          <img src={footer?.footer_logo || logoImg} alt="Mied Logo" />
                      ))}
                      </a>
                    </div>
                    <ul>
                      {GeneralSetting?.map((footer, index)=>(
                        <li key={index}>
                          {footer?.footer_text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-2 pl-xl-5 col-12">
                <div className="single-footer-wid">
                  {t("footer2", { returnObjects: true }).map((fot) => (
                    <>
                      <div className="wid-title">
                        <h3>{t(fot.title)}</h3>
                      </div>
                      <ul>
                        {data?.ulink?.map((extra,idx)=>
                          <li key={idx}>
                            <Link to={extra.url}>{extra.name}</Link>
                          </li>
                        )}
                      </ul>
                      {/* <ul>
                        {fot.links.map((subItem) => (
                          <li key={subItem.id}>
                            <Link to={subItem.link}>{t(subItem.name)}</Link>
                          </li>
                        ))}
                      </ul> */}
                    </>
                  ))}
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 col-12">
                <div className="single-footer-wid newsletter_widget style-2">
                  <div className="wid-title">
                    <h3>{i18n.language==="en"?"INFO":"معلومات"}</h3>
                  </div>
                  <div className="">
                    <ul className="mt-25">
                      <li className="single-info-footer">
                        <i className="flaticon-phone-call-1" />
                        {GeneralSetting?.map((footer, index) => (
                          <a key={index} href={`tel:${footer?.support_phone}`} 
                           style={i18n.language==='en'?{direction:'ltr'}:{direction:'ltr'}}>
                            <span>{footer?.support_phone}</span>
                          </a>
                        ))}
                      </li>
                      <li className="single-info-footer">
                        <i className="flaticon-email" />
                        {GeneralSetting?.map((footer, index) => (
                          <a key={index} href={`mailto:${footer?.support_email}`}>
                            <span>{footer?.support_email}</span>
                          </a>
                        ))}
                      </li>
                      <li className="single-info-footer">
                        <i className="flaticon-pin" />
                        {data?.extra?.map((extra,idx)=>
                          <span key={idx}>{extra?.contact_addresses}</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-3 col-12">
                <div className="single-footer-wid newsletter_widget style-2">
                  <div className="wid-title">
                    <h3>{i18n.language==="en"?"Newsletter":"النشرة الإخبارية"}</h3>
                  </div>
                  {GeneralSetting?.map((footer, index) => (
                  <p key={index}>
                      {footer?.newsletter_text}
                  </p>
                        ))}
                  <div className="newsletter_box">
                  <form onSubmit={handleSubmit} className="mt-10">
                    <i className="fal fa-envelope" />
                    <input
                      style={{paddingRight:i18n.language==='en'?"0":"60px"}}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={`${i18n.language === "en" ? "Enter email address" : "أدخل عنوان البريد الالكتروني"}`}
                      required
                    />
                    <button className="submit-btn" type="submit">
                      {isLoadingNewSletter ? (
                        i18n.language === "en" ? "JOIN..." : "جارٍ التحميل..."
                      ) : (
                        i18n.language === "en" ? "JOIN" : "ادخل"
                      )}
                    </button>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center" style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
              <div className="col-lg-5 order-3 mt-3 mt-lg-0 order-lg-1 col-12 text-center text-md-start">
                <div className="copyright-info">
                {GeneralSetting?.map((footer, index) => (
                  <p key={index} style={{textWrap:'nowrap'}}>
                    {footer?.copyright_text.replace(/<[^>]+>/g, '')}
                  </p>
                ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterFour;
