import "../../assets/Sass/StyleDashboard.scss";
import author from "../../assets/img/services/user-01.jpg";
import dashboard from "../../assets/img/icons/dashboard-icon-01.svg";
import gigs from "../../assets/img/icons/dashboard-icon-02.svg";
import purchase from "../../assets/img/icons/dashboard-icon-03.svg";
import sales from "../../assets/img/icons/dashboard-icon-04.svg";
import files from "../../assets/img/icons/dashboard-icon-05.svg";
import review from "../../assets/img/icons/dashboard-icon-06.svg";
import wishlist from "../../assets/img/icons/dashboard-icon-07.svg";
import messages from "../../assets/img/icons/dashboard-icon-08.svg";
import wallet from "../../assets/img/icons/dashboard-icon-09.svg";
import payment from "../../assets/img/icons/dashboard-icon-10.svg";
import profile from "../../assets/img/icons/user-icon.svg";
import reports from "../../assets/img/icons/Reports.svg";
import plan from "../../assets/img/icons/wallet-icon.svg";
import support from "../../assets/img/icons/blog-icon.svg";

import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveTabSlice } from "../../app/feature/TabSlice";
import { useTranslation } from "react-i18next";
import { useGetProfileQuery } from "../../app/feature/API/Profile";

const HomeDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleActiveTabSlice = (number) => {
    dispatch(setActiveTabSlice(number));
  };
  const {t} = useTranslation()
  const { data: ProfileData } = useGetProfileQuery();
  return (
    <div className="page-content content mt-100 mb-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-xl-3 theiaStickySidebar">
            <div className="user-sidebar">
              <div className="user-head">
                <span className="flex-shrink-0">
                  <img src={ProfileData?.photo} className="img-fluid" alt="img" />
                </span>
                <div className="user-information">
                  <div>
                    <h6>{ProfileData?.first_name}{" "}{ProfileData?.last_name}</h6>
                    <ul>
                      <li>{ProfileData?.country?.name}</li>
                    </ul>
                  </div>
                  <Link to="/user-dashboard/my-profile" className="user-edit">
                    <i className="fas fa-user-edit"></i>
                  </Link>
                </div>
              </div>
              <div className="user-body">
                <ul>
                  {/* <li>
                    <Link
                      to="/user-dashboard"
                      className={`${
                        location.pathname === "/user-dashboard" ? "active" : ""
                      }`}
                    >
                      <img src={dashboard} class="img-fluid" alt="img" />
                      {t('dashboard')}
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/user-dashboard/my-profile"
                      className={`${
                        location.pathname === "/user-dashboard/my-profile"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleActiveTabSlice(1)}
                    >
                      <img src={profile} className="img-fluid" alt="img" />
                      {t('myProfile')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-dashboard/my-reports"
                      className={`${
                        location.pathname === "/user-dashboard/my-reports"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img src={reports} className="img-fluid" alt="img" />
                      {t('myReports')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-dashboard/ticket-support"
                      className={`${
                        location.pathname === "/user-dashboard/ticket-support"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img src={support} className="img-fluid" alt="img" />
                      {t('ticketSupport')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-dashboard/my-wishlist"
                      className={`${
                        location.pathname === "/user-dashboard/my-wishlist"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img src={wishlist} className="img-fluid" alt="img" />
                      {t('myWishlist')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-dashboard/my-reviews"
                      className={`${
                        location.pathname === "/user-dashboard/my-reviews"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img src={review} className="img-fluid" alt="img" />
                      {t('myReviews')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-dashboard/my-profile"
                      className={`${
                        location.pathname === "/user-dashboard/my-profile"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleActiveTabSlice(4)}
                    >
                      <img src={plan} className="img-fluid" alt="img" />
                      {t('plansBilling')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-dashboard/payments"
                      className={`${
                        location.pathname === "/user-dashboard/payments"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img src={payment} className="img-fluid" alt="img" />
                      {t('payments')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/user-gigs">
                      <img
                        src={gigs}
                        className="img-fluid"
                        alt="img"
                      />
                      Manage Gigs
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-purchase">
                      <img
                        src={purchase}
                        className="img-fluid"
                        alt="img"
                      />
                      Purchase
                    </Link>
                  </li>
                  <li>
                    <Link href="/user-sales">
                      <img
                        src={sales}
                        className="img-fluid"
                        alt="img"
                      />
                      Sales
                    </Link>
                  </li> 
                  <li>
                    <Link href="/user-files">
                      <img
                        src={files}
                        className="img-fluid"
                        alt="img"
                      />
                      Files
                    </Link>
                  </li>
                  <li>
                    <Link href="/user-reviews">
                      <img
                        src={review}
                        className="img-fluid"
                        alt="img"
                      />
                      My Reviews
                    </Link>
                  </li>
                   <li>
                    <Link to="/user-message">
                      <img
                        src={messages}
                        className="img-fluid"
                        alt="img"
                      />
                      Messages
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-wallet">
                      <img
                        src={wallet}
                        className="img-fluid"
                        alt="img"
                      />
                      Wallet
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
