import { useEffect, useState } from "react";
import logo from "../../../assets/img/logo.jpg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useGetReportsQuery } from "../../../app/feature/API/Reports";
import { useAddWishlistMutation } from "../../../app/feature/API/Profile";

const CatOneReport = () => {
  const { t, i18n } = useTranslation();
  const [activeCat2, setActiveCat2] = useState(1);
  const [isNearRightEdge, setIsNearRightEdge] = useState(false);

  const handleActiveTab = (number) => {
    setActiveCat2(number);
  };


  const queryParams = {
    type: activeCat2 === 1 ? "" : activeCat2 === 2 ? "trending" : "most_downloaded"
  };

  const { data: AllReports } = useGetReportsQuery(queryParams);

  const addToCart = (report) => {
    const token = localStorage.getItem("token_Front_Mied");
    if (!token) {
      toast.error(t("loginToAddCart"));
      return;
    }

    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const isItemInCart = cartItems.some(item => item.slug === report.slug);
    
    if (isItemInCart) {
      toast.warning(t("alreadyInCart", { title: report.title }));
    } else {
      cartItems.push(report);
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      toast.success(t("addedToCart", { title: report.title }));
    }
  };

  const [addFavorite] = useAddWishlistMutation();

  const addToFavorites = async (report) => {
    const token = localStorage.getItem("token_Front_Mied");
    if (!token) {
      toast.error(t("loginToAddFavorites"));
      return;
    }
    try {
      const response = await addFavorite({ id: report.id }).unwrap();
      toast.success(t("addedToFavorites", { title: report.title }));
      console.log("responseFav(Wishlist)=>", response);
    } catch (error) {
      toast.error(t("errorAddingToFavorites"));
    }
  };

  useEffect(() => {
    const handleMouseEnter = (e) => {
      const gridContainer = document.querySelector(".boxesss-section");
      const gridItems = document.querySelectorAll(".box-report");
      if (gridItems.length === 0) return;

      const gridItemWidth = gridItems[0]?.getBoundingClientRect().width;
      const gridContainerWidth = gridContainer?.offsetWidth;
      const itemsInOneRow = Math.floor(gridContainerWidth / gridItemWidth);

      let order = Array.from(gridContainer.children).indexOf(e.target) + 1;
      setIsNearRightEdge(order % itemsInOneRow === 0);
    };

    const gridItems = document.querySelectorAll(".box-report");
    gridItems.forEach((div) => {
      div.addEventListener("mouseenter", handleMouseEnter);
    });

    return () => {
      gridItems.forEach((div) => {
        div.removeEventListener("mouseenter", handleMouseEnter);
      });
    };
  }, [AllReports]);

  return (
    <section className="container-reports" data-aos="fade-in">
      <div className="row mt-20">
        <div className="block-contents text-center mb-30">
          <div className="catOneCat">
            <li
              className={`latest-reports text-start catOneCat-li ${
                activeCat2 === 1 ? "active-catOne" : ""
              }`}
              onClick={() => handleActiveTab(1)}
            >
              {t("latest_reports")}
            </li>
            <li
              className={`latest-reports text-start catOneCat-li ${
                activeCat2 === 2 ? "active-catOne" : ""
              }`}
              onClick={() => handleActiveTab(2)}
            >
              {t("trending_reports")}
            </li>
            {/* <li
              className={`latest-reports text-start catOneCat-li ${
                activeCat2 === 3 ? "active-catOne" : ""
              }`}
              onClick={() => handleActiveTab(3)}
            >
              {t("most_downloaded")}
            </li> */}
          </div>
        </div>

        {activeCat2 === 1 && (
          <div className="boxesss-section mb-50 dire-ltr" data-aos="fade-up"  style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
          {AllReports?.slice(0, 10)?.map((report) => (
            <div className="box-report" key={report?.slug}>
            <div className="bg-white">
              <div className="top-box-report">
                <div className="header-box-report">
                  <div className="flex-center">
                    <div className="logo">
                      <img src={logo} alt="" loading="lazy" />
                    </div>
                    <div className="user-information w-70">
                    {report.review !== undefined && report.total_reviews !== undefined && (
                      <ul>
                        <li>
                          <i className="fas fa-star"></i>{" "}
                          {Number(report.review) !== 0 ? report.review : ""}
                          {Number(report.total_reviews) !== 0 ? ` (${report.total_reviews})` : ""}
                        </li>
                      </ul>
                    )}
                    </div>
                  </div>
                  <div className="text-desc">
                    {i18n.language==='en'?report?.title:report?.title_ar}
                  </div>
                </div>
                <div className="body-box-report">
                  <img src={report?.feature_image} alt="" />
                </div>
              </div>
              <div className="bottom-box-report">
{i18n.language === 'en' ? (
  <p>
    {report?.description
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first sentence
      ?.trim() || t("No_Data")} {/* Fallback if no data */}
  </p>
) : (
  <p>
    {report?.description_ar
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first sentence
      ?.trim() || t("No_Data")} {/* Fallback if no data */}
  </p>
)}
{/* Uncomment this if summary is to be used as a fallback or additional data */}
{/* <p>{report?.summary || <p dangerouslySetInnerHTML={{__html:i18n.language==='en' ? report?.description : report?.description_ar}}></p>}</p> */}
</div>

            </div>
            <div
              className={`${
                isNearRightEdge ? "right-screen-hover" : `${i18n.language !== 'ar' ? "hover-box-report":"hover-box-report hover-box-report-ar"}`
              }`} 
            >
              <p className="main-title">
              {i18n.language==='en'?report?.title:report?.title_ar}
              </p>
              <div className="subtitle-hover">
                <span className="best-hover-box">{report?.category}</span>
                <p className="updated-hover-box">
                  {i18n.language==='en'?"Updated":"تم التحديث"} <span className="date-hover-box">{new Date(report?.updated_at).toLocaleDateString(i18n.language==='en'?'en-US':'ar-US', { year: 'numeric', month: 'long' })}</span>
                </p>
              </div>
              <div className="time-zone-hover">
                <p> {report?.page || report.pages || 28} {i18n.language==='en'?"Pages":"الصفحات"} </p>
              </div>
              <div className="desc-hover">
{i18n.language === 'en' ? (
  <p>
    {report?.description
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first non-empty sentence
      ?.trim() || t("No_Data")} {/* Fallback if no valid data */}
  </p>
) : (
  <p>
    {report?.description_ar
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first non-empty sentence
      ?.trim() || t("No_Data")} {/* Fallback if no valid data */}
  </p>
)}
</div>

              <ul className={`${i18n.language === 'en' ? "ul-hover-box" : "ul-hover-box-ar"}`}>
{report &&
  (i18n.language === 'en' ? report.description : report.description_ar) ? (
    (i18n.language === 'en' ? report.description : report.description_ar)
      .replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean) // Remove empty strings
      .slice(1) // Exclude the first sentence
      .map((item, index) => (
        <li key={index}>
          <i className="fas fa-check"></i> {item.trim()}
        </li>
      ))
  ) : (
    <li>{t("No_Data")}</li>
  )}
</ul>


              {/* <div className="desc-hover">
                <p dangerouslySetInnerHTML={{ __html: report?.description }}></p>
              </div> */}
              {/* <ul class="ul-hover-box"><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li></ul> */}
              <div className="btns-hover">
              <h5>
                {Number(report?.current_price) === 0 ? i18n.language==='en'?"Free":"مجاني" : `$${report?.current_price}`}
              </h5>
                <button onClick={() => addToCart(report)}>{t("Add to cart")}</button>
                <div className="heart-hover" onClick={()=>addToFavorites(report)}>
                  <i className="fas fa-heart-circle"></i>
                </div>
              </div>
            </div>
          </div>
          ))}
          </div>
        )}

        {activeCat2 === 2 && (
          <div className="boxesss-section mb-50 dire-ltr" data-aos="fade-up"  style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
          {AllReports?.slice(0, 10)?.map((report) => (
            <div className="box-report" key={report?.slug}>
            <div className="bg-white">
              <div className="top-box-report">
                <div className="header-box-report">
                  <div className="flex-center">
                    <div className="logo">
                      <img src={logo} alt="" loading="lazy" />
                    </div>
                    <div className="user-information w-70">
                    {report.review !== undefined && report.total_reviews !== undefined && (
                      <ul>
                        <li>
                          <i className="fas fa-star"></i>{" "}
                          {Number(report.review) !== 0 ? report.review : ""}
                          {Number(report.total_reviews) !== 0 ? ` (${report.total_reviews})` : ""}
                        </li>
                      </ul>
                    )}
                    </div>
                  </div>
                  <div className="text-desc">
                    {i18n.language==='en'?report?.title:report?.title_ar}
                  </div>
                </div>
                <div className="body-box-report">
                  <img src={report?.feature_image} alt="" />
                </div>
              </div>
              <div className="bottom-box-report">
{i18n.language === 'en' ? (
  <p>
    {report?.description
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first sentence
      ?.trim() || t("No_Data")} {/* Fallback if no data */}
  </p>
) : (
  <p>
    {report?.description_ar
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first sentence
      ?.trim() || t("No_Data")} {/* Fallback if no data */}
  </p>
)}
{/* Uncomment this if summary is to be used as a fallback or additional data */}
{/* <p>{report?.summary || <p dangerouslySetInnerHTML={{__html:i18n.language==='en' ? report?.description : report?.description_ar}}></p>}</p> */}
</div>

            </div>
            <div
              className={`${
                isNearRightEdge ? "right-screen-hover" : `${i18n.language !== 'ar' ? "hover-box-report":"hover-box-report hover-box-report-ar"}`
              }`} 
            >
              <p className="main-title">
              {i18n.language==='en'?report?.title:report?.title_ar}
              </p>
              <div className="subtitle-hover">
                <span className="best-hover-box">{report?.category}</span>
                <p className="updated-hover-box">
                  {i18n.language==='en'?"Updated":"تم التحديث"} <span className="date-hover-box">{new Date(report?.updated_at).toLocaleDateString(i18n.language==='en'?'en-US':'ar-US', { year: 'numeric', month: 'long' })}</span>
                </p>
              </div>
              <div className="time-zone-hover">
                <p> {report?.page || report.pages || 28} {i18n.language==='en'?"Pages":"الصفحات"} </p>
              </div>
              <div className="desc-hover">
{i18n.language === 'en' ? (
  <p>
    {report?.description
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first non-empty sentence
      ?.trim() || t("No_Data")} {/* Fallback if no valid data */}
  </p>
) : (
  <p>
    {report?.description_ar
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first non-empty sentence
      ?.trim() || t("No_Data")} {/* Fallback if no valid data */}
  </p>
)}
</div>

              <ul className={`${i18n.language === 'en' ? "ul-hover-box" : "ul-hover-box-ar"}`}>
{report &&
  (i18n.language === 'en' ? report.description : report.description_ar) ? (
    (i18n.language === 'en' ? report.description : report.description_ar)
      .replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean) // Remove empty strings
      .slice(1) // Exclude the first sentence
      .map((item, index) => (
        <li key={index}>
          <i className="fas fa-check"></i> {item.trim()}
        </li>
      ))
  ) : (
    <li>{t("No_Data")}</li>
  )}
</ul>


              {/* <div className="desc-hover">
                <p dangerouslySetInnerHTML={{ __html: report?.description }}></p>
              </div> */}
              {/* <ul class="ul-hover-box"><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li></ul> */}
              <div className="btns-hover">
              <h5>
                {Number(report?.current_price) === 0 ? i18n.language==='en'?"Free":"مجاني" : `$${report?.current_price}`}
              </h5>
                <button onClick={() => addToCart(report)}>{t("Add to cart")}</button>
                <div className="heart-hover" onClick={()=>addToFavorites(report)}>
                  <i className="fas fa-heart-circle"></i>
                </div>
              </div>
            </div>
          </div>
          ))}
          </div>
        )}

        {activeCat2 === 3 && (
          <div className="boxesss-section mb-50 dire-ltr" data-aos="fade-up"  style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
          {AllReports?.slice(0, 10)?.map((report) => (
            <div className="box-report" key={report?.slug}>
            <div className="bg-white">
              <div className="top-box-report">
                <div className="header-box-report">
                  <div className="flex-center">
                    <div className="logo">
                      <img src={logo} alt="" loading="lazy" />
                    </div>
                    <div className="user-information w-70">
                    {report.review !== undefined && report.total_reviews !== undefined && (
                      <ul>
                        <li>
                          <i className="fas fa-star"></i>{" "}
                          {Number(report.review) !== 0 ? report.review : ""}
                          {Number(report.total_reviews) !== 0 ? ` (${report.total_reviews})` : ""}
                        </li>
                      </ul>
                    )}
                    </div>
                  </div>
                  <div className="text-desc">
                    {i18n.language==='en'?report?.title:report?.title_ar}
                  </div>
                </div>
                <div className="body-box-report">
                  <img src={report?.feature_image} alt="" />
                </div>
              </div>
              <div className="bottom-box-report">
{i18n.language === 'en' ? (
  <p>
    {report?.description
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first sentence
      ?.trim() || t("No_Data")} {/* Fallback if no data */}
  </p>
) : (
  <p>
    {report?.description_ar
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first sentence
      ?.trim() || t("No_Data")} {/* Fallback if no data */}
  </p>
)}
{/* Uncomment this if summary is to be used as a fallback or additional data */}
{/* <p>{report?.summary || <p dangerouslySetInnerHTML={{__html:i18n.language==='en' ? report?.description : report?.description_ar}}></p>}</p> */}
</div>

            </div>
            <div
              className={`${
                isNearRightEdge ? "right-screen-hover" : `${i18n.language !== 'ar' ? "hover-box-report":"hover-box-report hover-box-report-ar"}`
              }`} 
            >
              <p className="main-title">
              {i18n.language==='en'?report?.title:report?.title_ar}
              </p>
              <div className="subtitle-hover">
                <span className="best-hover-box">{report?.category}</span>
                <p className="updated-hover-box">
                  {i18n.language==='en'?"Updated":"تم التحديث"} <span className="date-hover-box">{new Date(report?.updated_at).toLocaleDateString(i18n.language==='en'?'en-US':'ar-US', { year: 'numeric', month: 'long' })}</span>
                </p>
              </div>
              <div className="time-zone-hover">
                <p> {report?.page || report.pages || 28} {i18n.language==='en'?"Pages":"الصفحات"} </p>
              </div>
              <div className="desc-hover">
{i18n.language === 'en' ? (
  <p>
    {report?.description
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first non-empty sentence
      ?.trim() || t("No_Data")} {/* Fallback if no valid data */}
  </p>
) : (
  <p>
    {report?.description_ar
      ?.replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean)[0] // Get the first non-empty sentence
      ?.trim() || t("No_Data")} {/* Fallback if no valid data */}
  </p>
)}
</div>

              <ul className={`${i18n.language === 'en' ? "ul-hover-box" : "ul-hover-box-ar"}`}>
{report &&
  (i18n.language === 'en' ? report.description : report.description_ar) ? (
    (i18n.language === 'en' ? report.description : report.description_ar)
      .replace(/<[^>]+>/g, '') // Remove HTML tags
      .trim() // Trim spaces
      .split('.') // Split into sentences
      .filter(Boolean) // Remove empty strings
      .slice(1) // Exclude the first sentence
      .map((item, index) => (
        <li key={index}>
          <i className="fas fa-check"></i> {item.trim()}
        </li>
      ))
  ) : (
    <li>{t("No_Data")}</li>
  )}
</ul>


              {/* <div className="desc-hover">
                <p dangerouslySetInnerHTML={{ __html: report?.description }}></p>
              </div> */}
              {/* <ul class="ul-hover-box"><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li></ul> */}
              <div className="btns-hover">
              <h5>
                {Number(report?.current_price) === 0 ? i18n.language==='en'?"Free":"مجاني" : `$${report?.current_price}`}
              </h5>
                <button onClick={() => addToCart(report)}>{t("Add to cart")}</button>
                <div className="heart-hover" onClick={()=>addToFavorites(report)}>
                  <i className="fas fa-heart-circle"></i>
                </div>
              </div>
            </div>
          </div>
          ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default CatOneReport;
