import { useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import SideBarArticles from "./SideBarArticles";
import img from "../../../assets/img/services/ex1.png";
import img2 from "../../../assets/img/services/ex2.png";
import { useEffect, useState } from "react";
import { useGetArticleDetailsQuery } from "../../../app/feature/API/Articles";
import { useTranslation } from "react-i18next";

const ContentArticles = () => {
  const { name } = useParams();
  const {data,isLoading} = useGetArticleDetailsQuery(name)
  const [activeExample, setActiveExample] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".sections-articles > div");
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActiveExample(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const {i18n} = useTranslation()

  return (
    <section
      className="blog-wrapper news-wrapper section-padding"
      id="sticky-section"
    >
      <div className="container">
      {isLoading &&         <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>}
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="title-header-topic">
              <h2>{name}</h2>
            </div>
            <div className="search-topic">
              <IoSearchOutline />
              <input type="text" placeholder={`${i18n.language==='en'?"Search here...":"ابحث هنا..."}`} />
            </div>
            <div className="content-topics">
              <div className="desc-articles mt-20">
                <p className="desc-app">
                  {data?.article?.meta_description}
                </p>
              </div>
              <div className="sections-articles section-article">
                <div id="ex1" className="pt-30">
                  <div className="title-header-topic">
                    <h2>{data?.article?.category_name}</h2>
                  </div>
                  <p className="desc-app">
                  <div dangerouslySetInnerHTML={{ __html: data?.article?.content || '' }} />
                  </p>
                  {/* <div className="image mt-10">
                    <img src={img} alt="" />
                  </div>
                  <p className="desc-app pt-10">
                    The position of the button may change depending on your
                    system configuration.
                  </p> */}
                </div>
                {/* <div id="ex2" className="pt-30">
                  <div className="title-header-topic">
                    <h2>Example 2</h2>
                  </div>
                  <p className="desc-app">
                    This section offers a detailed step-by-step guide for each
                    topic listed. Simply click on a link to jump to the specific
                    instructions for that article.
                  </p>
                  <div className="image mt-10">
                    <img src={img2} alt="" />
                  </div>
                </div>
                <div id="ex3" className="pt-30">
                  <div className="title-header-topic">
                    <h2>Example 3</h2>
                  </div>
                  <p className="desc-app">
                    This section offers a detailed step-by-step guide for each
                    topic listed. Simply click on a link to jump to the specific
                    instructions for that article.
                  </p>
                  <div className="image mt-10">
                    <iframe
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/EdR-5gen7L4"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Example Video"
                    ></iframe>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <SideBarArticles activeExample={activeExample} />
        </div>
      </div>
    </section>
  );
};

export default ContentArticles;
