import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetBlogsQuery , useGetBlogsCategoriesQuery} from "../../app/feature/API/Blogs";
import { useFetchExtraSettingQuery } from "../../app/feature/API/Helpers";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const { data: blogsData, error, isLoading } = useGetBlogsQuery({ title: search, page: 1 });
  const { data: blogsCategoriesData } = useGetBlogsCategoriesQuery();
  const { data } = useFetchExtraSettingQuery();

  return (
    <div className="col-12 col-lg-4">
      <div className="main-sidebar">
        <div className="single-sidebar-widget">
          <div>
            <h3> {i18n.language === "en" ? 'Search' : 'ابحث'}</h3>
          </div>
          <div className="search_widget">
            <form action="#">
              <input
              style={{paddingRight:i18n.language==='ar'?"75px":"0"}}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className={i18n.language==='ar'?"pr-80":""}
                placeholder={i18n.language === "en" ? 'Search your keyword...' : 'ابحث عن كلمتك الرئيسية...'}
              />
              <button type="submit">
                <i className="fal fa-search"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="single-sidebar-widget">
          <div>
            <h3>{i18n.language==='en'?"Popular":"شائع"}</h3>
          </div>
          <div className="popular-posts">
            {blogsData?.data?.slice(0,4).map((data) => (
              <div className="single-post-item" key={data.id}>
                <div
                  className="thumb bg-cover"
                  style={{ backgroundImage: `url(${data.main_image})` }}
                ></div>
                <div className="post-content">
                  <h5>
                    <Link to={data.link}>{data.title}</Link>
                  </h5>
                  <div className="post-date">
                    <i className="far fa-calendar-alt"></i>
                    {new Date(data?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="single-sidebar-widget">
          <div>
            <h3>{i18n.language==='en'?"Categories":"فئات"}</h3>
          </div>
          <div className="widget_categories">
            <ul>
              {blogsCategoriesData?.map((data) => (
                <li key={data.id}>
                  <Link>
                    <p  className={i18n.language==='ar'?"pr-40":""}>{data.name} </p> <span>{data.blog_count}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="single-sidebar-widget">
          <div>
            <h3>{i18n.language==='en'?"STAY UPDATED":"ابق على اطلاع"}</h3>
          </div>
          <div className="social-link">
        {data?.social
          ?.filter((social) =>
            ["fab fa-facebook-f", "fab fa-twitter", "fab fa-linkedin-in", "fab fa-instagram iconpicker-component"].includes(social?.icon)
          )
          .map((social, idx) => (
            <a key={idx} href={social?.url || "#"} target="_blank" rel="noreferrer">
              <i className={social.icon}></i>
            </a>
          ))}
      </div>
        </div>

        <div className="single-sidebar-widget">
          <div>
            <h3>{i18n.language==='en'?"Popular Tags":"الكلمات الشعبية"}</h3>
          </div>
          <div className="tagcloud">
            {blogsCategoriesData?.slice(0,8).map((data) => (
              <Link key={data.id} >
                {data.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
