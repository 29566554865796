import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const LogoutApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  reducerPath: "LogoutApi",
  tagTypes: ["LogoutApi"],
  endpoints: (build) => ({
    LogoutUser: build.mutation({
      query: () => ({
        url: `/logout`,
        method: "POST",
      }),
      invalidatesTags: ["LogoutApi"],
    }),
  }),
});

export const {
  useLogoutUserMutation
} = LogoutApi;
