import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useChangePasswordProfileMutation } from '../../app/feature/API/Profile';

const ChangePassword = () => {

  const { t } = useTranslation();
  
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [changePasswordProfile, { isLoading }] = useChangePasswordProfileMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (newPassword.length < 4) {
      toast.error(t('Password must be at least 4 characters long'));
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error(t('Password and confirmation do not match'));
      return;
    }

    try {
      await changePasswordProfile({
        password: newPassword,
        repeat_password: confirmPassword,
      });
      setNewPassword('');
      setConfirmPassword('');
      toast.success(t('Password changed successfully'));
    } catch (error) {
      toast.error(t('Failed to change password. Please try again.'));
    }
  };

  return (
    <div className="page-content content mt-100 mb-50">
      <div className="container">
        <div className="page-back-btn">
          <Link to="/user-dashboard/my-profile">
            <i className="fas fa-arrow-left me-2"></i>{t('Back to My Profile')}
          </Link>
        </div>
        <div className="Row">
          <div className="col-lg-6 mt-4">
            <div className="settings-card">
              <div className="settings-card-head">
                <h3>{t('Change Password')}</h3>
              </div>
              <div className="settings-card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>{t('New Password')}</label>
                    <input 
                      type="password" 
                      className="form-control" 
                      value={newPassword} 
                      onChange={(e) => setNewPassword(e.target.value)} 
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <label>{t('Confirm Password')}</label>
                    <input 
                      type="password" 
                      className="form-control" 
                      value={confirmPassword} 
                      onChange={(e) => setConfirmPassword(e.target.value)} 
                      required 
                    />
                  </div>
                  <button type="submit" className="btn btn-primary mt-3 btn-item" style={{borderRadius:'10px'}}>
                        <a  className="btn btn-primary border-raduis">
                          {isLoading? t('loading') : t('Change Password')}
                        </a>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
