import { useState } from "react";
import faqVideoBg from "../../assets/images/backgrounds/600d672e-8307-4991-a77b-b9422229e40f.jpg";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";
import { useGetFaqsQuery } from "../../app/feature/API/Faqs";
import { useGetHomeSectionsQuery } from "../../app/feature/API/CMS";
import { useTranslation } from "react-i18next";
const FaqOne = () => {
  const [expandedItem, setExpandedItem] = useState(null);
  const handleCollapse = (index) => {
    setExpandedItem((prevState) => (prevState === index ? null : index));
  };
  const {data:GeneralSetting} = useFetchGeneralSettingQuery();
  const {data:FaqData} = useGetFaqsQuery();
  const { data: aboutData, isLoading } = useGetHomeSectionsQuery('knowledgebase');
  const knowledgebaseSection = aboutData?.find(cta => cta.slug === 'knowledgebase_2');

  const {t, i18n} = useTranslation();


  return (
    <section className="faq-funfact-section section-padding" style={{direction:'ltr'}}>
      <div className="container">
        <div className="fun-fact-wrapper text-white mtm-30 text-center"></div>
        <div className="faq-wrapper section-padding pb-0" data-aos="fade-up">
          <div className="row equal">
            <div className="col-lg-6 col-12 col-xl-7 pe-lg-0">
            <div className="faq-content" style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
                <div className={i18n.language==='en'?"faq-accordion":"faq-accordion faq-accordion-ar"}>
                  <div className="accordion" id="accordion">
                    {FaqData?.map((data,idx) => (
                      <div className="accordion-item" key={idx}>
                        <h4 className="accordion-header">
                          <button
                           style={{paddingRight:i18n.language==='en'?"0":"60px"}}
                            onClick={() => handleCollapse(idx+1)}
                            className="accordion-button"
                            type="button"
                            aria-expanded={expandedItem === idx+1?"true":"false"}
                            aria-controls={idx+1}
                          >
                            {data?.question}
                          </button>
                        </h4>
                        <div
                          id={idx+1}
                          className={`accordion-collapse ${
                            expandedItem === idx+1 ? "show" : "collapse"
                          }`}
                          data-bs-parent="#accordion"
                        >
                          <div className="accordion-body desc-app" style={{paddingRight:i18n.language==='en'?"0":"60px"}}>{data?.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12 ps-lg-0">
              <div
                className="video-popup-wrapper d-flex justify-content-center align-items-center bg-cover bg-center"
                style={{ backgroundImage: `url(${knowledgebaseSection?.image})` }}
              >
              </div>
              <div className="box-cta-call d-flex align-items-center justify-content-between">
                <div className="icon">
                  <i className="flaticon-24-hours"></i>
                </div>
                <div className="content-text">
                  <span>Call for support</span>
                  {GeneralSetting?.map((phone, idx) => (
                    <h3  key={idx}>
                  <a href={`tel:${phone.support_phone}`} className="ml-10">
                    {phone.support_phone}
                  </a>
                    </h3>
                ))}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FaqOne;
