import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { MdAssuredWorkload } from "react-icons/md";
import { CgCalculator } from "react-icons/cg";
import { VscFilter } from "react-icons/vsc";
import { useGetJobsCategoriesQuery, useLazyGetJobsQuery } from "../../app/feature/API/Jobs";
import { useDispatch } from "react-redux";
import { setJobData } from "../../app/feature/SaveJobData";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const JobSearchOptions = () => {
  const {t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm')||"");
  const [selectedCategory, setSelectedCategory] = useState("");

  const { data: JobCategoryData } = useGetJobsCategoriesQuery();
  
  const [triggerSearch, { data: JobData ,isSuccess:JobDataSuccess , isLoading}] = useLazyGetJobsQuery(); 
  const handleSearch = () => {
    if (!searchTerm && !selectedCategory) {
      toast.warning(i18n.language==='en'?"Please, enter a search term or select a category.":"من فضلك، أدخل مصطلح بحث أو اختر فئة.");
      return; 
    }

    triggerSearch({
      jcategory_id: selectedCategory || undefined,
      title: searchTerm || undefined,
    });
  };

  useEffect(() => {
    if (JobDataSuccess) {
      if (JobData?.data?.length > 0) {
        // toast.warning(t('No_Data'));
        dispatch(setJobData(JobData));
      } else {
        // toast.success(t('No_Data'));
      }
    }
  }, [JobData, JobDataSuccess, dispatch]);

  const catId = Number(localStorage.getItem("selectedCategory"));

  useEffect(() => {
    if (JobCategoryData && catId) {
      const selectedCat = JobCategoryData.find((cta) => cta.id === catId);
      if (selectedCat) {
        setSelectedCategory(selectedCat.id); 
      }
    }
  }, [JobCategoryData, catId]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    localStorage.setItem("selectedCategory", categoryId); 
  };


  return (
    <React.Fragment>
      <div className="search-job-list mt-25 search-hero-seven">
        <div className={`form-search-hero-seven border-raduis-job-list border-job-list ${i18n.language === "en" ? "" : "dire-rtl "}`}>
          <MdAssuredWorkload className="svg-page-jop" />
          <input
            type="search"
            id="job-title"
            className="form-control filter-input-box"
            placeholder={
              i18n.language === "en"
                ? "Enter Keywords..."
                : "أدخل الكلمات الرئيسية.."
            }
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="filter-search-form flex-drop-down border-raduis-job-list border-job-list">
          <CgCalculator className="svg-page-jop" />
          <Input
            type="select"
            name="category"
            id="category"
            required
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">{i18n.language==="en"?"Select Category":"حدد الفئة"}</option>
            <option value="0">{i18n.language==="en"?"All":"الكل"}</option>
            {JobCategoryData?.map((cta, idx) => (
              <option key={idx} value={cta?.id}>
                {cta?.name}
              </option>
            ))}
          </Input>
        </div>
        <div className="wrap-btn">
          <button
            className="btn btn-primary submit-btn border-raduis-job-list btn-find-jop flter-btn-bg"
            type="submit"
            onClick={handleSearch}
          >
            <VscFilter className="search-jop svg-page-jop" />
            {isLoading 
              ? (i18n.language === "en" ? 'Loading...' : 'جار التحميل...') 
              : (i18n.language === "en" ? 'Filter' : 'ابحث')}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobSearchOptions;
