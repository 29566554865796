import { useLocation } from "react-router-dom";
import { useGetHomeSectionsQuery } from "../../../app/feature/API/CMS";
import img from "../../../assets/img/capabilities-img.jpg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const Capabilities = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState('');
  const [queryParamsSlug, setQueryParamsSlug] = useState('');
  useEffect(() => {
    if (location.pathname === '/whyegypt') {
      setQueryParams('whyegypt');
      setQueryParamsSlug('whyegypt_1');
    }
    else if(location.pathname === '/services') {
      setQueryParams('whatwedo');
      setQueryParamsSlug('whatwedo_cap');
    }
    else{
      setQueryParams('aboutus');
      setQueryParamsSlug('aboutus_cap');
    }
  }, [location.pathname]);

  const { data: aboutData, isLoading } = useGetHomeSectionsQuery(queryParams);
  const aboutSection = aboutData?.find(cta => cta.slug === queryParamsSlug);
  if (!aboutSection || aboutSection.show_sec !== 1) {
    return null;
  }
  
  return (
    <section className="sec-capabilities dire-ltr">
      <div className="capabilities">
        <div className={`flex-1 txt-capabilities ${i18n.language==='ar'?"mr-80":""}`} data-aos="fade-right">
          <div className={`words-capabilities ${i18n.language==='ar'?"words-capabilities-arabic":""}`}>
            <p className="text-white mb-30 txt-cap">
              {aboutSection?.sub}
            </p>
            <h2 className="text-white h2-capabilities mb-30">
            {aboutSection?.title}
            </h2>
            <p className="desc-cap-spacial" dangerouslySetInnerHTML={{__html:aboutSection?.dec}}></p>
          </div>
        </div>
        <div className="flex-1 image-capabilities" data-aos="fade-left">
          <div>
            <img src={aboutSection?.image} alt="" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Capabilities;
