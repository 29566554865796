import { useState } from 'react';
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineTopic } from "react-icons/md";
import { useGetArticleCategoriesQuery } from "../../../app/feature/API/Articles";
import SideBarTopic from "./SideBarTopic";
import { useTranslation } from 'react-i18next';

const TopicContent = () => {
  const { data: articleData, error, isLoading } = useGetArticleCategoriesQuery('');
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredTopics = articleData?.filter(topic =>
    topic.name.toLowerCase().includes(searchTerm)
  );
  const [t,i18n] = useTranslation()

  return (
    <section className="blog-wrapper news-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="title-header-topic text-center">
              <h2>{i18n.language==='en'?"All Topics":"كل المواضيع"}</h2>
              {/* <p className="desc-app">
                Learn how to use plugin shortcodes to add knowledge base
                sections anywhere in your content or templates
              </p> */}
            </div>
            <div className="search-topic">
              <IoSearchOutline />
              <input
                type="text"
                placeholder={`${i18n.language==='en'?"Search here...":"ابحث هنا..."}`}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="content-topics">
              {isLoading &&         <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>}
              {error && <p>Error: {error.message}</p>}
              {!isLoading && !error && !articleData?.length && <p>{t("No_Data")}</p>}
              {filteredTopics?.length > 0 && (
                <ul>
                  {filteredTopics.map((data, i) => (
                    <li key={i} className="all-topics">
                      <Link
                        to={`/topic/${data?.id}`}
                        className="topic-color list-topic"
                      >
                        <MdOutlineTopic /> {data?.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <SideBarTopic />
        </div>
      </div>
    </section>
  );
};

export default TopicContent;
