import { useEffect, useState } from "react";
import "../../assets/Sass/Auth.scss";
import Login from "./Login";
import Register from "./Register";
import AuthOverlay from "./AuthOverlay";
import { useNavigate } from "react-router-dom";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";

export default function AuthOne() {
  const [showRight, setShowRight] = useState(true);

  const signIn = () => {
    setShowRight(false);
  };
  const signUp = () => {
    setShowRight(true);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");

    if (token) {
      navigate("/");
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [navigate]);
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const login_register_ctrl = GeneralSetting?.map((e)=>e?.login_register_ctrl)

  useEffect(() => {

    if (Number(login_register_ctrl)===0) {
      navigate("/");
    }
  }, [login_register_ctrl, navigate]);
  
  return (
    <>
      <div
        className={`auth ${showRight ? "right-panel-active" : null}`}
        id="container"
      >
        <Register signIn={signUp} />
        <Login signUp={signIn} />
        <AuthOverlay signIn={signIn} signUp={signUp} />
      </div>
    </>
  );
}