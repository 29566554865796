import { useTranslation } from "react-i18next";
import { useFetchExtraSettingQuery } from "../../app/feature/API/Helpers";

const HeroSocials = () => {
  const [t, i18n] = useTranslation();
  const { data } = useFetchExtraSettingQuery();

  return (
    <div className="hero-social-elements d-none d-xxl-block">
      {/* <div className="long-arrow"></div> */}
      <div className="social-link">
        {data?.social
          // ?
          // .filter((social) =>
          //   ["fab fa-facebook-f", "fab fa-twitter", "fab fa-linkedin-in", "fab fa-instagram iconpicker-component"].includes(social?.icon)
          // )
          .map((social, idx) => (
            <a key={idx} href={social?.url || "#"} target="_blank" rel="noreferrer">
              <i className={social.icon}></i>
            </a>
          ))}
      </div>

      <div className={`${i18n.language === "ar" ? "flp-text-arabic" : "flp-text"}`} style={{paddingTop:i18n.language === "ar"?"0":"60px",paddingBottom:i18n.language === "ar"?"60px":"0"}}>
        <p>{t('HeroSocial')}</p>
      </div>
    </div>
  );
};

export default HeroSocials;
