import React from "react";
import { Col, Container, Row } from "reactstrap";
import PageBanner from "../Shared/PageBanner";
import bannerBg from "../assets/img/Banner/Top-Image6.jpg";
import JobSearchOptions from "../components/JobList/JobSearchOptions";
import Sidebar from "../components/JobList/Sidebar";
import Popular from "../components/JobList/Popular";
import JobVacancyList from "../components/JobList/JobVacancyList";
import { useGetBannersQuery } from "../app/feature/API/CMS";

const JobList = () => {
  const {data} = useGetBannersQuery('JobList')

  return (
    <React.Fragment>
      <PageBanner
        currentPage={data?.title}
        title={data?.title}
        bannerBg={data?.image}
      />
      <section className="section mt-100" style={{direction:'ltr'}}>
        <Container>
          <Row>
            <Col lg={9}>
              <div className="me-lg-5">
                <JobSearchOptions />
                <Popular />
                <JobVacancyList />
              </div>
            </Col>
            <Sidebar />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default React.memo(JobList);
