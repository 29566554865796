import { useGetMyWishlistQuery } from "../../app/feature/API/Profile";
import BoxWishlist from "./ReportsDashboard/BoxWishlist";
import { useTranslation } from "react-i18next"; 

const MyWishlist = () => {
  const { t ,i18n} = useTranslation();
  const { data , isLoading } = useGetMyWishlistQuery()
  if (isLoading) {
    return (
      <>
      {t("loading")} 
      <i className="fa fa-spinner fa-spin" /> 
      </>
    );
  }
  return (
    <>
      <div className="dashboard-header">
        <div className="main-title">
          <h3>{t("my_wishlist.title")}</h3> 
          {/* <p>{t("my_wishlist.showing_entries", { count: 7, total: 17 })}</p>  */}
        </div>
        <div className="head-info">
          <p>
            {/* {t("my_wishlist.total_wishlist")} <span>(17)</span> */}
          </p>
        </div>
      </div>
      <div className="row">
        <BoxWishlist  data={data}/>
      </div>
      {/* <div className="table-footer">
        <div id="tablepage">
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="DataTables_Table_0_paginate"
          >
            <ul className="pagination">
              <li
                className="paginate_button page-item previous disabled"
                id="DataTables_Table_0_previous"
              >
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="0"
                  tabIndex="0"
                  className="page-link"
                >
                  <i className="fa fa-angle-left"></i>{" "}
                </a>
              </li>
              <li className="paginate_button page-item active">
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="1"
                  tabIndex="0"
                  className="page-link"
                >
                  1
                </a>
              </li>
              <li
                className="paginate_button page-item next"
                id="DataTables_Table_0_next"
              >
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="3"
                  tabIndex="0"
                  className="page-link"
                >
                  {" "}
                  <i className=" fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>{" "}
      </div> */}
    </>
  );
};

export default MyWishlist;
