import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetJobsCategoriesQuery, useLazyGetJobsQuery } from "../../app/feature/API/Jobs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setJobData } from "../../app/feature/SaveJobData";
import { toast } from "react-toastify";

const Popular = () => {
  const [t,i18n] = useTranslation()
  const { data, error, isLoading } = useGetJobsCategoriesQuery();
  
    const dispatch = useDispatch();
  
    const [selectedCategory, setSelectedCategory] = useState("");
  
  
    
    const [triggerSearch, { data: JobData ,isSuccess:JobDataSuccess}] = useLazyGetJobsQuery(); 
  
    const handleSearch = (e) => {
      setSelectedCategory(e)
      triggerSearch({
        jcategory_id: selectedCategory || undefined,
        title: undefined,
      });
    };
  
    useEffect(() => {
      if (JobDataSuccess) {
        if (JobData?.data?.length > 0) {
          // toast.warning(t('No_Data'));
          dispatch(setJobData(JobData));
        } else {
          // toast.success(t('No_Data'));
        }
      }
    }, [JobData, JobDataSuccess, dispatch]);

  if (isLoading) {
    return <p>{t('loading')}</p>;
  }

  if (error) {
    return <p>Error loading data: {error.message}</p>;
  }

  const popular = data || []; 
  return (
    <React.Fragment>
      <div className="wedget-popular-title mt-minus-popular" style={{
      direction: i18n.language === 'en' ? 'ltr' : 'rtl'
    }}>
        <h6 className="serv-color">{i18n.language==="en"?"Popular":"شائع"}</h6>
        <ul className="list-inline">
          {(popular || []).slice(0,5).map((popularDetails) => (
            <li className="list-inline-item" key={popularDetails.id} onClick={()=>handleSearch(popularDetails.id)}>
              <div className="popular-box d-flex align-items-center">
                <div className="number flex-shrink-0 me-2 ms-2">
                  {popularDetails.job_count}
                </div>
                <Link to="#" className="primary-link stretched-link">
                  <p className="fs-14 mb-0 serv-color">{popularDetails.name}</p>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Popular;
