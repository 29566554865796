import { FaWhatsapp } from "react-icons/fa6";
import { useFetchExtraSettingQuery } from "../app/feature/API/Helpers";

const WhatsAppIcon = () => {
  const { data:GeneralSetting } = useFetchExtraSettingQuery();

  const whatsapp_number = GeneralSetting?.extra?.[0]?.whatsapp_number;

  const handleWhatsAppClick = () => {
    if (whatsapp_number) {
      const url = `https://wa.me/${whatsapp_number}`;
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="scroll-btn-wrapper whatsapp-btn-wrapper">
<button
  className="visible"
  type="button"
  onClick={handleWhatsAppClick}
  aria-label="Open WhatsApp Chat"
>
  <FaWhatsapp className="icon-whatsapp" />
</button>

    </div>
  );
};

export default WhatsAppIcon;
