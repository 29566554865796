import React, { useState } from "react"; // أضف useState
import { useTranslation } from "react-i18next";
import { useFetchExtraSettingQuery, useFetchPackagesQuery, useSubscribePackageMutation } from "../../../app/feature/API/Helpers";
import { toast } from "react-toastify";

const Pricing = () => {
  const { t } = useTranslation();
  const { data: PackageData } = useFetchPackagesQuery();
  const [subscribePackage, { isLoading }] = useSubscribePackageMutation();
  const [loadingPackageId, setLoadingPackageId] = useState(null);
  const { data } = useFetchExtraSettingQuery();

  const handleSubscribe = (packageId) => {
    setLoadingPackageId(packageId); 
    subscribePackage({ id: packageId })
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        setLoadingPackageId(null);
      })
      .catch((error) => {
        toast.error(error.data?.message || t("subscription_failed"));
        setLoadingPackageId(null); 
      });
  };
  const token = localStorage.getItem('token_Front_Mied')
  if(data?.extra.map((e)=>e?.active_pacakge !==1 )){
    return null;
  }
  return (
    <section
      className="pricing-wrapper section-padding pricing-bg"
      data-aos="fade-up"
      style={{paddingTop:'80px'}}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title">
                <h5>{t("subscribe")}</h5>
                <span>{t("smart_plans")}</span>
                <h2>{t("choose_your_plan")}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-two">
          <div className="container">
            <div className="row">
              {PackageData?.map((pkg) => (
                <div className="col-lg-4" key={pkg.id}>
                  <div className="pricing-two__single">
                    <div
                      className="pricing-two__top"
                      style={{
                        backgroundImage:
                          "linear-gradient(135deg, #3b1f5c, #695ea8) !important",
                      }}
                    >
                      <div className="pricing-two__bubble-1" />
                      <div className="pricing-two__bubble-2" />
                      <div className="pricing-two__bubble-3" />
                      <div className="pricing-two__bubble-4" />
                      <h3>{pkg.title}</h3>
                    </div>
                    <div className="pricing-two__bottom">
                      <DescriptionWithIcons description={pkg.description} />
                      <p>
                        <b>$</b> {pkg.price} <span>{t(pkg.duration)}</span>
                      </p>
                      <div className="text-center">
  {token ? (
    <button
      onClick={() => handleSubscribe(pkg.id)}
      className="thm-btn pricing-one__btn pricing-two__btn"
    >
      {isLoading && loadingPackageId === pkg.id ? (
        <>
          {t("loading")}
          <i className="fa fa-spinner fa-spin" />
        </>
      ) : (
        <>
          {t("choose_plan")}
          <i className="fa fa-angle-double-right" />
        </>
      )}
    </button>
  ) : (
<a
  href="#create_account"
  className="thm-btn pricing-one__btn pricing-two__btn"
  onClick={(e) => {
    e.preventDefault();
    const element = document.getElementById("create_account");
    window.scrollTo({
      top: element.offsetTop - 20,
      behavior: "smooth"
    });
  }}
>
  {t("choose_plan")}
  <i className="fa fa-angle-double-right" />
</a>

  )}
</div>

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;

const DescriptionWithIcons = ({ description }) => {
  const parts = description.split(/<br\s*\/?>/i);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <ul
      className="list-unstyled pricing-two__list border-none"
      style={{ marginRight: isArabic ? '50px' : '0px', marginLeft: !isArabic ? '50px' : '0px' }}
    >
      {parts.map((part, index) => (
        part.trim() && (
          <li key={index} style={{ textWrap: 'nowrap', textAlign: isArabic ? 'start' : 'start' }}>
            <i className="far fa-check" style={{ marginRight: !isArabic ? '6px' : '0px', marginLeft: isArabic ? '6px' : '0px' }} />
            <span
              dangerouslySetInnerHTML={{ __html: part }}
              style={{ display: 'inline-block' }}
            />
          </li>
        )
      ))}
    </ul>
  );
};
