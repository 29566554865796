import { useTranslation } from "react-i18next";
import bannerBg from "../../assets/img/exfrom.PNG";
import { useGetExportFromEgyptianSectionQuery } from "../../app/feature/API/CMS";
const Feature = () => {

  const {i18n} = useTranslation()
  const { data , isLoading } = useGetExportFromEgyptianSectionQuery()
  const ExportFromEgyptianSection = data?.data?.find(sec => sec.slug === 'whyegypt_213');
  const splitList = ExportFromEgyptianSection?.list
  ? [
    ExportFromEgyptianSection.list.slice(0, Math.ceil(ExportFromEgyptianSection.list.length / 2)), 
    ExportFromEgyptianSection.list.slice(Math.ceil(ExportFromEgyptianSection.list.length / 2))
  ]
  : [];
  if(ExportFromEgyptianSection?.show_sec !==1 ){
    return null;
  }
  return (
    <section className="our-service-wrapper section-padding bg-white">  
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title">
                <h5>{ExportFromEgyptianSection?.title}</h5>
                <span>{ExportFromEgyptianSection?.sub}</span>
                <h2>{ExportFromEgyptianSection?.title}</h2>
              </div>
              <p className="desc-exporter" dangerouslySetInnerHTML={{__html:ExportFromEgyptianSection?.dec}}>
              </p>
            </div>
          </div>
        </div>
        <div className="best-featured-wrapper section-padding">
          <div className="container">
            <div className="row mtm-40 align-items-center">
              <div className="col-lg-4 col-md-6 col-12" data-aos="fade-right">
                {splitList[0]?.map((d,idx)=>(
                  <div className="single-best-featured right" key={idx}>
                  <div className="icon">
                    {d?.icon?<i className={d?.icon}></i>:(
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="Outline"
                        viewBox="0 0 30 30"
                        enable-background="new 0 0 30 30"
                        fill="#ff5e14"
                        width="70px"
                        height="70px"
                      >
                        <g>
                          <path d="M21,15.25c-3.1709,0-5.75,2.57959-5.75,5.75s2.5791,5.75,5.75,5.75s5.75-2.57959,5.75-5.75S24.1709,15.25,21,15.25z M21,25.25c-2.34375,0-4.25-1.90674-4.25-4.25s1.90625-4.25,4.25-4.25s4.25,1.90674,4.25,4.25S23.34375,25.25,21,25.25z" />
                          <polygon points="20.25,21.05078 19.28027,20.08105 18.21973,21.1416 20.25,23.17188 23.78027,19.6416 22.71973,18.58105" />
                          <polygon points="4.75,4.75 9.75,4.75 9.75,7.5 11.25,7.5 11.25,4.75 16.75,4.75 16.75,11.25 11.25,11.25 11.25,9 9.75,9 9.75,12.75 18.25,12.75 18.25,4.75 23.25,4.75 23.25,14.41016 24.75,14.41016 24.75,3.25 3.25,3.25 3.25,22.75 13.83008,22.75 13.83008,21.25 4.75,21.25" />
                          <path d="M6.25,19.75h6.5v-4.5h-6.5V19.75z M7.75,16.75h3.5v1.5h-3.5V16.75z" />
                        </g>
                      </svg>
                    )}
                    </div>
                    <div className="content-text">
                      <h5>{d?.title}</h5>
                      <p className="desc-app">
                        {d?.desc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="col-lg-4 p-md-0 mt-5 mb-5 mt-md-0 mb-md-0 col-md-6 col-12"
                data-aos="fade-in"
              >
                <div className="center-img text-center mbm-50">
                  <img src={ExportFromEgyptianSection?.image || bannerBg} alt="" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12" data-aos="fade-left">
              {splitList[1]?.map((d,idx)=>(
                  <div className="single-best-featured right" key={idx}>
                  <div className="icon">
                    {d?.icon?<i className={d?.icon}></i>:(
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="Outline"
                        viewBox="0 0 30 30"
                        enable-background="new 0 0 30 30"
                        fill="#ff5e14"
                        width="70px"
                        height="70px"
                      >
                        <g>
                          <path d="M21,15.25c-3.1709,0-5.75,2.57959-5.75,5.75s2.5791,5.75,5.75,5.75s5.75-2.57959,5.75-5.75S24.1709,15.25,21,15.25z M21,25.25c-2.34375,0-4.25-1.90674-4.25-4.25s1.90625-4.25,4.25-4.25s4.25,1.90674,4.25,4.25S23.34375,25.25,21,25.25z" />
                          <polygon points="20.25,21.05078 19.28027,20.08105 18.21973,21.1416 20.25,23.17188 23.78027,19.6416 22.71973,18.58105" />
                          <polygon points="4.75,4.75 9.75,4.75 9.75,7.5 11.25,7.5 11.25,4.75 16.75,4.75 16.75,11.25 11.25,11.25 11.25,9 9.75,9 9.75,12.75 18.25,12.75 18.25,4.75 23.25,4.75 23.25,14.41016 24.75,14.41016 24.75,3.25 3.25,3.25 3.25,22.75 13.83008,22.75 13.83008,21.25 4.75,21.25" />
                          <path d="M6.25,19.75h6.5v-4.5h-6.5V19.75z M7.75,16.75h3.5v1.5h-3.5V16.75z" />
                        </g>
                      </svg>
                    )}
                    </div>
                    <div className="content-text">
                      <h5>{d?.title}</h5>
                      <p className="desc-app">
                        {d?.desc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
