import React from "react";
import { useTranslation } from 'react-i18next';

export default function AuthOverlay({ signIn, signUp }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="overlay-container">
        <div className="overlay-auth">
          <div className="overlay-panel overlay-right">
            <h1>{t('Welcome Back!')}</h1>
            <p>{t('To keep connected with us please login with your personal info')}</p>
            <button onClick={signUp}>{t('Sign In')}</button>
          </div>
          <div className="overlay-panel overlay-left">
            <h1>{t('Hello, Friend!')}</h1>
            <p>{t('Enter your personal details and start journey with us')}</p>
            <button className="ghost" onClick={signIn}>{t('Sign Up')}</button>
          </div>
        </div>
      </div>
    </>
  );
}
