import React, { useEffect } from "react"
import FaqOne from "../components/Faq/FaqOne"
import HeroFive from "../components/Hero/HeroFive"
import Inquiries from "../components/Knowledge/Inquiries"
import KnowledgeBaseSec from "../components/Knowledge/KnowledgeBaseSec"
import CtaReports from "../components/Reports/IndustryReport/CtaReports"
import { useNavigate } from "react-router-dom"
import { useFetchGeneralSettingQuery } from "../app/feature/API/Helpers"

const KnowledgeBase = () => {
  const navigate = useNavigate();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const knowledge_base_page_show = GeneralSetting?.map((e)=>e?.knowledge_base_page_show)

  useEffect(() => {

    if (Number(knowledge_base_page_show)===0) {
      navigate("/");
    }
  }, [knowledge_base_page_show, navigate]);
  return (
    <>
    <HeroFive/>
    <Inquiries/>
    <KnowledgeBaseSec/>
    <CtaReports/>
    <FaqOne/>
    </>
  )
}

export default React.memo(KnowledgeBase)