import { useTranslation } from "react-i18next";
import { useGetUserDevicesQuery, useDeleteUserDeviceMutation } from "../../app/feature/API/Profile";
import { Link } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";

const DeviceManagement = () => {
    const { t, i18n } = useTranslation();
    const { data: UserDevices } = useGetUserDevicesQuery();
    const [deleteUserDevice,{isLoading}] = useDeleteUserDeviceMutation();

    const handleDelete = async (id) => {
        try {
            await deleteUserDevice(id).unwrap();
        } catch (error) {
            console.error("Failed to delete the device: ", error);
        }
    };

    return (
        <div className="page-content content mt-100 mb-50">
            <div className="container">
                <div className="page-back-btn">
                    <Link to="/user-dashboard/my-profile">
                        <i className="fas fa-arrow-left me-2"></i>{t('Back to My Profile')}
                    </Link>
                </div>
                <div className="row">
                    <div className="col-lg-6 mt-20">
                        <div className="settings-card">
                            <div className="settings-card-head">
                                <h4>{t('Device Management')}</h4>
                            </div>
                            <div className="settings-card-body">
                                <span className="changed-info">
                                    {t('Last login')} {" "}
                                    {UserDevices && UserDevices.length > 0 && 
                                        new Date(UserDevices[0].updated_at).toLocaleString(
                                            `${i18n.language === 'en' ? "en" : "ar"}-EG`, 
                                            { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
                                        )
                                    }
                                </span>
                                <div className="device-list mt-3">
                                    {UserDevices && UserDevices.length > 0 ? (
                                        UserDevices.map(device => (
                                            <div key={device.id} className="device-item d-flex align-items-center justify-content-between mb-3 ">
                                                <div className=" d-flex align-items-center">
                                                    <div className="me-2">
                                                        <strong>{device.ip_address}</strong>
                                                    </div>
                                                    <div className="me-2">
                                                        {new Date(device.updated_at).toLocaleString(
                                                            `${i18n.language === 'en' ? "en" : "ar"}-EG`, 
                                                            { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
                                                        )}
                                                    </div>
                                                </div>
                                                <button 
                                                    className="btn btn-danger fs-3"
                                                    style={{borderRadius:'20px'}}
                                                    onClick={() => handleDelete(device.id)}
                                                >
                                                    <MdDeleteOutline/>
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <p>{t('No_Data')}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceManagement;
