import { useTranslation } from "react-i18next";
import Breadcrumbs from "../Shared/Breadcrumbs";
import CtaOne from "../components/CtaOne";
import GetStarted from "../components/GetStarted";
import HeroThree from "../components/Hero/HeroThree";
import LatestReport from "../components/Reports/LatestReport";
import Reports from "../components/Reports/Reports";
import EgyptianExporters from "../components/Services/Exports/EgyptianExporters";
import ExportFromEgyptian from "../components/Services/Exports/ExportFromEgyptian";
import Portfolio from "../components/Services/Exports/Portfolio";
import Pricing from "../components/Services/Exports/Pricing";
import ServicesCta from "../components/Services/Exports/ServicesCta";
import React from "react";

const ExportServices = () => {
  const {i18n} = useTranslation()
  return (
    <>
      <HeroThree />
      <Breadcrumbs to={`${i18n.language==='en'?"Services":"خدماتنا"}`} currentPage={`${i18n.language==='en'?"Egyptian Exporters":"المصدرون المصريون"}`} />
      <GetStarted />
      <EgyptianExporters />
      <ServicesCta />
      <ExportFromEgyptian />
      <CtaOne />
      <Reports />
      <LatestReport />
      <Pricing />
      <Portfolio />
    </>
  );
};

export default React.memo(ExportServices);;
