import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setActiveTabSlice } from "../../../../app/feature/TabSlice";
import TwoFactorAuthForm from "./Forms/TwoFactorAuthForm";
import { useGetActivitiesQuery, useGetProfileQuery, useGetUserDevicesQuery } from "../../../../app/feature/API/Profile";

const Security = () => {
  const { t ,i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const handleActiveTabSlice = (number) => {
    dispatch(setActiveTabSlice(number));
  };
  
  const { data: ProfileData } = useGetProfileQuery();
  const { data: UserDevices } = useGetUserDevicesQuery();
  const { data: activities } = useGetActivitiesQuery();

  return (
    <div className="page-content content">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="settings-card">
              <div className="settings-card-head">
                <h4>{t('Password')}</h4>
              </div>
              <div className="settings-card-body">
                <span className="changed-info">
                  {t('Last Changed')} {" "} {" "}
                   {new Date(ProfileData?.pass_updated_at).toLocaleString(`${i18n.language==='en'?"en":"ar"}-EG`, { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}
                </span>
              </div>
              <div className="settings-card-footer">
                <div className="btn-item">
                  <Link
                    to="/user-dashboard/password_and_security/change-password"
                    className="btn btn-primary border-raduis"
                    data-bs-toggle="modal"
                    data-bs-target="#change_password"
                  >
                    {t('Change Password')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <TwoFactorAuthForm/>
          </div>
          <div className="col-lg-6">
            <div className="settings-card">
              <div className="settings-card-head">
                <h4>{t('Device Management')}</h4>
              </div>
              <div className="settings-card-body">
                <span className="changed-info">
                  {t('Last login')}  {" "}
                  {UserDevices && UserDevices.length > 0 && 
                    new Date(UserDevices[0].updated_at).toLocaleString(
                      `${i18n.language === 'en' ? "en" : "ar"}-EG`, 
                      { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
                    )
                  }
                </span>
              </div>
              <div className="settings-card-footer">
                <div className="btn-item">
                  <Link to="/user-dashboard/password_and_security/device-management" className="btn btn-primary border-raduis">
                    {t('Manage')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="settings-card">
              <div className="settings-card-head">
                <h4>{t('Account Activity')}</h4>
              </div>
              <div className="settings-card-body">
                <span className="changed-info">
                {activities && activities.length > 0 && (
                  <>
                    {activities[0].action}{" "}
                    {new Date(activities[0].updated_at).toLocaleString(
                      `${i18n.language === 'en' ? "en" : "ar"}-EG`,
                      { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
                    )}
                  </>
                )}
                </span>
              </div>
              <div className="settings-card-footer">
                <div className="btn-item" onClick={()=>handleActiveTabSlice(3)}>
                  <a className="btn btn-primary border-raduis">
                    {t('View All')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
