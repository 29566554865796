import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const PortfolioApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${base_url}` ,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
}),
  reducerPath: "PortfolioApi",
  endpoints: (build) => ({
    getPortfolio: build.query({
        query: (page=1) => `/portfolio?page=${page}`,
        transformResponse: (response) => response.data,
        providesTags: ["PortfolioApi"],
    }),
    getPortfolioDetails: build.query({
      query: (slug) => `/portfolio/${slug}`,
      transformResponse: (response) => response.data,
      providesTags: ["PortfolioApi"],
    }),
  }),
});

export const {
  useGetPortfolioQuery,
  useGetPortfolioDetailsQuery
} = PortfolioApi;
