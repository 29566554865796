import React from "react";
import bannerBg from "../../../assets/img/cta_service.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../../../app/feature/API/CMS";

const ServicesCta = () => {
  const { t, i18n } = useTranslation();
  const { data: CtaData, isLoading } = useGetHomeSectionsQuery('exportservices');

  const reportsSection = CtaData?.find(cta => cta.slug === 'exportservices_contact');

  if (isLoading) {
    return <p>{t('loading')}</p>; 
  }
  if(reportsSection.show_sec !==1 ){
    return null;
  }
  return (
    <section
      className="pricing-cta-wrapper text-white bg-cover bg-center section-padding"
      style={{ backgroundImage: `url(${reportsSection?.image})`,direction:'ltr' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 ps-xl-0 pe-xl-0" data-aos="fade-up">
            <div className="pricing-contents">
              <h1>{reportsSection?.title}</h1>
              <div className="btn-wrapper d-flex align-items-end justify-content-center">
                <a href={reportsSection?.btn_url} className="border-raduis">{reportsSection?.btn_text}</a>
                <div className="btn-cta">
                  <div className="icon">
                    <i className={reportsSection?.icon} />
                  </div>
                  <div className="content">
                    <span className="text-star">{reportsSection?.sub}</span>
                    <h3>{reportsSection?.dec}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesCta;
