import React from "react";
import { Link } from "react-router-dom";
import FolderPlus from "../../assets/img/icons/FolderPlus.svg";
import { useTranslation } from "react-i18next";
import { useGetArticlesQuery } from "../../app/feature/API/Articles";

const KnowledgeBaseSec = () => {
  const { t } = useTranslation();
  const { data: articleData } = useGetArticlesQuery('');
  
  const filteredData = articleData
    ? Array.from(
        new Map(articleData.map((item) => [item.category_name, item])).values()
      ).slice(0, 6)
    : [];

  return (
    <section className="our-service-wrapper section-padding bg-white">
      <div className="container">
        <div className="row mb-80" data-aos="fade-up">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title pb-0">
                <h2>{t('knowledgeBase.title')}</h2>
              </div>
              <p className="desc-exporter">
                {t('knowledgeBase.description')}
              </p>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-in">
          {filteredData?.map((data, i) => (
            <div className="col-xl-4 col-md-6 col-12" key={i}>
              <div className="single-service-card knowledge-card" key={data.id}>
                <div className="content">
                  <div className="icon-title">
                    <img src={FolderPlus} alt="icon" />
                    <div className="service-title">
                      <Link to={`/topic/${data?.article_category_id}`}>
                        <h4>{data?.category_name}</h4>
                      </Link>
                    </div>
                  </div>
                  <div className="checked-features-list style-2 special-knoledge-list-mergin">
                    <ul className="mb-10">
                      {articleData?.filter(article => article.category_name === data.category_name)
                      .slice(0,4).map((article, j) => (
                        <Link to={`/articles/${article?.slug}`} key={j}>
                          <li>{article?.title}</li>
                        </Link>
                      ))}
                    </ul>
                    <Link className="hover-news-paper" to={`/topic/${data?.article_category_id}`}>
                      <i className="fal fa-arrow-right"></i> {t('knowledgeBase.moreArticles')} 
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KnowledgeBaseSec;
