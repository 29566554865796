import { useTranslation } from "react-i18next";
import { useGetWhyEgyptSectionsQuery } from "../../app/feature/API/CMS";
import { useEffect, useState } from "react";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";

const BussinessEgy = () => {
  const { t,i18n } = useTranslation();
  
  const { data: ServicesData, isLoading, error } = useGetWhyEgyptSectionsQuery();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const [services, setServices] = useState([]);
  useEffect(() => {
    if (ServicesData?.data) {
      setServices(ServicesData.data);
    }
  }, [ServicesData]);

  if (isLoading) {
    return <p className="text-center">{t("loading")}</p>;
  }

  if (error) {
    return
  }

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mtm-30">
          <div className="col-md-6 col-12 col-lg-4 mt-30">
          {GeneralSetting?.map((title, idx) => (
                  <div className="block-contents" data-aos="fade-right" key={idx}>
                    <div className="section-title">
                      <span style={{fontSize:i18n.language==='ar'&&"28px"}}>{title?.why_egypt_header}</span>
                      <h2 className="fz-2xl">{title?.why_egypt_title}</h2>
                    </div>
                    <p className="desc-app">
                      {title?.why_egypt_desc}
                    </p>
                  </div>
                ))}
          </div>
          {services.length > 0 ? (
            services.map((data) => (
              <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id} data-aos="fade-left">
                <div className="single-service-box style-1">
                  <div
                    className="service-bg bg-cover"
                    style={{ backgroundImage: `url(${data?.image})` }}
                  ></div>
                  <div className="icon">
                    <i className={data?.icon}></i>
                  </div>
                  <div className="contents">
                    <h4><p>{data?.title}</p></h4>
                    <p>{data?.description}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h2 className="text-center">{t("No_Data")}</h2>
          )}
        </div>
      </div>
    </section>
  );
};

export default BussinessEgy;
