import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { userLogin } from "../../app/feature/API/Login";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';

export default function Login({ signUp }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, error } = useSelector((state) => state.login);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // State to hold reCAPTCHA value

  const validate = () => {
    let isValid = true;
    const errors = { email: '', password: '' };

    if (!email) {
      errors.email = t('login.emailRequired');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = t('login.emailInvalid');
      isValid = false;
    }

    if (!password) {
      errors.password = t('login.passwordRequired');
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        // Check if reCAPTCHA was verified
        if (!captchaValue) {
          toast.error(t('captchaRequired')); 
          return;
        }

        const resultAction = await dispatch(userLogin({ email, password }));
        if (userLogin.fulfilled.match(resultAction)) {
          const { token } = resultAction.payload.data;
          localStorage.setItem('token_Front_Mied', token);
          toast.success(resultAction.payload.message || t("Successfully logged in"));
          navigate('/');
        } else {
          throw new Error(resultAction.payload.message || t("ErrorData"));
        }
      } catch (err) {
        toast.error(err.message || t("There was an issue with the submitted data"));
      }
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const onChangeCaptcha = (value) => {
    setCaptchaValue(value); // Set the value from reCAPTCHA
  };

  return (
    <div className="form-container sign-up-container">
      <form onSubmit={handleLogin}>
        <h1>{t('login.signIn')}</h1>
        <div className="flex-required-input">
          <input
            type="email"
            placeholder={t('login.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="astric">*</span>
        </div>
        <div style={{ width: '100%', textAlign: 'start' }}>
          {errors.email && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.email}</span>}
        </div>
        <div className="flex-required-input" style={{ position: 'relative' }}>
          <input
            type={showPassword ? "text" : "password"}
            placeholder={t('login.password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ paddingRight: '40px' }}
          />
          <span className="astric">*</span>
          <span
            type="button"
            onClick={handlePasswordToggle}
            style={{
              position: 'absolute',
              right: '30px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
          </span>
        </div>
        <div style={{ width: '100%', textAlign: 'start' }}>
          {errors.password && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.password}</span>}
        </div>
        {/* Add the reCAPTCHA component here */}
        <ReCAPTCHA
          sitekey="6LcYG20qAAAAABJgHbWG60SBUQ7EUw3YX0f_Pt6S" // Replace with your actual Site Key
          onChange={onChangeCaptcha}
        />
        <Link to="/forget-password">{t('login.forgotPassword')}</Link>


        <button type="submit" disabled={loading}>
          {loading ? t('login.loading') : t('login.signIn')}
        </button>
        {error && <p className="error-message">{t('login.error')}</p>}
        <p>
          {t('login.noAccount')} <span onClick={signUp} style={{ cursor: 'pointer' }}>{t('login.signUp')}</span>
        </p>
      </form>
    </div>
  );
}
