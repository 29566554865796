import { useTranslation } from 'react-i18next';
import PersonalInformationForm from "../PersonalInformationForm";
import BusinessInformationForm from '../BusinessInformationForm';
import ChangeEmailForm from '../ChangeEmailForm';

const MainProfile = () => {
  const { t } = useTranslation();
  return (
    <div className="profile-flex">
      <div className="profile-flex-full">
        <div className="settings-card">
          <div className="settings-card-head">
            <h4>{t('Personal Information')}</h4>
          </div>
          <PersonalInformationForm/>
        </div>
        <div className="settings-card">
          <div className="settings-card-head">
            <h4>{t('Business Information')}</h4>
          </div>
        <BusinessInformationForm/>
        </div>
      </div>
      <div className="profile-flex-full">
        <div className="settings-card">
          <div className="settings-card-head">
            <h4>{t('Change Email')}</h4>
          </div>
          <ChangeEmailForm/>
        </div>
      </div>
    </div>
  );
};

export default MainProfile;
