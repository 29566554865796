import React from "react";
import HeroFour from "../components/Hero/HeroFour";
import GetStartedReport from "../components/Reports/IndustryReport/GetStartedReport";
import CatOneReport from "../components/Reports/IndustryReport/CatOneReport";
import CapabilitiesReverse from "../components/Reports/IndustryReport/CapabilitiesReverse";
import CatTwoAllReports from "../components/Reports/IndustryReport/CatTwoAllReports";
import CtaReports from "../components/Reports/IndustryReport/CtaReports";
import Reports from "../components/Reports/Reports";
import Pricing from '../components/Services/Exports/Pricing'
import Candidates from "../components/job/Candidates";

const ReportsPage = () => {
  return (
    <>
      <HeroFour />
      <GetStartedReport />
      <CatOneReport/>
      <CapabilitiesReverse/>
      <CatTwoAllReports/>
      <CtaReports/>
      <Reports/>
      <Pricing/>
      <Candidates/>
    </>
  );
};

export default React.memo(ReportsPage);
