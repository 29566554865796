import React, { useState } from "react";
import { Col, Row, Modal, ModalBody, Input, Label } from "reactstrap";
import { Link, useParams } from "react-router-dom";

import jobImage1 from "../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../assets/images/featured-job/img-03.png";
import { FaArrowRight } from "react-icons/fa6";
import ModalApplyJob from "../ModalApplyJob";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useGetJobsDetailsQuery } from "../../app/feature/API/Jobs";
import { useTranslation } from "react-i18next";

const JobVacancyPost = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const {name} = useParams();
  const {data} = useGetJobsDetailsQuery(name)
  const parser = new DOMParser();
  const doc = parser.parseFromString(data?.job?.benefits, 'text/html');
  const text = doc.body.textContent || '';
  
  const slicedText = text.slice(0, 60);
  
  const doc2 = parser.parseFromString(data?.job?.salary, 'text/html');
  const text2 = doc2.body.textContent || '';
  
  const slicedText2 = text2;
  const [slug, setSlug] = useState("");
  const [t,i18n] = useTranslation()
  return (
    <React.Fragment>
      {data?.related?.map((e,idx)=>(
        <div
          key={idx}
          className={'job-box bookmark-post card mt-4'}
        >
          <div className="p-4">
            <Row>
              <Col lg={1}>
                <Link to={`/jobdetails/${e?.slug}`}>
                <i className={`${e?.icon}`} style={{fontWeight:"normal",fontSize:'30px',color:'#ff5e14'}}></i>

                </Link>
              </Col>
              <Col lg={10}>
                <div className="mt-3 mt-lg-0">
                  <h5 className="fs-17 mb-1">
                    <Link to={`/jobdetails/${e?.slug}`} className="text-dark">
                        {e?.title}  
                    </Link>{" "}
                    <small className="text-muted fw-normal fz-job">
                      {e?.experience}
                    </small>
                  </h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0 fz-job" dangerouslySetInnerHTML={{__html:e?.benefits?.slice(0,50)}}>
                      {/* {e?.benefits?.slice(0,50)} */}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0 fz-job" >
                        <i className="mdi mdi-map-marker"></i> {" "}
                        {e?.job_location}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0 fz-job" dangerouslySetInnerHTML={{__html:e?.salary}}>
                        {/* <i className="uil uil-wallet"></i>{" "} */}
                        
                      </p>
                    </li>
                  </ul>
                  <div className="mt-2">
                  <span
                  className={
                    e?.employment_status === 'Full-time' || e?.employment_status === 'وقت كامل'
                      ? "badge bg-success-subtle text-success fs-6 mt-1 mx-1"
                      : e?.employment_status === 'Part-time' || e?.employment_status === 'وقت جزئي'
                      ? "badge bg-danger-subtle text-danger fs-6 mt-1 mx-1"
                      : e?.employment_status === 'Contractual' || e?.employment_status === 'تعاقدي'
                      ? "badge bg-primary-subtle text-primary fs-6 mt-1 mx-1"
                      : "badge bg-primary-subtle text-primary fs-6 mt-1 mx-1"
                  }
                >
                  {e?.employment_status}
                </span>
                    {/* {(jobVacancyPostDetails.badges || []).map(
                      (badgeInner, key) => (
                        <span
                          className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                          key={key}
                        >
                          {badgeInner.badgeName}
                        </span>
                      )
                    )} */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="p-3 bg-light">
            <div className="row justify-content-between">
              {/* <Col md={8}>
                <div>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <i className="uil uil-tag"></i> Keywords :
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="primary-link text-muted">
                        Ui designer
                      </Link>
                      ,
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="primary-link text-muted">
                        developer
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col> */}

              <Col md={3}>
              <div className="text-start text-md-end">
                  <Link to="#" className="text-desc-link"  onClick={()=>{onOpenModal();setSlug(e?.slug)}}>
                  {t('applyNow')} <MdOutlineKeyboardDoubleArrowRight />
                  </Link>
                </div>
              </Col>
            </div>
          </div>
        </div>
      ))}

<div className="all-cat-job-cat mt-20 mb-20 text-center">
<Link to={'/job-list'}>
              {i18n.language === 'en'
                ? <>View All <FaArrowRight /></>
                : <> <FaArrowRight /> عرض الكل</>}
            </Link>
          </div>
<ModalApplyJob onCloseModal={onCloseModal} slug={slug} open={open}/>
    </React.Fragment>
  );
};

export default JobVacancyPost;
