import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../../../../app/feature/API/Profile';
import { toast } from 'react-toastify';

const SystemNotifications = () => {
  const { t } = useTranslation();
  const [updateUserSettings , {isLoading}] = useUpdateUserSettingMutation();
  const { data } = useGetUserSettingsQuery();
  const notificationTitles = {
    notification_ticket: t('Ticket Support'),
    security_alert: t('Security Alerts'),
    service_availability: t('Service Availability'),
  };

  const [notifications, setNotifications] = useState({
    notification_ticket: { email: false, sms: false },
    security_alert: { email: false, sms: false },
    service_availability: { email: false, sms: false },
  });

  useEffect(() => {
    if (data) {
      const initialNotifications = {
        'notification_ticket': { email: false, sms: false },
        'security_alert': { email: false, sms: false },
        'service_availability': { email: false, sms: false },
      };

      data.forEach((setting) => {
        if (setting.key in initialNotifications) {
          if (setting.value === 'all') {
            initialNotifications[setting.key] = { email: true, sms: true };
          } else if (setting.value === 'email') {
            initialNotifications[setting.key].email = true;
          } else if (setting.value === 'sms') {
            initialNotifications[setting.key].sms = true;
          }
        }
      });

      setNotifications(initialNotifications);
    }
  }, [data]);

  const handleChange = (notification, method) => {
    setNotifications((prev) => ({
      ...prev,
      [notification]: {
        ...prev[notification],
        [method]: !prev[notification][method],
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formattedNotifications = Object.entries(notifications).map(([key, value]) => ({
      key,
      value: value.email && value.sms ? 'all' : (value.email ? 'email' : 'sms'),
    }));
  
    const formData = {
      data: formattedNotifications,
    };
  
    try {
      await updateUserSettings(formData);
      toast.success(t('done')); 
    } catch (error) {
      toast.error(t('errorUpdatingSettings'));
      console.error('Error updating settings:', error);
    }
  };

  return (
    <div className="col-lg-6">
      <form onSubmit={handleSubmit}>
        <div className="table-card noti-setting-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>{t('System Notifications')}</th>
                  <th className='text-end'>{t('Email')}</th>
                  {/* <th>{t('SMS')}</th> */}
                </tr>
              </thead>
              <tbody>
                {Object.keys(notifications).map((notification) => (
                  <tr key={notification}>
                    <td>{notificationTitles[notification]}</td>
                    <td>
                      <div className="status-toggle d-flex align-items-center justify-content-end">
                        <input
                          type="checkbox"
                          id={`email-${notification}`}
                          className="check"
                          checked={notifications[notification].email}
                          onChange={() => handleChange(notification, 'email')}
                        />
                        <label htmlFor={`email-${notification}`} className="checktoggle"></label>
                      </div>
                    </td>
                    {/* <td>
                      <div className="status-toggle d-flex align-items-center">
                        <input
                          type="checkbox"
                          id={`sms-${notification}`}
                          className="check"
                          checked={notifications[notification].sms}
                          onChange={() => handleChange(notification, 'sms')}
                        />
                        <label htmlFor={`sms-${notification}`} className="checktoggle"></label>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="btn-item mt-4">
            <button type="button" className="btn-secondary m-0">
              {t('Cancel')}
            </button>
            <button type="submit" className="btn-primary">
              {isLoading?t('loading'):t('Save Changes')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SystemNotifications;
