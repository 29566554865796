import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import visa from "../../../../assets/img/visa.svg";
import calendar from "../../../../assets/img/calendar.svg";
import user_heart from "../../../../assets/img/user_heart.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Plan_Billing = () => {
  const { t } = useTranslation();
  const dropDownRef = useRef();
  const [dropDown, setDropDown] = useState(false);
  const [dropDownChoose, setDropDownChoose] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  const handleFilterData = () => {
    setFilterData(!filterData);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropDownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="page-content">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="billing-type">
              <div className="row">
                <div className="col-lg-6">
                  <div className="settings-card">
                    <div className="settings-card-head">
                      <h5>
                        {t("Current Plan")}: {t("Basic")}
                      </h5>
                      <span>{t("Renew On")}: 01 Feb 2024</span>
                    </div>
                    <div className="settings-card-body">
                      <div className="btn-item">
                        <a href="#" className="btn-primary">
                          {t("Manage")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="settings-card">
                    <div className="settings-card-head d-flex justify-content-between align-items-start">
                      <div className="payment-method-add">
                        <h5>{t("Payment Method")}</h5>
                        <span>{t("Expires On")}: 04 Mar 2024</span>
                      </div>
                      <a href="#" className="add-payment payment-btn">
                        {t("Add")}
                      </a>
                    </div>
                    <div className="settings-card-body">
                      <div className="payment-method-edit">
                        <div className="card-type">
                          <span className="payment-card-img">
                            <img src={visa} alt="" />
                          </span>
                          <div className="card-no">
                            <span>{t("Visa")} *****4023</span>
                          </div>
                        </div>
                        <a href="#" className="edit-payment payment-btn">
                          {t("Edit")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plan-bill-table">
          <div className="dashboard-header">
            <div className="main-title">
              <h3>{t("Invoices")}</h3>
              <div id="tableinfo"></div>
            </div>
            <div className="head-info">
              <p>
                {t("Total Payments")} <span>(0)</span>
              </p>
            </div>
          </div>
          <div className="table-filter">
            <ul className="filter-item">
              <li>
                <p>{t("Filter")}</p>
              </li>
              <li>
                <div
                  className="form-sort form-wrap date-time-picker-wrap"
                  onClick={handleFilterData}
                >
                  <span className="form-icon">
                    <img src={calendar} alt="icon" />
                  </span>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={t("From Date")}
                  />
                </div>
              </li>
              <li>
                <div className="form-sort form-wrap date-time-picker-wrap">
                  <span className="form-icon">
                    <img src={calendar} alt="icon" />
                  </span>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={t("To Date")}
                  />
                </div>
              </li>
              <li>
                <div className="drop-down-con" ref={dropDownRef}>
                  <div className="drop-down" onClick={handleDropDown}>
                    <img src={user_heart} alt="" loading="lazy" />
                    <p>{dropDownChoose ? dropDownChoose : t("Filter")}</p>
                    <button className="search-btn">
                      <i className="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div className={`drop ${dropDown ? "" : "d-none"}`}>
                    <p onClick={() => setDropDownChoose(t("Plan"))}>
                      {t("Plan")}
                    </p>
                    <p onClick={() => setDropDownChoose(t("Purchase"))}>
                      {t("Purchase")}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div id="tablefilter">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder={t("Search")}
                    aria-controls="DataTables_Table_0"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="table-responsive custom-table">
            <table className="table table-stripe datatable">
              <thead className="thead-light">
                <tr>
                  <th>{t("ID")}</th>
                  <th>{t("Invoice No")}</th>
                  <th>{t("Billing Date")}</th>
                  <th>{t("Plan")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Amount")}</th>
                  <th className="text-end">{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td colSpan={7} className="text-center">
                    <a >{t('No_Data')}</a>
                  </td>
                </tr>
                {/* <tr>
                  <td>1</td>
                  <td>
                    <a href="#">INV0045</a>
                  </td>
                  <td>01 Dec 2023 09:00AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$100</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <a href="#">INV0044</a>
                  </td>
                  <td>01 Nov 2023 10:00AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$200</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <a href="#">INV0043</a>
                  </td>
                  <td>01 Oct 2023 09:15AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$300</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <a href="#">INV0043</a>
                  </td>
                  <td>01 Sep 2023 09:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$400</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <a href="#">INV0041</a>
                  </td>
                  <td>30 Aug 2023 09:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$100</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <a href="#">INV0040</a>
                  </td>
                  <td>25 Aug 2023 07:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$200</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    <a href="#">INV0039</a>
                  </td>
                  <td>15 Aug 2023 06:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$500</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>
                    <a href="#">INV0038</a>
                  </td>
                  <td>10 Aug 2023 09:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$400</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>
                    <a href="#">INV0037</a>
                  </td>
                  <td>01 Aug 2023 11:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$500</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>
                    <a href="#">INV0036</a>
                  </td>
                  <td>20 Jul 2023 12:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$300</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>
                    <a href="#">INV0035</a>
                  </td>
                  <td>10 Jul 2023 05:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$400</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>
                    <a href="#">INV0034</a>
                  </td>
                  <td>01 Jul 2023 09:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$400</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>
                    <a href="#">INV0033</a>
                  </td>
                  <td>01 Jun 2023 11:30AM</td>
                  <td>{t("Basic")}</td>
                  <td>
                    <span className="badge badge-receive bg-success d-inline-flex align-items-center">
                      <i className="fas fa-check me-2"></i>
                      {t("Paid")}
                    </span>
                  </td>
                  <td>$500</td>
                  <td>
                    <div className="table-action justify-content-end">
                      <a href="javascript:void(0);">
                        <i className="fas fa-cloud-download"></i>
                      </a>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="table-footer">
            <div id="tablepage">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="DataTables_Table_0_paginate"
              >
                <ul className="pagination">
                  <li
                    className="paginate_button page-item previous disabled"
                    id="DataTables_Table_0_previous"
                  >
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="0"
                      tabIndex="0"
                      className="page-link"
                    >
                      <i className="fa fa-angle-left"></i>{" "}
                    </a>
                  </li>
                  <li className="paginate_button page-item active">
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabIndex="0"
                      className="page-link"
                    >
                      1
                    </a>
                  </li>
                  <li className="paginate_button page-item">
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="2"
                      tabIndex="0"
                      className="page-link"
                    >
                      2
                    </a>
                  </li>
                  <li
                    className="paginate_button page-item next"
                    id="DataTables_Table_0_next"
                  >
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="3"
                      tabIndex="0"
                      className="page-link"
                    >
                      {" "}
                      <i className=" fa fa-angle-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>{" "}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Plan_Billing;
