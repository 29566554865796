import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';

const hexToRgba = (hex, opacity) => {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }
  if (!/^([0-9A-F]{6})$/i.test(hex)) {
    throw new Error('Invalid Hex color code');
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1');
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const PopupModal = React.memo(({ show, handleClose, popupData }) => {
  if (!popupData) return null;

  return (
    <Modal
      open={show}
      onClose={handleClose}
      center
      styles={{
        modal: { maxWidth: '1000px', width: '98%', padding: 0 },
      }}
    >
<div className="modal-content-flex">
  <div className="modal-image-section">
    {popupData?.type === 1 && popupData.image && (
      <img
        src={popupData?.image}
        alt={popupData?.name}
        className="img-fluid-pop-up"
      />
    )}
    {(popupData?.type === 2 || popupData?.type === 3) &&
      popupData.background_image && (
        <>
          <img
            src={popupData?.background_image}
            alt={popupData?.name}
            className="img-fluid-pop-up"
          />
          <div
            className="modal-text-section-type-2"
            style={{
              backgroundColor: hexToRgba(
                popupData?.background_color,
                popupData?.background_opacity
              ),
              padding: '1.2rem',
              borderRadius: '10px',
            }}
          >
            {popupData?.title && (
              <h5 className="modal-subtitle" style={{ color: '#fff' }}>
                {popupData?.title}
              </h5>
            )}
            {popupData?.text && (
              <p className="modal-text" style={{ color: '#fff' }}>
                {popupData?.text}
              </p>
            )}
            {popupData?.button_text && (
              <a
                href={popupData?.button_url || '/'}
                target="_blank"
                rel="noopener noreferrer"
                className="modal-button"
                style={{
                  color: `#${popupData?.background_color || 'eeee'}`,
                  backgroundColor: `#${
                    popupData?.button_color === popupData?.background_color
                      ? 'fff'
                      : popupData?.button_color || 'fff'
                  }`,
                  padding: '10px 16px',
                  borderRadius: '10px',
                  textDecoration: 'none',
                  display: 'inline-block',
                  margin: '4px 0',
                  boxShadow: '0 0 1px 0px #fff',
                  width: '80%',
                  marginTop: '25px',
                  textAlign: 'center',
                  fontSize: '21px',
                }}
              >
                {popupData?.button_text || "btn"}
              </a>
            )}
          </div>
        </>
    )}
    {(popupData?.type === 4 || popupData?.type === 5) && popupData.image && (
      <div className='modal-content-flex'>
        <div className='modal-image-section'>
          <img
            src={popupData?.image}
            alt={popupData?.name}
            className="img-fluid-pop-up"
          />
        </div>
        <div
          className="modal-text-section"
          style={{
            padding: '1.2rem',
            borderRadius: '10px',
          }}
        >
          {popupData?.title && (
            <h5
              className="modal-subtitle"
              style={{
                color: `var(--services-color)`,textAlign: 'center'
              }}
            >
              {popupData?.title}
            </h5>
          )}
          {popupData?.text && (
            <p
              className="modal-text"
              style={{
                color: `var(--services-color)`,textAlign: 'center'
              }}
            >
              {popupData?.text}
            </p>
          )}
          {popupData?.button_text && (
            <a
              href={popupData?.button_url  || '/'}
              target="_blank"
              rel="noopener noreferrer"
              className="modal-button"
              style={{
                color: `#${popupData?.background_color || 'eeee'}`,
                backgroundColor: `#${
                  popupData?.button_color === popupData?.background_color
                    ? 'fff'
                    : popupData?.button_color || 'fff'
                }`,
                padding: '10px 16px',
                borderRadius: '36px',
                textDecoration: 'none',
                display: 'inline-block',
                margin: '4px 0',
                boxShadow: '0 0 1px 0px #fff',
                width: '80%',
                marginTop: '25px',
                textAlign: 'center',
                fontSize: '21px',
              }}
            >
              {popupData?.button_text  || 'btn'}
            </a>
          )}
        </div>
      </div>
    )}
    {(popupData?.type === 6) && popupData.background_image && (
      <>
        <img
          src={popupData?.background_image}
          alt={popupData?.name}
          className="img-fluid-pop-up"
        />
        <div
          className="modal-text-section-type-2"
          style={{
            padding: '1.2rem',
            borderRadius: '10px',
          }}
        >
          {popupData?.title && (
            <h5 className="modal-subtitle" style={{ color: '#fff' }}>
              {popupData?.title}
            </h5>
          )}
          {popupData?.text && (
            <p className="modal-text" style={{ color: '#fff' ,marginBottom:'20px'}}>
              {popupData?.text}
            </p>
          )}
          {popupData?.end_date && popupData?.end_time && (
            <CountdownTimer
              endDate={popupData?.end_date}
              endTime={popupData?.end_time}
            />
          )}
          {popupData?.button_text && (
            <a
              href={popupData?.button_url  || '/'}
              target="_blank"
              rel="noopener noreferrer"
              className="modal-button"
              style={{
                color: `#${popupData?.background_color || 'eeee'}`,
                backgroundColor: `#${
                  popupData?.button_color === popupData?.background_color
                    ? 'fff'
                    : popupData?.button_color || 'fff'
                }`,
                padding: '10px 16px',
                borderRadius: '36px',
                textDecoration: 'none',
                display: 'inline-block',
                margin: '4px 0',
                boxShadow: '0 0 1px 0px #fff',
                width: '50%',
                marginTop: '25px',
                textAlign: 'center',
                fontSize: '21px',
              }}
            >
              {popupData?.button_text  || 'btn'}
            </a>
          )}
        </div>
      </>
    )}
    {(popupData?.type === 7 ) && popupData.image && (
      <div className='modal-content-flex'>
        <div className='modal-image-section'>
          <img
            src={popupData?.image}
            alt={popupData?.name}
            className="img-fluid-pop-up"
          />
        </div>
        <div
          className="modal-text-section"
          style={{
            backgroundColor: hexToRgba(
              popupData?.background_color,
              popupData?.background_opacity
            ),
            padding: '1.2rem',
            borderRadius: '10px',
          }}
        >
          {popupData?.title && (
            <h5
              className="modal-subtitle"
              style={{
                color: `#fff`,textAlign: 'center'
              }}
            >
              {popupData?.title}
            </h5>
          )}
          {popupData?.text && (
            <p
              className="modal-text"
              style={{
                color: `#fff`,textAlign: 'center',marginBottom:'20px'
              }}
            >
              {popupData?.text}
            </p>
          )}
          {popupData?.end_date && popupData?.end_time && (
            <CountdownTimer
              endDate={popupData?.end_date}
              endTime={popupData?.end_time}
            />
          )}
          {popupData?.button_text && (
            <a
              href={popupData?.button_url || '/'}
              target="_blank"
              rel="noopener noreferrer"
              className="modal-button"
              style={{
                color: `#${popupData?.background_color || 'eeee'}`,
                backgroundColor: `#${
                  popupData?.button_color === popupData?.background_color
                    ? 'fff'
                    : popupData?.button_color || 'fff'
                }`,
                padding: '10px 16px',
                borderRadius: '36px',
                textDecoration: 'none',
                display: 'inline-block',
                margin: '4px 0',
                boxShadow: '0 0 1px 0px #fff',
                width: '80%',
                marginTop: '25px',
                textAlign: 'center',
                fontSize: '21px',
              }}
            >
              {popupData?.button_text  || 'btn'}
            </a>
          )}
        </div>
      </div>
    )}
  </div>
</div>

    </Modal>
  );
});

export default PopupModal;



const CountdownTimer = ({ endDate, endTime }) => {
  const {i18n } = useTranslation();

  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    const targetTime = new Date(`${endDate} ${endTime}`).getTime();

    const interval = setInterval(() => {
      const now = Date.now();
      const difference = targetTime - now;

      if (difference <= 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate, endTime]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        marginBottom: '15px',
      }}
    >
      {Object.entries(timeLeft).map(([key, value]) => (
        <div
          key={key}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            textAlign: 'center',
          }}
        >
          <h3 style={{ margin: 0 , color: '#fff',}}>{value}</h3>
          <p style={{ margin: 0, fontSize: '14px' }}>{key === 'days' ? i18n.language==='en'?"Days":"ايام" 
          : key === 'hours' ?  i18n.language==='en'?"Hours":"ساعات"
          : key === 'minutes' ?  i18n.language==='en'?"Minutes":"دقائق"
          :  i18n.language==='en'?"Seconds":"ثواني"}</p>
        </div>
      ))}
    </div>
  );
};
