import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFetchGeneralSettingQuery, useFetchLinksQuery } from "../../app/feature/API/Helpers";

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
  const { t , i18n} = useTranslation();
  const {data:HeaderLink} = useFetchLinksQuery()
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();

  return (
    <div className={mobileMenu ? "mobile-nav" : "mobile-nav show"}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          {HeaderLink?.filter(
    (subItem) => subItem.show_link !== 0
  )?.map((menuItem) => (
            <li key={menuItem.id}>
              {menuItem.links?.length > 0 ? (
                <>
                  <Link className="has-arrow" to={menuItem?.link}>
                    {menuItem.title}
                  </Link>
                  <ul className={`${i18n.language==='ar'?"submenu-arabic-mobile-menu":""}`}>
                    {menuItem.links?.filter(
    (subItem) => subItem.show_link !== 0
  )?.map((subItem) => (
                      <li key={subItem.id}>
                        <Link to={subItem.link}>{subItem.title}</Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link to={menuItem.link}>{menuItem.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className="action-bar">
        {GeneralSetting?.map((footer, index) => (
           <a key={index} href={`mailto:${footer?.support_email}`}>
            <i className="fal fa-envelope"></i> {footer?.support_email}
          </a>
        ))}
        {GeneralSetting?.map((footer, index) => (
          <a key={index} href={`tel:${footer?.support_phone}`}>
            <i className="fal fa-phone"></i>{footer?.support_phone}
          </a>
        ))}
        <Link to="/contact" className="d-btn theme-btn">
          {t('btn-contact-mobile-menu')}
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
