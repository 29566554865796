import sectionBg from "../../assets/img/services/project-bg.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPortfolioQuery } from "../../app/feature/API/Portfolio";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { GrPrevious } from 'react-icons/gr';
import { GrNext } from 'react-icons/gr';

const PortfolioTwo = () => {
  const { t ,i18n} = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const {data:PortfolioData ,isLoading} = useGetPortfolioQuery(currentPage);
  const Portfolio = PortfolioData?.data || [];

  const pageCount =PortfolioData?.meta?.last_page

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  return (
    <section
      className=" bg-cover"
      style={{ backgroundImage: `url(${sectionBg})` }}
    >
      <div className="container pt-80">
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="block-contents">
              <div className="section-title">
                <h5 className="textleft text-white font-size-success">
                  {t('portfolioTwo.successStories')}
                </h5>
                <span style={{fontSize:i18n.language==='ar'&&"40px"}}>{t('portfolioTwo.recentSuccessStories')}</span>
                <h2 className="text-white">
                  {t('portfolioTwo.seeRecentSuccessStories')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="project-carousel-card-active success-stories-grid text-white">
      {isLoading&&(
              <div className="spinner-overlay">
              <div className="spinner"></div>
              </div>
            )}
        {PortfolioData?.map((data,idx) => (
          <Link to={`/portfolioDetails/${data?.slug}`} className="single-project-card" key={data.id} >
            <p
              className="project-thumb bg-cover"
              style={{ backgroundImage: `url(${data.featured_image})` }}
            ></p>
            <div className="contents">
              <div className="project-number">
                <h2>0{data.serial_number}</h2>
              </div>
              <div className="project-details">
                <span>{data.title}</span>
                <h3>
                  <Link to="/">{data.location}</Link>
                </h3>
                <p>{t('portfolioTwo.client')}: {data.client_name}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="table-footer pt-50">
      {pageCount > 1 && PortfolioData.length > 0 &&(
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col-12">
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              previousLabel={<GrPrevious />}
              nextLabel={<GrNext />}
            />
          </div>
        </div>
        )}
      </div>
    </section>
  );
};

export default PortfolioTwo;
