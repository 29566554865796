import { useTranslation } from "react-i18next";
import PageBanner from "../../../Shared/PageBanner";
import bannerBg from "../../../assets/img/Banner/Top-Image8.jpg";
import ContentArticles from "./ContentArticles";
import { useNavigate } from "react-router-dom";
import { useFetchGeneralSettingQuery } from "../../../app/feature/API/Helpers";
import { useEffect } from "react";

const Articles = () => {
  const {i18n} = useTranslation()
  const navigate = useNavigate();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const knowledge_base_page_show = GeneralSetting?.map((e)=>e?.knowledge_base_page_show)

  useEffect(() => {

    if (Number(knowledge_base_page_show)===0) {
      navigate("/");
    }
  }, [knowledge_base_page_show, navigate]);
  return (
    <>
      <PageBanner title={`${i18n.language==='en'?"Articles":"مقالات"}`} bannerBg={bannerBg} currentPage={`${i18n.language==='en'?"Articles":"مقالات"}`} />
      <ContentArticles/>
    </>
  );
};

export default Articles;
