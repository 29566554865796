import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TranslationEn from "./Local/en.json";
import TranslationAr from "./Local/ar.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: TranslationEn,
  },
  ar: {
    translation: TranslationAr,
  },
};

const setDirection = (lang) => {
  const dir = lang === 'ar' ? 'rtl' : 'ltr';
  document.documentElement.dir = dir;
};

const loadLanguageFromLocalStorage = () => {
  const storedLanguage = localStorage.getItem('userLanguage');
  return storedLanguage || 'en';
};

const saveLanguageToLocalStorage = (lang) => {
  localStorage.setItem('userLanguage', lang);
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: loadLanguageFromLocalStorage(),
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', (lang) => {
  setDirection(lang);
  saveLanguageToLocalStorage(lang); 
});

setDirection(i18n.language); 

export default i18n;
