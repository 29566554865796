import { useTranslation } from "react-i18next";
import PageBanner from "../Shared/PageBanner";
import bannerBg from "../assets/img/Banner/Top-Image8.jpg";
import DetailsPort from "./DetailsPort";
import { useGetBannersQuery } from "../app/feature/API/CMS";
import React from "react";

const PortfolioDetails = () => {
  const {t,i18n} = useTranslation()
  const {data} = useGetBannersQuery('portfolioDetails')

  return (
    <>
      <PageBanner title={data?.title}
       bannerBg={data?.image} currentPage={data?.title} />
      <DetailsPort/>
    </>
  );
};

export default React.memo(PortfolioDetails);