import { Link } from "react-router-dom";
import img from "../../assets/img/services/egypt.avif";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { useFetchExtraSettingQuery, useFetchGeneralSettingQuery, useSaveContactUsMutation } from "../../app/feature/API/Helpers";

export default function Contact() {
  const { i18n } = useTranslation();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const lang = i18n.language;
  
    if (!formValues.name || formValues.name.length < 3) {
      newErrors.name = lang === 'en' ? "Name must be at least 3 characters long." : "يجب أن يكون الاسم مكونًا من 3 أحرف على الأقل.";
    }
    if (!formValues.email || !/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = lang === 'en' ? "Email is not valid." : "البريد الإلكتروني غير صالح.";
    }
    if (!formValues.phone || formValues.phone.length < 10) {
      newErrors.phone = lang === 'en' ? "Phone number must be at least 10 characters long." : "يجب أن يكون رقم الهاتف مكونًا من 10 أرقام على الأقل.";
    }
    if (!formValues.subject || formValues.subject.length < 3) {
      newErrors.subject = lang === 'en' ? "Subject must be at least 3 characters long." : "يجب أن يكون الموضوع مكونًا من 3 أحرف على الأقل.";
    }
    if (!formValues.message || formValues.message.length < 10) {
      newErrors.message = lang === 'en' ? "Message must be at least 10 characters long." : "يجب أن تكون الرسالة مكونة من 10 أحرف على الأقل.";
    }
  
    return newErrors;
  };
  
  const [saveContact] = useSaveContactUsMutation()
  const onSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return; 
    }

    try {
        
        const response = await saveContact(formValues).unwrap();
        
        toast.success(response.message || 'Contact us submitted successfully!');

        setFormValues({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        });
        setErrors({}); 
    } catch (error) {
        console.error("Error submitting contact:", error);
        toast.error('Failed to submit the contact form. Please try again later.');
    }
};
const { data: GeneralSetting } = useFetchGeneralSettingQuery();
const { data } = useFetchExtraSettingQuery();


  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-8 col-12">
            <div className="contact-form">
              <h2>{i18n.language === 'en' ? "Get in Touch" : "ابقى على تواصل"}</h2>
              <form
                onSubmit={onSubmit}
                className="row"
                id="contact-form"
              >
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="name"
                      placeholder={i18n.language === 'en' ? "Name" : "الاسم"}
                      value={formValues.name}
                      onChange={handleChange}
                    />
                    {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="email"
                      name="email"
                      placeholder={i18n.language === 'en' ? "Email" : "البريد الالكتروني"}
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <div className="Phone-number">
                      {/* <div className="lang-menu phone-number-menu">
                        <div className="selected-lang">
                          +02
                          <img src={img} alt="" />
                        </div>
                      </div> */}
                      <input
                        type="text"
                        name="phone"
                        placeholder={i18n.language === 'en' ? "Number" : "رقم الهاتف"}
                        value={formValues.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                      {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="subject"
                      placeholder={i18n.language === 'en' ? "Subject" : "موضوع"}
                      value={formValues.subject}
                      onChange={handleChange}
                    />
                  </div>
                    {errors.subject && <span style={{ color: 'red' }}>{errors.subject}</span>}
                </div>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <textarea
                      name="message"
                      placeholder={i18n.language === 'en' ? "Message" : "رساله"}
                      value={formValues.message}
                      onChange={handleChange}
                    />
                  </div>
                    {errors.message && <span style={{ color: 'red' }}>{errors.message}</span>}
                </div>
                <div className="col-md-12 col-12">
                  <input
                    className="submit-btn border-radius"
                    type="submit"
                    value={i18n.language === 'en' ? "Submit Now" : "ارسال"}
                  />
                </div>
              </form>
              <span className="form-message" />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h2>{i18n.language === 'en' ? "CONTACT INFO" : "معلومات الاتصال"}</h2>
                <div className="single-info">
                  <div className="icon"style={{
                            marginLeft: i18n.language === 'en' ? '0' : '20px',
                            marginRight: i18n.language === 'en' ? '20px' : '0',
                            marginBottom:'1px'
                          }}>
                    <i className="flaticon-email" />
                  </div>
                  <div className="text">
                    <span>{i18n.language === 'en' ? "Email Us" : "ارسل لنا عبر البريد الإلكتروني"}</span>
                    {GeneralSetting?.map((footer, index) => (
                          <a key={index} href={`mailto:${footer?.support_email}`}
                          >
                            <h5>{footer?.support_email}</h5>
                          </a>
                        ))}
                  </div>
                </div>
                <div className="single-info"> 
                  <div className="icon" style={{
                            marginLeft: i18n.language === 'en' ? '0' : '20px',
                            marginRight: i18n.language === 'en' ? '20px' : '0',
                            marginBottom:'1px'
                          }}>
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>{i18n.language === 'en' ? "Call Us" : "اتصل بنا"}</span>
                    {GeneralSetting?.map((footer, index) => (
                          <a key={index} href={`tel:${footer?.support_phone}`} 
                           style={i18n.language==='en'?{direction:'ltr'}:{direction:'ltr'}}>
                            <h5>{footer?.support_phone}</h5>
                          </a>
                    ))}
                  </div>
                </div>
                <div className="single-info">
                <div className="icon" style={{
                            marginLeft: i18n.language === 'en' ? '0' : '20px',
                            marginRight: i18n.language === 'en' ? '20px' : '0',
                            marginBottom:'1px'
                          }}>
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text">
                    <span>{i18n.language === 'en' ? "Location" : "موقع"}</span>
                    {data?.extra?.map((extra,idx)=>
                    <h5 key={idx}>{extra?.contact_addresses?.slice(0,23)}</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="social-media-contact-us">
                <div className="follow-us">
                  <p>{i18n.language === 'en' ? "Follow Us" : "تابعنا"}</p>
                </div>
                <div className="social-link">
        {data?.social
          // ?
          // .filter((social) =>
          //   ["fab fa-facebook-f", "fab fa-twitter", "fab fa-linkedin-in", "fab fa-instagram iconpicker-component"].includes(social?.icon)
          // )
          .map((social, idx) => (
            <a key={idx} href={social?.url || "#"} target="_blank" rel="noreferrer">
              <i className={social.icon}></i>
            </a>
          ))}
      </div>
              </div>
              {data?.extra?.map((e)=>e?.is_ticket===1&&(
                <div className="live-chat">
                  <h2>{i18n.language === 'en' ? "Support" : "دعم"}</h2>
                  <p>{i18n.language === 'en' ? "We're here to support you with what you need." : "نحن هنا لندعمك بما تحتاجه."}</p>
                  <Link to="/user-dashboard/ticket-support" className="flex-baseline">
                    <i className="flaticon-chatting fz-xl" />{i18n.language === 'en' ? " Open a support ticket" : "افتح تذكره الدعم"}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
