import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const ProfileApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
  }),
  reducerPath: "ProfileApi",
  tagTypes: ["ProfileUser"],
  endpoints: (build) => ({
    getProfile: build.query({
      query: () => `/profile`,
      transformResponse: (response) => response.data,
      providesTags: ["ProfileUser"],
    }),
    getUserSettings: build.query({
      query: () => `/profile/user-settings`,
      transformResponse: (response) => response.data,
      providesTags: ["ProfileUser"],
    }),
    getUserDevices: build.query({
      query: () => `/profile/devices`,
      transformResponse: (response) => response.data,
      providesTags: ["ProfileUser"],
    }),
    deleteUserDevice: build.mutation({
      query: (id) => ({
        url: `/profile/devices`, 
        method: 'POST', 
        body: { id },
      }),
      invalidatesTags: ['ProfileUser'], 
    }),
    updateProfile: build.mutation({
      query: (profileData) => ({
        url: `/profile/update`,
        method: "POST",
        body: profileData,
      }),
      invalidatesTags: ["ProfileUser"],
    }),
    changePasswordProfile: build.mutation({
      query: (profileData) => ({
        url: `/profile/change-password`,
        method: "POST",
        body: profileData,
      }),
      invalidatesTags: ["ProfileUser"],
    }),
    UpdateUserSetting: build.mutation({
      query: (profileData) => ({
        url: `/profile/user-settings`,
        method: "POST",
        body: profileData,
      }),
      invalidatesTags: ["ProfileUser"],
    }),
    getUserSetting: build.query({
      query: () => `/profile/user-settings`,
      transformResponse: (response) => response.data,
      providesTags: ["ProfileUser"],
    }),
    getActivities: build.query({
      query: () => `/profile/activities`,
      transformResponse: (response) => response.data,
      providesTags: ["ProfileUser"],
    }),
    addWishlist: build.mutation({
      query: (wishlistData) => ({
        url: `/wishlist`,
        method: "POST",
        body: wishlistData,
      }),
      invalidatesTags: ["my-wishlist"],
    }),
    delWishlist: build.mutation({
      query: (wishlistData) => ({
        url: `/wishlist/delete`,
        method: "POST",
        body: wishlistData,
      }),
      invalidatesTags: ["my-wishlist"],
    }),
    getMyReports: build.query({
      query: () => `/my-reports`,
      transformResponse: (response) => response.data,
      providesTags: ["my-reports"],
    }),
    getMyWishlist: build.query({
      query: () => `/wishlist`,
      transformResponse: (response) => response.data,
      providesTags: ["my-wishlist"],
    }),
    getCountersFaqs: build.query({
      query: () => `/cms/counters`,
      transformResponse: (response) => response.data,
      providesTags: ["counters"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useAddWishlistMutation,
  useGetMyReportsQuery,
  useGetMyWishlistQuery,
  useDelWishlistMutation,
  useGetCountersFaqsQuery,
  useChangePasswordProfileMutation,
  useUpdateUserSettingMutation,
  useGetUserSettingsQuery,
  useGetUserDevicesQuery,
  useDeleteUserDeviceMutation,
  useGetActivitiesQuery,
} = ProfileApi;
