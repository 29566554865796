import React from "react";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";


const TableReports = ({ MyReportsData }) => {

  const handleViewReport = (report) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFillColor(240, 240, 240);
    doc.rect(0, 0, pageWidth, 297, "F");

    doc.setFontSize(22);
    doc.setTextColor(40, 40, 40);
    doc.setFont("helvetica", "bold");
    doc.text("Report Details", pageWidth / 2, 30, null, null, "center");

    doc.addImage(report.feature_image, "JPEG", 15, 40, 60, 60);

    doc.setDrawColor(0, 51, 102); 
    doc.setLineWidth(0.5);
    doc.roundedRect(80, 40, pageWidth - 95, 60, 3, 3, 'S'); 

    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.setFont("helvetica", "normal");

    doc.setTextColor(0, 51, 102); 
    doc.text("ID:", 85, 50);
    doc.text("Title:", 85, 60);
    doc.text("Category:", 85, 70);
    doc.text("Price:", 85, 80);
    doc.text("Updated:", 85, 90);

    doc.setTextColor(40, 40, 40);
    doc.text(`#${report.id}`, 115, 50);
    doc.text(report.title, 115, 60);
    doc.text(report.category, 115, 70);
    doc.text(`$${report.current_price}`, 115, 80);
    doc.text(
      new Date(report.updated_at).toLocaleDateString('en-EG', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }),
      115, 90
    );

    doc.setDrawColor(200, 200, 200); 
    doc.line(15, 110, pageWidth - 15, 110);

    doc.setFontSize(16);
    doc.setTextColor(0, 51, 102);
    doc.text("Summary:", 15, 120);
    
    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.setFont("helvetica", "normal");
    doc.text(report.summary, 15, 130, { maxWidth: pageWidth - 30, align: "justify" });

    doc.line(15, 150, pageWidth - 15, 150);

    doc.setFontSize(16);
    doc.setTextColor(0, 51, 102);
    doc.text("Description:", 15, 160);

    doc.setFontSize(12);
    doc.setTextColor(40, 40, 40);
    doc.text(stripHtmlTags(report.description), 15, 170, { maxWidth: pageWidth - 30, align: "justify" });

    doc.output("dataurlnewwindow");
  };
  const { t } = useTranslation();

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText || "";
  };

  return (
    <>
      <div className="table-responsive custom-table">
        <table className="table table-stripe datatable">
          <thead className="thead-light">
            <tr>
              <th>{t('ID')}</th>
              <th>{t('Report')}</th>
              <th>{t('Date & time')}</th>
              <th>{t('Price')}</th>
              <th>{t('Category')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {MyReportsData?.map((reports, idx) => (
              <tr key={idx}>
                <td>#{reports?.id}</td>
                <td>
                  <h2 className="table-avatar d-flex align-items-center">
                    <a href="javascript:void(0);" className="avatar">
                      <img src={reports?.feature_image} className="rounded" alt="User Image" />
                    </a>
                    <a href="javascript:void(0);" className="text-dark">
                      {reports?.summary?.slice(0, 50)}
                    </a>
                  </h2>
                </td>
                <td>{new Date(reports?.updated_at).toLocaleDateString('en-EG', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric'
                })}</td>
                <td>
                  <span>${reports?.current_price}</span>
                </td>
                <td>{reports?.category}</td>
                <td>
                  <div className="table-action">
                    <a href="javascript:void(0);" onClick={() => handleViewReport(reports)}>
                      <i className="fa fa-eye"></i>
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableReports;
