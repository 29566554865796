import Breadcrumbs from "../Shared/Breadcrumbs";
import GetStarted from "../components/GetStarted";
import HeroSix from "../components/Hero/HeroSix";
import EgyptianImporters from "../components/Services/Imports/EgyptianImporters";
import ServicesCta2 from "../components/Services/Imports/ServicesCta2";
import ImportFromEgyptian from "../components/Services/Imports/ImportFromEgyptian";
import CtaOne from "../components/CtaOne";
import Reports from "../components/Reports/Reports";
import LatestReport from "../components/Reports/LatestReport";
import Pricing from "../components/Services/Exports/Pricing";
import Portfolio from "../components/Services/Exports/Portfolio";
import { useTranslation } from "react-i18next";
import ServicesCta from "../components/Services/Exports/ServicesCta";
import React from "react";

const ImportServices = () => {
  const {i18n} = useTranslation()
  return (
    <>
      <HeroSix />
      <Breadcrumbs to={`${i18n.language==='en'?"Services":"خدماتنا"}`} currentPage={`${i18n.language==='en'?"International Importers":"المستوردون الدوليون"}`}  />
      <GetStarted />
      <EgyptianImporters />
      <ServicesCta />
      <ImportFromEgyptian />
      <CtaOne/>
      <Reports/>
      <LatestReport/>
      <Pricing/>
      <Portfolio/>
    </>
  );
};

export default React.memo(ImportServices);
