import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../../../../app/feature/API/Profile';

const TwoFactorAuthForm = () => {
  const { data, refetch } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateUserSettingMutation();
  const [twoFAEnabled, setTwoFAEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  const handleCheckboxChange = (e) => {
    setTwoFAEnabled(e.target.checked);
    handleSubmit(e.target.checked);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);
    setError(null);

    const updatedTwoFAEnabled = !twoFAEnabled;

    const formData = {
      data: [
        {
          key: '2fa',
          value: updatedTwoFAEnabled ? '1' : '0',
        },
      ],
    };

    try {
      await updateUserSettings(formData);
      console.log('data=>', data);
      setTwoFAEnabled(updatedTwoFAEnabled);
    } catch (err) {
      setError(t('Error updating settings, please try again.'));
      console.error(err);
    } finally {
      setLoading(false);
      refetch();
    }
  };

  useEffect(() => {
    if (data) {
      const setting = data.find((d) => d.key === '2fa');
      setTwoFAEnabled(setting ? setting.value !== '0' : false); // قم بتعيين القيمة وفقًا للبيانات
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="settings-card">
        <div className="settings-card-head d-flex justify-content-between align-items-center">
          <h4>{t('Two Factor Authentication')}</h4>
          <div className="status-toggle d-flex align-items-center">
            <input
              type="checkbox"
              id="user2"
              className="check"
              checked={twoFAEnabled}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="user2" className="checktoggle"></label>
          </div>
        </div>
        <div className="settings-card-body">
        {data && data.some((d) => d.key === '2fa') && (
  <span className="changed-info">
    {t('Last Changed')}{" "}
    {new Date(data.find((d) => d.key === '2fa').updated_at).toLocaleString(
      `${i18n.language === 'en' ? "en" : "ar"}-EG`, 
      { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }
    )}
  </span>
)}

          {error && <div className="error-message">{error}</div>}
        </div>
        <div className="settings-card-footer">
          <div className="btn-item">
            <button type="submit" className="btn btn-primary border-radius" style={{borderRadius:'50px'}} disabled={loading}>
              {loading ? t('loading') : (twoFAEnabled ? t('Disable') : t('Enable'))}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TwoFactorAuthForm;
