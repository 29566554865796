import React from "react";
import About from "../components/About/About";
import CtaOne from "../components/CtaOne";
import GetStarted from "../components/GetStarted";
import HeroOne from "../components/Hero/HeroOne";
import HeroSocials from "../components/Hero/HeroSocials";
import LatestReport from "../components/Reports/LatestReport";
import Reports from "../components/Reports/Reports";
import ServicesOne from "../components/Services/ServicesOne";
import 'aos/dist/aos.css'
const HomeOne = () => {
  return (
    <>
      <HeroSocials />
      <HeroOne />
      <About />
      <GetStarted/>
      <ServicesOne />
      <CtaOne />
      <Reports />
      <LatestReport/>
    </>
  );
};

export default React.memo(HomeOne);
