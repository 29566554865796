import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProfileQuery, useUpdateProfileMutation } from '../../../app/feature/API/Profile';
import { toast } from 'react-toastify';

const ChangeEmailForm = () => {
  const { t } = useTranslation();
  const { data: ProfileData } = useGetProfileQuery();

  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();

  useEffect(() => {
    if (ProfileData) {
      setCurrentEmail(ProfileData.email);
    }
  }, [ProfileData]);

  const validateForm = () => {
    const newErrors = {};

    if (!currentEmail) {
      newErrors.currentEmail = t('Current Email is required');
    }
    if (!newEmail) {
      newErrors.newEmail = t('New Email is required');
    } else if (!/\S+@\S+\.\S+/.test(newEmail)) {
      newErrors.newEmail = t('New Email is invalid');
    }
    if (!confirmEmail) {
      newErrors.confirmEmail = t('Confirm Email is required');
    } else if (newEmail !== confirmEmail) {
      newErrors.confirmEmail = t('Emails do not match');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (validateForm()) {
        try {
          const response = await updateProfile({ section:'email',new_email:newEmail,new_email_cfrm:confirmEmail }).unwrap();
          toast.success(response.message);
           setConfirmEmail('')
           setNewEmail('')
          } catch (err) {
            console.error('Update failed: ', err);
            toast.error(t('Email update failed!'));
          }    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="settings-card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-wrap">
              <label>{t('Current Email')}</label>
              <input
                type="email"
                className="form-control"
                disabled
                value={currentEmail}
                onChange={(e) => setCurrentEmail(e.target.value)}
              />
              {errors.currentEmail && <div  className="error-text" style={{color:'red'}}>{errors.currentEmail}</div>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-wrap">
              <label>{t('New Email')}</label>
              <input
                type="email"
                className="form-control"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              {errors.newEmail && <div  className="error-text" style={{color:'red'}}>{errors.newEmail}</div>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-wrap">
              <label>{t('Confirm Email')}</label>
              <input
                type="email"
                className="form-control"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
              />
              {errors.confirmEmail && <div  className="error-text" style={{color:'red'}}>{errors.confirmEmail}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="settings-card-footer">
        <div className="btn-item">
          <button className="btn-primary" type="submit">
                    {isUpdating ? t('loading') : t('Save Changes')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChangeEmailForm;
