import { Link, useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { GrArticle } from "react-icons/gr";
import { useGetArticlesQuery } from "../../../app/feature/API/Articles";
import SideBarTopic from "./SideBarTopic";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TopicContentDetails = () => {
    const { name } = useParams();
    const { data, error, isLoading } = useGetArticlesQuery(name);
    const [searchTerm, setSearchTerm] = useState("");
    const [originalData, setOriginalData] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [metaDescriptions, setMetaDescriptions] = useState([]);

    useEffect(() => {
        if (data) {
            setOriginalData(data);
            const uniqueCategoryNames = [...new Set(data.map(e => e.category_name))];
            const uniqueMetaDescription = [...new Set(data.map(e => e.meta_description))];
            setCategoryNames(uniqueCategoryNames);
            setMetaDescriptions(uniqueMetaDescription);
        }
    }, [data]);

    const filteredArticles = originalData.filter(article =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const {i18n} = useTranslation()

    return (
        <section className="blog-wrapper news-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="title-header-topic text-center">
                            <h2>{i18n.language==='en'?"Topics":"المواضيع"}: {categoryNames.join(", ")}</h2>
                            <p className="desc-app">{metaDescriptions.join(", ")}</p>
                        </div>
                        <div className="search-topic">
                            <IoSearchOutline />
                            <input
                                type="text"
                                placeholder={`${i18n.language==='en'?"Search here...":"ابحث هنا..."}`}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="content-topics">
                        {isLoading &&         <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>}
                            <ul>
                              {filteredArticles.length === 0 && (
                                <p className='text-center'>No Data Available</p>
                              )}
                                {filteredArticles.map((article) => (
                                    <li key={article.id} className="all-topics">
                                        <Link to={`/articles/${article.slug}`} className="topic-color list-topic">
                                            <GrArticle /> {article.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <SideBarTopic />
                </div>
            </div>
        </section>
    );
};

export default TopicContentDetails;
