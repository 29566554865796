import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Modal, ModalBody } from "reactstrap";

//Images Import
import jobImage1 from "../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../assets/images/featured-job/img-04.png";
import jobImage5 from "../../assets/images/featured-job/img-05.png";
import jobImage6 from "../../assets/images/featured-job/img-06.png";
import jobImage7 from "../../assets/images/featured-job/img-07.png";
import { FaAnglesLeft, FaAnglesRight, FaLocationDot } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import ModalApplyJob from "../ModalApplyJob";
import { HiMiniStar } from "react-icons/hi2";
import { CiClock1 } from "react-icons/ci";
import { useGetJobsQuery } from "../../app/feature/API/Jobs";
import ReactPaginate from "react-paginate";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const JobVacancyList = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const savedExperience = localStorage.getItem("workExperience") || "";
  const savedDatePosted = localStorage.getItem("datePosted") || "";
  const savedEmploymentType = localStorage.getItem("employmentType") || "";
  const savedTagId = localStorage.getItem("selectedTagId") || "";
  const job_location = localStorage.getItem("searchValue") || "";
  const [slug, setSlug] = useState("");
  const [t,i18n] = useTranslation()
  const [currentPage, setCurrentPage] = useState(1);

  const queryParameters = {
    page: currentPage,
    experience: savedExperience,
    date_post: savedDatePosted,
    employment_status: savedEmploymentType,
    jcategory_id: savedTagId,
  };
  
  const { data, isLoading, isError, refetch } = useGetJobsQuery(queryParameters);
  
  useEffect(() => {
    if (data?.meta?.current_page) {
      setCurrentPage(data.meta.current_page);
    }
  }, [data]);
  
  const JobVacancyList = data?.data || [];
  const jobDataFromRedux = useSelector((state) => state.jobs.jobData);
  const jobsToDisplay = jobDataFromRedux?.data?.length > 0 ? jobDataFromRedux?.data : data?.data || [];
  const pageCount =data?.meta?.last_page
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 500);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [refetch]);
  

  if (isLoading) {
    return <div className="spinner-overlay">
        <div className="spinner"></div>
      </div>
  }
  
  if (data?.data.length === 0) {
    return <p style={{textAlign:'center',paddingTop:'50px',fontSize:'30px',fontWeight:600}}>{t('No_Data')}</p>
  }
  console.log(data?.data.length)
  return (
    <React.Fragment>
      
      <div style={{
      direction: i18n.language === 'en' ? 'ltr' : 'rtl'
    }}>
        {jobsToDisplay.map((recentJobDetails, key) => (
      <div
      key={key}
      className={
          "job-box card bookmark-post mt-4"
      }
    >
      <div className="bookmark-label text-center">
        <Link to="#" className="text-white align-middle">
          <HiMiniStar />
        </Link>
      </div>
      <div className="p-4">
        <Row>
          <Col md={2} className="mt-1">
            <div className="text-center mb-4 mb-md-0">
              <Link to={`/jobdetails/${recentJobDetails?.slug}`}>
                {/* <img
                  src={recentJobDetails.icon || jobImage2}
                  alt=""
                  className="img-fluid rounded-3"
                /> */}
               <i className={`${recentJobDetails?.category_icon}`} style={{fontWeight:"normal",fontSize:'50px',color:'#ff5e14'}}></i>
              </Link>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mb-2 mb-md-0">
              <h5 className="fs-18 mb-1">
                <Link to={`/jobdetails/${recentJobDetails?.slug}`} className="text-desc">
                  {recentJobDetails?.title.slice(0, 25)}
               </Link>
              </h5>
              {/* <p className="text-muted fz-job">
                {recentJobDetails?.benefits.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 36)}...
              </p> */}
            </div>
          </Col>
          <Col lg={2}>
            <div className="d-flex mb-2">
              <div className="flex-shrink-0">
                <FaLocationDot className="text-desc" />
              </div>
              <p className="text-muted mb-0 fz-job"  style={{whiteSpace:'nowrap'}}>{recentJobDetails?.job_location}</p>
            </div>
          </Col>
          <Col lg={2}>
            <div className="d-flex mb-0">
                    <div className="flex-shrink-0">
                      <CiClock1 className="text-desc" />
                    </div>
                    <p className="text-muted fz-job" style={{marginBottom:'-2px',whiteSpace:'nowrap'}}>
                      {" "}
                {/* {recentJobDetails.salary.replace(/<\/?[^>]+(>|$)/g, "")} */}
                {recentJobDetails?.created_at}
                    </p>
                  </div>
          </Col>
          <Col lg={2}>
            <div>
              <span
                className={
                  recentJobDetails?.employment_status === 'Full-time' || recentJobDetails?.employment_status === 'وقت كامل'
                    ? "badge bg-success-subtle text-success fs-6 mt-1 mx-1"
                    : recentJobDetails?.employment_status === 'Part-time' || recentJobDetails?.employment_status === 'وقت جزئي'
                    ? "badge bg-danger-subtle text-danger fs-6 mt-1 mx-1"
                    : recentJobDetails?.employment_status === 'Contractual' || recentJobDetails?.employment_status === 'تعاقدي'
                    ? "badge bg-primary-subtle text-primary fs-6 mt-1 mx-1"
                    : "badge bg-primary-subtle text-primary fs-6 mt-1 mx-1"
                }
              >
                {recentJobDetails?.employment_status}
              </span>
              {(recentJobDetails.badges || []).map((badgeInner, key) => (
                <span
                  className={
                    "badge " + badgeInner.badgeclassName + " fs-13 mt-1"
                  }
                  key={key}
                >
                  {badgeInner.badgeName}
                </span>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
        <Col sm={12}className={`${i18n.language === 'en'?'ml-language-2':'mr-language-2'} mt-career`}>
              <div className="mb-2 mb-md-0">
                {/* <h5 className="fs-18 mb-1" style={{textWrap:'nowrap'}}>
                  <Link to={`/jobdetails/${recentJobDetails?.slug}`} className="text-desc">
                   {recentJobDetails?.title}
                  </Link>
                </h5> */}
                <p className="text-muted fz-job">
                    {recentJobDetails?.benefits.replace(/<\/?[^>]+(>|$)/g, "").slice(0, i18n.language === 'en'?80:90)}
                </p>
              </div>
            </Col>
        </Row>
      </div>
      <div className="p-3 bg-light">
        <Row>
          <Col md={4}>
            <div>
              <p className="text-muted mb-0">
                <span className="text-dark">{i18n.language === 'en'?"Experience":"خبرة"} :</span>{" "}
                {recentJobDetails.experience}
              </p>
            </div>
          </Col>
          <Col lg={6} md={5}>
            <div>
              <p className="text-muted mb-0">
                <span className="text-dark">
                {recentJobDetails.deadline && `${t("closingDate")} :`}
                </span>
                {recentJobDetails.deadline}
              </p>
            </div>
          </Col>
          <Col lg={2} md={3}>
            <div className="text-start text-md-end">
              <Link to="#" className="text-desc-link" onClick={()=>{onOpenModal();setSlug(recentJobDetails.slug)}}>
                {t('applyNow')} <MdOutlineKeyboardDoubleArrowRight />
               </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
        ))}
      </div>
      <div className="table-footer mb-50 pt-20">
      {pageCount > 1 && JobVacancyList.length > 0 &&(
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col-12">
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              previousLabel={<GrPrevious />}
              nextLabel={<GrNext />}
            />
          </div>
        </div>
        )}
       </div>
      <ModalApplyJob open={open} slug={slug} onCloseModal={onCloseModal} />
    </React.Fragment>
  );
};

export default JobVacancyList;





  // const jobVacancyList = [
  //   {
  //     id: 1,
  //     companyImg: jobImage1,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "3 min ago",
  //     fullTime: true,
  //     timing: "Full Time",
  //     addclassNameBookmark: false,
  //     badges: [],
  //     experience: "2 - 3 years"
  //   },
  //   {
  //     id: 2,
  //     companyImg: jobImage2,
  //     jobDescription: "Digital Marketing Manager",
  //     companyName: "Jobcy Technology Pvt.Ltd",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "15 min ago",
  //     fullTime: true,
  //     timing: "Full Time",
  //     catogary: "Recent Jobs",
  //     addclassNameBookmark: true,
  //     badges: [
  //       {
  //         id: 1,
  //         badgeclassName: "bg-warning-subtle text-warning",
  //         badgeName: "Urgent"
  //       },
  //       {
  //         id: 2,
  //         badgeclassName: "bg-primary-subtle text-primary",
  //         badgeName: "Freelance"
  //       }
  //     ],
  //     experience: "4+ years"
  //   },
  //   {
  //     id: 3,
  //     companyImg: jobImage3,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location: " Egypt, Cairo",
  //     jobPostTime: "37 min ago",
  //     fullTime: true,
  //     timing: "Full Time",
  //     addclassNameBookmark: false,
  //     badges: [],
  //     experience: "2 - 3 years"
  //   },
  //   {
  //     id: 4,
  //     companyImg: jobImage4,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "50 min ago",
  //     freeLance: true,
  //     timing: "Freelance",
  //     addclassNameBookmark: false,
  //     badges: [],
  //     experience: "2 - 3 years"
  //   },
  //   {
  //     id: 5,
  //     companyImg: jobImage5,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "1 month ago",
  //     partTime: true,
  //     timing: "Part Time",
  //     addclassNameBookmark: true,
  //     badges: [],
  //     experience: "2 - 3 years"
  //   },
  //   {
  //     id: 6,
  //     companyImg: jobImage6,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "2 month ago",
  //     freeLance: true,
  //     timing: "Freelance",
  //     addclassNameBookmark: false,
  //     badges: [
  //       {
  //         id: 1,
  //         badgeclassName: "bg-warning-subtle text-warning",
  //         badgeName: "Urgent"
  //       }
  //     ],
  //     experience: "2-3 years"
  //   },
  //   {
  //     id: 7,
  //     companyImg: jobImage7,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "2 month ago",
  //     partTime: true,
  //     timing: "Part Time",
  //     addclassNameBookmark: false,
  //     badges: [
  //       {
  //         id: 1,
  //         badgeclassName: "bg-warning-subtle text-warning",
  //         badgeName: "Urgent"
  //       }
  //     ],
  //     experience: "2-3 years"
  //   },
  //   {
  //     id: 8,
  //     companyImg: jobImage3,
  //     jobDescription: "Product Director",
  //     companyName: "Creative Agency",
  //     location:  " Egypt, Cairo",
  //     jobPostTime: "3 month ago",
  //     internship: true,
  //     timing: "Internship",
  //     addclassNameBookmark: false,
  //     badges: [
  //       {
  //         id: 1,
  //         badgeclassName: "bg-warning-subtle text-warning",
  //         badgeName: "Private"
  //       }
  //     ],
  //     experience: "2-3 years"
  //   }
  // ];