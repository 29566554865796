import { useTranslation } from "react-i18next";
import coreImg2 from "../../assets/img/services/reports.jpg";
import { Link } from "react-router-dom";
import { useGetHomeSectionsQuery } from "../../app/feature/API/CMS";

const Reports = () => {
  const { t, i18n } = useTranslation();
  const { data: CtaData, isLoading } = useGetHomeSectionsQuery('home');

  const reportsSection = CtaData?.find(cta => cta.slug === 'home_sec_2');

  if (isLoading) {
    return <p>{t('loading')}</p>; 
  }
  if(reportsSection?.show_sec !==1 ){
    return null;
  }
  const splitList = reportsSection?.list
    ? [
        reportsSection.list.slice(0, Math.ceil(reportsSection.list.length / 2)), 
        reportsSection.list.slice(Math.ceil(reportsSection.list.length / 2))
      ]
    : [
        [i18n.language === 'en' ? "Industry Insights" : "رؤى الصناعة", i18n.language === 'en' ? "Consumer Trends" : "اتجاهات المستهلك"],
        [i18n.language === 'en' ? "Policies" : "السياسات", i18n.language === 'en' ? "Processes" : "العمليات"]
      ];

  return (
    <section className="content-block-wrapper section-padding bg-white fix" style={{paddingBottom:'80px'}}>
      <div className="container">
        <div className="row mb-minus">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title">
                <h5>{reportsSection?.title || (i18n.language === 'en' ? "Reports" : "التقارير")}</h5>
                <span style={{fontSize:i18n.language==='ar'&&"30px"}}>{reportsSection?.sub || (i18n.language === 'en' ? "Subscribe or PAY-AS-YOU-GO" : "اشترك أو ادفع كما تذهب")}</span>
                <h2>{reportsSection?.title || (i18n.language === 'en' ? "Industry reports" : "تقارير الصناعة")}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center section-padding pb-0">
          <div
            className="col-xl-6 col-lg-6 col-12 mt-5 mt-lg-0 order-2 order-lg-1"
            data-aos="fade-right"
          >
            <div className="section-title-3">
              <p className="txt-reports line-minus">
                <p className="main-desc-report">{reportsSection?.sec_header || (i18n.language === 'en' ? "Reports" : "التقارير")}</p>
                <br />
                <span className="sec-desc-report">{reportsSection?.sec_title || (i18n.language === 'en' ? "Subscribe or PAY-AS-YOU-GO" : "اشترك أو ادفع كما تذهب")}</span>
              </p>
            </div>
            <div className="checked-features-list style-2 flex-center">
              <ul>
                {splitList[0].map((item, index) => (
                  <li key={index}>{item?.title}</li>
                ))}
              </ul>
              <ul>
                {splitList[1].map((item, index) => (
                  <li key={index}>{item?.title}</li>
                ))}
              </ul>
            </div>
            <div className="flex-start">
              <a
                href={reportsSection?.btn_url}
                className="theme-btn me-sm-4 wow fadeInLeft btn-explore mt-20 learn-more bg-Subscripe border-raduis"
                data-wow-duration="1.2s"
                data-wow-delay=".8s"
              >
                {reportsSection?.btn_text || t('btnNameReport1')}
              </a>
              <a
                href={reportsSection?.btn2_url}
                className="theme-btn me-sm-4 wow fadeInLeft btn-explore mt-20 learn-more bg-transparent border-raduis"
                data-wow-duration="1.2s"
                data-wow-delay=".8s"
              >
                {reportsSection?.btn2_text || t('btnNameReport2')}
              </a>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 ps-xl-5 col-12 order-1 order-lg-2"
            data-aos="fade-left"
          >
            <div className="content-block-img">
              <img src={reportsSection?.image || coreImg2} alt="Reports" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reports;
