import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const ReportsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en";
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept-Language", language);
      return headers;
    },
  }),
  reducerPath: "ReportsApi",
  endpoints: (build) => ({
    getReports: build.query({
      query: ({ category_id, title, type }) => {
        let queryString = '';
        
        if (category_id || title || type) {
          queryString = '?';
          const queryParams = [];
    
          if (category_id) {
            queryParams.push(`category_id=${category_id}`);
          }
          if (title) {
            queryParams.push(`title=${title}`);
          }
          if (type) {
            queryParams.push(`type=${type}`);
          }
    
          queryString += queryParams.join('&');
        }
    
        return `/reports${queryString}`;
      },
      transformResponse: (response) => response.data,
      providesTags: ["reports"],
    }),
    
    getReportsLazySearch: build.query({
      query: ({ category_id, title }) => {
        let queryString = '';
        if (category_id || title) {
          queryString = '?';
          if (category_id) {
            queryString += `category_id=${category_id}`;
          }
          if (category_id && title) {
            queryString += '&';
          }
          if (title) {
            queryString += `title=${title}`;
          }
        }

        return `/reports${queryString}`;
      },
      transformResponse: (response) => response.data,
      providesTags: ["reports"],
    }),
    getCategoriesReports: build.query({
      query: () =>`/reports/categories`,
      transformResponse: (response) => response.data,
      providesTags: ["reports"],
    }),
    getReviewsReports: build.query({
      query: () =>`/my-reports/reviews`,
      transformResponse: (response) => response.data,
      providesTags: ["reports"],
    }),
    postReviewsReports: build.mutation({
      query: (newReport) => ({
        url: `/my-reports/review`,
        method: 'POST',
        body: newReport,
      }),
      invalidatesTags: ["reports"],
    }),
    
  }),
});

export const {
  useGetReportsQuery,
  useGetCategoriesReportsQuery,
  useLazyGetReportsLazySearchQuery,
  useGetReviewsReportsQuery,
  usePostReviewsReportsMutation,
} = ReportsApi;
