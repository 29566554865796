import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const FaqsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${base_url}` ,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
}),
  reducerPath: "FaqsApi",
  endpoints: (build) => ({
    getFaqs: build.query({
        query: () => `/faqs`,
        transformResponse: (response) => response.data,
        providesTags: ["Faqs"],
    }),

  }),
});

export const {
  useGetFaqsQuery,
} = FaqsApi;
