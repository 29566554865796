import React from "react";
import { Link } from "react-router-dom";
import knowledge1 from "../../assets/img/icons/Knowledge1.svg";
import knowledge2 from "../../assets/img/icons/Knowledge2.svg";
import knowledge3 from "../../assets/img/icons/knowledge3.svg";
import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../../app/feature/API/CMS";

const Inquiries = () => {
  const { t } = useTranslation();
  const { data: CtaData, isLoading } = useGetHomeSectionsQuery('knowledgebase');

  const reportsSection = CtaData?.find(cta => cta.slug === 'knowledgebase_cats');
  console.log('reportsSection',reportsSection)
  const img = [knowledge2, knowledge1, knowledge3];

  return (
    <section className="our-service-wrapper section-padding bg-white">
        {isLoading&&(
            <>
            {t("loading")} 
            <i className="fa fa-spinner fa-spin" /> 
            </>
          )}
      <div className="container">
      <div className="row mtm-30 pt-10" data-aos="fade-left">
        {reportsSection?.list?.map((inq, idx) =>(
        <div className="col-md-6 col-12 col-lg-4 mt-30" data-aos="fade-left" key={idx}>
          <div className="single-inquiries-box style-1">
            <div className="icon mb-30">
              <i className={inq?.icon} style={{fontSize:'130px',color:'#695ea8'}}></i>
              {/* <img src={img[1]} alt="" /> */}
            </div>
            <div className="contents">
              <h4 className="mb-10 inquire-title">{inq?.title}</h4>
              <p className="mb-10">{inq?.desc}</p>
            </div>
          </div>
        </div>
        ))}

      <div className="flex-center-btn">
        <Link
          className="theme-btn me-sm-4 wow fadeInLeft btn-explore border-raduis inquire-btn"
          data-wow-duration="1.2s"
          data-wow-delay=".8s"
          to="/topic"
        >
          {t("inquiries.viewAllButton")}
        </Link>
      </div>
    </div>
      </div>
    </section>
  );
};

export default Inquiries;


        {/* <div className="row mtm-30 pt-10" data-aos="fade-left">
          {CategoryData && CategoryData.length > 0 ? (
            CategoryData.slice(0, 3).map((cta, i) => (
              <div
                key={i}
                className="col-md-6 col-12 col-lg-4 mt-30"
                data-aos="fade-left"
              >
                <div className="single-inquiries-box style-1">
                  <div className="icon mb-30">
                    {cta?.icon?<i className={cta?.icon}></i>:(
                      <img src={img[i]} alt="" />
                    )}
                  </div>
                  <div className="contents">
                    <h4 className="mb-10 inquire-title">{cta?.name}</h4>
                    {cta?.description ? (
                      <p className="mb-10">
                        {cta.description}
                      </p>
                    ) : ''}

                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12">
              <p>{t("inquiries.noDataAvailable", "لا توجد بيانات متاحة")}</p>
            </div>
          )}
          <div className="flex-center-btn">
            <Link
              to={"/topic"}
              className="theme-btn me-sm-4 wow fadeInLeft btn-explore border-raduis inquire-btn"
              data-wow-duration="1.2s"
              data-wow-delay=".8s"
            >
              {t("inquiries.viewAllButton")}
            </Link>
          </div>
        </div> */}