import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MainPageDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/user-dashboard/my-reports');
  }, [navigate]);

  return (
    <>
      {/* The content below will be rendered but the user will be redirected */}
      {/* 
      <div className="dashboard-header">
        <div className="main-title">
          <h3>{t('overview')}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 d-flex">
          <div className="dash-widget flex-fill">
            <p>{t('content1')}</p>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <div className="dash-widget flex-fill">
            <p>{t('content2')}</p>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="dash-earning flex-fill">
            <p>{t('content3')}</p>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <div className="dash-widget flex-fill">
            <p>{t('content4')}</p>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <div className="dash-widget flex-fill">
            <p>{t('content5')}</p>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <div className="dash-widget flex-fill">
            <p>{t('content6')}</p>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <div className="dash-widget flex-fill">
            <p>{t('content7')}</p>
          </div>
        </div>
      </div> 
      */}
    </>
  );
};

export default MainPageDashboard;
