import React, { useState, useRef, useEffect } from "react";
import bannerBg from "../../assets/img/Banner/Top-Image4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useGetArticlesTitleQuery } from "../../app/feature/API/Articles";
import { Link } from "react-router-dom";
import { useGetBannersQuery } from "../../app/feature/API/CMS";

const HeroFive = () => {
  const { t, i18n } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const { data } = useGetArticlesTitleQuery(searchQuery);
  const {data:benner} = useGetBannersQuery('knowledgebase')

  const filteredArticles = data?.filter(article =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Refs for the input and dropdown container
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchQuery(""); // Optionally clear the input when clicking outside
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="hero-wrapper hero-1 pb-1">
      <Swiper
        modules={[Navigation]}
        loop
        style={{ height: "500px" }}
      >
        <SwiperSlide
          className="bg-cover"
          style={{
            backgroundImage: `url(${benner?.image})`,
          }}
        >
          <div className="container">
            <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
              <div className="hero-contents pe-lg-3 content-four">
                <h1
                  className="wow fadeInUp animated fz-size-herofour text-white"
                  data-wow-duration="3s"
                >
                  {benner?.title}
                </h1>
                <div className="search-hero-four mt-20">
                  <div
                    className={`form-search-hero-four fadeInUp animated icon-style-hero-five 
                    ${i18n.language === 'en' ? "" : "dire-rtl"}`}
                    data-wow-duration="3s"
                  >
                    <i className="icon fas fa-search mr-20 ml-10"></i>
                    <input
                      ref={inputRef}
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder={t('hero.searchPlaceholder')}
                    />
                    {/* Show dropdown only if searchQuery is not empty */}
                    {searchQuery && (
                      <div 
                        ref={dropdownRef}
                        style={{
                          position: 'absolute',
                          backgroundColor: '#fff',
                          width: '96%',
                          height: 'auto',
                          maxHeight: '140px',
                          top: '105%',
                          borderRadius: '8px',
                          boxShadow: '0px 6px 8px 0px',
                          overflowY: 'auto',
                          zIndex: 1000 
                        }}
                      >
                        {filteredArticles?.length > 0 ? (
                          filteredArticles.map((article) => (
                            <Link to={`/articles/${article?.slug}`} key={article?.id}>
                              <p  style={{ padding: '0 15px', borderBottom: '1px solid #ddd' ,color:'#695ea8'}}>
                                {article?.title}
                                </p>
                            </Link>
                          ))
                        ) : (
                          <div style={{ padding: '10px' }}>{t('No_Data')}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HeroFive;
