import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useGetTicketsDetailsQuery, useSaveTicketsReplyMutation } from "../../app/feature/API/Tickets";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const TicketDetails = () => {
  const { t, i18n } = useTranslation();
  const [textEditorVisible, setTextEditorVisible] = useState(false);
  const [body, setBody] = useState("");

  const { ticket_number } = useParams();
  const { data: ticketDetailsData, error, isLoading } = useGetTicketsDetailsQuery(ticket_number);
  const [saveReply, { isLoading: isSubmitting }] = useSaveTicketsReplyMutation();

  const handleToggleTextEditor = () => {
    setTextEditorVisible(prev => !prev);
  };

  const handleBodyChange = (content) => {
    setBody(content);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    if (body.trim()) {
      try {
        await saveReply({ reply: body, ticket_number }).unwrap();
        toast.success(t("replySavedSuccessfully"));
        setBody("");
        setTextEditorVisible(false);
      } catch (error) {
        console.error("Failed to save ticket:", error);
        handleError(error);
      }
    } else {
      toast.error(t("replyBodyRequired"));
    }
  };

  const handleError = (error) => {
    if (error.status === 422 && error.data?.errors) {
      const errorMessages = error.data.errors.map(err => err.reply).flat().join(', ');
      toast.error(errorMessages || t("errorSavingReply"));
    } else {
      toast.error(t("errorSavingReply"));
    }
  };
  if (error) return <div>Error loading tickets: {error.message}</div>;

  return (
    <div className="page-content content mt-100 mb-50">
      <div className="container">
        <div className="page-back-btn">
          <Link to="/user-dashboard">
            <i className="fas fa-arrow-left me-2"></i>
            {t("backToDashboard")}
          </Link>
        </div>
        <div className="ticket-details">
          <div className="left-ticket-details">
          {isLoading&&(
               <div>   
               {t("loading")} 
               <i className="fa fa-spinner fa-spin" /> 
               </div>
            )}
            <div className="item-left-ticket-details">
              <div style={{maxHeight:'400px',overflow:'auto'}}>
                <div className="header-item-left-ticket-details">
                  {/* <div className="name-company">
                    <p className="letter-brand">B</p>
                    <p className="name">
                      <span>Brandmarksgroup</span> {t("reportedTimeAgo")} <br />
                      <p>{t("updateSoon")}</p>
                    </p>
                    <p className="badge-company">{ticketDetailsData?.status}</p>
                  </div> */}
                  <div className="btns-company">
                    <button className="replay" onClick={handleToggleTextEditor}>
                      {t("reply")}
                    </button>
                  </div>
                </div>
                {ticketDetailsData?.replies?.map((reply, index) => (
                  <div className="name-company mt-10" key={index}>
                    <p className="letter-brand">{i18n.language==='en'?"y":"ا"}</p>
                    <p className="name">
                      <span>{i18n.language==='en'?"You":"انت"}</span> <br />
                      <div dangerouslySetInnerHTML={{ __html: reply.reply }}></div>
                    </p>
                  </div>
                ))}
              </div>
              <div className="body-item-left-ticket-details mt-20">
                <div className="name-company">
                  <p className="letter-brand">B</p>
                  {!textEditorVisible ? (
                    <div className="click-to-show" onClick={handleToggleTextEditor}>
                      <p>{t("clickToAddComment")}</p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                      <div className="quill-details">
                        <ReactQuill
                          placeholder={t("writeADescription")}
                          modules={TicketDetails.modules}
                          formats={TicketDetails.formats}
                          onChange={handleBodyChange}
                          value={body}
                          className="quill-details-sm"
                        />
                        <div className="settings-card-footer pt-60">
                          <div className="btn-item" style={{display:'flex',gap:'15px'}}>
                            <button type="button" onClick={handleToggleTextEditor} className="btn-secondary  w-180 mt-10">
                              {t("cancel")}
                            </button>
                            <div className="btn-item">
                              <button className="btn-primary w-180 mt-10" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? (i18n.language === "en" ? "Submitting..." : "يتم التقديم...") : (i18n.language === "en" ? "Submit" : "يُقدِّم")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right-ticket-details">
            <div className="wrap-right-ticket-details">
              <h3>{t("ticketDetails")}</h3>
            </div>
            <div className="form-wrap mt-10">
              <span>#{ticketDetailsData?.id}</span>
              <br />
              <br />
              <span>{t("status")}</span>: <span>{ticketDetailsData?.status}</span>
              <br />
              <br />
              <span>{new Date(ticketDetailsData?.created_at).toLocaleDateString('en-EG', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TicketDetails.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

TicketDetails.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
];

export default TicketDetails;
