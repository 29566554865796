import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom"; 
import "./assets/Sass/animation.scss";
import "./assets/Sass/bootstrap.scss";
import "./assets/Sass/icons.scss";
import "./assets/Sass/Style.scss";
import "./assets/Sass/index.scss";
import { Provider } from "react-redux";
import store from "./app/Store";
import './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Provider store={store}>
      <HelmetProvider>
        <App />
        <ToastContainer />
      </HelmetProvider>
    </Provider>
  </HashRouter>
);

reportWebVitals();
