import React from 'react'
import HeroTwo from '../components/Hero/HeroTwo'
import GetStarted from '../components/GetStarted'
import ExporterServices from '../components/Services/main/ExporterServices'
import InternationalServices from '../components/Services/main/InternationalServices'
import WhatWeDo from '../components/Services/main/WhatWeDo'
import Capabilities from '../components/Services/main/Capabilities'

const Services = () => {
  return (
    <>
    <HeroTwo/>
    <WhatWeDo/>
    <Capabilities/>
    <GetStarted/>
    <ExporterServices/>
    <InternationalServices/>
    </>
  )
}

export default React.memo(Services)