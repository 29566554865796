import { useEffect, useState, useRef } from "react";
import logo from "../../../assets/img/logo.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDelWishlistMutation } from "../../../app/feature/API/Profile";

const BoxWishlist = ({ data }) => {
  const [isNearRightEdge, setIsNearRightEdge] = useState(false);
  const { t,i18n } = useTranslation();
  const boxesssSectionRef = useRef(null);
  const [delFavorite] = useDelWishlistMutation();

  const addToCart = (report) => {
    const token = localStorage.getItem("token_Front_Mied");
    if (!token) {
      toast.error(t("loginToAddCart"));
      return;
    }

    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const isItemInCart = cartItems.some(item => item.slug === report.slug);

    if (isItemInCart) {
      toast.warning(t("alreadyInCart", { title: report.title }));
    } else {
      cartItems.push(report);
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      toast.success(t("addedToCart", { title: report.title }));
    }
  };

  const addToFavorites = async (report) => {
    const token = localStorage.getItem("token_Front_Mied");
    if (!token) {
      toast.error(t("loginToAddFavorites"));
      return;
    }
    try {
      const response = await delFavorite({ id: report.id }).unwrap();
      toast.success(t("removedToFavorites", { title: report.title }));
      console.log('responseFav(Wishlist)=>', response);
    } catch (error) {
      toast.error(t("errorAddingToFavorites"));
    }
  };

  const handleMouseEnter = (e) => {
    const gridContainer = boxesssSectionRef.current;
    if (!gridContainer) return;

    const gridItems = document.querySelectorAll(".box-report");
    if (gridItems.length === 0) return;

    const gridItemWidth = gridItems[0]?.getBoundingClientRect().width;
    const gridContainerWidth = gridContainer.offsetWidth;
    const itemsInOneRow = Math.floor(gridContainerWidth / gridItemWidth);

    let order = Array.from(gridContainer.children).indexOf(e.target) + 1;
    setIsNearRightEdge(order % itemsInOneRow === 0);
  };

  useEffect(() => {
    const gridItems = document.querySelectorAll(".box-report");
    gridItems.forEach((div) => {
      div.addEventListener("mouseenter", handleMouseEnter);
    });

    return () => {
      gridItems.forEach((div) => {
        div.removeEventListener("mouseenter", handleMouseEnter);
      });
    };
  }, [data]);

  if(data.length === 0) {
	return <p className="text-center fs-3">{t('No_Data')}</p>
  }

  return (
    <section>
      <div ref={boxesssSectionRef} className="boxesss-section"  style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
        {data?.map((report) => (
            <div className="box-report" key={report?.slug}>
            <div className="bg-white">
              <div className="top-box-report">
                <div className="header-box-report">
                  <div className="flex-center">
                    <div className="logo">
                      <img src={logo} alt="" loading="lazy" />
                    </div>
                    <div className="user-information w-70">
                    {report.review !== undefined && report.total_reviews !== undefined && (
                      <ul>
                        <li>
                          <i className="fas fa-star"></i>{" "}
                          {Number(report.review) !== 0 ? report.review : ""}
                          {Number(report.total_reviews) !== 0 ? ` (${report.total_reviews})` : ""}
                        </li>
                      </ul>
                    )}
                    </div>
                  </div>
                  <div className="text-desc">
                    {i18n.language==='en'?report?.title:report?.title_ar}
                  </div>
                </div>
                <div className="body-box-report">
                  <img src={report?.feature_image} alt="" />
                </div>
              </div>
              <div className="bottom-box-report">
              {i18n.language==='en'?(
                <p>
                  {report?.description
                    .replace(/<[^>]+>/g, '') 
                    .trim() 
                    .split('.')
                    .filter(Boolean)[0]
                    .trim()}
                </p>
              ):(
                <p>
                {report?.description_ar
                  .replace(/<[^>]+>/g, '') 
                  .trim() 
                  .split('.')
                  .filter(Boolean)[0]
                  .trim()}
              </p>
              )}
                {/* <p>{report?.summary || <p dangerouslySetInnerHTML={{__html:i18n.language==='en'?report?.description:report?.description_ar}}></p>}</p> */}
              </div>
            </div>
            <div
              className={`${
                isNearRightEdge ? "right-screen-hover" : `${i18n.language === 'en' ? "hover-box-report":"hover-box-report hover-box-report-ar"}`
              }`} 
            >
              <p className="main-title">
              {i18n.language==='en'?report?.title:report?.title_ar}
              </p>
              <div className="subtitle-hover">
                <span className="best-hover-box">{report?.category}</span>
                <p className="updated-hover-box">
                  Updated <span className="date-hover-box">{new Date(report?.updated_at).toLocaleDateString(i18n.language==='en'?'en-US':'ar-US', { year: 'numeric', month: 'long' })}</span>
                </p>
              </div>
              <div className="time-zone-hover">
                <p> {report?.page || report.pages || 28} Pages </p>
              </div>
              <div className="desc-hover">
                {i18n.language==='en'?(
                  <p>
                    {report?.description
                      .replace(/<[^>]+>/g, '') 
                      .trim() 
                      .split('.')
                      .filter(Boolean)[0]
                      .trim()}
                  </p>
                ):(
                  <p>
                  {report?.description_ar
                    .replace(/<[^>]+>/g, '') 
                    .trim() 
                    .split('.')
                    .filter(Boolean)[0]
                    .trim()}
                </p>
                )}
              </div>
              <ul className={`${i18n.language==='en'?"ul-hover-box":"ul-hover-box-ar"}`}>
                {i18n.language==='en'?(
                  report?.description
                  .replace(/<[^>]+>/g, '')
                  .trim() 
                  .split('.')
                  .filter(Boolean)
                  .slice(1) 
                  .map((item, index) => (
                    <li key={index}><i className="fas fa-check"></i>{item.trim()}</li>
                  ))
                ):(
                  report?.description_ar
                  .replace(/<[^>]+>/g, '')
                  .trim() 
                  .split('.')
                  .filter(Boolean)
                  .slice(1) 
                  .map((item, index) => (
                    <li key={index}><i className="fas fa-check"></i>{item.trim()}</li>
                  ))
                )}
              </ul>
              {/* <div className="desc-hover">
                <p dangerouslySetInnerHTML={{ __html: report?.description }}></p>
              </div> */}
              {/* <ul class="ul-hover-box"><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li><li><i class="fas fa-check"></i>How to get the most out of the industry</li></ul> */}
              <div className="btns-hover">
                <h5>${report?.current_price}</h5>
                <button onClick={() => addToCart(report)}>{t("Add to cart")}</button>
                <div className="heart-hover" onClick={()=>addToFavorites(report)}>
                  <i className="fas fa-heart-circle"></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BoxWishlist;
