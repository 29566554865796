import { useTranslation } from 'react-i18next'
import PageBanner from '../Shared/PageBanner'
import bannerBg from '../assets/img/Banner/Top-Image3.jpg'
import Portfolio from '../components/Services/Exports/Portfolio'
import PortfolioTwo from '../components/SuccessStories/PortfolioTwo'
import { useGetBannersQuery } from '../app/feature/API/CMS'
import React, { useEffect } from 'react'
import { useFetchGeneralSettingQuery } from '../app/feature/API/Helpers'
import { useNavigate } from 'react-router-dom'

const SuccessStories = () => {
  const {i18n} = useTranslation()
  const {data} = useGetBannersQuery('successstories')
  const navigate = useNavigate();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const SuccessStoriesPage = GeneralSetting?.map((e)=>e?.success_stories_page_show)

  useEffect(() => {

    if (Number(SuccessStoriesPage)===0) {
      navigate("/");
    }
  }, [SuccessStoriesPage, navigate]);
  return (
    <>
    <PageBanner currentPage={data?.title} title={data?.title} 
    bannerBg={data?.image}/>
    <Portfolio/>
    <PortfolioTwo/>
    </>
  )
}

export default React.memo(SuccessStories)