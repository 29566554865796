import React from 'react'
import { Link } from 'react-router-dom';
import { TfiLayersAlt } from "react-icons/tfi";
import { PiAirplayDuotone } from "react-icons/pi";
import { IoBagHandleOutline } from "react-icons/io5";
import { FaArrowRight, FaUserTie } from "react-icons/fa";
import { FaRegHospital } from "react-icons/fa";
import { PiTelegramLogoBold } from "react-icons/pi";
import { GrGallery } from "react-icons/gr";
import { AiFillAndroid } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { useGetJobsCategoriesQuery } from '../../app/feature/API/Jobs';
import { BiSolidCategoryAlt } from "react-icons/bi";

const JobsCategories = () => {
  const categories = [
    {
      id: 1,
      icon: <TfiLayersAlt />,
      name: "IT & Software",
      job: 2024
    },
    {
      id: 2,
      icon: <PiAirplayDuotone />,
      name: "Technology",
      job: 1250
    },
    {
      id: 3,
      icon: <IoBagHandleOutline />,
      name: "Government",
      job: 802
    },
    {
      id: 4,
      icon: <FaUserTie />,
      name: "Accounting / Finance",
      job: 577
    },
    {
      id: 5,
      icon: <FaRegHospital />,
      name: "Construction / Facilities",
      job: 285
    },
    {
      id: 6,
      icon: <PiTelegramLogoBold />,
      name: "Tele-communications",
      job: 495
    },
    {
      id: 7,
      icon: <GrGallery />,
      name: "Design & Multimedia",
      job: 1045
    },
    {
      id: 8,
      icon: <AiFillAndroid />,
      name: "Human Resource",
      job: 1516
    }
  ];

  const {i18n} = useTranslation();
  const {data : JobCategoryData ,isLoading} = useGetJobsCategoriesQuery();
  return (
    <section className="our-service-wrapper section-padding bg-white">
      <div className="container" data-aos="fade-up">
        <div className="row mb-80">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title pb-20">
                <h2>{i18n.language === 'en' ? "BROWSE Jobs Categories" : "تصفح فئات الوظائف"}</h2>
              </div>
              <p className="desc-cat">
                {i18n.language === 'en' ? "Find the right vacancy for your career." : "ابحث عن الوظيفة الشاغرة المناسبة لحياتك المهنية."}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
        {isLoading&&(
           <div className="spinner-overlay">
           <div className="spinner"></div>
         </div>
        )}
          {JobCategoryData?.slice(0,8).map((data) => (
            <div className="col-xl-3 col-md-6 col-12 mb-50" key={data.id}>
              <div className="single-jop-category-card">
                <div className="service-cover-img bg-cover"></div>
                <div className="content-job-category">
                  <div className="icon-title">
                    <div className="icon"><i className={data?.icon}></i> </div>
                    <div className="service-title">
                      <h4 className="name-jop-category">
                        <Link to="/job-list">{data?.name}</Link>
                      </h4>
                    </div>
                  </div>
                  <p className="desc-app mt-2">{data?.job_count} {i18n.language==='en'?"Jobs":"وظائف"}</p>
                </div>
              </div>
            </div>
          ))}
          <div className='all-cat-job-cat'>
            <Link to='/job-list'>
              {i18n.language === 'en' ? <>
                <FaArrowRight /> Browse All Categories
              </> :
                <>
                  <FaArrowRight /> {" "}
                  تصفح جميع الفئات
                </>
              }
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobsCategories;
