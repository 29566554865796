import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const ServicesApi = createApi({
  reducerPath: "ServicesApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getServices: build.query({
      query: ({ user_type, page = 1}) => 
        `/services?page=${page}&user_type=${user_type}`,
      transformResponse: (response) => response,
      providesTags: ["ServicesApi"],
    }),
    getServicesSmart: build.query({
      query: ({ user_type ='', industry_id = '',country_code=''}) => 
        `/services?user_type=${user_type}&industry_id=${industry_id}&country_code=${country_code}`,
      transformResponse: (response) => response.data,
      providesTags: ["ServicesApi"],
    }),
  }),
});

export const { useGetServicesQuery,useLazyGetServicesSmartQuery } = ServicesApi;
