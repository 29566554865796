import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "../../../node_modules/react-quill/dist/quill.snow.css";
import { useSaveTicketsMutation } from "../../app/feature/API/Tickets";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const NewTicket = () => {
	const dropDownRef = useRef();
	const [dropDown3, setDropDown3] = useState(false);
	const [body, setBody] = useState("");
	const [subject, setSubject] = useState("");
	const [saveTicket, { isLoading, }] = useSaveTicketsMutation(); 

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
				setDropDown3(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const handleBody = (e) => {
		setBody(e);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await saveTicket({ subject, message: body }).unwrap();
			const { message } = response; 
			toast.success(message);
			setBody("");
			setSubject("");
		} catch (err) {
			console.error("Failed to save ticket: ", err);
			toast.error("An error occurred while saving the ticket.");
		}
	};
	
	const [t,i18n] = useTranslation();
	return (
		<div className="page-content content mt-100 mb-50">
			<div className="container">
				<div className="page-back-btn">
					<Link to="/user-dashboard">
						<i className="fas fa-arrow-left me-2"></i>
						{t('Back to Dashboard')}
					</Link>
				</div>
				<div className="settings-card mt-40">
					<div className="settings-card-head">
						<h4>{i18n.language==="en"?"Submit a ticket":"إرسال تذكرة"}</h4>
						<p>
							{i18n.language==="en"?"Please note: This help desk is for inquiries related to the MEID platform. Specifics related to your contract work and projects should go directly to your client contact.":"يرجى ملاحظة: مكتب المساعدة هذا مخصص للاستفسارات المتعلقة بمنصة MEID. يجب أن تنتقل التفاصيل المتعلقة بأعمال العقد والمشاريع الخاصة بك مباشرة إلى جهة اتصال العميل الخاص بك."}
						</p>
					</div>
					<form onSubmit={handleSubmit}>
						<div className="settings-card-body">
							<div className="row">
								<div className="col-md-12">
									<div className="form-wrap">
										<label>
											{i18n.language==="en"?"Subject":"موضوع"}</label>
										<input
											type="text"
											className="form-control"
											value={subject}
											onChange={(e) => setSubject(e.target.value)}
											required
										/>
									</div>
								</div>
								{/* <div className="col-md-12">
									<div className="form-wrap">
										<label>Question type</label>
										<div className="drop-down-con" ref={dropDownRef}>
											<div className="drop-down" onClick={handleDropDown3}>
												<p>
													{dropDownChoose3 ? dropDownChoose3 : "Question type"}
												</p>
												<p className="search-btn">
													<i className="fas fa-chevron-down"></i>
												</p>
											</div>
											<div className={`drop ${dropDown3 ? "" : "d-none"}`}>
												<p
													onClick={() => setDropDownChoose3("Question type 1")}
												>
													Question type 1
												</p>
												<p
													onClick={() => setDropDownChoose3("Question type 2")}
												>
													Question type 2
												</p>
											</div>
										</div>
									</div>
								</div> */}
								<div className="col-md-12 mb-55">
									<div className="form-wrap">
										<label>{i18n.language==="en"?"Description":"وصف"}</label>
										<ReactQuill
											placeholder={i18n.language==="en"?"Write a Description":"اكتب وصف"}
											modules={NewTicket.modules}
											formats={NewTicket.formats}
											onChange={handleBody}
											value={body}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="settings-card-footer">
							<div className="btn-item">
								<button className="btn-primary w-180 mt-10" type="submit" disabled={isLoading}>
								{isLoading ? (i18n.language === "en" ? "Submitting..." : "يتم التقديم...") : (i18n.language === "en" ? "Submit" : "يُقدِّم")}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

NewTicket.modules = {
	toolbar: [
		["bold", "italic", "underline", "strike", "blockquote"],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image", "video"],
		["clean"],
		["code-block"],
	],
};

NewTicket.formats = [
	"header",
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"link",
	"image",
	"video",
	"code-block",
];

export default NewTicket;
