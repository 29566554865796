import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const SliderApi = createApi({
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
  }),
  reducerPath: "SliderApi",
  endpoints: (build) => ({
    getSliders: build.query({
      query: (type) => `/sliders?type=${type}`,
      transformResponse: (response) => response.data,
      providesTags: ["Sliders"],
    }),
  }),
});

export const {
  useGetSlidersQuery,
} = SliderApi;
