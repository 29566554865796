import { useEffect } from "react";
import Routing from "./Routes/Routing";
import Aos from "aos";
function App() {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return <Routing />;
}
export default App;
