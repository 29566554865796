import React from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "../components/job/JobDetailsDescription";
import JobVacancyPost from "../components/job/JobVacancyPost";
import PageBanner from "../Shared/PageBanner";
import bannerBg from '../assets/img/Banner/Top-Image6.jpg'
import RightSideContent from "../components/job/RightSideContent";
import { useGetBannersQuery } from "../app/feature/API/CMS";


const JobDetails = () => {
  const {data} = useGetBannersQuery('jobdetails')

  return (
    <React.Fragment>
          <PageBanner currentPage={data?.title} title={data?.title} bannerBg={data?.image}/>
      <section className="section job-details">
        <Container>
          <Row>
            <Col lg={8}>
              <JobDetailsDescription />
              <JobVacancyPost />
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <RightSideContent />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default React.memo(JobDetails);
