import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Input } from "reactstrap";
import { useGetJobsCategoriesQuery } from "../../app/feature/API/Jobs";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const [toggleFirst, setToggleFirst] = useState(true);
  const [toggleSecond, setToggleSecond] = useState(true);
  const [toggleThird, setToggleThird] = useState(true);
  const [toggleFourth, setToggleFourth] = useState(true);
  const [toggleFifth, setToggleFifth] = useState(true);

  const [isChecked, setIsChecked] = useState("");
  const [isDateChecked, setIsDateChecked] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [selectedTagId, setSelectedTagId] = useState("");

  const { data: JobCategoryData } = useGetJobsCategoriesQuery();
  const { i18n } = useTranslation();

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  useEffect(() => {
    const savedExperience = localStorage.getItem("workExperience");
    const savedDatePosted = localStorage.getItem("datePosted");
    const savedSearchValue = localStorage.getItem("searchValue");
    const savedEmploymentType = localStorage.getItem("employmentType");
    const savedTagId = localStorage.getItem("selectedTagId");

    if (savedExperience) {
      setIsChecked(savedExperience);
    }
    if (savedDatePosted) {
      setIsDateChecked(savedDatePosted);
    }
    if (savedSearchValue) {
      setSearchValue(savedSearchValue);
    }
    if (savedEmploymentType) {
      setEmploymentType(savedEmploymentType);
    }
    if (savedTagId) {
      setSelectedTagId(savedTagId);
    }
  }, []);

  const handleOnChange = (value) => {
    setIsChecked(value);
    saveToLocalStorage("workExperience", value);
  };

  const handleDateOnChange = (value) => {
    setIsDateChecked(value);
    saveToLocalStorage("datePosted", value);
  };

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    saveToLocalStorage("searchValue", newValue);
  };

  const handleEmploymentTypeChange = (value) => {
    setEmploymentType(value);
    saveToLocalStorage("employmentType", value);
  };

  const handleTagClick = (id) => {
    setSelectedTagId(id);
    saveToLocalStorage("selectedTagId", id);
  };

  const dateOptions = [
    { label: i18n.language === "en" ? "All" : "الكل", value: "all" },
    { label: i18n.language === "en" ? "Last Hour" : "الساعة الأخيرة", value: "last_hour" },
    { label: i18n.language === "en" ? "Last 24 hours" : "آخر 24 ساعة", value: "last_24" },
    { label: i18n.language === "en" ? "Last 7 days" : "آخر 7 أيام", value: "last_7" },
    { label: i18n.language === "en" ? "Last 30 days" : "آخر 30 يومًا", value: "last_30" }
  ];

  const experienceOptions = [
    { label: i18n.language === "en" ? "No experience" : "بدون خبرة", value: "0" },
    { label: i18n.language === "en" ? "0-3 years" : "0-3 سنوات", value: "0-3" },
    { label: i18n.language === "en" ? "3-6 years" : "3-6 سنوات", value: "3-6" },
    { label: i18n.language === "en" ? "More than 6 years" : "أكثر من 6 سنوات", value: "*-7" }
  ];

  const employmentOptions = [
    { label: i18n.language === "en" ? "Full Time" : "دوام كامل", value: "Full-time" },
    { label: i18n.language === "en" ? "Part Time" : "دوام جزئي", value: "Part-time" }
  ];

  return (
    <React.Fragment>
          <Col lg={3} style={{
      direction: i18n.language === 'en' ? 'ltr' : 'rtl'
    }}
    >
        <div className="side-bar mt-lg-0 pb-30">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="locationOne">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFirst(!toggleFirst);
                  }}
                  role="button"
                  aria-expanded={toggleFirst}
                >
                  {i18n.language === "en" ? "Location" : "الموقع"}
                </Button>
              </h2>
              <Collapse isOpen={toggleFirst}>
                <div className="accordion-body">
                  <div className="side-title">
                    <div className="mb-3">
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={i18n.language === "en" ? "Search..." : "ابحث..."}
                          value={searchValue}
                          onChange={handleSearchChange}
                        />
                        <p
                          className="bg-transparent border-none-jo-list border-0 position-absolute top-50 end-0 translate-middle-y me-2"
                        >
                          <span className="mdi mdi-magnify text-muted"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-4">
              <h2 className="accordion-header" id="experienceOne">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleSecond(!toggleSecond);
                  }}
                  role="button"
                  aria-expanded={toggleSecond}
                >
                  {i18n.language === "en" ? "Work Experience" : "الخبرة العملية"}
                </Button>
              </h2>
              <Collapse isOpen={toggleSecond}>
                <div className="accordion-body">
                  <div className="side-title">
                    {experienceOptions.map(({ label, value }) => (
                      <div className="form-check mt-2" key={value}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="workExperience"
                          value={value}
                          id={`flexCheckChecked-${value}`}
                          checked={isChecked === value}
                          onChange={() => handleOnChange(value)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`flexCheckChecked-${value}`}
                        >
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="jobType">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleThird(!toggleThird);
                  }}
                  role="button"
                  aria-expanded={toggleThird}
                >
                  {i18n.language === "en" ? "Type of Employment" : "نوع العمل"}
                </Button>
              </h2>
              <Collapse isOpen={toggleThird}>
                <div className="accordion-body">
                  <div className="side-title">
                    {employmentOptions.map(({ label, value }) => (
                      <div className="form-check mt-2" key={value}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="employmentType"
                          value={value}
                          id={`flexRadioDefault-${value}`}
                          checked={employmentType === value}
                          onChange={() => handleEmploymentTypeChange(value)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`flexRadioDefault-${value}`}
                        >
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="datePosted">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFourth(!toggleFourth);
                  }}
                  role="button"
                  aria-expanded={toggleFourth}
                >
                  {i18n.language === "en" ? "Date Posted" : "تاريخ النشر"}
                </Button>
              </h2>
              <Collapse isOpen={toggleFourth}>
                <div className="accordion-body">
                  <div className="side-title">
                    {dateOptions.map(({ label, value }) => (
                      <div className="form-check mt-2" key={value}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="datePosted"
                          value={value}
                          id={`flexCheckChecked-${value}`}
                          checked={isDateChecked === value}
                          onChange={() => handleDateOnChange(value)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`flexCheckChecked-${value}`}
                        >
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="tags">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFifth(!toggleFifth);
                  }}
                  role="button"
                  aria-expanded={toggleFifth}
                >
                  {i18n.language === "en" ? "Tags" : "الوسوم"}
                </Button>
              </h2>
              <Collapse isOpen={toggleFifth}>
                <div className="accordion-body">
                  <div className="tags-cloud">
                    {JobCategoryData?.map((tag) => (
                      <p
                        key={tag.id}
                        style={{cursor: 'pointer'}}
                        className={`badge tag-cloud2 fs-13 mt-2 ${selectedTagId === tag.id ? 'badge tag-cloud fs-13 mt-2' : ''}`}
                        onClick={() => handleTagClick(tag.id)}
                      >
                        {tag.name}
                      </p>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Sidebar;
