import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const CMSApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem("userLanguage") || "en";
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept-Language", language);
      return headers;
    },
  }),
  reducerPath: "CMSApi",
  endpoints: (build) => ({
    getHomeSections: build.query({
      query: (section) => `/cms/sections/${section}`,
      transformResponse: (response) => response.data,
      providesTags: ["CMSApi"],
    }),
    getBanners: build.query({
      query: (page_name) => `/cms/banners/${page_name}`,
      transformResponse: (response) => response.data,
      providesTags: ["CMSApi"],
    }),
    getWhyEgyptSections: build.query({
      query: () => `/cms/whyegy/benefits`,
      transformResponse: (response) => response,
      providesTags: ["CMSApi"],
    }),
    getExportFromEgyptianSection: build.query({
      query: () => `/cms/sections/whyegypt`,
      transformResponse: (response) => response,
      providesTags: ["CMSApi"],
    }),
    getLogoRotateSection: build.query({
      query: () => `/cms/logos/rotater`,
      transformResponse: (response) => response.data,
      providesTags: ["CMSApi"],
    }),
  }),
});

export const {
  useGetHomeSectionsQuery,
  useGetWhyEgyptSectionsQuery,
  useGetExportFromEgyptianSectionQuery,
  useGetBannersQuery,
  useGetLogoRotateSectionQuery
} = CMSApi;
