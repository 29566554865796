
import { Routes, Route } from "react-router-dom";
import HomeOne from "../pages/HomeOne";
import AppLayout from "../Layout/AppLayout";
import Services from "../pages/Services";
import News from "../pages/News";
import ExportServices from "../pages/ExportServices";
import ContactOne from "../pages/ContactOne";
import WhyEgypt from "../pages/WhyEgypt";
import SuccessStories from "../pages/SuccessStories";
import KnowledgeBase from "../pages/KnowledgeBase";
import AboutUs from "../pages/AboutUs";
import Team from "../pages/Team";
import ImportServices from "../pages/ImportServices";
import ReportsPage from "../pages/ReportsPage";
import Index from "../pages/UserDashboard/Index";
import HomeDashboard from "../pages/UserDashboard/HomeDashboard";
import Profile from "../pages/UserDashboard/profile/Profile";
import MainPageDashboard from "../pages/UserDashboard/MainPageDashboard";
import MyReports from "../pages/UserDashboard/MyReports";
import MyWishlist from "../pages/UserDashboard/MyWishlist";
import Payments from "../pages/UserDashboard/Payments";
import MyReview from "../pages/UserDashboard/MyReview";
import TicketSupport from "../pages/UserDashboard/TicketSupport";
import NewTicket from "../pages/UserDashboard/NewTicket";
import TicketStatus from "../pages/UserDashboard/TicketStatus";
import TicketDetails from "../pages/UserDashboard/TicketDetails";
import AuthOne from "../pages/Auth/AuthOne";
import Careers from "../pages/Careers";
import JobDetails from "../pages/JobDetails";
import ResultPage from "../pages/ResultPage";
import JobList from "../pages/JobList";
import NewsDetails from "../pages/NewsDetails";
import Topics from "../components/Knowledge/KnowledgeDetails/Topics";
import TopicDetails from "../components/Knowledge/KnowledgeDetails/TopicDetails";
import Articles from "../components/Knowledge/KnowledgeDetails/Articles";
import ScrollToTop from "../components/ScrollTopPage";
import ForgetPassword from "../pages/Auth/Forget/ForgetPassword";
import ResetPassword from "../pages/Auth/Reset/ResetPassword";
import ConfirmEmail from "../pages/Auth/Confirm/ConfirmEmail";
import Cart from "../pages/Cart";
import ChangePassword from "../pages/UserDashboard/ChangePassword";
import DeviceManagement from "../pages/UserDashboard/DeviceManagement";
import PortfolioDetails from "../pages/PortfolioDetails";

const Routing = () => {
  return (
    <ScrollToTop
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -100 }}
      transition={{ duration: 1 }}
    >
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<HomeOne />} />
          <Route path="/services" element={<Services />} />
          <Route path="/exportservices" element={<ExportServices />} />
          <Route path="/importservices" element={<ImportServices />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsDetails/:slug" element={<NewsDetails />} />
          <Route path="/portfolioDetails/:slug" element={<PortfolioDetails />} />
          <Route path="/contact" element={<ContactOne />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/whyegypt" element={<WhyEgypt />} />
          <Route path="/successstories" element={<SuccessStories />} />
          <Route path="/smart-service-request" element={<ResultPage />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/jobdetails/:name" element={<JobDetails />} />
          <Route path="/job-list" element={<JobList />} />
          <Route path="/knowledgebase" element={<KnowledgeBase />} />
          <Route path="/topic" element={<Topics />} />
          <Route path="/topic/:name" element={<TopicDetails />} />
          <Route path="/articles/:name" element={<Articles />} />
          <Route path="/aboutus" element={<AboutUs />} />
          {/* <Route path="/team" element={<Team />} /> */}
          <Route path="/cart" element={<Cart/>} />
          <Route path="/user-dashboard" element={<Index />}>
            <Route path="/user-dashboard/my-profile" element={<Profile />} />
            <Route path="/user-dashboard/new-ticket" element={<NewTicket />} />
            <Route
              path="/user-dashboard/ticket-status"
              element={<TicketStatus />}
            />
            <Route
              path="/user-dashboard/ticket-details/:ticket_number"
              element={<TicketDetails />}
            />
            <Route path="/user-dashboard" element={<HomeDashboard />}>
              <Route index element={<MainPageDashboard />} />
              <Route
                path="/user-dashboard/my-reports"
                element={<MyReports />}
              />
              <Route
                path="/user-dashboard/my-wishlist"
                element={<MyWishlist />}
              />
              <Route path="/user-dashboard/my-reviews" element={<MyReview />} />
              <Route
                path="/user-dashboard/ticket-support"
                element={<TicketSupport />}
              />
              <Route path="/user-dashboard/payments" element={<Payments />} />
            </Route>
              <Route path="/user-dashboard/password_and_security/change-password" element={<ChangePassword />} />
              <Route path="/user-dashboard/password_and_security/device-management" element={<DeviceManagement />} />
          </Route>
        </Route>
        <Route path="/login" element={<AuthOne />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Routing;
