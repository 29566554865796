import { FormGroup, Input, Label } from 'reactstrap'
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchCountriesQuery, useFetchIndustriesQuery } from "../../app/feature/API/Helpers";
import { useLazyGetServicesSmartQuery } from "../../app/feature/API/Services";
import { toast } from "react-toastify";
import { setServicesData } from "../../app/feature/SaveJobData";

const Recommended = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleActivityChange = (e) => {
    setSelectedActivity(e.target.value);
  };

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const [triggerSearch, { data: ServicesData ,isSuccess:ServicesDataSuccess}] = useLazyGetServicesSmartQuery(); 

  const handleSearch = () => {
    if (!selectedActivity && !selectedIndustry && !selectedCountry) {
      const message = i18n.language==="en"?"من فضلك،ادخل البيانات بالكامل.":"Please enter all the data"
      toast.warning(message);
      return; 
    }

    triggerSearch({
      user_type: selectedActivity || '',
      industry_id: selectedIndustry || '',
      country_code: selectedCountry || '',
    });
  };

  useEffect(() => {
    if (ServicesDataSuccess) {
      if (ServicesData?.length > 0) {
        toast.success(t("Results found for the search!"));
        dispatch(setServicesData(ServicesData));
        localStorage.setItem("services", JSON.stringify(ServicesData))
        window.location.reload();
        navigate(`/smart-service-request`);
      } else {
        toast.info(t("No results found for this value!"));
      }
    }
  }, [dispatch, navigate, ServicesDataSuccess, ServicesData?.length, ServicesData]);
  const getStartedData = t("GetStartedSection", { returnObjects: true });
  const { data: AllCountries } = useFetchCountriesQuery();
  const { data: AllIndustries } = useFetchIndustriesQuery();

  return (
    <section className="content-block-wrapper section-padding bg-white fix">
      <div className="container">
        <div className="row align-items-center section-padding pb-0">
          <div className="col-xl-12 col-lg-12 col-12 mt-5 mt-lg-0 order-2 order-lg-1
          recommendedSec" data-aos="fade-right">
            <div className="section-title-3">
              <p className="txt-reports line-minus">
                <span className="sec-desc-report recom-text">{t("recommendedForYou")}</span>{" "}
                <br />
                <p className='sec-txt-recom'>{t("basedOn")} <span className="main-desc-report recom-third">{t("yourSelection")}</span></p>
              </p>
            </div>
            <form action="">
      <div className="pt-40 flex-select-getStarted">
        <FormGroup className="mb-3 flex-1 wrap-select-get-start">
          <Label for={`I want to`} className="form-label label-getStarted">
            {i18n.language === 'en' ? 'I want to' : 'اريد'}
          </Label>
          <Input
            type="select"
            name="select an Activity"
            id="I want to"
            required
            className="select-getStarted"
            value={selectedActivity || localStorage.getItem('selectedActivityRecommended')}
            onChange={handleActivityChange}
          >
            <option value="" disabled>
              {i18n.language === 'en' ? 'select an Activity' : 'حدد نشاطا'}
            </option>
            <option value="importer">
              {i18n.language === 'en'
                ? 'Import Products From Egypt'
                : 'استيراد المنتجات من مصر'}
            </option>
            <option value="exporter">
              {i18n.language === 'en'
                ? 'Export Products From Egypt'
                : 'تصدير المنتجات الي مصر'}
            </option>
          </Input>
        </FormGroup>

        <FormGroup className="mb-3 flex-1 wrap-select-get-start">
          <Label
            for="Choose Your Industry"
            className="form-label label-getStarted"
          >
            {i18n.language === 'en' ? 'Choose Your Industry' : 'اختر صناعتك'}
          </Label>
          <Input
            type="select"
            name="Choose Your Industry"
            id="Choose Your Industry"
            required
            className="select-getStarted"
            value={selectedIndustry  || localStorage.getItem('selectedIndustryRecommended')}
            onChange={handleIndustryChange}
          >
            <option value="" disabled>
              {i18n.language === 'en' ? 'Choose Your Industry' : 'اختر صناعتك'}
            </option>
            <option value="0">
              {i18n.language === 'en' ? 'All' : 'الكل'}
            </option>
            {AllIndustries &&
              AllIndustries.map((industry) => (
                <option key={industry.id} value={industry.id}>
                  {industry.name}
                </option>
              ))}
          </Input>
        </FormGroup>

        <FormGroup className="mb-3 flex-1 wrap-select-get-start">
          <Label
            for="Choose Your Country"
            className="form-label label-getStarted"
          >
            {i18n.language === 'en' ? 'Choose Your Country' : 'اختر دولتك'}
          </Label>
          <Input
            type="select"
            name="Choose Your Country"
            id="Choose Your Country"
            required
            className="select-getStarted"
            value={selectedCountry  || localStorage.getItem('selectedCountryRecommended')}
            onChange={handleCountryChange}
          >
            <option value="" disabled>
              {i18n.language === 'en' ? 'Choose Your Country' : 'اختر دولتك'}
            </option>
            <option value="0">
              {i18n.language === 'en' ? 'All' : 'الكل'}
            </option>
            {AllCountries &&
              AllCountries.map((country) => (
                <option key={country.id} value={country.code}>
                  {country.name}
                </option>
              ))}
          </Input>
        </FormGroup>
      </div>
      <div className="text-align-end mt-20">
        <span style={{cursor:'pointer'}} className="btn-advance" onClick={handleSearch}>
          {t('btnNameSectionGetStarted')}
        </span>
      </div>
    </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Recommended
