import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useResetPasswordMutation } from "../../../app/feature/API/User";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ResetPasswordForm() {
        
  const DefaultEmail = localStorage.getItem('emailForgetPassword')
  const { t } = useTranslation();
  const [email, setEmail] = useState(DefaultEmail||"");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errors, setErrors] = useState({ email: '', otp: '', newPassword: '', repeatPassword: '' });
  const [forgetPassword, { isLoading }] = useResetPasswordMutation();

  const validate = () => {
    let isValid = true;
    const errors = { email: '', otp: '', newPassword: '', repeatPassword: '' };

    if (!email) {
      errors.email = t('login.emailRequired');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = t('login.emailInvalid');
      isValid = false;
    }

    if (!otp) {
      errors.otp = t('login.otpRequired');
      isValid = false;
    }

    if (!newPassword) {
      errors.newPassword = t('login.passwordRequired');
      isValid = false;
    } else if (newPassword.length < 6) {
      errors.newPassword = t('login.passwordMinLength');
      isValid = false;
    }

    if (newPassword !== repeatPassword) {
      errors.repeatPassword = t('login.passwordMismatch');
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
const navigate = useNavigate();
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
       const response = await forgetPassword({
          email,
          otp,
          new_password: newPassword,
          repeat_password: repeatPassword,
        }).unwrap();
        toast.success(response.message || "Form submitted successfully");
        localStorage.removeItem('emailForgetPassword')
        navigate('/login');
      } catch (err) {
        toast.error("هناك مشكله في البيانات المرسله");
      }
    }
  };

  return (
    <div className="form-container sign-in-container">
      <form onSubmit={handleResetPassword}>
        <h1>{t('login.resetPassword')}</h1>
        
        {/* Email Input */}
        <div className="flex-required-input">
          <input
            type="email"
            placeholder={t('login.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="astric">*</span>
        </div>
        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}

        {/* OTP Input */}
        <div className="flex-required-input">
          <input
            type="text"
            placeholder={t('login.otp')}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <span className="astric">*</span>
        </div>
        {errors.otp && <span style={{ color: 'red' }}>{errors.otp}</span>}

        {/* New Password Input */}
        <div className="flex-required-input">
          <input
            type="password"
            placeholder={t('login.newPassword')}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <span className="astric">*</span>
        </div>
        {errors.newPassword && <span style={{ color: 'red' }}>{errors.newPassword}</span>}

        {/* Repeat Password Input */}
        <div className="flex-required-input">
          <input
            type="password"
            placeholder={t('login.repeatPassword')}
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
          <span className="astric">*</span>
        </div>
        {errors.repeatPassword && <span style={{ color: 'red' }}>{errors.repeatPassword}</span>}

        {/* Submit Button */}
        <button type="submit" disabled={isLoading}>
          {isLoading ? t('login.loading') : t('login.resetPassword')}
        </button>
      </form>
    </div>
  );
}
