import React, { useEffect } from 'react'
import HeroSeven from '../components/Hero/HeroSeven'
import JobsCategories from '../components/job/JobsCategories'
import RandomJobs from '../components/job/RandomJobs'
import Candidates from '../components/job/Candidates'
import ModalApplyJob from '../components/ModalApplyJob'
import { useFetchGeneralSettingQuery } from '../app/feature/API/Helpers'
import { useNavigate } from 'react-router-dom'

const Careers = () => {
  const navigate = useNavigate();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const careers_page_show = GeneralSetting?.map((e)=>e?.careers_page_show)

  useEffect(() => {

    if (Number(careers_page_show)===0) {
      navigate("/");
    }
  }, [careers_page_show, navigate]);
  return (
    <>
     <HeroSeven/>
    <JobsCategories/>
    <RandomJobs/>
    {/* <Candidates/> */}
    <ModalApplyJob/>
    </>
  )
}

export default React.memo(Careers)
