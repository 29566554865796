import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useFetchCitiesQuery, useFetchCountriesQuery, useFetchIndustriesQuery } from "../../../app/feature/API/Helpers";
import { useGetProfileQuery, useUpdateProfileMutation } from "../../../app/feature/API/Profile";
import { toast } from "react-toastify";

const BusinessInformationForm = () => {
    const { t } = useTranslation();
    const dropDownRefCountry = useRef();
    const dropDownRefCity = useRef();
    const dropDownRefIndustry = useRef();

    const [dropDownCountryOpen, setDropDownCountryOpen] = useState(false);
    const [dropDownCityOpen, setDropDownCityOpen] = useState(false);
    const [dropDownIndustryOpen, setDropDownIndustryOpen] = useState(false);

    const [formData, setFormData] = useState({
        selectedCountry: "",
        selectedCountryCode: "",
        selectedCity: "",
        selectedCityID: "",
        selectedIndustry: "",
        selectedIndustryId: "",
        selectedCompanyWebsite: "",
        phone: "",
        job_title: '',
        job_company: ''
    });

    const [countrySearch, setCountrySearch] = useState("");
    const [citySearch, setCitySearch] = useState("");
    const [industrySearch, setIndustrySearch] = useState("");
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [errors, setErrors] = useState({});

    const { data: AllCountries } = useFetchCountriesQuery();
    const { data: AllCities } = useFetchCitiesQuery(formData.selectedCountryCode);
    const { data: AllIndustries } = useFetchIndustriesQuery();
    const { data: ProfileData } = useGetProfileQuery();
    const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropDownRefCountry.current && !dropDownRefCountry.current.contains(event.target)) {
                setDropDownCountryOpen(false);
            }
            if (dropDownRefCity.current && !dropDownRefCity.current.contains(event.target)) {
                setDropDownCityOpen(false);
            }
            if (dropDownRefIndustry.current && !dropDownRefIndustry.current.contains(event.target)) {
                setDropDownIndustryOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (AllCountries) {
            setCountries(AllCountries);
        }
    }, [AllCountries]);

    useEffect(() => {
        if (AllCities?.data) {
            const cityOptions = Object.entries(AllCities.data).map(([key, value]) => ({
                id: key,
                name: value,
            }));
            setCities(cityOptions);
        }
    }, [AllCities]);

    useEffect(() => {
        if (AllIndustries) {
            setIndustries(AllIndustries);
        }
    }, [AllIndustries]);

    useEffect(() => {
        if (ProfileData) {
            const selectedIndustry = industries.find(ind => ind.id === ProfileData.company_industry);

            setFormData({
                selectedCountry: ProfileData?.company_country?.name|| "",
                selectedCountryCode: ProfileData?.company_country?.code || "",
                selectedCity: ProfileData?.company_city?.name || "",
                selectedCityID: ProfileData?.company_city?.id || "",
                selectedIndustry: selectedIndustry?.name || "",
                selectedIndustryId: ProfileData?.company_industry || "",
                selectedCompanyWebsite: ProfileData?.company_website || "",
                phone: ProfileData?.phone || "",
                job_title: ProfileData?.job_title || '',
                job_company: ProfileData?.job_company || ''
            });
        }
    }, [ProfileData]);
    
    

    const filteredCountries = countries.filter(country => 
        country.name.toLowerCase().includes(countrySearch.toLowerCase())
    );

    const filteredCities = cities.filter(city => 
        city.name.toLowerCase().includes(citySearch.toLowerCase())
    );

    const filteredIndustries = industries.filter(industry => 
        industry.name.toLowerCase().includes(industrySearch.toLowerCase())
    );

    const validateForm = () => {
        const newErrors = {};
        if (!formData.selectedIndustry) newErrors.industry = t('Industry is required');
        if (!formData.selectedCountry) newErrors.country = t('Country is required');
        if (!formData.selectedCity) newErrors.city = t('City is required');
        if (!formData.phone) newErrors.phone = t('Phone number is required');

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataToSubmit = {
            section: 'business',
            job_title: formData.job_title || ProfileData?.job_title || '',
            job_company: formData.job_company || ProfileData?.job_company || '',
            company_country: formData?.selectedCountryCode || ProfileData?.company_country?.code || '', 
            company_city: formData?.selectedCityID || ProfileData?.company_city?.id || '',
            company_industry: String(formData?.selectedIndustryId || ProfileData?.company_industry || ''), 
            phone: formData.phone || ProfileData?.phone || '',
            company_website: formData.selectedCompanyWebsite || '',
        };
        

        try {
            const response = await updateProfile(formDataToSubmit).unwrap();
            toast.success(response.message);
        } catch (error) {
            console.error("Failed to update profile", error);
            toast.success(t('Profile updated successfully!'));
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="settings-card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Job title')}</label>
                            <input
                                type="text"
                                name="job_title"
                                className="form-control"
                                value={formData.job_title}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Company')}</label>
                            <input
                                type="text"
                                name="job_company"
                                className="form-control"
                                value={formData.job_company}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Country')}</label>
                            <div className="drop-down-con" ref={dropDownRefCountry}>
                                <div className="drop-down" onClick={() => setDropDownCountryOpen(!dropDownCountryOpen)}>
                                    <p>{formData.selectedCountry || t('Select Country')}</p>
                                    <p className="search-btn">
                                        <i className="fas fa-chevron-down"></i>
                                    </p>
                                </div>
                                <div className={`drop ${dropDownCountryOpen ? "" : "d-none"}`}>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t("Search")}
                                        value={countrySearch}
                                        onChange={(e) => setCountrySearch(e.target.value)}
                                        style={{ width: '100%', padding: '2px 18px', borderBottom: '1px solid #eee' }}
                                    />
                                    {filteredCountries.map((country) => (
                                        <p key={country.id} onClick={() => {
                                            setFormData({
                                                ...formData,
                                                selectedCountry: country.name,
                                                selectedCountryCode: country.code,
                                            });
                                            setDropDownCountryOpen(false);
                                            setFormData(prevData => ({
                                                ...prevData,
                                                selectedCity: "",
                                                selectedCityID: "",
                                            }));
                                        }}>
                                            {country.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {errors.country && <p className="error-text">{errors.country}</p>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('City')}</label>
                            <div className="drop-down-con" ref={dropDownRefCity}>
                                <div className="drop-down" onClick={() => setDropDownCityOpen(!dropDownCityOpen)}>
                                    <p>{formData.selectedCity || t('Select City')}</p>
                                    <p className="search-btn">
                                        <i className="fas fa-chevron-down"></i>
                                    </p>
                                </div>
                                <div className={`drop ${dropDownCityOpen ? "" : "d-none"}`}>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t("Search")}
                                        value={citySearch}
                                        onChange={(e) => setCitySearch(e.target.value)}
                                        style={{ width: '100%', padding: '2px 18px', borderBottom: '1px solid #eee' }}
                                    />
                                    {filteredCities.map((city) => (
                                        <p key={city.id} onClick={() => {
                                            setFormData({
                                                ...formData,
                                                selectedCity: city.name,
                                                selectedCityID: city.id
                                            });
                                            setDropDownCityOpen(false);
                                        }}>
                                            {city.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {errors.city && <p className="error-text">{errors.city}</p>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Industry')}</label>
                            <div className="drop-down-con" ref={dropDownRefIndustry}>
                                <div className="drop-down" onClick={() => setDropDownIndustryOpen(!dropDownIndustryOpen)}>
                                    <p>{formData.selectedIndustry || t('Select Industry')}</p>
                                    <p className="search-btn">
                                        <i className="fas fa-chevron-down"></i>
                                    </p>
                                </div>
                                <div className={`drop ${dropDownIndustryOpen ? "" : "d-none"}`}>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t("Search")}
                                        value={industrySearch}
                                        onChange={(e) => setIndustrySearch(e.target.value)}
                                        style={{ width: '100%', padding: '2px 18px', borderBottom: '1px solid #eee' }}
                                    />
                                    {filteredIndustries.map((industry) => (
                                        <p key={industry.id} onClick={() => {
                                            setFormData({
                                                ...formData,
                                                selectedIndustry: industry.name,
                                                selectedIndustryId: industry.id
                                            });
                                            setDropDownIndustryOpen(false);
                                        }}>
                                            {industry.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {errors.industry && <p className="error-text">{errors.industry}</p>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Phone')}</label>
                            <input
                                type="text"
                                name="phone"
                                className="form-control"
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                            {errors.phone && <p className="error-text">{errors.phone}</p>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Website')}</label>
                            <input
                                type="text"
                                name="selectedCompanyWebsite"
                                className="form-control"
                                value={formData.selectedCompanyWebsite}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="settings-card-footer">
                <div className="btn-item">
                    <button className="btn-primary" type="submit">
                    {isUpdating ? t('loading') : t('Save Changes')}
                    </button>
                </div>
                </div>
            </div>
        </form>
    );
};

export default BusinessInformationForm;
