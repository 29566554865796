/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import mainLogo from "../../assets/img/logo.jpg";
import MobileMenu from "./MobileMenu";
import { FaPlus } from "react-icons/fa6";
import { useGetProfileQuery } from "../../app/feature/API/Profile";
import { useLogoutUserMutation } from "../../app/feature/API/Logout";
import { toast } from "react-toastify";
import {  useFetchGeneralSettingQuery, useFetchLinksQuery } from "../../app/feature/API/Helpers";

const HeaderOne = () => {
  const [offset, setOffset] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);
  const location = useLocation();
  const { t ,i18n} = useTranslation();
  const navigate = useNavigate();

  const { data: ProfileData } = useGetProfileQuery();
  const { data } = useFetchGeneralSettingQuery();

  const [logoutUser] = useLogoutUserMutation();

  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");
    setIsLoggedIn(!!token);
    setMobileMenu(true);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(e.target)) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleLogout = async () => {
    try {
      await logoutUser().unwrap();
      toast.success(t('Logged out Successfully'))
      localStorage.removeItem("token_Front_Mied");
      localStorage.removeItem("datePosted");
      localStorage.removeItem("employmentType");
      localStorage.removeItem("selectedTagId");
      localStorage.removeItem("workExperience");
      localStorage.removeItem("searchValue");
      setIsLoggedIn(false);
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out", error);
    }
  };
const {data:HeaderLink} = useFetchLinksQuery()
  return (
    <header className={`header-wrap header-1 ${i18n.language==='ar'&&"header-ar"}`}>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="logo">
          {data?.map((data,idx)=>(
            <Link to="/" key={idx}>
              <img src={data?.logo} alt={data?.website_title} style={{width:'350px'}}/>
            </Link>
          ))}
        </div>
        <div className="header-right-area d-flex mr-special">
          <div className="main-menu d-none d-xl-block HeaderMIED">
          {/* <ul>
  {t("HeaderLink", { returnObjects: true })
.filter((menuItem) => {
  const isHidden =
    (menuItem.name === "Success Stories" &&
      !data?.some((e) => e.success_stories_page_show === 1)) ||
    (menuItem.name === "Knowledge Base" &&
      !data?.some((e) => e.knowledge_base_page_show === 1)) ||
    (menuItem.name === "Careers" &&
      !data?.some((e) => e.careers_page_show === 1))
  return !isHidden;
})
    .map((menuItem) => (
      <li key={menuItem.id}>
        {menuItem.Link ? (
          <>
            <a>
              <p style={{ color: "#632166" }}>
                {t(menuItem.title || menuItem.name)}
                <FaPlus
                  color="#632166"
                  style={{ marginLeft: "5px", marginTop: "-3px" }}
                />
              </p>
            </a>
            <ul className="sub-menu">
            {menuItem?.Link
                ?.filter(
                  (subItem) =>
                    !(subItem.name === "News" && !data?.some((e) => e.news_page_show === 1))
                )
                .map((subItem) => (
                  <li key={subItem.id}>
                    <Link to={subItem.link}>{t(subItem.name)}</Link>
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <Link to={menuItem.link}>{t(menuItem.name)}</Link>
        )}
      </li>
    ))}
</ul> */}
<ul>
  {HeaderLink?.filter(
    (subItem) => subItem.show_link !== 0
  ).map((link, idx) => (
    <li key={idx}>
      <>
        <Link to={link?.link || ""}>
          <p style={{ color: "#632166" }}>
            {link?.title}
            {link?.links?.length > 0 && (
              <FaPlus
                color="#632166"
                style={{ marginLeft: "5px", marginTop: "-3px" }}
              />
            )}
          </p>
        </Link>
        {link?.links?.length > 0 && ( 
          <ul className="sub-menu">
            {link?.links
              ?.filter(
                (subItem) => subItem.show_link !== 0
              )
              .map((subItem) => (
                <li key={subItem.id}>
                  <Link to={subItem.link}>{subItem.title}</Link>
                </li>
              ))}
          </ul>
        )}
      </>
    </li>
  ))}
</ul>
          </div>
          <div className="header-right-elements d-flex align-items-center justify-content-between">
            <span
              onClick={handleOffset}
              className="side-menu-toggle d-none d-xl-block"
            >
              <i className="fal fa-bars"></i>
            </span>
            <div className="d-inline-block ms-4">
              <div className="mobile-nav-wrap">
                <div id="hamburger" onClick={handleMobileMenu}>
                  <i className="fal fa-bars"></i>
                </div>
                <MobileMenu
                  mobileMenu={mobileMenu}
                  handleMobileMenu={handleMobileMenu}
                />
              </div>
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
