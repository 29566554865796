import React from 'react'
import PageBanner from '../Shared/PageBanner'
import bannerBg from '../assets/img/Banner/Top-Image6.jpg'
import BussinessEgy from '../components/WhyEgy/BussinessEgy'
import Capabilities from '../components/Services/main/Capabilities'
import Feature from '../components/WhyEgy/Feature'
import FaqTwo from '../components/Faq/FaqTwo'
import { useTranslation } from 'react-i18next'
import { useFetchGeneralSettingQuery } from '../app/feature/API/Helpers'

const WhyEgypt = () => {
  
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const WhyEgyptPhotoBanner = GeneralSetting?.map((d)=>d?.why_egypt_intro_img);
  const {i18n} = useTranslation();

  return (
    <>
    <PageBanner currentPage={i18n.language==='en'?'Why Egypt':"لماذا مصر"}
     title={i18n.language==='en'?'Why Egypt':"لماذا مصر"}  bannerBg={WhyEgyptPhotoBanner}/>
    <BussinessEgy/>
    <Capabilities/>
    <Feature/>
    <FaqTwo/>
    </>
  )
}

export default React.memo(WhyEgypt)