import React, { useState } from "react"; 
import { useTranslation } from 'react-i18next';
import { useGetServicesQuery } from "../../../app/feature/API/Services";
import ReactPaginate from 'react-paginate';
import img from "../../../assets/img/exporter_message.png";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useFetchGeneralSettingQuery } from "../../../app/feature/API/Helpers";

const InternationalServices = () => {
  const { t , i18n} = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: ServicesData } = useGetServicesQuery({ user_type: 'importer', page: currentPage });
  const services = ServicesData?.data || [];
  const pageCount = ServicesData?.meta?.last_page || 0;

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1); // Adjust for 1-based page index
  };
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mb-80" data-aos="fade-up">
          <div className="col-xl-12">
          {GeneralSetting?.map((title, idx) => (
                  <div className="block-contents  text-center" key={idx}>
                    <div className="section-title pb-20">
                      <h5>{title?.service_sec_2_header}</h5>
                      <span style={{fontSize:i18n.language==='ar'&&"40px"}}>{title?.service_sec_2_header}</span>
                      <h2>{title?.service_sec_2_title}</h2>
                    </div>
                    <p className="desc-exporter">
                      {title?.service_sec_2_desc}
                    </p>
                  </div>
                ))}
          </div>
        </div>
        <div className="row mtm-30 pt-10" data-aos="fade-left">
          {services.length > 0 ? (
            services.map((data) => (
              <div
                className="col-md-6 col-12 col-lg-4 mt-30"
                key={data.id}
                data-aos="fade-left"
              >
                <div className="single-service-box style-1" style={{ minHeight: '331px' }}>
                  <div
                    className="service-bg bg-cover"
                    style={{ backgroundImage: `url(${data?.main_image})` }}
                  ></div>
                  <div className="icon">
                  <i className={data?.icon}></i>
                  </div>
                  <div className="contents">
                  <h4><p>{data?.title}</p></h4>
                  <p className="desc-exp-s" dangerouslySetInnerHTML={{ __html: `<ul>${data?.summary?.replace(/&nbsp;/g, "")
                    .replace(/(?:•)\s?(.*?)(<br>|$)/g, "<li>$1</li>")}</ul>` }}></p>
                </div>
                </div>
              </div>
            ))
          ) : (
            <h2 className="text-center">{t("No_Data")}</h2>
          )}
          {pageCount > 1 && services.length > 0 &&(
          <div className="col-md-6 col-12 col-lg-4 mt-30" data-aos="fade-left">
            <div className="single-service-box-exception style-1 bg-hover text-center">
              <div className="service-bg bg-cover"></div>
              <div className="icon">
                <img src={img} alt="" />
              </div>
              <div className="contents-special-export-sec">
                <h4 className="text-white txt-special-1">{t('have_an_inquiry')}</h4>
                <div>
                  <h3 className="text-white txt-special-2">{t('contact_our')}</h3>
                  <h2 className="text-white txt-special-3">{t('specialized_agents')}</h2>
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
        {pageCount > 1 && services.length > 0 &&(
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col-12">
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              previousLabel={i18n.language === "en" ? <GrPrevious /> : <GrNext />}
              nextLabel={i18n.language === "en" ? <GrNext /> : <GrPrevious />}
            />
          </div>
        </div>
        )}
      </div>
    </section>
  );
};

export default InternationalServices;
