import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Input, Label, Button, FormGroup, Row, Col } from 'reactstrap';
import { useFetchCitiesQuery, useFetchCountriesQuery } from '../app/feature/API/Helpers';
import { useApplyJobMutation } from '../app/feature/API/Jobs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ModalApplyJob = ({ open, onCloseModal, slug}) => {
  const [t,i18n] = useTranslation()
  const  lang = i18n.language==="en"?'en':'ar'
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    country_code: '',
    city_id: '',
    email: '',
    dob: '',
    gender: '',
    phone: '',
    cv: null,
  });

  const [errors, setErrors] = useState({});
  const [successMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, [name]: name === 'cv' ? files[0] : value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = lang === 'ar' ? 'الاسم الأول مطلوب' : 'First name is required';
    if (!formData.last_name) newErrors.last_name = lang === 'ar' ? 'الاسم الأخير مطلوب' : 'Last name is required';
    if (!formData.country_code) newErrors.country_code = lang === 'ar' ? 'الدولة مطلوبة' : 'Country is required';
    if (!formData.city_id) newErrors.city_id = lang === 'ar' ? 'المدينة مطلوبة' : 'City is required';
    if (!formData.email) newErrors.email = lang === 'ar' ? 'البريد الإلكتروني مطلوب' : 'Email is required';
    if (!formData.dob) newErrors.dob = lang === 'ar' ? 'تاريخ الميلاد مطلوب' : 'Date of birth is required';
    if (!formData.gender) newErrors.gender = lang === 'ar' ? 'الجنس مطلوب' : 'Gender is required';
    if (!formData.phone) newErrors.phone = lang === 'ar' ? 'رقم الهاتف مطلوب' : 'Phone number is required';
    if (!formData.cv) newErrors.cv = lang === 'ar' ? 'يجب تحميل السيرة الذاتية' : 'CV upload is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [applyJob, { isLoading, isError }] = useApplyJobMutation();

  const resetForm = () => {
    setFormData({
      first_name: '',
      last_name: '',
      country_code: '',
      city_id: '',
      email: '',
      dob: '',
      gender: '',
      phone: '',
      cv: null,
    });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      await applyJob({ slug, applyJobData: formData }).unwrap();
      toast.success(lang === 'ar' ? 'تم إرسال طلب التقديم بنجاح!' : 'Job application submitted successfully!');
      onCloseModal();
      resetForm();
    } catch (err) {
      console.error('Error applying for the job:', err);
      if (err.status === 422 && err.data.errors) {
        const apiErrors = {};
        err.data.errors.forEach((errorItem) => {
          const [field, messages] = Object.entries(errorItem)[0];
          apiErrors[field] = messages[0];
        });
        setErrors(apiErrors);
      }
    }
  };

  const [cities, setCities] = useState([]);
  const { data: AllCountries } = useFetchCountriesQuery();
  const { data: AllCities } = useFetchCitiesQuery(formData.country_code);

  useEffect(() => {
    if (AllCities?.data) {
      const cityOptions = Object.entries(AllCities.data).map(([key, value]) => ({
        id: key,
        name: value,
      }));
      setCities(cityOptions);
    }
  }, [AllCities]);

  if (isError) return null;

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center
      styles={{modal: { minHeight:'70vh', minWidth:'700px' }}} >
        <h2 className='desc-app text-center'>{lang === 'ar' ? 'التقدم لهذه الوظيفة' : 'Apply For This Job'}</h2>
        <form onSubmit={handleSubmit} >
          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="first_name" className="form-label">
                  {lang === 'ar' ? 'الاسم الأول' : 'First Name'}
                </Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder={lang === 'ar' ? 'أدخل اسمك الأول' : 'Enter your first name'}
                  value={formData.first_name}
                  onChange={handleChange}
                  style={{ borderColor: errors.first_name ? 'red' : undefined }}
                />
                {errors.first_name && <div style={{ color: 'red' }}>{errors.first_name}</div>}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="last_name" className="form-label">
                  {lang === 'ar' ? 'الاسم الأخير' : 'Last Name'}
                </Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder={lang === 'ar' ? 'أدخل اسمك الأخير' : 'Enter your last name'}
                  value={formData.last_name}
                  onChange={handleChange}
                  style={{ borderColor: errors.last_name ? 'red' : undefined }}
                />
                {errors.last_name && <div style={{ color: 'red' }}>{errors.last_name}</div>}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="country_code" className="form-label">
                  {lang === 'ar' ? 'الدولة' : 'Country'}
                </Label>
                <Input
                  type="select"
                  name="country_code"
                  id="country_code"
                  value={formData.country_code}
                  onChange={handleChange}
                  style={{ borderColor: errors.country_code ? 'red' : undefined }}
                >
                  <option value="">{lang === 'ar' ? 'اختر الدولة' : 'Select Country'}</option>
                  {AllCountries && AllCountries.map((country) => (
                    <option key={country.id} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Input>
                {errors.country_code && <div style={{ color: 'red' }}>{errors.country_code}</div>}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="city_id" className="form-label">
                  {lang === 'ar' ? 'المدينة' : 'City'}
                </Label>
                <Input
                  type="select"
                  name="city_id"
                  id="city_id"
                  value={formData.city_id}
                  onChange={handleChange}
                  style={{ borderColor: errors.city_id ? 'red' : undefined }}
                >
                  <option value="">{lang === 'ar' ? 'اختر المدينة' : 'Select City'}</option>
                  {cities && cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Input>
                {errors.city_id && <div style={{ color: 'red' }}>{errors.city_id}</div>}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="email" className="form-label">
                  {lang === 'ar' ? 'البريد الإلكتروني' : 'Email Address'}
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={lang === 'ar' ? 'أدخل بريدك الإلكتروني' : 'Enter your email'}
                  value={formData.email}
                  onChange={handleChange}
                  style={{ borderColor: errors.email ? 'red' : undefined }}
                />
                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="dob" className="form-label">
                  {lang === 'ar' ? 'تاريخ الميلاد' : 'Date of Birth'}
                </Label>
                <Input
                  type="date"
                  name="dob"
                  id="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  style={{ borderColor: errors.dob ? 'red' : undefined }}
                />
                {errors.dob && <div style={{ color: 'red' }}>{errors.dob}</div>}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="phone" className="form-label">
                  {lang === 'ar' ? 'رقم الهاتف' : 'Phone Number'}
                </Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder={lang === 'ar' ? 'أدخل رقم هاتفك' : 'Enter your phone number'}
                  value={formData.phone}
                  onChange={handleChange}
                  style={{ borderColor: errors.phone ? 'red' : undefined }}
                />
                {errors.phone && <div style={{ color: 'red' }}>{errors.phone}</div>}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-3">
                <Label for="gender" className="form-label">
                  {lang === 'ar' ? 'الجنس' : 'Gender'}
                </Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  style={{ borderColor: errors.gender ? 'red' : undefined }}
                >
                  <option value="">{lang === 'ar' ? 'اختر الجنس' : 'Select Gender'}</option>
                  <option value="male">{lang === 'ar' ? 'ذكر' : 'Male'}</option>
                  <option value="female">{lang === 'ar' ? 'أنثى' : 'Female'}</option>
                </Input>
                {errors.gender && <div style={{ color: 'red' }}>{errors.gender}</div>}
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="mb-3">
            <Label for="cv" className="form-label">
              {lang === 'ar' ? 'السيرة الذاتية' : 'CV'}
            </Label>
            <Input
              type="file"
              name="cv"
              id="cv"
              onChange={handleChange}
              style={{ borderColor: errors.cv ? 'red' : undefined }}
            />
            {errors.cv && <div style={{ color: 'red' }}>{errors.cv}</div>}
          </FormGroup>

          <Button type="submit" color="primary" className="w-100 apply-job">
            {isLoading ? t('loading') : t('sendApplication')}
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default ModalApplyJob;
