import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../../assets/img/Banner/Top-Image9.jpg";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"; 
import logo from "../../assets/img/logo.jpg"; 
import { toast } from "react-toastify";
import { useAddWishlistMutation } from "../../app/feature/API/Profile";
import { useLazyGetReportsLazySearchQuery } from "../../app/feature/API/Reports"; 
import { useGetBannersQuery, useGetHomeSectionsQuery } from "../../app/feature/API/CMS";

const HeroFour = ({ title }) => {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [getReports, { data: AllReports = [], isLoading }] = useLazyGetReportsLazySearchQuery();
  const [hoveredReport, setHoveredReport] = useState(null);
  const hoverTimeout = useRef(null); 
  
  const handleMouseEnter = (report) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => {
      setHoveredReport(report);
    }, 300); 
  };
  
  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => {
      setHoveredReport(null);
    }, 1000);
  };
  
  const [filteredReports, setFilteredReports] = useState(AllReports);
  const [showResults, setShowResults] = useState(false); 
  const searchResultsRef = useRef(null); 
  const { data: CtaData, isLoading:loadingsec } = useGetHomeSectionsQuery('reports');

  const reportsSection = CtaData?.find(cta => cta.slug === 'intro');
  const {data} = useGetBannersQuery('reports')

  useEffect(() => {
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      const filtered = AllReports.filter(report =>
        report.title.toLowerCase().includes(term)
      );
      setFilteredReports(filtered);
      setShowResults(true); 
    } else {
      setFilteredReports(AllReports);
      setShowResults(false); 
    }
  }, [AllReports, searchTerm]);

  const addToCart = (report) => {
    const token = localStorage.getItem("token_Front_Mied");
    if (!token) {
      toast.error(t("loginToAddCart"));
      return;
    }

    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const isItemInCart = cartItems.some(item => item.slug === report.slug);
    
    if (isItemInCart) {
      toast.warning(t("alreadyInCart", { title: report.title }));
    } else {
      cartItems.push(report);
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      toast.success(t("addedToCart", { title: report.title }));
    }
  };

  const [addFavorite] = useAddWishlistMutation();

  const addToFavorites = async (report) => {
    const token = localStorage.getItem("token_Front_Mied");
    if (!token) {
      toast.error(t("loginToAddFavorites"));
      return;
    }
    try {
      const response = await addFavorite({ id: report.id }).unwrap();
      toast.success(t("addedToFavorites", { title: report.title }));
      console.log('responseFav(Wishlist)=>', response);
    } catch (error) {
      toast.error(t("errorAddingToFavorites"));
    }
  };

  const handleSearch = () => {
    getReports({ title: searchTerm });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  if (isLoading || loadingsec) {
    return <p>{t('loading')}</p>; 
  }

  return (
    <section className="hero-wrapper hero-1 pb-1">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          loop
          style={{ height: "500px" }}
        >
          <SwiperSlide
            className="bg-cover"
            style={{
              backgroundImage: `url(${encodeURI(reportsSection?.image)})`,
            }}
          >
            <div className="container">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3 content-four" style={{backgroundColor:'rgba(0 0 0 / .2)'}}>
                  <h1
                    className="wow fadeInUp animated fz-size-herofour"
                    data-wow-duration="3s"
                  >
                    {data?.title}
                  </h1>
                  <div className="search-hero-four mt-20">
                    <div
                      className={`form-search-hero-four fadeInUp animated
                      ${i18n.language === 'en' ? "" : "dire-rtl"}`}
                      data-wow-duration="3s"
                    >
                      <i className="icon fas fa-search mr-20 ml-10"></i>
                      <input
                        type="text"
                        placeholder={reportsSection?.sub}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <button className="Search-btn-hero-four" onClick={handleSearch}>
                      {isLoading ? (
                            <>
                              {t("loading")} 
                              <i className="fa fa-spinner fa-spin" /> 
                            </>
                          ) : (
                            <>
                              {t("searchButton")}
                            </>
                          )}
                      </button>
                      {showResults && filteredReports.length > 0 && ( 
                        <div
                          ref={searchResultsRef} 
                          className="search-results mt-10"
                          style={{
                            position: "absolute",
                            top: "50px",
                            backgroundColor: "#fff",
                            width: "100%",
                            left: "0",
                            borderRadius: "10px",
                            padding: "10px 25px",
                            display: "flex", 
                            alignItems: "start",
                            flexDirection: 'column' ,
                            maxHeight: "200px", 
                            overflowY: "auto", 
                          }}
                        >
                          {filteredReports.map(report => (
                            <div>
                            <div key={report.id} className="search-result-item"
                             style={{ display: "flex", alignItems: "center", width: "100%", 
                             paddingBottom: '5px', borderBottom: '1px solid #eee' }}
                             onMouseEnter={() => handleMouseEnter(report)}
                             onMouseLeave={handleMouseLeave}
                             >
                              <img
                                src={report.feature_image}
                                alt={report.title}
                                className="search-result-image"
                                style={{
                                  borderRadius: "50%",
                                  width: "60px",
                                  height: "60px",
                                  marginRight: "15px",
                                }}
                              />
                              <div className="search-result-details" style={{ flex: 1 }}>
                                <Link style={{ fontWeight: "bold", display: "block" ,color:'#2b3040'}}>{report.title}</Link>
                                <p style={{ margin: "5px 0" ,color:'#2b3040'}}>
                                  {report.summary ? report.summary.slice(0, 100) : ""}
                                </p>
                              </div>
                              <div className="icons" style={{ display: "flex", alignItems: "center" }}>
                                {/* <i className="fas fa-shopping-cart" onClick={() => addToCart(report)} style={{ marginRight: "10px", cursor: "pointer" }}></i>
                                <i className="fas fa-heart" onClick={() => addToFavorites(report)} style={{ cursor: "pointer" }}></i> */}
                              </div>
                            </div>
                            {hoveredReport && (
        <div className="right-screen-hove">
          <p className="main-title" style={{color:'#2b3040'}}>
            {i18n.language === "en" ? hoveredReport?.title : hoveredReport?.title_ar}
          </p>
          <div className="subtitle-hover">
            <span className="best-hover-box" >{hoveredReport?.category}</span>
            <p className="updated-hover-box" style={{color:'#2b3040'}}>
              Updated{" "}
              <span className="date-hover-box" style={{color:'#2b3040'}}>
                {new Date(hoveredReport?.updated_at).toLocaleDateString(
                  i18n.language === "en" ? "en-US" : "ar-US",
                  { year: "numeric", month: "long" }
                )}
              </span>
            </p>
          </div>
          <div className="time-zone-hover" >
            <p>{hoveredReport?.page || hoveredReport.pages || 28} Pages</p>
          </div>
          <div className="desc-hover" style={{color:'#2b3040'}}>
  {i18n.language === "en" ? (
    <p style={{color:'#2b3040'}}>
      {hoveredReport?.description
        ? hoveredReport.description
            .replace(/<[^>]+>/g, "")
            .trim()
            .split(".")
            .filter(Boolean)[0]
            .trim()
        : "No description available."}
    </p>
  ) : (
    <p style={{color:'#2b3040'}}>
      {hoveredReport?.description_ar
        ? hoveredReport.description_ar
            .replace(/<[^>]+>/g, "")
            .trim()
            .split(".")
            .filter(Boolean)[0]
            .trim()
        : "لا يوجد وصف متاح."}
    </p>
  )}
</div>

<ul className="ul-hover-box" >
  {i18n.language === "en"
    ? hoveredReport?.description
      ? hoveredReport.description
          .replace(/<[^>]+>/g, "")
          .trim()
          .split(".")
          .filter(Boolean)
          .slice(1)
          .map((item, index) => (
            <li key={index}>
              <i className="fas fa-check"></i>
              {item.trim()}
            </li>
          ))
      : <li>No additional details available.</li>
    : hoveredReport?.description_ar
    ? hoveredReport.description_ar
        .replace(/<[^>]+>/g, "")
        .trim()
        .split(".")
        .filter(Boolean)
        .slice(1)
        .map((item, index) => (
          <li key={index}>
            <i className="fas fa-check"></i>
            {item.trim()}
          </li>
        ))
    : <li>لا توجد تفاصيل إضافية متاحة.</li>}
</ul>

          <div className="btns-hover">
            <h5>${hoveredReport?.current_price}</h5>
            <button onClick={() => addToCart(hoveredReport)}>
              {t("Add to cart")}
            </button>
            <div
              className="heart-hover"
              onClick={() => addToFavorites(hoveredReport)}
            >
              <i className="fas fa-heart-circle"></i>
            </div>
          </div>
        </div>
      )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="list-categories-hero-four mt-10 fadeInUp animated"
                    data-wow-duration="3s"
                    style={{position: 'relative',zIndex:'-1'}}
                    dangerouslySetInnerHTML={{
                      __html: reportsSection?.dec
                        ?.split(",")
                        .map((process) => `<li>${process.trim()}</li>`)
                        .join(""),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
    </section>
  );
};

export default HeroFour;
