import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMakeReportOrderMutation } from '../../app/feature/API/Helpers';
import { toast } from 'react-toastify';

const CartTable = () => {
  const { t, i18n } = useTranslation();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [removingItems, setRemovingItems] = useState([]); // للحفاظ على العناصر التي يتم حذفها بشكل مؤقت

  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedItems);
    calculateTotal(storedItems);
  }, []);

  const calculateTotal = (items) => {
    const total = items.reduce((acc, item) => acc + parseFloat(item.current_price) || 0, 0);
    setTotalPrice(total);
  };

  const handleRemove = (slug) => {
    setRemovingItems([...removingItems, slug]); // أضف العنصر إلى قائمة العناصر المحذوفة لعمل الأنيميشن
    setTimeout(() => { // بعد انتهاء الأنيميشن احذف العنصر من القائمة
      const updatedItems = cartItems.filter((item) => item.slug !== slug);
      setCartItems(updatedItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      calculateTotal(updatedItems);
    }, 300); // مدة الأنيميشن (300ms)
  };

  const [makeReportOrder , {isLoading}] = useMakeReportOrderMutation();

  const handleCheckout = async () => {
    if (cartItems.length === 0) {
      toast.error(i18n.language === 'en' ? "Your cart is empty, can't proceed with checkout." : "العربة فارغة، لا يمكن إتمام الشراء.");
      return;
    }
    const itemIds = cartItems.map((item) => item.id);
    try {
      const response = await makeReportOrder({ ids: itemIds }).unwrap();
      console.log('Order submitted:', response);
      toast.success(response.message || (i18n.language === 'en' ? "Done!" : "تم!"));

      // مسح localStorage بعد نجاح الشراء
      localStorage.removeItem('cartItems');
      // بدء أنيميشن إزالة العناصر
      setRemovingItems(cartItems.map(item => item.slug));
      setTimeout(() => {
        setCartItems([]); // مسح العناصر بعد الأنيميشن
        setTotalPrice(0); // تصفير إجمالي السعر
      }, 300); // مدة الأنيميشن

    } catch (error) {
      console.error('Failed to submit order:', error);
      toast.error(i18n.language === 'en' ? "Failed to submit order." : "فشل في إرسال الطلب.");
    }
  };

  return (
    <section className='content-block-wrapper section-padding bg-white fix'>
      <div className="container">
        <div className="dashboard-header">
          <div className="head-info">
            <p>
              {i18n.language === 'en' ? 'Total Items' : 'إجمالي العناصر'} : <span>{cartItems.length}</span>
            </p>
          </div>
          <div className="head-info">
            <p>{i18n.language === 'en' ? 'Total Price' : 'إجمالي السعر'} : <span>${totalPrice.toFixed(2)}</span></p>
            <div id="tableinfo"></div>
          </div>
        </div>
        <div className="table-responsive custom-table">
          {cartItems.length > 0 ? (
            <table className="table table-stripe datatable">
              <thead className="thead-light">
                <tr>
                  <th>{i18n.language === 'en' ? 'Reports' : 'التقارير'}</th>
                  <th>{i18n.language === 'en' ? 'Price' : 'السعر'}</th>
                  <th>{i18n.language === 'en' ? 'Remove' : 'حذف'}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.slug} className={removingItems.includes(item.slug) ? 'fade-out' : ''}>
                    <td>
                      <h2 className="table-avatar d-flex align-items-center">
                        <a href="javascript:void(0)" className="avatar">
                          <img src={item.feature_image} className="rounded" alt="Report Image" />
                        </a>
                        <a href="javascript:void(0);" className="text-dark">{item.title}</a>
                      </h2>
                    </td>
                    <td>${parseFloat(item.current_price).toFixed(2)}</td>
                    <td>
                      <div className="table-action">
                        <a href="javascript:void(0);" onClick={() => handleRemove(item.slug)}>
                          <i className="fa fa-trash"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className='p-5 text-center fs-1'>{i18n.language === 'en' ? 'Your cart is empty' : 'العربة فارغة'}</p>
          )}
        </div>
        <div className="col-md-12 col-12 d-flex justify-content-end" style={{marginTop:'15px'}}>
          <button
            className="theme-btn me-sm-4 wow fadeInLeft btn-explore"
            type="button" 
            onClick={handleCheckout}
          >
          {isLoading? (
          <>
            {t("loading")} 
            <i className="fa fa-spinner fa-spin" /> 
          </>
          ) : (
          <>
            {i18n.language === 'en' ? "Checkout" : "إتمام الشراء"}
          </>
          )}

          </button>
        </div>
      </div>
    </section>
  );
};

export default CartTable;
