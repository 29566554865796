import PageBanner from "../Shared/PageBanner";
import Contact from "../components/Contact/Contact";
import bannerBg from "../assets/img/Banner/Top-Image5.jpg";
import { useTranslation } from "react-i18next";
import { useGetBannersQuery } from "../app/feature/API/CMS";

export default function ContactOne() {
  const {i18n} = useTranslation()
  const {data} = useGetBannersQuery('contact')

  return (
    <>
      <PageBanner
        title={data?.title}
        bannerBg={data?.image}
        currentPage={data?.title}
      />
      <Contact />
    </>
  );
}
