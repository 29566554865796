import React from 'react'
import { useTranslation } from 'react-i18next';
import { useFetchGeneralSettingQuery } from '../../app/feature/API/Helpers';

const SelectLevel = () => {
  const { t , i18n} = useTranslation();
  const servicesData = JSON.parse(localStorage.getItem('services') || '[]');
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const modifyListItems = (html) => {
    if (!html) return '';
    return html.replace(/<li>/g, '<li>• ');
  };
  return (
    <section className="content-block-wrapper section-padding bg-white fix sec-select-level"  data-aos="fade-right">
      <div className="container">
            <hr/>
        <div className="row align-items-center section-padding pb-0">
          <div className="col-xl-12 col-lg-12 col-12 mt-lg-0 order-2 order-lg-1
          recommendedSec">
            <div className="section-title-3 pt-50">
              <p className="txt-reports line-minus">
                <span className="fontz-18">{t("recommended")}</span>{" "}
                <br />
                <span className="main-desc-report recom-third"><p className='serv-level'>{t("services")}</p></span></p>
                <p className='desc-title-serv'>{t("carefullyCuratedRecommendations")}
                </p>
            </div>
            {servicesData?.map((servicesData,idx)=>(
              <div key={idx}>
                <div className='serv-level-flex mt-4'>
                    <div className='img' style={{maxWidth:'450px'}}>
                        <img src={servicesData?.main_image} alt={servicesData?.title} />
                    </div>
                    <div style={{marginTop:'-22px'}}>
                      <p className="title-sec-serv">{servicesData?.title}</p>
                      <div className={`${i18n.language==='en'?"desc-serv-level":"desc-serv-level desc-serv-level-ar"}`}  dangerouslySetInnerHTML={{ __html: servicesData?.summary }}>
                      </div>
                {/* {servicesData?.summary} */}
                {/* {t("basedOnYourObjective")} */}
                 {/* <span className='orange-color'>{t("importProductsFromEgypt")}</span>, 
                 {t("our")} <span className='orange-color'>{t("agricultureExperts")}</span>
                  {t("atMIED")} {t("haveExtensiveExperienceIn")} <span className='orange-color'>{t("afghanistan")}</span>
                  . {t("harnessingTheRichAgriculturalHeritageOfEgypt")} {t("weOfferAGatewayToAWorldOfPossibilities")}.
                   {t("ourProfessionalTeamDiligentlyNavigatesTheComplexitiesOfInternationalTrade")}, 
                   {t("ensuringASeamlessImportProcessForYou")}.
                    {t("fromTheFertileNileDeltaToTheSunKissedFieldsOfUpperEgypt")}
                     {t("weSourceTheFinestCropsSeedsAndAgriculturalProductsThatEgyptHasToOffer")} */}
                 </div>
              </div>
                </div>
            ))}
              <div className="text-align-end mt-20">
              {GeneralSetting?.map((footer, index) => (
                <a key={index} href={`tel:${footer?.support_phone}`} 
                className="btn-advance"
                  style={{
                    direction: i18n.language === 'en' ? 'ltr' : 'rtl', 
                    backgroundColor: '#695ea8'
                  }}>
                  {t("chatWithExpert")}
                </a>
              ))}
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SelectLevel
