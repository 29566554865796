import React from "react";
import { Link, useParams } from "react-router-dom";
import { FaInstagram } from 'react-icons/fa';
import Sidebar from "../Sidebar";
import { useGetBlogsDetailsQuery } from "../../../app/feature/API/Blogs";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

const Details = () => {
  const {slug} = useParams()
  const { data: blogsData, error, isLoading } = useGetBlogsDetailsQuery(slug);
  const {t,i18n} = useTranslation()
  const shareUrl = window.location.href;
  const title = blogsData;
  const getFirstFTagContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const firstFTag = doc.querySelector('p');
    return firstFTag ? firstFTag.innerHTML : '';
  };  return (
    <section className="blog-wrapper news-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="blog-post-details border-wrap">
              <div className="single-blog-post post-details">
                <div className="post-content">
                  <div className="post-cat">
                    <Link className="cat-newdetails">
                      {blogsData?.blog?.bcategory}
                    </Link>
                  </div>
                  <h2>
                  {blogsData?.blog?.title}
                  </h2>
                  <div className="post-meta">
                    {/* <span>
                      <i className="fal fa-comments"></i>35 Comments
                    </span> */}
                    <span>
                      <i className="fal fa-calendar-alt"></i>
                      {new Date(blogsData?.blog?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                      </span>
                  </div>

                    <img src={blogsData?.blog?.main_image} alt={blogsData?.blog?.title}
                    style={{maxHeight:'400px',width:'100%',objectFit:'cover'}} />
                  <p>
                    <div dangerouslySetInnerHTML={{__html:blogsData?.blog?.content}}/>
                  </p>
                  {/* <p>
                    Northern anchovy–bass yellowtail barracuda zander yellowfin
                    grouper gurnard skipjack tuna shark burrowing goby eulachon
                    wobbegong. Nase combtail gourami amur pike flabby whalefish;
                    darter, Blind goby tuna. Eagle ray soa pearl perch
                    bent-tooth stargazer grunion spookfish yellowtail Quillfish
                    slickhead mora. snake worm mackerel sockeye salmon banjo
                    catfish toadfish sauger four-eyed fish
                  </p>
                  <h3>BUILDING REPAIR & CONSTRUCTION</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incidid unt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in repreh enderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                  <blockquote>
                    Thousands of successful projects we are one of the most
                    trusted construction companies.
                  </blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incidid unt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute.
                  </p>

                  <ul>
                    <li>We track every dollar</li>
                    <li>We’re an open book</li>
                    <li>100% goes to the field</li>
                    <li>Received the highest grades</li>
                  </ul>
                  <h3>Lorem ipsum dolor sit amet, consectetur adipisicing</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus
                  </p>
                  <img className="alignleft" src={img1} alt="" />

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium.
                  </p>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incidid unt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in repreh enderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p> */}
                </div>
              </div>
              <div className="row tag-share-wrap">
                <div className="col-lg-8 col-12">
                  <h4>{i18n.language==='en'?"Related Tags":"العلامات ذات الصلة"}</h4>
                  <div className="tagcloud">
                    {blogsData?.related?.map((related)=>(
                      <Link to={`/newsDetails/${related?.slug}`}>
                      {related?.bcategory}</Link>
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-3 mt-lg-0 text-lg-end">
      <h4>{i18n.language === 'en' ? "Social Share" : "المشاركة الاجتماعية"}</h4>
      <div className="social-share">
  <FacebookShareButton
    url={shareUrl}
    quote={blogsData?.blog?.title}
    hashtag={`#${blogsData?.blog?.bcategory}`}
    children={blogsData?.blog?.title}>
    <FacebookIcon size={32} round />
  </FacebookShareButton>

  <TwitterShareButton
    url={shareUrl}
    title={blogsData?.blog?.title} 
    hashtags={[`${blogsData?.blog?.bcategory}`]}> 
    <TwitterIcon size={32} round />
  </TwitterShareButton>

  <LinkedinShareButton
    url={shareUrl}
    title={blogsData?.blog?.title}
    summary={getFirstFTagContent(blogsData?.blog?.content || '')}> 
    <LinkedinIcon size={32} round />
  </LinkedinShareButton>
</div>

    </div>
              </div>

              <div className="related-post-wrap">
                <h3>{i18n.language==='en'?"Related Post":"وظيفة ذات صلة"}</h3>

                <div className="row">
                  {blogsData?.related?.map((related,idx)=>(
                  <div className="col-md-6 col-12" key={idx}>
                    <div className="single-related-post"key={idx+1}>
                      <div
                        className="featured-thumb bg-cover"
                        style={{ backgroundImage: `url(${related?.main_image})` }}
                      ></div>
                      <div className="post-content">
                        <div className="post-date">
                          <span>
                            <i className="fal fa-calendar-alt"></i>
                            {new Date(related?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                          </span>
                        </div>
                        <h4 className="fz-related-post">
                          <Link to={`/newsDetails/${related?.slug}`}>
                            {related?.title}
                          </Link>
                        </h4>
                        <p>
                        <div dangerouslySetInnerHTML={{ __html: getFirstFTagContent(related?.content || '') }} />
                        </p>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
              </div>

              {/* <div className="comments-section-wrap pt-40">
                <div className="comments-heading">
                  <h3>03 Comments</h3>
                </div>
                <ul className="comments-item-list">
                  <li className="single-comment-item">
                    <div className="author-img">
                      <img src={author1} alt="" />
                    </div>
                    <div className="author-info-comment">
                      <div className="info">
                        <h5>
                          <a href="#">Rosalina Kelian</a>
                        </h5>
                        <span>19th May 2018</span>
                        <a href="#" className="theme-btn minimal-btn">
                          <i className="fal fa-reply"></i>Reply
                        </a>
                      </div>
                      <div className="comment-text">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna. Ut enim ad minim veniam, quis nostrud
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="single-comment-item">
                    <div className="author-img">
                      <img src={author2} alt="" />
                    </div>
                    <div className="author-info-comment">
                      <div className="info">
                        <h5>
                          <a href="#">Arista Williamson</a>
                        </h5>
                        <span>21th Feb 2020</span>
                        <a href="#" className="theme-btn minimal-btn">
                          <i className="fal fa-reply"></i>Reply
                        </a>
                      </div>
                      <div className="comment-text">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco nisi ut aliquip ex ea
                          commodo consequat.
                        </p>
                      </div>
                    </div>

                    <ul className="replay-comment">
                      <li className="single-comment-item">
                        <div className="author-img">
                          <img src={author1} alt="" />
                        </div>
                        <div className="author-info-comment">
                          <div className="info">
                            <h5>
                              <a href="#">Salman Ahmed</a>
                            </h5>
                            <span>29th Jan 2022</span>
                            <a href="#" className="theme-btn minimal-btn">
                              <i className="fal fa-reply"></i>Reply
                            </a>
                          </div>
                          <div className="comment-text">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam..
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div> */}

              {/* <div className="comment-form-wrap mt-40">
                <h3>Post Comment</h3>
                <CommentForm />
              </div> */}
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </section>
  );
};

export default Details;
