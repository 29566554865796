import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const ArticleApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${base_url}` ,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
}),
  reducerPath: "ArticleApi",
  endpoints: (build) => ({
    getArticles: build.query({
      query: (article_category_id='') => `/articles?article_category_id=${article_category_id}`,
      transformResponse: (response) => response.data,
      providesTags: ["Articles"],
  }),
  getArticlesTitle: build.query({
    query: (title='') => `/articles?title=${title}`,
    transformResponse: (response) => response.data,
    providesTags: ["Articles"],
}),
    getArticleCategories: build.query({
        query: () => `/articles/categories`,
        transformResponse: (response) => response.data,
        providesTags: ["Articles"],
    }),
    getArticleDetails: build.query({
      query: (slug) => `/articles/${slug}`,
      transformResponse: (response) => response.data,
      providesTags: ["Articles"],
  }),
  }),
});

export const {
  useGetArticleCategoriesQuery,
  useGetArticlesQuery,
  useGetArticleDetailsQuery,
  useGetArticlesTitleQuery
} = ArticleApi;
