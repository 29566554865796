import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../../assets/img/Banner/Top-Image2.jpg";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useGetSlidersQuery } from "../../app/feature/API/Sliders";

const HeroTwo = () => {
  const { t , i18n} = useTranslation();
  const { data: sliders, isLoading, isError } = useGetSlidersQuery('services');
  if (isError) return <div>Error loading sliders</div>;

  return (
    <section className="hero-wrapper hero-1 pb-1">
     {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <Swiper
        navigation={true}
        modules={[Navigation]}
        loop
        style={{ height: "500px" }}
      >
        {sliders?.map((sliderData, idx) => (
          <SwiperSlide
            className="bg-cover container-hero-one-bg-gray"
            style={{
              backgroundImage: `url(${sliderData?.image})`,
            }}
            key={idx}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                  <div
                    className={`hero-contents pe-lg-3 ${
                      i18n.language === "en"
                        ? "center-content"
                        : "center-content-arabic"
                    } ${i18n.language === "ar" ? "content-arabic-hero-one" : ""}`}
                  >
                    <h1
                      className=" wow fadeInLeft animated fz-size-heroOne mb-20 hero1-text-h1-max-width"
                      data-wow-duration="1.3s"
                    >
                      {sliderData?.title}
                    </h1>
                    <p
                      className=" wow fadeInLeft animated mb-20 hero1-text-h1-max-width"
                      data-wow-duration="1.3s"
                      data-wow-delay=".4s"
                    >
                      {sliderData?.text}
                    </p>
                    <Link
                      to={sliderData.button_url && sliderData.button_url !== '#' ? sliderData.button_url : `/reports`}
                      target={sliderData.button_url && sliderData.button_url !== '#' ? `_blank` : `_self`}
                      className="theme-btn me-sm4 wow fadeInLeft btn-explore border-raduis"
                      style={{marginRight:"0"}}
                      data-wow-duration="1.2s"
                      data-wow-delay=".8s"
                    >
                      {sliderData?.button_text}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default HeroTwo;
