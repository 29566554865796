import { useState } from "react";
import faqOneData from "../../assets/data/faqOneData";
import faqVideoBg from "../../assets/images/backgrounds/600d672e-8307-4991-a77b-b9422229e40f.jpg";
import CountUp from "react-countup";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";
import { useGetFaqsQuery } from "../../app/feature/API/Faqs";
import { useGetCountersFaqsQuery } from "../../app/feature/API/Profile";
import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../../app/feature/API/CMS";
const FaqTwo = () => {
  const [expandedItem, setExpandedItem] = useState(null);
  const handleCollapse = (index) => {
    setExpandedItem((prevState) => (prevState === index ? null : index));
  };
  const {data:GeneralSetting} = useFetchGeneralSettingQuery();
  const {data:FaqData} = useGetFaqsQuery();
  const {data:FaqDataCounters} = useGetCountersFaqsQuery();
  const {t, i18n} = useTranslation();
  const showSec = GeneralSetting?.map((e)=>e?.statistics_page_show)
  
  const { data: aboutData, isLoading } = useGetHomeSectionsQuery('knowledgebase');
  const knowledgebaseSection = aboutData?.find(cta => cta.slug === 'knowledgebase_2');
  if (knowledgebaseSection?.show_sec !== 1) {
    return null;
  }
  return (
    <section className="faq-funfact-section section-padding">
      <div className="container">
        {Number(showSec)!==0&&(
        <div className="fun-fact-wrapper text-white mtm-30 text-center">
            <div className="row" style={{rowGap:'20px'}}>
              {FaqDataCounters?.map((faqCounter,idx)=>(
                <div className="col-lg-3 col-md-6 col-12" key={idx}>
                  <div className="single-fun-fact mb-4 mb-lg-0">
                    <h2>
                  {faqCounter?.quantity < 1000 
                    ? new Intl.NumberFormat().format(faqCounter?.quantity) 
                    : faqCounter?.quantity >= 1e9 
                      ? t('quantityB', { value: new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(faqCounter?.quantity / 1e9) }) 
                      : faqCounter?.quantity >= 1e6 
                        ? t('quantityM', { value: new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(faqCounter?.quantity / 1e6) }) 
                        : t('quantityK', { value: new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(faqCounter?.quantity / 1e3) })}
                      </h2>
                    <h3>{faqCounter?.title}</h3>
                  </div>
                </div>
              ))}
              {/* <div className="col-lg-3 col-md-6 col-12">
                <div className="single-fun-fact mb-4 mb-lg-0">
                  <h2>
                    <CountUp end={FaqDataCounters?.quantity} duration={3}></CountUp>+
                  </h2>
                  <h3>{i18n.language==='en'?"HAPPY CLIENTS":"عملاء سعداء"}
                  </h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 mb-4 mb-md-0">
                <div className="single-fun-fact">
                  <h2>
                    <CountUp end={FaqDataCounters?.quantity} duration={3}></CountUp>+
                  </h2>
                  <h3>{i18n.language==='en'?"Skilled Employee":"الموظف الماهر"}</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-fun-fact">
                  <h2>
                  {FaqDataCounters?.quantity}
                  </h2>
                  <h3>{i18n.language==='en'?"Country Coverage":"تغطية الدولة"}</h3>
                </div>
              </div> */}
            </div>
          </div>
        )}
        <div className="faq-wrapper section-padding pb-0" data-aos="fade-up" style={{direction:"ltr",marginTop:'-50px'}}>
        <div className="row equal">
            <div className="col-lg-6 col-12 col-xl-7 pe-lg-0">
              <div className="faq-content" style={{direction:i18n.language==='en'?"ltr":"rtl"}}>
                <div className={i18n.language==='en'?"faq-accordion":"faq-accordion faq-accordion-ar"}>
                  <div className="accordion" id="accordion">
                    {FaqData?.map((data,idx) => (
                      <div className="accordion-item" key={idx}>
                        <h4 className="accordion-header">
                          <button
                           style={{paddingRight:i18n.language==='en'?"0":"60px"}}
                            onClick={() => handleCollapse(idx+1)}
                            className="accordion-button"
                            type="button"
                            aria-expanded={expandedItem === idx+1?"true":"false"}
                            aria-controls={idx+1}
                          >
                            {data?.question}
                          </button>
                        </h4>
                        <div
                          id={idx+1}
                          className={`accordion-collapse ${
                            expandedItem === idx+1 ? "show" : "collapse"
                          }`}
                          data-bs-parent="#accordion"
                        >
                          <div className="accordion-body desc-app" style={{paddingRight:i18n.language==='en'?"0":"60px"}}>{data?.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12 ps-lg-0">
              <div
                className="video-popup-wrapper d-flex justify-content-center align-items-center bg-cover bg-center"
                style={{ backgroundImage: `url(${knowledgebaseSection?.image})`}}
              >
              </div>
              <div className="box-cta-call d-flex align-items-center justify-content-between">
                <div className="icon">
                  <i className="flaticon-24-hours"></i>
                </div>
                <div className="content-text">
                  <span>{i18n.language==='en'?"Call for support":"اتصل للحصول على الدعم"}</span>
                  {GeneralSetting?.map((phone, idx) => (
                    <h3  key={idx}>
                  <a href={`tel:${phone.support_phone}`} className="ml-10">
                    {phone.support_phone}
                  </a>
                    </h3>
                ))}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FaqTwo;
