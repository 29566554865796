import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");

    if (token) {
      navigate("/");
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [navigate]);
  return (
    <div className={`auth right-panel-active`}
    id="container">
    <ResetPasswordForm/>
      <div className="overlay-container">
        <div className="overlay-auth">
          <div className="overlay-panel overlay-left">
          <h1>Welcome Back!</h1>
            <p>
            If you forgot your password, you can reset password
            </p>

          </div>
          <div className="overlay-panel overlay-right">
            <h1>Hello, Friend!</h1>
            <p>Enter your personal details and start journey with us</p>
          </div>
        </div>
      </div>
    </div>
  );
}