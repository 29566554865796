import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPortfolioQuery } from "../../../app/feature/API/Portfolio";
import { useFetchGeneralSettingQuery } from "../../../app/feature/API/Helpers";

const Portfolio = () => {
  const { t ,i18n} = useTranslation();
  const location = useLocation();
  const {data:PortfolioData ,isLoading} = useGetPortfolioQuery(1);
  const { data } = useFetchGeneralSettingQuery();
  const showSec = data?.map((e)=>e?.success_stories_page_show)
  if(Number(showSec)!==1){
    return null;
  }
  return (
    <section
      className="portfolio-carousel-wrapper clearfix section-padding"
      data-aos="fade-in"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-xl-7 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span style={{fontSize:i18n.language==='ar'&&"40px"}}>{t('portfolio.exportingFromEgypt')}</span>
                <h2>{t('portfolio.caseStudies')}</h2>
              </div>
            </div>
          </div>
          {location.pathname === "/successstories" ? (
            ""
          ) : (
            <div className="col-lg-3 offset-lg-1 col-xl-3 offset-xl-2 text-lg-end col-12">
              <Link to="/successstories" className="theme-btn border-raduis">
                {t('portfolio.viewAll')}
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="portfolio-carousel-active">
            {isLoading&&(
              <>
              {t("loading")} 
              <i className="fa fa-spinner fa-spin" /> 
              </>
            )}
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          loop
          breakpoints={{
            "@0.00": {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: true,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: true,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            "@1.50": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
                {isLoading&&(
              <div className="spinner-overlay">
              <div className="spinner"></div>
              </div>
            )}
          {PortfolioData?.map((data) => (
            <SwiperSlide className="single-project-item" key={data.id}>
              <Link to={`/portfolioDetails/${data?.slug}`} >
                <div
                  className="project-img bg-cover bg-center popup-gallery"
                  style={{ backgroundImage: `url(${data?.featured_image})` }}
                >
                  <div className="icon">
                    <i className="fal fa-plus"></i>
                  </div>
                </div>
              </Link>
              <div className="content-text">
                <span>{data?.client_name}</span>
                <h4 className="third-color">
                  <p>{data?.title}</p>
                </h4>
                <p>{new Date(data.created_at).toLocaleDateString('en-US', { year: 'numeric',month: 'long', day: 'numeric', })}</p>
                </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Portfolio;
