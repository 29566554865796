import { Link } from "react-router-dom";
import { MdOutlineTopic } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
import { useGetArticleCategoriesQuery, useGetArticlesQuery } from "../../../app/feature/API/Articles";
import { useTranslation } from "react-i18next";

const shuffleArray = (array) => {
  const arrayCopy = [...array];
  return arrayCopy.sort(() => Math.random() - 0.5);
};

const SideBarTopic = () => {
  const { data: articleData, error, isLoading } = useGetArticleCategoriesQuery('');
  const { data: PopularArticles, error: PopularArticlesError, isLoading: PopularArticlesLoading } = 
  useGetArticlesQuery('');

  const shuffledArticles = PopularArticles ? shuffleArray(PopularArticles) : [];
  const [t,i18n] = useTranslation()

  return (
    <div className="col-12 col-lg-4">
      <div className="main-sidebar">
        <div className="recenttopic">
          <h2 className="topic-color">{`${i18n.language==='en'?"Recent topics":"المواضيع الأخيرة"}`}</h2>
          <ul>
            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {!isLoading && !error && !articleData?.length && <p>{t("No_Data")}</p>}
            {articleData?.slice(0, 6).map((data, i) => (
              <li key={i}>
                <Link to={`/topic/${data?.id}`} className="topic-color list-topic">
                  <MdOutlineTopic /> {data.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="recenttopic">
          <h2 className="topic-color">{`${i18n.language==='en'?"Popular Articles":"  مقالات عامه"}`}</h2>
          <ul>
            {PopularArticlesLoading && <p>Loading...</p>}
            {PopularArticlesError && <p>Error: {PopularArticlesError.message}</p>}
            {!PopularArticlesLoading && !PopularArticlesError && !shuffledArticles.length && <p>No Popular Articles available.</p>}
            {shuffledArticles.slice(0, 4).map((data) => (
              <li key={data.id}>
                <Link to={`/articles/${data?.slug}`} className="topic-color list-topic">
                  <GrArticle /> {data?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SideBarTopic;
