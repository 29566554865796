import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
const PageBanner = ({ bannerBg, currentPage, title }) => {
  const location = useLocation();
  // const handleGoBack = () => {
  //   window.history.back();
  // };
  const{t,i18n} = useTranslation()
  return (
    <div
      className="page-banner-wrap bg-cover"
      style={{ backgroundImage: `url(${bannerBg})`}}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="page-heading text-white">
              <h1>{title}</h1>
            </div>
            <div className="breadcrumb-wrap">
              <nav>
                <ol className="breadcrumb">
                  <li className={`breadcrumb-item`}>
                    <Link to="/">{i18n.language==='en'?"Home":"الصفحه الرئيسيه"}</Link>
                  </li>
                  {location.pathname !== "/user-dashboard" &&
                    (location.pathname === "/user-dashboard/my-profile" ||
                      location.pathname === "/user-dashboard/new-ticket" ||
                      location.pathname === "/user-dashboard/ticket-details" ||
                      location.pathname ===
                        "/user-dashboard/ticket-status") && (
                      <li className="breadcrumb-item">
                        <Link to="/user-dashboard">{i18n.language==='en'?"User Dashboard":"لوحة تحكم المستخدم"}</Link>
                      </li>
                    )}

                  <li className={`breadcrumb-item active ${i18n.language==='ar'?"arabic-lang":""}`} aria-current="page">
                    {currentPage}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
