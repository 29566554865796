import React, { useEffect } from 'react'
import PageBanner from '../Shared/PageBanner'
import bannerBg from '../assets/img/Banner/Top-Image8.jpg'
import NewsWrapper from '../components/News/NewsWrapper'
import { useTranslation } from 'react-i18next'
import { useGetBannersQuery } from '../app/feature/API/CMS'
import { useFetchGeneralSettingQuery } from '../app/feature/API/Helpers'
import { useNavigate } from 'react-router-dom'

const News = () => {
  const {t,i18n} = useTranslation()
  const {data} = useGetBannersQuery('news')
  const navigate = useNavigate();
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const news_page_show = GeneralSetting?.map((e)=>e?.news_page_show)

  useEffect(() => {

    if (Number(news_page_show)===0) {
      navigate("/");
    }
  }, [news_page_show, navigate]);

  return (
    <>
      <PageBanner title={data?.title} bannerBg={data?.image} currentPage={data?.title} />
      <NewsWrapper/>
    </>
  )
} 

export default React.memo(News)