import React, { useEffect, useRef, useState } from "react";
import calendar from "../../../assets/img/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { useGetProfileQuery, useUpdateProfileMutation } from "../../../app/feature/API/Profile";
import { useFetchCitiesQuery, useFetchCountriesQuery } from "../../../app/feature/API/Helpers";
import { toast } from "react-toastify";
import ImageDisplay from "./ImageDisplay";

const PersonalInformationForm = () => {
    const dropDownRef2 = useRef();
    const dropDownRef3 = useRef();
    const { t, i18n } = useTranslation();
    const [image, setImage] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [cities, setCities] = useState([]);
    const [dropDown, setDropDown] = useState(false);
    const [dropDownChoose, setDropDownChoose] = useState("");
    const [dropDown2, setDropDown2] = useState(false);
    const [dropDownChoose2, setDropDownChoose2] = useState("");
    const [filterData, setFilterData] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [countrySearch, setCountrySearch] = useState("");
    const [citySearch, setCitySearch] = useState("");

    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    
    const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();
    
    const [errors, setErrors] = useState({});
    const { data: ProfileData } = useGetProfileQuery();

    const { data: AllCountries } = useFetchCountriesQuery();
    const { data: AllCities } = useFetchCitiesQuery(selectedCountry);

    const handleFilterData = () => {
        setFilterData(!filterData);
    };
    const handleDropDown = () => {
        setDropDown(!dropDown);
    };
    const handleDropDown2 = () => {
        setDropDown2(!dropDown2);
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0]; 
        if (file) {
            setImage(file);
        }
    };

    const handleRemoveImage = () => {
        setImage(ProfileData?.photo);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropDownRef2.current && !dropDownRef2.current.contains(event.target)) {
                setDropDown(false);
            }
            if (dropDownRef3.current && !dropDownRef3.current.contains(event.target)) {
                setDropDown2(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    
    useEffect(() => {
        if (ProfileData) {
            setFirstName(ProfileData?.first_name);
            setLastName(ProfileData?.last_name);
            setPhone(ProfileData?.phone || "");
            setAddress(ProfileData?.address || "");
            setSelectedDate(new Date(ProfileData?.date_of_birth)); 
            setImage(ProfileData?.photo);
            setCountry(ProfileData?.country?.name || "");
            setCity(ProfileData?.city?.name || "");
            setSelectedCountry(ProfileData.country?.name);
        }
    }, [ProfileData]);

    useEffect(() => {
        if (AllCities?.data) {
            const cityOptions = Object.entries(AllCities.data).map(([key, value]) => ({
                id: key,
                name: value,
            }));
            setCities(cityOptions);
        }
    }, [AllCities]);

    const filteredCountries = AllCountries?.filter(country => 
        country.name.toLowerCase().includes(countrySearch.toLowerCase())
    );

    const filteredCities = cities.filter(city => 
        city.name.toLowerCase().includes(citySearch.toLowerCase())
    );

    const validateForm = () => {
        const newErrors = {};
        if (!firstName) newErrors.firstName = t('First Name is required');
        if (!lastName) newErrors.lastName = t('Last Name is required');
        if (!phone) newErrors.phone = t('Phone number is required');
        if (!address) newErrors.address = t('Address is required');
        if (!selectedCountry) newErrors.country = t('Country is required');
        if (!city) newErrors.city = t('City is required');
        if (!selectedDate) newErrors.dateOfBirth = t('Date of Birth is required');

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const formData = new FormData();
        formData.append('section', 'info');
        formData.append('first_name', firstName || ProfileData?.first_name || ''); 
        formData.append('last_name', lastName || ProfileData?.last_name || '');
        formData.append('phone', phone || ProfileData?.phone || ''); 
        formData.append('address', address || ProfileData?.address || '');
        formData.append('country', selectedCountry || '');
        formData.append('city', city || '');
        formData.append('date_of_birth', selectedDate ? selectedDate.toISOString() : ''); 
        // formData.append('photo', image || ProfileData?.photo || '');
    
        if (image && typeof image === 'object') {
            formData.append('photo', image);
        }
        
        try {
            const response = await updateProfile(formData).unwrap();
            toast.success(response.message);
        } catch (error) {
            console.error("Failed to update profile", error);
            toast.error(t('Profile update failed!'));
        }
    };
    
    
        return (
            <form onSubmit={handleSaveChanges}>
            <div className="settings-card-body">
                <div className="img-upload-head">
                    <ImageDisplay image={image}/>
                    <div className="img-formate">
                        <p>
                            {t('Max file size is 5MB, Minimum dimension: 150x150 And Suitable files are .jpg & .png')}
                        </p>
                        <div className="upload-remove-btns">
                            <div className={`drag-upload form-wrap ${i18n.language === 'ar' ? "ml-10" : ""}`}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                <div className="img-upload">
                                    <p>{t('Upload Image')}</p>
                                </div>
                            </div>
                            <div className="img-remove-btn" onClick={handleRemoveImage}>
                                <a>{t('Remove Image')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 gap-8">
                        <div className="form-wrap">
                            <label>{t('First Name')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        {errors.firstName && <span className="error-text" style={{color:'red'}}>{errors.firstName}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Last Name')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        {errors.lastName && <span className="error-text" style={{color:'red'}}>{errors.lastName}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Phone number')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        {errors.phone && <span className="error-text" style={{color:'red'}}>{errors.phone}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap calendar-wrap">
                            <label>{t('Date of Birth')}</label>
                            <div
                                className="form-sort form-wrap date-time-picker-wrap relative"
                                onClick={handleFilterData}
                            >
                                <span className="form-icon top">
                                    <img src={calendar} alt="icon" />
                                </span>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={t("Date of Birth")}
                                    className={`w-full ${i18n.language === 'ar' ? "margin-4" : ""}`}
                                />
                            </div>
                        {errors.selectedDate && <span className="error-text" style={{color:'red'}}>{errors.selectedDate}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('Country')}</label>
                            <div className="drop-down-con" ref={dropDownRef2}>
                                <div className="drop-down" onClick={handleDropDown}>
                                    <p>{dropDownChoose ? dropDownChoose : t(country)}</p>
                                    <p className="search-btn">
                                        <i className="fas fa-chevron-down"></i>
                                    </p>
                                </div>
                                <div className={`drop ${dropDown ? "" : "d-none"}`}>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t("Search")}
                                        value={countrySearch}
                                        onChange={(e) => setCountrySearch(e.target.value)}
                                        style={{width:'100%',padding:'2px 18px' ,borderBottom:'1px solid #eee'}}
                                    />
                                    {filteredCountries && filteredCountries.map((country) => (
                                        <p key={country.id} onClick={() => { 
                                            setDropDownChoose(country.name);
                                            setCountry(country.code);
                                            setSelectedCountry(country.code);
                                            handleDropDown(); 
                                        }}>
                                            {t(country.name)}
                                        </p>
                                    ))}
                                </div>
                        {errors.country && <span className="error-text" style={{color:'red'}}>{errors.country}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <label>{t('City')}</label>
                            <div className="drop-down-con" ref={dropDownRef3}>
                                <div className="drop-down" onClick={handleDropDown2}>
                                    <p>{dropDownChoose2 ? dropDownChoose2 : t(city)}</p>
                                    <p className="search-btn">
                                        <i className="fas fa-chevron-down"></i>
                                    </p>
                                </div>
                                <div className={`drop ${dropDown2 ? "" : "d-none"}`}>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder={t("Search")}
                                        value={citySearch}
                                        onChange={(e) => setCitySearch(e.target.value)}
                                        style={{width:'100%',padding:'2px 18px' ,borderBottom:'1px solid #eee'}}
                                    />
                                    {filteredCities && filteredCities.map((city) => (
                                        <p key={city.id} onClick={() => {
                                            setDropDownChoose2(city.name);
                                            setCity(city.id);
                                            handleDropDown2();
                                        }}>
                                            {t(city.name)}
                                        </p>
                                    ))}
                                </div>
                        {errors.city && <span className="error-text" style={{color:'red'}}>{errors.city}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-wrap">
                            <label>{t('Address')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        {errors.address && <span className="error-text" style={{color:'red'}}>{errors.address}</span>}
                        </div>
                    </div>
                </div>
                <div className="settings-card-footer">
                <div className="btn-item">
                    <button className="btn-primary" type="submit">
                    {isUpdating ? t('loading') : t('Save Changes')}
                    </button>
                </div>
                </div>
            </div>
        </form>
    );
    };


    export default PersonalInformationForm;