import React, { useState, useEffect } from "react";
import { useFetchCitiesQuery, useFetchCountriesQuery, useFetchIndustriesQuery } from "../../app/feature/API/Helpers";
import { useSaveUserMutation } from "../../app/feature/API/User";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register({ signIn }) {
  const {t, i18n} = useTranslation();
  const getStartedData = t("GetStartedSection", { returnObjects: true });
  const [state, setState] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    job_title: "",
    job_company: "",
    company_industry: "",
    company_country: "",
    company_city: "",
    date_of_birth: "",
    company_website: "",
    user_type: "",
  });
  const [errors, setErrors] = useState({});
  const [saveUser, { isLoading, error }] = useSaveUserMutation();
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCountryChange = (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      company_country: value,
    }));
  };

  const validateStep0 = () => {
    if (!formValues.user_type) {
      return "Please select a user type.";
    }
    return null;
  };

  const validateStep1 = () => {
    const newErrors = {};
    if (!formValues.first_name) newErrors.first_name = t('First name is required');
    if (!formValues.last_name) newErrors.last_name = t('Last name is required');
    if (!formValues.email) newErrors.email = t('Email is required');
    if (!formValues.password) newErrors.password = t('Password is required');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = () => {
    const newErrors = {};
    if (!formValues.job_title) newErrors.job_title = t('Job title is required');
    if (!formValues.job_company) newErrors.job_company = t('Company name is required');
    if (!formValues.company_industry) newErrors.company_industry = t('Industry is required');
    if (!formValues.company_country) newErrors.company_country = t('Country is required');
    if (!formValues.company_city) newErrors.company_city = t('City is required');
    if (!formValues.company_website) newErrors.company_website = t('Company Website is required');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();
    let isValid = false;
    if (state === 0) {
      const validationError = validateStep0();
      if (validationError) {
        setErrors((prevErrors) => ({ ...prevErrors, general: validationError }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, general: undefined }));
        isValid = true;
      }
    } else if (state === 1) {
      isValid = validateStep1();
    } else if (state === 2) {
      isValid = validateStep2();
    }
    
    if (isValid) {
      setState((prevState) => prevState + 1);
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, general: 'Please fix the errors before proceeding.' }));
    }
  };
  const handlePrev = (e) => {
    e.preventDefault();
    if (state === 0) {
      return;
    }
    if (state === 2) {
      validateStep1();
    } else if (state === 1) {
      validateStep0();
    }
    setState((prevState) => prevState - 1);
  };
  
const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateStep2()&& recaptchaValue) {
      try {
        const defaultDateOfBirth = "01/01/2000"; 
        const response = await saveUser(formValues).unwrap();
        toast.success(response.message || t("Form submitted successfully"));
        localStorage.setItem("EmailRegister",formValues.email);
        navigate('/confirm-email');
        setFormValues({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          job_title: "",
          job_company: "",
          company_industry: "",
          company_country: "",
          company_city: "",
          date_of_birth: defaultDateOfBirth,
          company_website: "",
          user_type: "",
        })

      } catch (err) {
        toast.error(t("ErrorData"));
      }
    } else if (!recaptchaValue) {
      toast.error(t('captchaRequired')); 
    }
  };
  

  const { data: AllCountries } = useFetchCountriesQuery();
  const { data: AllCities } = useFetchCitiesQuery(selectedCountry);
  const { data: AllIndustries } = useFetchIndustriesQuery();
  
  useEffect(() => {
    if (AllCities?.data) {
      const cityOptions = Object.entries(AllCities.data).map(([key, value]) => ({
        id: key,
        name: value,
      }));
      setCities(cityOptions);
    }
  }, [AllCities]);

  return (
    <>
      {state === 0 ? (
        <div className="form-container sign-in-container">
          <form action="#">
            <div className="content-tabs-getStarted mt-4 mb-4">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div
                    className="single-pricing-plan bg-gray flex-center-center psm-20 mtsm-20"
                    onClick={() => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        user_type: "egyptian_exporter",
                      }));
                      setState(1);
                    }}
                  >
                    <div className="package-head">
                      <div className="icon">
                      <svg
                      className="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Outline"
                      x="0px"
                      y="0px"
                      viewBox="0 0 30 30"
                      enableBackground="new 0 0 30 30"
                      xmlSpace="preserve"
                      fill="#632166"
                      width={150}
                    >
                      <g>
                        <path d="M24.75,20.31714v-5h-3v-5H8.25v2.53998h1.5v-1.03998h1.5v1.75h1.5v-1.75h1.5v3.5h-4.5v-0.96002h-1.5v0.96002h-3v5h-2v4.5h2   v2h5.5v-2h5.03998v-1.5H9.25v2h-2.5v-2h-2v-1.5h20.5v1.5h-2v2h-2.5v-2h-3.46002v1.5H19.25v2h5.5v-2h2v-4.5H24.75z M15.75,11.81714   h1.5v1.75h1.5v-1.75h1.5v3.5h-4.5V11.81714z M11.25,20.31714h-4.5v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z M17.25,20.31714h-4.5   v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z M23.25,20.31714h-4.5v-3.5h1.5v1.75h1.5v-1.75h1.5V20.31714z" />
                        <polygon
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#ff5e14"
                          points="23.25,7.42035 23.25,10.06714 24.75,10.06714 24.75,7.42035 25.60254,7.95337 26.39746,6.68091 24,5.18286    21.60254,6.68091 22.39746,7.95337  "
                        />

                        <polygon
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#ff5e14"
                          points="14.25,5.42035 14.25,8.06714 15.75,8.06714 15.75,5.42035 16.60254,5.95337 17.39746,4.68091 15,3.18286    12.60254,4.68091 13.39746,5.95337  "
                        />
                        <polygon
                          fill="#ff5e14"
                          points="5.25,7.42035 5.25,10.06714 6.75,10.06714 6.75,7.42035 7.60254,7.95337 8.39746,6.68091 6,5.18286    3.60254,6.68091 4.39746,7.95337  "
                        />
                      </g>
                    </svg>
                      </div>
                    </div>
                    <span className="mt-10 txt-getStarted text-center">
                    {getStartedData[0].nameBox1}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div
                    className="single-pricing-plan bg-gray flex-center-center psm-20 mtsm-10"
                    onClick={() => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        user_type: "international_importer",
                      }));
                      setState(1);
                    }}
                  >
                    <div className="package-head">
                      <div className="icon">
                      <svg
                      className="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Outline"
                      x="0px"
                      y="0px"
                      viewBox="0 0 30 30"
                      enableBackground="new 0 0 30 30"
                      xmlSpace="preserve"
                      fill="#632166"
                      width={150}
                    >
                      <g xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.125,16.30499v-5.69l-9.75-5.41998l-9.75,5.41998v14.19h19.5v-4h-1.5v2.5h-1.5v-0.97003h-1.5v0.97003h-1.5v-3h-1.5v-3   h-4.5v3h-1.5v3h-1.5v-4.96002h-1.5v4.96002h-1.5v-11.81l8.25-4.58002l8.25,4.58002v4.81H23.125z M15.625,23.30499h-1.5v-1.5h1.5   V23.30499z M12.625,18.80499h1.5v1.5h-1.5V18.80499z M11.125,21.80499h1.5v1.5h-1.5V21.80499z" />
                        <polygon points="6.625,16.84503 8.125,16.84503 8.125,14.80499 20.125,14.80499 20.125,13.30499 6.625,13.30499  " />
                        <rect x="10.375" y="10.30499" width="6" height="1.5" />
                        <polygon
                          fill="#ff5e14"
                          points="19.9986,17.80499 20.86279,16.46027 19.60107,15.64972 17.7334,18.55499 19.60107,21.46027 20.86279,20.64972    19.9986,19.30499 26.375,19.30499 26.375,17.80499  "
                        />
                      </g>
                    </svg>
                      </div>
                    </div>
                    <span className="mt-10 txt-getStarted text-center">
                    {getStartedData[0].nameBox2}
                    </span>
                  </div>
                  <p>
             {i18n.language==='en'?" Already have an account?":"هل لديك حساب بالفعل؟"} <span onClick={signIn} style={{cursor:'pointer'}}>{i18n.language==='en'?"Sign In":"تسجيل الدخول"}</span>
            </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : state === 1 ? (
        <div className="form-container sign-in-container">
          <form>
            <h1>{i18n.language==='en'?"Create Account":"إنشاء حساب"}</h1>
            <div className="flex-required-input">
              <input
                type="text"
                name="first_name"
                placeholder={`${i18n.language==='en'?"First Name":"الاسم الأول"}`}
                onChange={handleInputChange}
                value={formValues.first_name}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.first_name && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.first_name}</span>}
            </div>
            <div className="flex-required-input">
              <input
                type="text"
                name="last_name"
                placeholder={`${i18n.language==='en'?"Last Name":"اسم العائلة"}`}
                onChange={handleInputChange}
                value={formValues.last_name}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.last_name && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.last_name}</span>}
            </div>
            <div className="flex-required-input">
              <input
                type="email"
                name="email"
                placeholder={`${i18n.language==='en'?"Email":"بريد إلكتروني"}`}
                onChange={handleInputChange}
                value={formValues.email}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.email && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.email}</span>}
            </div>
            <div className="flex-required-input">
              <input
                type="password"
                name="password"
                placeholder={`${i18n.language==='en'?"Password":"كلمة المرور"}`}
                onChange={handleInputChange}
                value={formValues.password}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.password && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.password}</span>}
            </div>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginTop:"15px"}}>
            <button onClick={handlePrev}>{`${i18n.language==='en'?"Previous":"السابق"}`}</button>
            <button onClick={handleNext}>{`${i18n.language==='en'?"Next":"التالي"}`}</button>
            </div>
            {/* <div className="text-center" style={{ color: 'red' }}>
              {errors.general}
            </div> */}
           <p>
           {i18n.language==='en'?" Already have an account?":"هل لديك حساب بالفعل؟"} <span onClick={signIn} style={{cursor:'pointer'}}>{i18n.language==='en'?"Sign In":"تسجيل الدخول"}</span>
           </p>
          </form>
        </div>
      ) : state === 2 ? (
        <div className="form-container sign-in-container">
          <form>
          <h1>{i18n.language==='en'?"Create Account":"إنشاء حساب"}</h1>
          <div className="flex-required-input">
              <input
                type="text"
                name="job_title"
                placeholder={`${i18n.language==='en'?"Job Title":"مسمى وظيفي"}`}
                onChange={handleInputChange}
                value={formValues.job_title}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.job_title && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.job_title}</span>}
            </div>
            <div className="flex-required-input">
              <input
                type="text"
                name="job_company"
                placeholder={`${i18n.language==='en'?"Company Name":"اسم الشركة"}`}
                onChange={handleInputChange}
                value={formValues.job_company}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.job_company && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.job_company}</span>}
            </div>
            <div className="flex-required-input">
              <select
                name="company_industry"
                onChange={handleInputChange}
                value={formValues.company_industry}
              >
                <option value="">{`${i18n.language==='en'?"Select Industry":"اختر الصناعة"}`}</option>
                {AllIndustries && AllIndustries.map((industry) => (
                  <option key={industry.id} value={industry.id}>
                    {industry.name}
                  </option>
                ))}
              </select>
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.company_industry && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.company_industry}</span>}
            </div>
            <div className="flex-required-input">
              <select
                name="company_country"
                onChange={handleCountryChange}
                value={formValues.company_country}
              >
                <option value="">{`${i18n.language==='en'?"Select Country":"اختر البلد"}`}</option>
                {AllCountries && AllCountries.map((country) => (
                  <option key={country.id} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.company_country && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.company_country}</span>}
            </div>
            <div className="flex-required-input">
              <select
                name="company_city"
                onChange={handleInputChange}
                value={formValues.company_city}
              >
                <option value="">{`${i18n.language==='en'?"Select City":"اختر المدينة"}`}</option>
                {cities && cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.company_city && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.company_city}</span>}
            </div>
            <div className="flex-required-input">
              <input
                type="url"
                name="company_website"
                placeholder={`${i18n.language==='en'?"Company Website":"موقع الشركة"}`}
                onChange={handleInputChange}
                value={formValues.company_website}
              />
              <span className="astric">*</span>
            </div>
            <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.company_website && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.company_website}</span>}
            </div>
            {/* <div className="flex-required-input">
              <input
                type="date"
                name="date_of_birth"
                placeholder={`${i18n.language==='en'?"Date of Birth":"تاريخ الميلاد"}`}
                onChange={handleInputChange}
                value={formValues.date_of_birth}
              />
              <span className="astric">*</span>
            </div> */}
            {/* <div style={{ width: '100%', textAlign: 'start' }}>
              {errors.date_of_birth && <span style={{ color: 'red', fontSize: '18px', width: '100%' }}>{errors.date_of_birth}</span>}
            </div> */}
            <ReCAPTCHA
          sitekey="6LcYG20qAAAAABJgHbWG60SBUQ7EUw3YX0f_Pt6S" // Replace with your actual Site Key
          onChange={handleRecaptchaChange}
        />
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginTop:"15px"}}>
            <button onClick={handlePrev}>{`${i18n.language==='en'?"Previous":"سابق"}`}</button>
            <button onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? (i18n.language === "en" ? "Submitting..." : "يتم التقديم...") : (i18n.language === "en" ? "Submit" : "يُقدِّم")}
            </button>
            </div>

            <p>
            {i18n.language==='en'?" Already have an account?":"هل لديك حساب بالفعل؟"} <span onClick={signIn} style={{cursor:'pointer'}}>{i18n.language==='en'?"Sign In":"تسجيل الدخول"}</span>
            </p>
            {/* <div className="text-center" style={{ color: 'red' }}>
              {errors.general}
            </div> */}
          </form>
        </div>
      ) : null}
    </>
  );
}
