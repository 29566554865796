import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Input, Label, Button, FormGroup, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { usePostReviewsReportsMutation } from '../app/feature/API/Reports';

const ModalReviewReport = ({ open, onClose, reportId }) => {
  const [t, i18n] = useTranslation();
  const lang = i18n.language === "en" ? 'en' : 'ar';

  const [formData, setFormData] = useState({
    report_id: reportId || '', 
    review: '',
    comment: '',
  });

  const [errors, setErrors] = useState({});

  React.useEffect(() => {
    setFormData((prev) => ({ ...prev, report_id: reportId }));
  }, [reportId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.report_id) newErrors.report_id = lang === 'ar' ? 'معرف التقرير مطلوب' : 'Report ID is required';
    if (!formData.review) newErrors.review = lang === 'ar' ? 'التقييم مطلوب' : 'Review is required';
    if (!formData.comment) newErrors.comment = lang === 'ar' ? 'التعليق مطلوب' : 'Comment is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [submitReview, { isLoading, isError }] = usePostReviewsReportsMutation();
  const resetForm = () => {
    setFormData({
      report_id: reportId,
      review: '',
      comment: '',
    });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      await submitReview(formData).unwrap();
      toast.success(lang === 'ar' ? 'تم إرسال المراجعة بنجاح!' : 'Review submitted successfully!');
      onClose();
      resetForm();
    } catch (err) {
      console.error('Error submitting review:', err);
      if (err.status === 422 && err.data.errors) {
        setErrors(err.data.errors);
      }
    }
  };
  if (isError) return null;

  return (
    <div>
      <Modal open={open} onClose={onClose} center styles={{ modal: { minHeight: 'auto', height: 'auto', minWidth: '600px' } }}>
        <h2 className='desc-app text-center'>{lang === 'ar' ? 'اكتب مراجعتك' : 'Write Your Review'}</h2>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup className="mb-3">
                <Label for="review" className="form-label">
                  {lang === 'ar' ? 'التقييم' : 'Review'}
                </Label>
                <Input
                  type="select"
                  name="review"
                  id="review"
                  value={formData.review}
                  onChange={handleChange}
                  style={{ borderColor: errors.review ? 'red' : undefined }}
                >
                  <option value="">{lang === 'ar' ? 'اختر التقييم' : 'Select Review'}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Input>
                {errors.review && <div style={{ color: 'red' }}>{errors.review}</div>}
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="mb-3">
                <Label for="comment" className="form-label">
                  {lang === 'ar' ? 'التعليق' : 'Comment'}
                </Label>
                <Input
                  type="textarea"
                  name="comment"
                  id="comment"
                  placeholder={lang === 'ar' ? 'اكتب تعليقك هنا' : 'Write your comment here'}
                  value={formData.comment}
                  onChange={handleChange}
                  style={{ borderColor: errors.comment ? 'red' : undefined }}
                />
                {errors.comment && <div style={{ color: 'red' }}>{errors.comment}</div>}
              </FormGroup>
            </Col>
          </Row>

          <Button type="submit" color="primary" className="w-100 apply-job">
            {isLoading ? t('loading') : t('submitReview')}
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default ModalReviewReport;
