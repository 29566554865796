import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const JobsApi = createApi({
  reducerPath: "JobsApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${base_url}`,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem("token_Front_Mied");
        const language = localStorage.getItem('userLanguage') || "en"
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
  
        headers.set("Accept-Language", language);
  
        return headers;
      },
  }),
  endpoints: (build) => ({
    getJobs: build.query({
      query: ({ employment_status = '', jcategory_id = '', title = '' , experience='',date_post = '',location=''}) => {
        let queryStr = '/jobs?';
        
        if (employment_status) {
          queryStr += `employment_status=${employment_status}&`;
        }
        if (experience) {
          queryStr += `experience=${experience}&`;
        }
        if (jcategory_id) {
          queryStr += `jcategory_id=${jcategory_id}&`;
        }
        if (date_post) {
          queryStr += `date_post=${date_post}&`;
        }
        if (title) {
          queryStr += `title=${title}&`;
        }
        if (location) {
          queryStr += `location=${location}&`;
        }
        return queryStr.slice(-1) === '&' ? queryStr.slice(0, -1) : queryStr;
      },
      transformResponse: (response) => response,
      providesTags: ["JobsApi"],
    }),
    getJobsList: build.query({
      query: ([page = 1],employment_status = '',jcategory_id='',title='') => 
        `/jobs?page=${page}&employment_status=${employment_status}&jcategory_id=${jcategory_id}&title=${title}`,
      transformResponse: (response) => response,
      providesTags: ["JobsApi"],
    }),
    getJobsDetails: build.query({
        query: (slug) => 
          `/jobs/${slug}`,
        transformResponse: (response) => response.data,
        providesTags: ["JobsApi"],
      }),
      getJobsCategories: build.query({
        query: () => 
          `/jobs/categories`,
        transformResponse: (response) => response.data,
        providesTags: ["JobsApi"],
      }),
      applyJob: build.mutation({
        query: ({ slug, applyJobData }) => {
          const formData = new FormData();
          Object.keys(applyJobData).forEach((key) => {
            formData.append(key, applyJobData[key]);
          });
          return {
            url: `/jobs/${slug}/apply`,
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["JobsApi"],
      }),
      
      }),
});

export const { useGetJobsQuery , useGetJobsDetailsQuery,useGetJobsCategoriesQuery ,useLazyGetJobsQuery,useApplyJobMutation} = JobsApi;
