import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const BlogsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${base_url}` ,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },
}),
  reducerPath: "BlogsApi",
  endpoints: (build) => ({
    getBlogs: build.query({
      query: ({ title = '', page = 1}) => 
        `/blogs?page=${page}&title=${title}`,
      transformResponse: (response) => response,
      providesTags: ["Blogs"],
    }),
    
    getBlogsCategories: build.query({
        query: () => `/blogs/categories`,
        transformResponse: (response) => response.data,
        providesTags: ["Blogs"],
    }),
    getBlogsDetails: build.query({
      query: (slug) => `/blogs/${slug}`,
      transformResponse: (response) => response.data,
      providesTags: ["Blogs"],
  }),
  }),
});

export const {
  useGetBlogsCategoriesQuery,
  useGetBlogsDetailsQuery,
  useGetBlogsQuery
} = BlogsApi;
