import React, { useState, useEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import jobImage2 from "../../assets/images/featured-job/img-02.png";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { HiMiniStar } from "react-icons/hi2";
import ModalApplyJob from "../ModalApplyJob";
import { useTranslation } from "react-i18next";
import { useGetJobsQuery } from "../../app/feature/API/Jobs";
import mainLogo from "../../assets/img/logo.jpg";

const RandomJobs = () => {
  const [displayData, setDisplayData] = useState("");
  const [slug, setSlug] = useState("");
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useGetJobsQuery(displayData);
  const hasFetched = useRef(false);
  const categories = [
    { value: "", label: i18n.language === 'en' ? "Recent Jobs" : "الوظائف الأخيرة" },
    { value: "Part-time", label: i18n.language === 'en' ? "Part Time" : "وقت جزئي" },
    { value: "Full-time", label: i18n.language === 'en' ? "Full Time" : "وقت كامل" },
  ];

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      );
    }

    if (isError) {
      return <p>{i18n.language === 'en' ? "Failed to load jobs" : "فشل في تحميل الوظائف"}</p>;
    }

    if (!data?.data?.length) {
      return <p style={{fontSize:'30px',textAlign:'center',padding:'30px 0'}}>{i18n.language === 'en' ? "No jobs available" : "لا توجد وظائف متاحة"}</p>;
    }

    const filteredJobs = displayData === ""
      ? data?.data
      : data?.data?.filter(job => job.employment_status === displayData);

    return (filteredJobs || []).slice(0, 4).map((recentJobDetails, key) => (
      <div
        key={key}
        className={
          recentJobDetails.addclassNameBookmark
            ? "job-box bookmark-post card mt-4"
            : "job-box card bookmark-post mt-4"
        }
      >
        <div className="bookmark-label text-center">
          <Link to="#" className="text-white align-middle">
            <HiMiniStar />
          </Link>
        </div>
        <div className="p-4">
          <Row>
            <Col md={2} className="mt-1">
              <div className="text-center mb-4 mb-md-0">
                <Link to={`/jobdetails/${recentJobDetails?.slug}`} >
                {recentJobDetails?.category_icon === null?(
                  <img
                    src={mainLogo}
                    alt={recentJobDetails?.title}
                    style={{ width: "80px", height: "80px" }}
                  />
                ):(
                  <i className={`${recentJobDetails?.category_icon}`} style={{fontWeight:"normal",fontSize:'50px',color:'#ff5e14'}}></i>
                )}
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-2 mb-md-0">
                <h5 className="fs-18 mb-1" style={{textWrap:'nowrap'}}>
                  <Link to={`/jobdetails/${recentJobDetails?.slug}`} className="text-desc">
                   {recentJobDetails?.title}
                  </Link>
                </h5>
                {/* <p className="text-muted fz-job">
                    {recentJobDetails?.benefits.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 150)}...
                </p> */}
              </div>
            </Col>
            <Col lg={2}>
              <div className="d-flex mb-2">
                <div className="flex-shrink-0">
                  <FaLocationDot className="text-desc" />
                </div>
                <p className="text-muted mb-0 fz-job">{recentJobDetails?.job_location}</p>
              </div>
            </Col>
            <Col lg={2}>
              <div>
                <p className="text-muted mb-2 fz-job">
                  {recentJobDetails.salary.replace(/<\/?[^>]+(>|$)/g, "")}
                </p>
              </div>
            </Col>
            <Col lg={2}>
              <div>
                <span
                  className={
                    recentJobDetails.employment_status === 'Full-time' || recentJobDetails.employment_status === 'وقت كامل'
                      ? "badge bg-success-subtle text-success fs-6 mt-1 mx-1"
                      : recentJobDetails.employment_status === 'Part-time' || recentJobDetails.employment_status === 'وقت جزئي'
                      ? "badge bg-danger-subtle text-danger fs-6 mt-1 mx-1"
                      : recentJobDetails.employment_status === 'Contractual' || recentJobDetails.employment_status === 'تعاقدي'
                      ? "badge bg-primary-subtle text-primary fs-6 mt-1 mx-1"
                      : "badge bg-primary-subtle text-primary fs-6 mt-1 mx-1"
                  }
                >
                  {recentJobDetails?.employment_status}
                </span>
                {(recentJobDetails.badges || []).map((badgeInner, key) => (
                  <span
                    className={
                      "badge " + badgeInner.badgeclassName + " fs-13 mt-1"
                    }
                    key={key}
                  >
                    {badgeInner.badgeName}
                  </span>
                ))}
              </div>
            </Col>
          </Row>
        <Row>
        <Col sm={12}className={`${i18n.language === 'en'?'ml-language':'mr-language'} mt-career`}>
              <div className="mb-2 mb-md-0">
                {/* <h5 className="fs-18 mb-1" style={{textWrap:'nowrap'}}>
                  <Link to={`/jobdetails/${recentJobDetails?.slug}`} className="text-desc">
                   {recentJobDetails?.title}
                  </Link>
                </h5> */}
                <p className="text-muted fz-job">
                    {recentJobDetails?.benefits.replace(/<\/?[^>]+(>|$)/g, "").slice(0, i18n.language === 'en'?140:115)}...
                </p>
              </div>
            </Col>
        </Row>
        </div>
        <div className="p-3 bg-light">
          <Row>
            <Col lg={4}>
              <div>
                <p className="text-muted mb-0">
                  <span className="text-dark">{i18n.language === 'en'?"Experience":"خبرة"} :</span>{" "}
                  {recentJobDetails.experience}
                </p>
              </div>
            </Col>
            <Col lg={6} md={5}>
              <div>
                <p className="text-muted mb-0">
                  <span className="text-dark">
                  {recentJobDetails.deadline && `${t("closingDate")} :`}
                  </span>
                  {recentJobDetails.deadline}
                </p>
              </div>
            </Col>
            <Col lg={2} md={3}>
              <div className="text-start text-md-end">
              <p className="text-desc-link" style={{ cursor: 'pointer' }} onClick={() => { onOpenModal(); setSlug(recentJobDetails.slug); }}>
                {t('applyNow')} <MdOutlineKeyboardDoubleArrowRight />
              </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ));
  };

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mb-80" data-aos="fade-up">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title pb-20">
                <h2>{i18n.language === 'en' ? "Job Posts" : "المشاركات الوظيفية"}</h2>
              </div>
              <p className="desc-cat">
                {i18n.language === 'en'
                  ? "Browse and apply to jobs that match your experience"
                  : "تصفح وتقدم إلى الوظائف التي تتناسب مع خبرتك"}
              </p>
            </div>
          </div>
        </div>
        <div className="" data-aos="fade-out">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <ul
                className="job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4"
                id="pills-tab"
                role="tablist"
              >
                {categories.map((category, index) => (
                  <li className="nav-item" role={category.value} key={index}>
                    <button
                      className={`nav-link ${displayData === category.value ? "active" : ""}`}
                      type="button"
                      role="tab"
                      aria-selected={displayData === category.value}
                      onClick={() => setDisplayData(category.value)}
                    >
                      {category.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {renderContent()}
          <div className="all-cat-job-cat mt-20 text-center">
            <Link to={'/job-list'}>
              {i18n.language === 'en'
                ? <>View All <FaArrowRight /></>
                : <> <FaArrowRight /> عرض الكل</>}
            </Link>
          </div>
        </div>
      </div>
      <ModalApplyJob open={open} slug={slug} onCloseModal={onCloseModal} />
    </section>
  );
};

export default RandomJobs;

