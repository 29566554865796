import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../../assets/img/Banner/Top-Image9.jpg";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { IoIosSearch } from "react-icons/io";
import { MdAssuredWorkload } from "react-icons/md";
import { CgCalculator } from "react-icons/cg";
import { IoPricetagsOutline } from "react-icons/io5";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useGetJobsCategoriesQuery, useLazyGetJobsQuery } from "../../app/feature/API/Jobs"; 
import { toast } from "react-toastify";
import { setJobData } from "../../app/feature/SaveJobData";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetBannersQuery } from "../../app/feature/API/CMS";

const HeroSeven = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { t,i18n } = useTranslation();
  const { data: JobCategoryData} = useGetJobsCategoriesQuery();
  const {data} = useGetBannersQuery('careers')

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [triggerSearch, { data: JobData ,isSuccess:JobDataSuccess}] = useLazyGetJobsQuery(); 

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  const handleTrendingSearch = (e) => {
    setSelectedCategory(e);
    triggerSearch({
      jcategory_id: selectedCategory || undefined,
      title: searchTerm || undefined,
    });
  };

  const handleSearch = () => {
    if (!searchTerm && !selectedCategory) {
      toast.warning(i18n.language==='en'?"Please, enter a search term or select a category.":"من فضلك، أدخل مصطلح بحث أو اختر فئة.");
      return; 
    }

    triggerSearch({
      jcategory_id: selectedCategory || undefined,
      title: searchTerm || undefined,
    });
  };

  useEffect(() => {
    if (JobDataSuccess) {
      if (JobData?.data?.length > 0) {
        toast.success(i18n.language==='en'?"Data already found.":"تم العثور علي بيانات بالفعل ");
        localStorage.setItem("searchTerm", searchTerm);
        localStorage.setItem("selectedCategory", selectedCategory);
        dispatch(setJobData(JobData));
        navigate(`/job-list`);
      } else {
        toast.success(t('No_Data'));
      }
    }
  }, [JobDataSuccess, JobData, dispatch, navigate, searchTerm, selectedCategory, i18n.language, t]);
  

  return (
    <section className="hero-wrapper hero-1 pb-1">
      <Swiper
        navigation={true}
        modules={[Navigation]}
        loop
        style={{ height: "500px" }}
      >
        <SwiperSlide
          className="bg-cover"
          style={{
            backgroundImage: `url(${data?.image})`,
          }}
        >
          <div className="container">
            <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
              <div className="hero-contents pe-lg-3 content-four hero-seven">
                <div className="search-hero-seven mt-20 search-hero-seven">
                  <div
                    className={`form-search-hero-seven fadeInUp animated
                     ${i18n.language === "en" ? "" : "dire-rtl "}`}
                    data-wow-duration="3s"
                  >
                    <MdAssuredWorkload className="svg-page-jop" />
                    <input
                      type="search"
                      id="job-title"
                      className="form-control filter-input-box"
                      placeholder={
                        i18n.language === "en"
                          ? "Enter Keywords..."
                          : "أدخل الكلمات الرئيسية.."
                      }
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div
                    className={`filter-search-form flex-drop-down  fadeInUp animated  
                    ${i18n.language === "en" ? "" : "dire-rtl "}`}
                    data-wow-duration="3s"
                  >
                    <CgCalculator className="svg-page-jop" />
                    <Input
                      type="select"
                      name="country"
                      id="country"
                      required
                      value={selectedCategory} 
                      onChange={handleCategoryChange}
                    >
            <option value="">{i18n.language==="en"?"Select Category":"حدد الفئة"}</option>
            {JobCategoryData?.map((cta, idx) => (
                        <option key={idx} value={cta?.id}>
                          {cta?.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div
                    className="wrap-btn fadeInUp animated "
                    data-wow-duration="3s"
                  >
                    <button
                      className="btn btn-primary submit-btn btn-find-jop"
                      type="button"
                      onClick={handleSearch} 
                      style={{textWrap:"nowrap"}}
                    >
                      {i18n.language === "en" ? (
                        <>
                          <IoIosSearch className="search-jop svg-page-jop" />{" "}
                          Find Job
                        </>
                      ) : (
                        <>
                          ابحث عن وظيفة{" "}
                          <IoIosSearch className="search-jop svg-page-jop" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
                <div
                  className={`list-categories-hero-four fadeInUp animated m-t-page-jop
                  ${i18n.language === 'en' ? "" : "dire-rtl "}`}
                  data-wow-duration="3s"
                >
                  <IoPricetagsOutline className="tag-jop-page" />
                  <li>{i18n.language === 'en' ? "Trending Keywords:" : "الكلمات الرئيسية الشائعة:"}</li>
                  {JobCategoryData?.slice().sort(() => 0.5 - Math.random()).slice(0, 4).map((cta, idx) => (
                    <li key={idx} onClick={()=>handleTrendingSearch(cta?.id)}>{cta?.name}</li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HeroSeven;
