import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const HelpersApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${base_url}` ,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },}),
  reducerPath: "HelpersApi",
  tagTypes: ["Helpers"],
  endpoints: (build) => ({
    fetchCountries: build.query({
      query: () => `/helpers/countries`,
      transformResponse: (response) => response.data,
      providesTags: ["Helpers"],
    }),
    fetchCities: build.query({
        query: (CountryCode) => `/helpers/cities/${CountryCode}`,
        transformResponse: (response) => response,
        providesTags: ["Helpers"],
      }),
      fetchIndustries: build.query({
        query: () => `/helpers/industries`,
        transformResponse: (response) => response.data,
        providesTags: ["Helpers"],
      }),
      fetchGeneralSetting: build.query({
        query: () => `/helpers/settings`,
        transformResponse: (response) => response.data,
        providesTags: ["Helpers"],
      }),
      fetchExtraSetting: build.query({
        query: () => `/helpers/extra-settings`,
        transformResponse: (response) => response.data,
        providesTags: ["Helpers"],
      }),
      fetchPackages: build.query({
        query: () => `/helpers/packages`,
        transformResponse: (response) => response.data,
        providesTags: ["Helpers"],
      }),
      fetchLinks: build.query({
        query: () => `/helpers/get-links`,
        transformResponse: (response) => response.data,
        providesTags: ["Helpers"],
      }),
      saveNewsletter: build.mutation({
        query: (newsletterData) => ({
          url: `/helpers/newsletter`,
          method: "POST",
          body: newsletterData,
        }),
        invalidatesTags: ["tickets"],
      }),
      saveContactUs: build.mutation({
        query: (contactData) => ({
          url: `/helpers/contact-us`,
          method: "POST",
          body: contactData,
        }),
        invalidatesTags: ["contact-us"],
      }),
      subscribePackage: build.mutation({
        query: (subscribePackageData) => ({
          url: `/packages/subscribe`,
          method: "POST",
          body: subscribePackageData,
        }),
        invalidatesTags: ["Helpers"],
      }),
      MakeReportOrder: build.mutation({
        query: (reportOrderData) => ({
          url: `/packages/make-report-order`,
          method: "POST",
          body: reportOrderData,
        }),
        invalidatesTags: ["Helpers"],
      }),
  }),
});

export const {
  useFetchCountriesQuery,
  useFetchCitiesQuery,
  useFetchIndustriesQuery,
  useFetchGeneralSettingQuery,
  useFetchPackagesQuery,
  useSaveNewsletterMutation,
  useSaveContactUsMutation,
  useSubscribePackageMutation,
  useMakeReportOrderMutation,
  useFetchExtraSettingQuery,
  useFetchLinksQuery
} = HelpersApi;
