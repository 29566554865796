import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useConfirmEmailMutation } from "../../../app/feature/API/User";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

export default function ConfirmEmailForm() {
  const { t, i18n } = useTranslation();
  const defaultConfirmEmail = localStorage.getItem('EmailRegister');
  const [email, setEmail] = useState(defaultConfirmEmail || "");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({ email: '', otp: '' });
  const [confirm, { isLoading }] = useConfirmEmailMutation();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const navigate = useNavigate();

  const validate = () => {
    let isValid = true;
    const errors = { email: '', otp: '' };

    if (!email) {
      errors.email = t('login.emailRequired');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = t('login.emailInvalid');
      isValid = false;
    }

    if (!otp) {
      errors.otp = t('login.otpRequired');
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (validate() && recaptchaToken) {
      try {
        const response = await confirm({ email, otp }).unwrap();
        const { token } = response.data;
        localStorage.setItem("token_Front_Mied", token);
        localStorage.removeItem('EmailRegister');
        navigate('/');
        toast.success(response.message || "Confirm Email successfully");
      } catch (err) {
        toast.error(err.data?.message || "هناك مشكله في البيانات المرسله");
      }
    } else if (!recaptchaToken) {
      toast.error(t('captchaRequired')); 
    }
  };

  return (
    <div className="form-container sign-in-container">
      <form onSubmit={handleResetPassword}>
        <h1>{`${i18n.language === 'en' ? "Confirm Email" : ".تأكيد عنوان البريد الإلكتروني"}`}</h1>
        
        <div className="flex-required-input">
          <input
            type="email"
            placeholder={t('login.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <span className="astric">*</span>
        </div>
        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}

        <div className="flex-required-input">
          <input
            type="text"
            placeholder={t('login.otp')}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <span className="astric">*</span>
        </div>
        {errors.otp && <span style={{ color: 'red' }}>{errors.otp}</span>}
        
        {/* reCAPTCHA Component */}
        <div className="mb-2">
        <ReCAPTCHA
          sitekey="6LcYG20qAAAAABJgHbWG60SBUQ7EUw3YX0f_Pt6S" // Replace with your reCAPTCHA site key
          onChange={(token) => setRecaptchaToken(token)} // Handle token change
        />
        </div>

        {/* Submit Button */}
        <button type="submit" disabled={isLoading}>
          {isLoading ? (i18n.language === 'en' ? "Confirming..." : "...تأكيد") : (i18n.language === 'en' ? "Confirm Email" : ".تأكيد عنوان البريد الإلكتروني")}
        </button>
      </form>
    </div>
  );
}
