import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { useGetPortfolioDetailsQuery } from "../app/feature/API/Portfolio";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SidebarPortfolio from "./SidebarPortfolio";

const DetailsPort = () => {
  const { slug } = useParams();
  const { data: blogsData, error, isLoading } = useGetPortfolioDetailsQuery(slug);
  const { t, i18n } = useTranslation();
  const shareUrl = window.location.href;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const getFirstFTagContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const firstFTag = doc.querySelector("p");
    return firstFTag ? firstFTag.innerHTML : "";
  };

  if(isLoading){
    return <p>{t('loading')}</p>
  }

  return (
    <section className="blog-wrapper news-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="blog-post-details border-wrap">
              <div className="single-blog-post post-details">
                <div className="post-content">
                  <Slider {...settings}>
                    {blogsData?.images?.map((imageObj, index) => (
                      <div key={index}>
                        <img
                          src={imageObj.image}
                          alt={blogsData?.title}
                          style={{ maxHeight: "400px", width: "100%", objectFit: "cover" }}
                        />
                      </div>
                    ))}
                  </Slider>

                  {/* <h2>{blogsData?.title}</h2> */}
                  <div className="post-meta">
                    <span>
                      <i className="fal fa-calendar-alt"></i>
                      {new Date(blogsData?.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: blogsData?.content }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SidebarPortfolio />
        </div>
      </div>
    </section>
  );
};

export default DetailsPort;
