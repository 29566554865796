import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../../Base/base_Url";

export const UserApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${base_url}` ,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token_Front_Mied");
      const language = localStorage.getItem('userLanguage') || "en"
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Accept-Language", language);

      return headers;
    },}),
  reducerPath: "UserApi",
  endpoints: (build) => ({
    saveUser: build.mutation({
      query: (userData) => ({
        url: `/register`,
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["saveUser"],
    }),
    forgetPassword: build.mutation({
      query: (email) => ({
        url: `/forget`,
        method: "POST",
        body: {email}, 
      }),
      invalidatesTags: ["forgetUser"],
    }),
    resetPassword: build.mutation({
      query: (email) => ({
        url: `/reset`,
        method: "POST",
        body: email, 
      }),
      invalidatesTags: ["resetUser"],
    }),
    confirmEmail: build.mutation({
      query: (email) => ({
        url: `/confirm`,
        method: "POST",
        body: email, 
      }),
      invalidatesTags: ["confirmUser"],
    }),
  }),
});

export const {
  useSaveUserMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useConfirmEmailMutation,
} = UserApi;
