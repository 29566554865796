import { useTranslation } from "react-i18next";
import PageBanner from "../Shared/PageBanner"
import CartTable from "../components/Cart/CartTable";

const Cart = () => {
  const { i18n } = useTranslation(); 

  return (
    <>
      <PageBanner currentPage={i18n.language==='en'?'My Cart':'سلة التسوق الخاصة بي'} title={i18n.language==='en'?'My Cart':'سلة التسوق الخاصة بي'}/>
      <CartTable/>
    </>
  )
}

export default Cart
