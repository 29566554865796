import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetServicesQuery } from "../../app/feature/API/Services";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";
import React from "react";

const ServicesOne = () => {
  const [t, i18n] = useTranslation();

  const { data: ServicesDataExporter, isLoading: isLoadingExporter } = useGetServicesQuery({ user_type: 'exporter'});
  const servicesExporter = ServicesDataExporter?.data?.filter(service => service.feature === 1) || [];

  const { data: ServicesDataImporter, isLoading: isLoadingImporter } = useGetServicesQuery({ user_type: 'importer'});
  const servicesImporter = ServicesDataImporter?.data?.filter(service => service.feature === 1) || [];

  const hasData = servicesExporter.length > 0 || servicesImporter.length > 0;

  const { data: GeneralSetting } = useFetchGeneralSettingQuery();

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        {(isLoadingExporter || isLoadingImporter) && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
          </div>
        )}
        {!isLoadingExporter && !isLoadingImporter && hasData && (
          <>
            <div className="row mb-80">
              <div className="col-xl-12">
                <div className="block-contents text-center">
                  <div className="section-title">
                    {t('servicesOne', { returnObjects: true }).map((serv, idx) => (
                      <React.Fragment key={idx}>
                        <h5>{t(serv.title1)}</h5>
                        <span>{t(serv.title2)}</span>
                        <h2>{t(serv.title1)}</h2>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mtm-30">
              <div className="col-md-6 col-12 col-lg-4 mt-30">
                {GeneralSetting?.map((title, idx) => (
                  <div className="block-contents" data-aos="fade-right" key={idx}>
                    <div className="section-title">
                      <span>{title?.service_sec_1_header}</span>
                      <h2 className="fz-2xl">{title?.service_sec_1_title}</h2>
                    </div>
                    <p className="desc-app">
                      {title?.service_sec_1_desc}
                    </p>
                  </div>
                ))}
              </div>
              {servicesExporter.length > 0 ? (
                servicesExporter.map((data) => (
                  <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id} data-aos="fade-left">
                    <div className="single-service-box style-1">
                      <div
                        className="service-bg bg-cover"
                        style={{ backgroundImage: `url(${data?.main_image})` }}
                      ></div>
                      <div className="icon">
                        <i className={data?.icon}></i>
                      </div>
                      <div className="contents">
                        <h4><p>{data?.title?.length > 54 ? `${data.title.slice(0, 54)}...` : data?.title}</p></h4>
                        <p>
                          {data?.summary?.length > 178 ? `${data.summary.slice(0, 178)}...` : data?.summary}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h2 className="text-center">{t("No_Data")}</h2>
              )}
              <Link
                to="/exportservices"
                className="theme-btn me-sm-4 wow fadeInLeft btn-explore mt-30 learn-more border-radius mrbtn"
                data-wow-duration="1.2s"
                data-wow-delay=".8s"
              >
                {t('btnNameServices')}
              </Link>
            </div>

            <div className="row mt-70">
              <div className="col-md-6 col-12 col-lg-4 mt-30" data-aos="fade-right">
                {GeneralSetting?.map((title, idx) => (
                  <div className="block-contents" data-aos="fade-right" key={idx}>
                    <div className="section-title">
                      <span>{title?.service_sec_2_header}</span>
                      <h2 className="fz-2xl">{title?.service_sec_2_title}</h2>
                    </div>
                    <p className="desc-app">
                      {title?.service_sec_2_desc}
                    </p>
                  </div>
                ))}
              </div>

              {servicesImporter.length > 0 ? (
                servicesImporter.map((data) => (
                  <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id} data-aos="fade-left">
                    <div className="single-service-box style-1">
                      <div
                        className="service-bg bg-cover"
                        style={{ backgroundImage: `url(${data?.main_image})` }}
                      ></div>
                      <div className="icon">
                        <i className={data?.icon}></i>
                      </div>
                      <div className="contents">
                        <h4><p>{data?.title?.length > 54 ? `${data.title.slice(0, 54)}...` : data?.title}</p></h4>
                        <p>
                          {data?.summary?.length > 178 ? `${data.summary.slice(0, 178)}...` : data?.summary}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h2 className="text-center">{t("No_Data")}</h2>
              )}
              <Link
                to="/importservices"
                className="theme-btn me-sm-4 wow fadeInLeft btn-explore mt-30 learn-more border-radius mrbtn"
                data-wow-duration="1.2s"
                data-wow-delay=".8s"
              >
                {t('btnNameServices')}
              </Link>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ServicesOne;
