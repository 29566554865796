import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ to, currentPage }) => {
  const {i18n} = useTranslation()
  return (
    <section>
      <div className="container mt-40">
        <div className="row">
          <div className="breadcrumb-wrap">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="breadcrumbs-to" to={`/${to}`}>
                    {to}
                  </Link>
                </li>

                <li className={`breadcrumb-item active ${i18n.language==='ar'?"arabic-lang":""}`} aria-current="page">
                    {currentPage}
                  </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;
