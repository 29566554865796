import { useTranslation } from "react-i18next";
import img from "../../../assets/img/capabilities-img.jpg";
import { useGetHomeSectionsQuery } from "../../../app/feature/API/CMS";
const CapabilitiesReverse = () => {
  const { t, i18n } = useTranslation();
  const { data: aboutData, isLoading } = useGetHomeSectionsQuery('reports');
  const aboutSection = aboutData?.find(cta => cta.slug === 'reports_1');
  if(aboutSection?.show_sec !==1 ){
    return null;
  }
  return (
      <div className="sec-capabilities">
        <div className="capabilities-reverse mt-80">
        <div className={`flex-1 txt-capabilities ${i18n.language==='ar'?"mr-100":""}`} data-aos="fade-right">
          <div className={`words-capabilities ${i18n.language==='ar'?"words-capabilities-arabic":""}`}>
            <p className="text-white mb-30 txt-cap">
            {aboutSection?.sub}

            </p>
            <h2 className="text-white h2-capabilities mb-30">
            {aboutSection?.title}

            </h2>
            <p className="desc-cap-spacial">{aboutSection?.dec}</p>
          </div>
        </div>
          <div className="flex-1 image-capabilities" data-aos="fade-right">
            <div className="reverse">
              <img src={aboutSection?.image} alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
  );
};

export default CapabilitiesReverse;
