import user from "../../assets/img/services/user-01.jpg";
import logo from '../../assets/img/logo.jpg';
import { useTranslation } from "react-i18next";
import { useGetReviewsReportsQuery } from "../../app/feature/API/Reports";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import calendar from "../../assets/img/calendar.svg";

const MyReview = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useGetReviewsReportsQuery();
  const [filterData, setFilterData] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleFilterData = () => {
    setFilterData(!filterData);
  };

  const clearDateFilters = () => {
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
  };

  if (isLoading) return <p>{t('No_Data')}</p>;
  if (isError) return null;

  const filteredReviews = data?.filter(review => {
    const createdAt = new Date(review.created_at);
    const isWithinDateRange =
      (!selectedDateFrom || createdAt >= selectedDateFrom) &&
      (!selectedDateTo || createdAt <= selectedDateTo);
    return (
      review.comment.toLowerCase().includes(searchTerm.toLowerCase()) &&
      isWithinDateRange
    );
  });

  const timeSince = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    const intervals = [
      { label: i18n.language === 'en' ? 'year' : 'سنة', seconds: 365 * 24 * 60 * 60 },
      { label: i18n.language === 'en' ? 'month' : 'شهر', seconds: 30 * 24 * 60 * 60 },
      { label: i18n.language === 'en' ? 'day' : 'يوم', seconds: 24 * 60 * 60 },
      { label: i18n.language === 'en' ? 'hour' : 'ساعة', seconds: 60 * 60 },
      { label: i18n.language === 'en' ? 'minute' : 'دقيقة', seconds: 60 },
      { label: i18n.language === 'en' ? 'second' : 'ثانية', seconds: 1 },
    ];

    return seconds < 60 ? `${i18n.language === 'en' ? 'now' : 'الآن'}` :
      intervals.map(i => Math.floor(seconds / i.seconds) >= 1 ? ` ${Math.floor(seconds / i.seconds)} ${i.label} ${Math.floor(seconds / i.seconds) > 1 ? `${i18n.language === 'en' ? 'ago' : 'منذ'}` : ''}` : null).find(Boolean) || `${i18n.language === 'en' ? 'now' : 'الآن'}`;
  };

  return (
    <>
      <div className="dashboard-header">
        <div className="main-title">
          <h3>{t('my_review.title')}</h3>
        </div>
        <div className="head-info"></div>
      </div>

      <div className="table-filter">
        <ul className="filter-item">
          <li>
            <p>{t("Filter")}</p>
          </li>
          <li>
            <div className="form-sort form-wrap date-time-picker-wrap" onClick={handleFilterData}>
              <span className="form-icon">
                <img src={calendar} alt="icon" />
              </span>
              <DatePicker
                selected={selectedDateFrom}
                onChange={(date) => setSelectedDateFrom(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText={t("From Date")}
              />
            </div>
          </li>
          <li>
            <div className="form-sort form-wrap date-time-picker-wrap">
              <span className="form-icon">
                <img src={calendar} alt="icon" />
              </span>
              <DatePicker
                selected={selectedDateTo}
                onChange={(date) => setSelectedDateTo(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText={t("To Date")}
              />
            </div>
          </li>
            <p onClick={clearDateFilters}>
              x
            </p>
        </ul>
        <div id="tablefilter">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <label>
              <i className="fas fa-search"></i>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t("Search")}
                aria-controls="DataTables_Table_0"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="user-review" style={{ maxHeight: '942px', overflow: 'auto' }}>
        <ul className="review-lists">
          {filteredReviews?.map((review) => (
            <li key={review.id}>
              <div className="review-wrap">
                <div className="top--report">
                  <div className="header-box-report">
                    <div className="Review-logo-rate">
                      <div className="logo">
                        <img src={logo} alt="Logo" loading="lazy" className="m-19" />
                      </div>
                      <div className="user-information w-84">
                        <ul>
                          <li>
                            <i className="fas fa-star"></i> {review.review} ({review.total_reviews})
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-desc minus-m">
                      {review.title || 'Sample Report Title Placeholder'}
                    </div>
                  </div>
                  <div className="body-box-report review-card">
                    <img src={review.image} alt="Review" />
                  </div>
                </div>

                <div className="user-review">
                  <div className="review-user-info">
                    <div className="review-img">
                      <img src={review?.user_img || user} alt="User" />
                    </div>
                    <div className="reviewer-info">
                      <div className="reviewer-loc">
                        <h6>
                          <a href="javascript:void(0);">{review.user_name}</a>
                        </h6>
                        <p>
                          <i className="flaticon-pin"></i> <span>{review?.location || 'cairo'}</span>
                        </p>
                      </div>
                      <div className="reviewer-rating">
                        <div className="star-rate">
                          <span className="ratings">
                            {[...Array(review.review)].map((_, i) => (
                              <i key={i} className="fa fa-star filled"></i>
                            ))}
                          </span>
                          <span className="rating-count">{review.review}</span>
                        </div>
                        <p>{timeSince(review?.created_at)}</p>
                      </div>
                    </div>
                  </div>

                  <div className="review-content">
                    <p>{review.comment}</p>
                  </div>
                </div>
{/* 
                <div className="table-action">
                  <a href="javascript:void(0);">
                    <i className="fa fa-trash-alt"></i>
                  </a>
                </div> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MyReview;
