import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../../../app/feature/API/CMS";

const CtaReports = () => {
  const { t } = useTranslation();
  const { data: aboutData, isLoading } = useGetHomeSectionsQuery('knowledgebase');
  const knowledgebaseSection = aboutData?.find(cta => cta.slug === 'knowledgebase_1');
  if(knowledgebaseSection?.show_sec !==1 ){
    return null;
  }
  return (
    <section
      className="cta-wrapper cta-theme-bg"
      style={{
        marginTop: "120px",
      }}
    >
      <div className="container">
        <div className="row align-items-center" data-aos="fade-up">
          <div className="col-md-8 col-xl-7 offset-xl-1 col-12">
            <div className="cta-text align-items-center d-md-flex text-center text-md-start">
              <i className={`${knowledgebaseSection?.icon || 'flaticon-email'} ml-10`}></i>
              <h2>{knowledgebaseSection?.title}</h2>
            </div>
          </div>
          <div className="col-md-4 text-center text-md-end">
            <Link
              to={'/contact'}
              className="theme-btn border-btn text-center border-raduis-get"
            >
              {knowledgebaseSection?.btn_text}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaReports;
