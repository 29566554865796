import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useGetServicesQuery } from "../../../app/feature/API/Services";
import ReactPaginate from 'react-paginate';
import img from "../../../assets/img/exporter_message.png";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useFetchGeneralSettingQuery } from "../../../app/feature/API/Helpers";

const EgyptianExporters = () => {
  const { t ,i18n} = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: ServicesData } = useGetServicesQuery({ user_type: 'exporter', page: currentPage});
  const services = ServicesData?.data || [];

  const pageCount =ServicesData?.meta?.last_page

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mb-80" data-aos="fade-up">
          <div className="col-xl-12">
          {GeneralSetting?.map((title, idx) => (
                  <div className="block-contents  text-center" key={idx}>
                    <div className="section-title pb-20">
                      <h5>{title?.service_sec_1_header}</h5>
                      <span>{title?.service_sec_1_header}</span>
                      <h2>{title?.service_sec_1_title}</h2>
                    </div>
                    <p className="desc-exporter">
                      {title?.service_sec_1_desc}
                    </p>
                  </div>
                ))}
          </div>
        </div>
        <div className="row" data-aos="fade-out">
        {services.length > 0 ? (
            services.map((data) => (
            <div className="col-xl-4 col-md-6 col-12" key={data?.id}>
              <div className="single-service-card">
                <div
                  className="service-cover-img bg-cover"
                  style={{ backgroundImage: `url(${data?.main_image})` }}
                ></div>
                <div className="content">
                <div className="icon-title" style={{marginRight:i18n.language==='ar'&&"-15px"}}>
                <div className="icon" style={{marginRight:i18n.language==='ar'&&'0px',marginLeft:i18n.language==='ar'&&'20px'}}>
                  <i className={data?.icon}></i>
                  </div>
                    <div className="service-title">
                    <h4><p>{data?.title}</p></h4>
                    </div>
                  </div>
                  <p className="desc-exp-s" dangerouslySetInnerHTML={{ __html: `<ul>${data?.summary?.replace(/&nbsp;/g, "")
                    .replace(/(?:•)\s?(.*?)(<br>|$)/g, "<li>$1</li>")}</ul>` }}></p>             </div>
              </div>
            </div>
          ))          ) : (
            <h2 className="text-center">{t('No_Data')}</h2>
          )}
        </div>
        {pageCount > 1 && services.length > 0 &&(
        <div className="row" style={{ marginTop: '40px' }}>
          <div className="col-12">
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              previousLabel={i18n.language === "en" ? <GrPrevious /> : <GrNext />}
              nextLabel={i18n.language === "en" ? <GrNext /> : <GrPrevious />}
            />
          </div>
        </div>
        )}
      </div>
    </section>
  );
};

export default EgyptianExporters;
