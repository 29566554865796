import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetHomeSectionsQuery,
  useGetLogoRotateSectionQuery,
} from "../../app/feature/API/CMS";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const About = () => {
  const {t, i18n} = useTranslation();
  const { data: aboutData, isLoading } = useGetHomeSectionsQuery("aboutus");
  const { data: aboutData2, isLoading2 } = useGetHomeSectionsQuery("home");
  const aboutSection = aboutData?.find((cta) => cta.slug === "about_1");
  const aboutSection2 = aboutData2?.find(
    (cta) => cta.slug === "home_vision_mission"
  );
  const { data: logoData, isLoading: logosLoading } = useGetLogoRotateSectionQuery();

  const logos = logoData || [];

  if (isLoading || isLoading2 || logosLoading) {
    return <p>{t("loading")}</p>;
  }

  if(aboutSection.show_sec !==1 ){
    return null;
  }
  return (
    <section className="about-section section-padding bg-gray center-column">
      <div className="container">
        <div className="row mb-10">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title">
                <h5 style={{ width: "74%" }}>{aboutSection?.sec_title}</h5>
                <span style={{fontSize:i18n.language==='ar'&&"40px"}}>{aboutSection?.sec_title}</span>
                <h2>{aboutSection?.sec_header}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="logos-slider mb-60">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
            }}
          >
            {logos.map((logo, index) => (
              <SwiperSlide key={index}>
                <div className="logo-slide">
                  <img
                    src={logo.image}
                    alt={logo.alt || `Logo ${index + 1}`}
                    className="logo-image"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      margin: "0 auto",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="row mb-60" data-aos="fade-out">
          <div className="col-xl-6 col-12 pe-xl-0">
            <div
              className="about-cover-bg bg-cover me-xl-5"
              style={{
                backgroundImage: `url(${aboutSection?.image})`,
              }}
            >
              <div className="our-experience-years">
                <div className="wrapp-words-about-us" style={{width:'180px',height:'143px',display:"flex",flexDirection:'column-reverse',gap:'10px',justifyContent:'center',paddingTop:'16px'}}>
                  <div className="year-outline">
                    <h2 style={{fontSize:'70px'}}>{aboutSection?.exp?.match(/\d+/)?.[0]}</h2>
                  </div>
                  <p style={{fontSize:i18n.language==='en'?'35px':'30px'}}>
                    {/* {i18n.language === "en" ? "Years Experience" : "سنوات الخبرة"} */}
                    {aboutSection?.exp?.replace(/\d+/g, "")}
                  </p> 
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mt-5 mt-lg-0 col-12">
            <div className="block-contents mt-non-mobile" >
              <div className="section-title">
                <p className="txt-reports about-txt line-height mb-20 ">
                  <span className="third-color" >{aboutSection?.title}</span>
                </p>
              </div>
              <p
                className="desc-app color-desc"
                dangerouslySetInnerHTML={{ __html: aboutSection?.dec }}
              ></p>
            </div>
          </div>
        </div>
        <div className="row mb-60" data-aos="fade-out">
          {aboutSection2?.list.map((list, idx) => (
            <div className="col-lg-4 col-md-6 col-12" key={idx}>
              <div className="single-pricing-plan bg-white">
                <div className="package-head">
                  <div className="icon">
                    <i
                      className={`${list?.icon}`}
                      style={{ color: "rgb(106, 95, 169)", fontSize: "80px" }}
                    ></i>
                  </div>
                </div>
                <p className="txt-reports size-about-box">
                  <span className="third-color">{list?.title}</span>
                </p>
                <p className="mt-40 desc-app">{list?.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <div>
          <a
            href={aboutSection2?.btn_url || '/'}
            className="theme-btn me-sm-4 border-raduis wow fadeInLeft btn-explore mt-0 learn-more bg-Subscripe align-items-center"
            data-wow-duration="1.2s"
            data-wow-delay=".8s"
          >
            {aboutSection2?.btn_text || "Learn More"}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
