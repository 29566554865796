import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { KnoweldgeBbaseData } from "../../../assets/data/KnowledgeBaseData";
import { GrArticle } from "react-icons/gr";
import { HashLink } from "react-router-hash-link";
import { useGetArticlesQuery } from "../../../app/feature/API/Articles";
import { useTranslation } from "react-i18next";


const shuffleArray = (array) => {
  const arrayCopy = [...array];
  return arrayCopy.sort(() => Math.random() - 0.5);
};

const SideBarArticles = () => {
  const [activeExample, setActiveExample] = useState(null);
  const { data: PopularArticles, error: PopularArticlesError, isLoading: PopularArticlesLoading } = 
  useGetArticlesQuery('');

  const shuffledArticles = PopularArticles ? shuffleArray(PopularArticles) : [];
  const handleExampleClick = (exampleId) => {
    setActiveExample(exampleId);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sidebar = document.querySelector(".main-sidebar");

      if (scrollPosition > 50) {
        sidebar.classList.add("sticky-sidebar");
      } else {
        sidebar.classList.remove("sticky-sidebar");
      }

      const sections = document.querySelectorAll(".section-article > div");
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActiveExample(`#${section.id}`);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [t,i18n] = useTranslation()

  return (
    <div className="col-12 col-lg-4">
      <div className="main-sidebar">
        {/* <div className="recenttopic">
          <h2 className="topic-color">In this article</h2>
          <ul>
            <li>
              <HashLink
                smooth
                to="#ex1"
                className={`topic-color list-topic ${
                  activeExample === "#ex1" ? "active-article" : ""
                }`}
                onClick={() => handleExampleClick("#ex1")}
              >
                1. Example 1
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="#ex2"
                className={`topic-color list-topic ${
                  activeExample === "#ex2" ? "active-article" : ""
                }`}
                onClick={() => handleExampleClick("#ex2")}
              >
                2. Example 2
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="#ex3"
                className={`topic-color list-topic ${
                  activeExample === "#ex3" ? "active-article" : ""
                }`}
                onClick={() => handleExampleClick("#ex3")}
              >
                3. Example 3
              </HashLink>
            </li>
          </ul>
        </div> */}
        <div className="recenttopic">
          <h2 className="topic-color">{`${i18n.language==='en'?"Popular Articles":"  مقالات عامه"}`}</h2>
          <ul>
            {PopularArticlesLoading && <p>Loading...</p>}
            {PopularArticlesError && <p>Error: {PopularArticlesError.message}</p>}
            {!PopularArticlesLoading && !PopularArticlesError && !shuffledArticles.length && <p>{t("No_Data")}</p>}
            {shuffledArticles.slice(0, 4).map((data) => (
              <li key={data.id}>
                <Link to={`/articles/${data?.slug}`} className="topic-color list-topic">
                  <GrArticle /> {data?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBarArticles;
