import React, { useState, useEffect, useRef } from "react";
import img1 from "../../assets/img/services/amrica.svg";
import img2 from "../../assets/img/services/egypt.avif";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";
import { IoCartOutline } from "react-icons/io5";
import { useGetProfileQuery } from "../../app/feature/API/Profile";
import { useLogoutUserMutation } from "../../app/feature/API/Logout";
import { toast } from "react-toastify";

const TopHeader = () => {
  const { data: GeneralSetting } = useFetchGeneralSettingQuery();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { data: ProfileData } = useGetProfileQuery();
  const [logoutUser] = useLogoutUserMutation();
  const [offset, setOffset] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);
  const profileMenuRef = useRef(null);
  const location = useLocation();
  const { t ,i18n} = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");
    setIsLoggedIn(!!token);
    setMobileMenu(true);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(e.target)) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleLogout = async () => {
    try {
      await logoutUser().unwrap();
      toast.success(t('Logged out Successfully'))
      localStorage.removeItem("token_Front_Mied");
      localStorage.removeItem("datePosted");
      localStorage.removeItem("employmentType");
      localStorage.removeItem("selectedTagId");
      localStorage.removeItem("workExperience");
      localStorage.removeItem("searchValue");
      setIsLoggedIn(false);
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out", error);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");
    setIsLoggedIn(!!token);
  }, []);

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };


  const [cartCount, setCartCount] = useState(0);
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartCount(cartItems.length);

    const intervalId = setInterval(() => {
      const updatedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      setCartCount(updatedCartItems.length);
    }, 1000);

    return () => {
      clearInterval(intervalId); 
    };
  }, []);
  const baseColor = GeneralSetting?.[0]?.base_color;
  const backgroundColor = baseColor?.startsWith("#") ? baseColor : `#${baseColor}`;  
  return (
    <div className="footer-bottom top-header" 
    style={{backgroundColor,direction:i18n.language==="en"?"ltr":"rtl"}}>
      <div className={`flex-center-end mr-40 mr-special ${i18n.language==='en'?"":"top-header-p"}`} style={{width:"100%"}}>
        {isLoggedIn&&(
          <>
              <Link to={'/cart'} className="my-profile" style={{position:'relative',fontSize:'26px'}}>
                <span style={{position:'absolute',top:'-5px',right:'-10px',width:'20px',
                  height:'20px',borderRadius:'20px',display:'flex',justifyContent:'center',
                  alignItems:'center',backgroundColor:'#ff5e14',fontSize:'21px'}}>{cartCount}</span>
                <IoCartOutline />
              </Link>
              <span className="line-between"></span>
          </>
        )}
          {isLoggedIn&&(
            <>
            <Link className="wrap-myprofile" to={`${isLoggedIn ? '/user-dashboard/my-profile' : '/login'}`}>
              <p className="my-profile">
                <CgProfile />
              </p>
              <p className="txt-profile">
                {t('topheader', { returnObjects: true }).map(account => account.Account)}
              </p>
            </Link>
            <span className="line-between"></span>
            </>
          )}
        {GeneralSetting?.map((e)=>e.lang_switcher_ctrl===1&&(
          <>
            <div className="lang-menu">
              <div className="selected-lang">
                {i18n.language === "en" ? (
                  <img src={img1} alt="" />
                ) : (
                  <img src={img2} alt="" />
                )}
              </div>
              <ul>
                <li onClick={() => handleChangeLanguage('en')}>
                  <p className="en"></p>
                </li>
                <li onClick={() => handleChangeLanguage('ar')}>
                  <p className="ar"></p>
                </li>
              </ul>
            </div>
            <span className="line-between none-top-header"></span>
          </>
        ))}

        <p className="none-top-header" style={{direction:'ltr'}}>
          <i className="fas fa-paper-plane"></i>{" "}
          {GeneralSetting?.map((email, idx) => (
            <a href={`mailto:${email.support_email}`} className="ml-10" style={{ color: "white", fontWeight: 100 }} key={idx}>
              {email.support_email}
            </a>
          ))}
        </p>

        <span className="line-between none-top-header"></span>

        <p className="none-top-header">
          <i className="flaticon-phone-call-1 pt-1"></i>{" "}
          <span className="ml-10">
            {GeneralSetting?.map((phone, idx) => (
              <a href={`tel:${phone.support_phone}`} className="ml-10" style={{ color: "white", fontWeight: 100 }} key={idx}>
                {phone.support_phone}
              </a>
            ))}
          </span>
        </p>
        <span className="line-between"></span>
        <div className="header-right-elements d-flex align-items-center justify-content-between">
            {isLoggedIn && ProfileData ? (
              <div
                style={{ position: "relative", display: "inline-block" }}
                ref={profileMenuRef}
              >
                <img
                  src={ProfileData?.photo || "https://via.placeholder.com/40"}
                  alt="avatar"
                  style={{ borderRadius: "50%", cursor: "pointer",width:'40px' 
                    ,height:'40px' ,objectPosition: 'center' ,objectFit:'cover'}}
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                />
                {showProfileMenu && (
                  <ul
                    style={{
                      position: "absolute",
                      right: i18n.language==='en'?0:-125,
                      top: "100%",
                      backgroundColor: "#fff",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      listStyle: "none",
                      padding: "10px",
                      margin: 0,
                      width: "170px",
                      borderRadius: "10px",
                      zIndex: "555555",
                    }}
                  >
                    <li style={{ padding: "5px 0" }}>
                      {ProfileData?.first_name} {" "} {ProfileData?.last_name}
                    </li>
                    <li style={{ padding: "5px 0" }}>
                      <Link style={{color:'#000',fontWeight:'100'}} to="/user-dashboard/my-profile"
                       onClick={()=>setShowProfileMenu(false)}>{t("Profile")}</Link>
                    </li>
                    <li
                      style={{ padding: "5px 0", cursor: "pointer" }}
                      onClick={handleLogout}
                    >
                      {t("Logout_title")}
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              GeneralSetting?.map((e, index) =>
                e?.login_register_ctrl === 1 ? (
                  <Link to="/login" className="theme-btn" key={index} style={{padding:'10px 25px'}}>
                    {i18n.language === "en" ? "Login / Register" : "تسجيل الدخول / التسجيل"}
                  </Link>
                ) : ""
              )
            )}

          </div>
      </div>
    </div>
  );
};

export default TopHeader;
