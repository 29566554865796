import React from "react";
import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../../../app/feature/API/CMS";

const GetStartedReport = () => {
  const { t, i18n } = useTranslation();
  const { data: CtaData, isLoading } = useGetHomeSectionsQuery('reports');

  const reportsSection = CtaData?.find(cta => cta.slug === 'how_to_create');

  if (isLoading) {
    return <p>{t('loading')}</p>; 
  }
  return (
    <section className="cta-nine go-top mt-80 mb-100" id="create_account" style={{marginTop:'100px'}}>
      <div className="container-fluid">
        <div className="sectiom- mb-50" data-aos="fade-in">
          <h2>{reportsSection?.title}</h2>
          <p className="desc-app" style={{fontSize:i18n.language==='ar'&&"30px"}}>
            {reportsSection?.sub}
          </p>
        </div>
        <div className="row">
          {reportsSection?.list?.map((list, index) =>(
            <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="1000" key={index}>
                <div className="cta-nine__single">
                  <div className="cta-nine__icon">
                    <i className={list?.icon}/>
                    <h3>{list?.title}</h3>
                    <p style={{fontSize:i18n.language==='ar'&&"20px"}}>{list?.desc}</p>
                  </div>
                </div>
              </div>
          ))}
          {/* <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="1000">
            <div className="cta-nine__single">
              <div className="cta-nine__icon">
                <i className="fal fa-user-alt" />
                <h3>{t('step1Title')}</h3>
                <p>{t('step1Description')}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div className="cta-nine__single">
              <div className="cta-nine__icon">
                <i className="fal fa-cloud-download" />
                <h3>{t('step2Title')}</h3>
                <p>{t('step2Description')}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="5000">
            <div className="cta-nine__single">
              <div className="cta-nine__icon">
                <i className="fal fa-chart-line" />
                <h3>{t('step3Title')}</h3>
                <p>{t('step3Description')}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-duration="7000">
            <div className="cta-nine__single">
              <div className="cta-nine__icon">
                <i className="fal fa-gift-card" />
                <h3>{t('step4Title')}</h3>
                <p>{t('step4Description')}</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default GetStartedReport;
