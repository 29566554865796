import { useEffect, useState } from "react";
import ScrollTopBtn from "../components/ScrollTopBtn";
import Preloader from "../components/PreLoader";
import HeaderOne from "../components/Header/Header";
import FooterFour from "../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import TopHeader from "../components/Header/TopHeader";
import WhatsAppIcon from "../components/WhatsAppIcon";
import { useFetchExtraSettingQuery } from "../app/feature/API/Helpers";
import PopupModal from "../PopupModal";

const AppLayout = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const { data, isLoading } = useFetchExtraSettingQuery();
  const [popupData, setPopupData] = useState(null);
  
  useEffect(() => {
    if (!isLoading && data && data.popups) {
      const popups = data.popups;
      
      if (popups.length > 1) {
        const randomIndex = Math.floor(Math.random() * popups.length);
        setPopupData(popups[randomIndex]);
      } else {
        setPopupData(popups[0]);
      }
      
      setShowPopup(true);
    }
  }, [data, isLoading]);
  

  let timeoutId;

  const resetTimeout = () => {
    const token = localStorage.getItem("token_Front_Mied");
    
    if (!token) {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      localStorage.removeItem("token_Front_Mied");
      window.location.reload();
    }, 540000);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    resetTimeout();

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.clear();
  //   }, 1000);
  
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  
  if (loading) {
    return <Preloader />;
  } else {
    return (
      <>
        <TopHeader />
        <HeaderOne />
        <ScrollTopBtn />
        <WhatsAppIcon />
        <Outlet />
        <FooterFour />
        <PopupModal
          show={showPopup}
          handleClose={() => setShowPopup(false)}
          popupData={popupData}
        />
      </>
    );
  }
};

export default AppLayout;
