import { useState } from "react";
import { useTranslation } from 'react-i18next'; // استيراد هذا الخط لاستخدام مكتبة react-i18next
import calendar from "../../assets/img/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import gigs from '../../assets/img/services/gigs-03.jpg'
import user from '../../assets/img/services/user-01.jpg'

const Payments = () => {
  const [filterData, setFilterData] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const { t } = useTranslation(); 

  const handleFilterData = () => {
    setFilterData(!filterData);
  };

  return (
    <>
      <div className="dashboard-header">
        <div className="main-title">
          <h3>{t('payments2.title')}</h3>
          <div id="tableinfo"></div>
        </div>
        <div className="head-info">
          <p>
            {t('payments2.totalPayments')} <span>(17)</span>
          </p>
        </div>
      </div>
      <div className="table-filter">
        <ul className="filter-item">
          <li>
            <p>{t('payments2.filter')}</p>
          </li>
          <li>
            <div
              className="form-sort form-wrap date-time-picker-wrap"
              onClick={handleFilterData}
            >
              <span className="form-icon">
                <img src={calendar} alt="icon" />
              </span>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText={t('payments2.fromDate')}
              />
            </div>
          </li>
          <li>
            <div className="form-sort form-wrap date-time-picker-wrap">
              <span className="form-icon">
                <img src={calendar} alt="icon" />
              </span>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText={t('payments2.toDate')}
              />
            </div>
          </li>
        </ul>
        <div id="tablefilter">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <label>
              <i className="fas fa-search"></i>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t('payments2.search')}
                aria-controls="DataTables_Table_0"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="table-responsive custom-table">
        <table className="table table-stripe datatable">
          <thead className="thead-light">
            <tr>
              <th>{t('payments2.id')}</th>
              <th>{t('payments2.report')}</th>
              <th>{t('payments2.dateTime')}</th>
              <th>{t('payments2.amount')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <td colSpan={5} className="text-center">
              <a >{t('No_Data')}</a>
          </td>
            {/* <tr>
              <td>#157</td>
              <td>
                <h2 className="table-avatar d-flex align-items-center">
                  <a href="javascript:void(0);" className="avatar">
                    <img
                      src={gigs}
                      className="rounded"
                      alt="User Image"
                    />
                  </a>
                  <a href="javascript:void(0);" class="text-dark">
                    {t('payments.formTitle')}
                  </a>
                </h2>
              </td>
              <td>22 مايو 2023 10:50 مساءً</td>
              <td>$1454</td>
              <td>
                <div class="table-action">
                  <a href="javascript:void(0);">
                    <i class="fa fa-eye"></i>
                  </a>
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      {/* <div className="table-footer">
        <div id="tablepage">
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="DataTables_Table_0_paginate"
          >
            <ul class="pagination">
              <li
                class="paginate_button page-item previous disabled"
                id="DataTables_Table_0_previous"
              >
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="0"
                  tabindex="0"
                  class="page-link"
                >
                  <i class="fa fa-angle-left"></i>{" "}
                </a>
              </li>
              <li class="paginate_button page-item active">
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="1"
                  tabindex="0"
                  class="page-link"
                >
                  1
                </a>
              </li>
              <li
                class="paginate_button page-item next"
                id="DataTables_Table_0_next"
              >
                <a
                  href="#"
                  aria-controls="DataTables_Table_0"
                  data-dt-idx="3"
                  tabindex="0"
                  class="page-link"
                >
                  {" "}
                  <i class=" fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>{" "}
      </div> */}
    </>
  );
};

export default Payments;
