import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { TbWorld } from "react-icons/tb";
import { GoDownload } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useGetTicketsQuery } from "../../app/feature/API/Tickets";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'; 

const TicketStatus = () => {
  const dropDownRef = useRef();
  const [dropDown3, setDropDown3] = useState(false);
  const [dropDownChoose3, setDropDownChoose3] = useState("");
  const handleDropDown3 = () => {
    setDropDown3(!dropDown3);
  };

  const dropDownRef2 = useRef();
  const [dropDown2, setDropDown2] = useState(false);
  const [dropDownChoose2, setDropDownChoose2] = useState("");
  const handleDropDown2 = () => {
    setDropDown2(!dropDown2);
  };

  const { t } = useTranslation();
  const { data: ticketsStatus, error, isLoading } = useGetTicketsQuery({ status: dropDownChoose2, sort: dropDownChoose3 });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropDownRef.current.contains(event.target)) {
        setDropDown3(false);
      }
      if (!dropDownRef2.current.contains(event.target)) {
        setDropDown2(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleExport = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Ticket_Status", 14, 20);

    const data = ticketsStatus.map(ticket => [
      ticket.ticket_number,
      ticket.subject,
      new Date(ticket.created_at).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      ticket.status,
    ]);

    autoTable(doc, {
      head: [['Id', 'Subject', 'Created At', 'Status']],
      body: data,
      theme: 'grid',
      styles: { 
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240],
      },
    });

    doc.save("Ticket_Status.pdf"); 
  };
  if (error) return <div>Error loading tickets: {error.message}</div>;

  return (
    <div className="page-content content mt-100 mb-50">
      <div className="container">
        <div className="page-back-btn">
          <Link to="/user-dashboard">
            <i className="fas fa-arrow-left me-2"></i>{t('ticket_status.back_to_dashboard')}
          </Link>
        </div>
        <div className="ticket-status">
          <div className="left-ticket-status">
            {isLoading&&(
               <div>   
               {t("loading")} 
               <i className="fa fa-spinner fa-spin" /> 
               </div>
            )}
            {ticketsStatus?.length > 0 ? (
              ticketsStatus.map(({ ticket_number, subject, created_at, status }) => (
                <div className="item-left-ticket-status" key={ticket_number}>
                  <Link
                    to={`/user-dashboard/ticket-details/${ticket_number}`}
                    className="icon-text-status"
                  >
                    <div className="icon">
                      <TbWorld />
                    </div>
                    <div className="text-left-ticket-status">
                      <p className="txt-one">{subject} #{ticket_number}</p>
                      <p className="date-ticket">
                        Created on {new Date(created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })} - via MIED Website
                      </p>
                    </div>
                  </Link>
                  <p className="status-ticket-text">{status}</p>
                </div>
              ))
            ) : (
              <div className="no-tickets-message">
                {t('No_Data')}
              </div>
            )}
          </div>
          <div className="right-ticket-status">
            <div className="wrap-right-ticket-status">
              <button className="down-ticket" onClick={handleExport}>
                <GoDownload /> {t('ticket_status.export_tickets')}
              </button>
            </div>
            <div className="form-wrap">
              <label>{t('ticket_status.status')}</label>
              <div className="drop-down-con" ref={dropDownRef2}>
                <div className="drop-down" onClick={handleDropDown2} aria-expanded={dropDown2} role="button" tabIndex={0}>
                  <p>{dropDownChoose2 ? dropDownChoose2 : t('ticket_status.resolved_or_closed')}</p>
                  <p className="search-btn">
                    <i className="fas fa-chevron-down"></i>
                  </p>
                </div>
                <div className={`drop ${dropDown2 ? "" : "d-none"}`}>
                  <p onClick={() => setDropDownChoose2('All')}>{t('ticket_status.all')}</p>
                  <p onClick={() => setDropDownChoose2('open')}>{t('ticket_status.open')}</p>
                  <p onClick={() => setDropDownChoose2('pending')}>{t('ticket_status.pending')}</p>
                  <p onClick={() => setDropDownChoose2('closed')}>{t('ticket_status.closed')}</p>
                  <p onClick={() => setDropDownChoose2('resolved')}>{t('ticket_status.resolved')}</p>
                </div>
              </div>
            </div>
            <div className="form-wrap">
              <label>{t('ticket_status.sort_by')}</label>
              <div className="drop-down-con" ref={dropDownRef}>
                <div className="drop-down" onClick={handleDropDown3} aria-expanded={dropDown3} role="button" tabIndex={0}>
                  <p>{dropDownChoose3 ? dropDownChoose3 : t('ticket_status.date_created')}</p>
                  <p className="search-btn">
                    <i className="fas fa-chevron-down"></i>
                  </p>
                </div>
                <div className={`drop ${dropDown3 ? "" : "d-none"}`}>
                  <p onClick={() => setDropDownChoose3('date')}>{t('ticket_status.date_created')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketStatus;
