/* eslint-disable jsx-a11y/alt-text */
import { Row, Col, Card, CardImg, CardBody } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";


import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import MailChimp from "../../assets/images/logo/mailchimp.svg";
import WordPress from "../../assets/img/services/personImage.jpg";
import Instagram from "../../assets/images/logo/Instagram.svg";
import { useTranslation } from "react-i18next";
import { useGetFeedbackQuery } from "../../app/feature/API/Feedbacks";
import { useFetchGeneralSettingQuery } from "../../app/feature/API/Helpers";

const Candidates = () => {
      const {i18n} = useTranslation()
      const {data:FeedbackData,isLoading:CandidatesLoading} = useGetFeedbackQuery();
      const { data } = useFetchGeneralSettingQuery();
      const showSec = data?.map((e)=>e?.testimonial_page_show)
      if(Number(showSec)!==1){
        return null;
      }
    return (
      <section className="our-service-wrapper section-padding bg-white">
        <div className="container"  data-aos="fade-up">
          <div className="row mb-80">
            <div className="col-xl-12">
              <div className="block-contents text-center">
                <div className="section-title pb-20">
                  <h2>{i18n.language==='en'?"What Clients Say About Our Reports":"ماذا يقول العملاء عن تقاريرنا"}</h2>
                </div>
              </div>
            </div>
          </div>
          {CandidatesLoading&&(
   <div className="spinner-overlay">
   <div className="spinner"></div>
 </div>
          )}
          <Row className="justify-content-center">
            <Col lg={10}>
              <Swiper
                className="pb-5"
                loop={true}
                modules={[Autoplay, Pagination]}
                slidesPerView={1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={{ clickable: true }}
              >
                <div className="swiper-wrapper">
                  {FeedbackData?.map((testimonalDetails, key) => (
                    <SwiperSlide key={key}>
                      <Card className="testi-box border-none">
                        <CardBody>
                          <div className="mb-4">
                            <img
                              src={testimonalDetails?.image || WordPress}
                              alt={testimonalDetails.name}
                              style={{width: '90px', height:'90px',borderRadius:'100px'
                                ,objectFit:'cover',objectPosition:'center'}}
                            />
                          </div>
                          <p className="testi-content lead text-muted mb-4">
                            {testimonalDetails?.feedback}
                          </p>
                          <h5 className="mb-0 color-services">{testimonalDetails.name}</h5>
                          <p className="text-muted mb-0">
                            {testimonalDetails?.subject}
                          </p>
                        </CardBody>
                      </Card>
                    </SwiperSlide>
                  ))}
                </div>
                <div className="swiper-pagination"></div>
              </Swiper>
            </Col>
          </Row>
        </div>
      </section>
    )
}

export default Candidates
