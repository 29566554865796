import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetHomeSectionsQuery } from "../app/feature/API/CMS";

const CtaOne = () => {
  const {t} = useTranslation();
  const { data: CtaData, isLoading } = useGetHomeSectionsQuery('home'); 
  const ctaSection = CtaData?.find((cta) => cta.slug === "home_sec_1");

  if (isLoading) {
    return (
      <section className="promo-banner-cta section-padding bg-cover">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>{t('loading')}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
  if(ctaSection.show_sec !==1 ){
    return null;
  }
  return (
    <>
      {CtaData?.map((cta) => cta.slug === 'home_sec_1' && (
        <section
          key={cta.slug}
          className="promo-banner-cta section-padding bg-cover"
          style={{ backgroundImage: `url(${cta?.image})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center center-cta" data-aos="fade-up">
                <a
                  href={cta?.btn_url || '/'}
                  className="theme-btn me-sm-4 wow fadeInLeft btn-explore mt-20 cta-page mb-20 border-raduis cta-non-hover"
                  data-wow-duration="1.2s"
                  data-wow-delay=".8s"
                >
                  {cta?.btn_text || 'btn'}
                </a>
                <h2 className="text-white">
                  {cta?.title}
                </h2>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default CtaOne;
