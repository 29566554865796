import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageBanner from "../../Shared/PageBanner";
import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token_Front_Mied");

    if (!token) {
      navigate("/");
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [navigate]);
  
  let title = "";
  switch (pathname) {
    case "/user-dashboard/my-reports":
      title = t("dashboardLink");
      break;
    case "/user-dashboard/my-profile":
      title = t("myProfileLink");
      break;
    case "/user-dashboard/my-reports":
      title = t("myReportsLink");
      break;
    case "/user-dashboard/my-wishlist":
      title = t("myWishlistLink");
      break;
    case "/user-dashboard/payments":
      title = t("paymentsLink");
      break;
    case "/user-dashboard/my-reviews":
      title = t("myReviewLink");
      break;
    case "/user-dashboard/new-ticket":
      title = t("newTicketLink");
      break;
    case "/user-dashboard/ticket-support":
      title = t("ticketSupportLink");
      break;
    case "/user-dashboard/ticket-status":
      title = t("ticketStatusLink");
      break;
    case "/user-dashboard/password_and_security/change-password":
        title = t("Change Password");
      break;
    case "/user-dashboard/ticket-details":
      title = t("ticketDetailsLink");
      break;
    default:
      title = t("dashboardLink");
  }
  return (
    <>
      <PageBanner currentPage={title} title={title} />
      <Outlet />
    </>
  );
};

export default Index;
