import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import JobDetailImage from "../../assets/img/services/job-detail.jpg";
import JobImage10 from "../../assets/images/featured-job/img-10.png";
import { Link, useParams } from "react-router-dom";
import { FaFacebookF, FaGoogle, FaStar, FaUser } from "react-icons/fa6";
import { FaStarHalfStroke } from "react-icons/fa6";
import { SlSocialLinkedin } from "react-icons/sl";
import { FaRegCircle } from "react-icons/fa";
import { useGetJobsDetailsQuery } from "../../app/feature/API/Jobs";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { Helmet } from 'react-helmet-async';

const cleanHtmlContent = (html) => {
  if (!html) return [];
  const spans = html.match(/<span[^>]*>(.*?)<\/span>/g) || [];  
  const filteredSpans = spans.filter((_, index) => index % 2 === 0);
  return filteredSpans
    .map(item => item.replace(/<\/?[^>]+(>|$)/g, "").trim().replace(/\s+/g, ""));
};

const JobDetailsDescription = () => {
  const { t ,i18n} = useTranslation();
  const { name } = useParams();
  const { data, isLoading } = useGetJobsDetailsQuery(name);
  const responsibilities = cleanHtmlContent(data?.job?.job_responsibilities || "");
  const qualifications = cleanHtmlContent(data?.job?.educational_requirements || "");
  const experience = cleanHtmlContent(data?.job?.experience_requirements || "");
  const additional_requirements = cleanHtmlContent(data?.job?.additional_requirements || "");

  if (isLoading) {
    return (
      <div className="spinner-overlay">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Card className="job-detail overflow-hidden">
      <Helmet>
        <meta property="og:title" content={data?.job?.title || 'Job Title'} />
        <meta property="og:description" content={`Salary: ${data?.job?.salary}. ${data?.job?.benefits}`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://yourwebsite.com/job-image.jpg" />
      </Helmet>
      <div>
        <img src={data?.job?.image || JobDetailImage} alt="Job Detail" className="img-fluid" style={{width:"100%",height: "400px"}} />
        {/* <div className="job-details-compnay-profile">
          <img
            src={JobImage10}
            alt="Company Profile"
            className="img-fluid rounded-3 position-img-job-details-brand"
          />
        </div> */}
      </div>
      <CardBody className="p-4">
        <div>
          <Row>
            <Col md={8}>
              <h5 className="mb-1 title-job-details">{data?.job?.title}</h5>
              <ul className="list-inline text-muted mb-0">
                <li className="list-inline-item">
                  <FaUser /> {data?.job?.vacancy} {i18n.language==='en'?"Vacancy":"خالي"} 
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Row className="g-2">
            <Col lg={3}>
              <div className="border rounded-start p-3">
                <p className="text-muted mb-0 fs-13">{t('job.experience')}</p>
                <p className="text-qualified mb-0">{data?.job?.experience}</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="border p-3">
                <p className="text-muted fs-13 mb-0">{t('job.employeeType')}</p>
                <p className="text-qualified mb-0">{data?.job?.employment_status}</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="border p-3">
                <p className="text-muted fs-13 mb-0">{t('job.position')}</p>
                <p className="text-qualified mb-0">{data?.job?.position || '`'}</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="border rounded-end p-3">
                <p className="text-muted fs-13 mb-0">{t('job.offerSalary')}</p>
                <p className="text-qualified mb-0">{data?.job?.salary.replace(/<\/?[^>]+(>|$)/g, "")}</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <h5 className="mb-3 subtitle-job-details">{t('job.jobDescription')}</h5>
          <div className="job-detail-desc">
            <p className="text-muted mb-0" dangerouslySetInnerHTML={{__html:data?.job?.benefits}}>
              {/* {data?.job?.benefits.replace(/<\/?[^>]+(>|$)/g, "")} */}
            </p>
          </div>
        </div>

        <div className="mt-4">
          <h5 className="mb-3 subtitle-job-details">{t('job.responsibilities')}</h5>
          <div className="job-detail-desc mt-2">
            <ul className="job-detail-list list-unstyled mb-0 text-muted">
              {/* {responsibilities.map((resp, index) => (
                <li key={index}>
                  <FaRegCircle className="bollets-icon-job-details" /> {resp}
                </li>
              ))} */}
              <li dangerouslySetInnerHTML={{__html:data?.job?.job_responsibilities}}></li>
            </ul>
          </div>
        </div>

        <div className="mt-4">
          <h5 className="mb-3 subtitle-job-details">{t('job.qualification')}</h5>
          <div className="job-detail-desc mt-2">
            <ul className="job-detail-list list-unstyled mb-0 text-muted">
              {/* {qualifications.map((qual, index) => (
                <li key={index}>
                  <FaRegCircle className="bollets-icon-job-details" /> {qual}
                </li>
              ))} */}
              <li dangerouslySetInnerHTML={{__html:data?.job?.educational_requirements}}></li>
            </ul>
          </div>
        </div>

        <div className="mt-4">
          <h5 className="mb-3 subtitle-job-details">{t('job.skillExperience')}</h5>
          <div className="job-details-desc">
            <ul className="job-detail-list list-unstyled mb-0 text-muted">
              {/* {experience.map((exp, index) => (
                <li key={index}>
                  <FaRegCircle className="bollets-icon-job-details" /> {exp}
                </li>
              ))}
              {additional_requirements.map((req, index) => (
                <li key={index}>
                  <FaRegCircle className="bollets-icon-job-details" /> {req}
                </li>
              ))} */}
              <li dangerouslySetInnerHTML={{__html:data?.job?.experience_requirements}}></li>
              <li dangerouslySetInnerHTML={{__html:data?.job?.additional_requirements}}></li>
            </ul>
            <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
              {data?.job?.language_requirements?.length > 0 ? (
                data.job.language_requirements.map((e) => (
                  <span key={e} className="badge qualified-lang">{e}</span>
                ))
              ) : (
                ["PHP","JS", "Marketing", "REACT", "PHOTOSHOP"].map((item) => (
                  <span key={item} className="badge qualified-lang">{item}</span>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="mt-4 pt-3">
  <ul className="list-inline mb-0">
    <li className="list-inline-item mt-1">{t('job.shareJob')}:</li>

      <li className="list-inline-item mt-2">
      <a 
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(`${data?.job?.title}\n${t('job.salary')}: ${data?.job?.salary}\n${t('job.description')}: ${data?.job?.benefits}`)}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="btn-social-job btn-faceBook"
      >
        <FaFacebookF className="icon-social-job-details" /> {t('job.facebook')}
      </a>
    </li>

    <li className="list-inline-item mt-2 me-2 ms-2">
      <a 
        href={`mailto:?subject=${encodeURIComponent(data?.job?.title)}&body=${encodeURIComponent(`${t('job.salary')}: ${data?.job?.salary}\n${t('job.description')}: ${data?.job?.benefits}\n\n${window.location.href}`)}`} 
        className="btn-social-job btn-dangerr"
      >
        <FaGoogle className="icon-social-job-details" /> {t('job.google')}
      </a>
    </li>

    <li className="list-inline-item mt-2">
      <a 
        href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(data?.job?.title)}&summary=${encodeURIComponent(`${t('job.salary')}: ${data?.job?.salary}\n${t('job.description')}: ${data?.job?.benefits}`)}`} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="btn-social-job btn-successs"
      >
        <SlSocialLinkedin className="icon-social-job-details" /> {t('job.linkedin')}
      </a>
    </li>
  </ul>
</div>

      </CardBody>
    </Card>
  );
};

export default JobDetailsDescription;
