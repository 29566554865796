// jobsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobData: [],
    ServicesData: [],
  },
  reducers: {
    setJobData(state, action) {
      state.jobData = action.payload;
    },
    setServicesData(state, action) {
      state.jobData = action.payload;
    },
  },
});

export const { setJobData ,setServicesData} = jobsSlice.actions;
export default jobsSlice.reducer;
